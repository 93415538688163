<template>
    <span class="d-inline mr-10">
        <!-- {{ lifeTimeHoursMinutes }} -->
        <v-dialog v-model="warningTimeExpired" max-width="600">
            <FocusLock>
                <v-card>
                    <v-card-title class="headline font-weight-bold mb-2" v-text="$t('inactivity.modal.title')" />
                    <v-card-text>{{ $t('inactivity.modal.text') }} ({{ lifetime }})</v-card-text>
                </v-card>
            </FocusLock>
        </v-dialog>
    </span>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { NAME as NAME_SECURITY } from '@/store/modules/security';
const securityModul = namespace(NAME_SECURITY);
import {
    RESET_INACTIVITYWARNINGTIME,
    RESET_INACTIVITYLOGOUTTIME,
    UPDATE_INACTIVITYTIMES,
    INACTIVITYLOGOUT
} from '@/store/modules/security/actions';
import { INACTIVITYLOGOUTTIMEGetter, INACTIVITYWARNINGTIMEGetter } from '@/store/modules/security/getters';

//Die folgende Komponente prüft ob der User noch aktiv ist. Falls er über eine gewisse Zeit inaktiv ist, wird er einfach abgemeldet
@Component
export default class AutoLogout extends Vue {
    //Auf die folgenden events wird geprüft ob keine inaktivität vorliegt
    private events = ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'];

    private activityHappend = false;
    private timerInterval;
    private lifetimeFieldInterval;

    private warningTimeExpired = false;
    private logoutTimeExpired = false;
    private lifetime = '';

    @securityModul.Action(INACTIVITYLOGOUT) inactivityLogout!: Function;
    @securityModul.Action(RESET_INACTIVITYWARNINGTIME) resetInactivityWarningTime!: Function;
    @securityModul.Action(RESET_INACTIVITYLOGOUTTIME) resetInactivityLogoutTime!: Function;
    @securityModul.Action(UPDATE_INACTIVITYTIMES) updateInactivityTimes!: Function;
    @securityModul.Getter(INACTIVITYWARNINGTIMEGetter) inactivityWarningTime!: number;
    @securityModul.Getter(INACTIVITYLOGOUTTIMEGetter) inactivityLogoutTime!: number;

    mounted() {
        this.events.forEach(event => {
            window.addEventListener(event, this.resetTimers);
        });

        this.resetTimers();
        this.resetTimersInStore();
        this.timerInterval = setInterval(() => {
            this.resetTimersInStore();
        }, 10000);

        this.lifetimeFieldInterval = setInterval(() => {
            this.recalculateLifetime();
        }, 1000);
    }

    beforeDestroy() {
        this.events.forEach(event => {
            window.removeEventListener(event, this.resetTimers);
        });
        clearInterval(this.timerInterval);
        clearInterval(this.lifetimeFieldInterval);
    }

    //Den Timer der Inaktivität zurücksetzten
    resetTimers() {
        this.activityHappend = true;
    }

    resetTimersInStore() {
        if (this.activityHappend) {
            this.resetInactivityWarningTime();
            this.resetInactivityLogoutTime();
            this.activityHappend = false;
        }
    }

    recalculateLifetime() {
        this.updateInactivityTimes();
        this.warningTimeExpired = Date.now() > this.inactivityWarningTime;
        this.logoutTimeExpired = Date.now() > this.inactivityLogoutTime;

        if (this.inactivityLogoutTime) {
            const inactivityLogoutTimeInMs = this.inactivityLogoutTime - Date.now();

            const lifetimeInHours = Math.floor(inactivityLogoutTimeInMs / (1000 * 3600));
            const lifetimeInMinutes = Math.floor((inactivityLogoutTimeInMs / (1000 * 3600) - lifetimeInHours) * 60);
            const lifetimeInSeconds = Math.floor(
                ((inactivityLogoutTimeInMs / (1000 * 3600) - lifetimeInHours) * 60 - lifetimeInMinutes) * 60
            );
            this.lifetime = `${('00' + lifetimeInHours).slice(-2)}:${('00' + lifetimeInMinutes).slice(-2)}:${(
                '00' + lifetimeInSeconds
            ).slice(-2)}`;
        } else {
            this.lifetime = '';
        }
    }

    get lifeTimeHoursMinutes() {
        return this.lifetime.substring(0, 5);
    }

    @Watch('logoutTimeExpired')
    logoutTimeExpiredChanged(newVal) {
        if (newVal) {
            this.inactivityLogout();
        }
    }
}
</script>
