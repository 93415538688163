import { MutationTree } from 'vuex';
import { IHomeState } from './state';
import { HomeFilterDto } from '@/types/HomeFilterDto';
import { TvsBuchungApiDto, KontoSaldoApiDto } from '@/api-schema';

export const HOMEMESSAGE = 'homeMessage';
export const MAPTABLEVALUES = 'mapTable';
export const ADDTABLEVALUES = 'addTable';
export const MAPTABS = 'mapTabs';
export const APPLYFILTER = 'applyFilter';
export const CLEARFILTER = 'clearFilter';
export const MAPVERRECHNUNGEN = 'mapVerrechnungList';
export const MAPDAHRLENEN = 'mapDahrlehnenList';
export const TOTAL_NUMBER_OF_RECORDS = 'mapTotalNumberOfRecords';
export const OFFSET = 'mapOffset';
export const LOADING = 'mapLoading';

export const mutations: MutationTree<IHomeState> = {
    [MAPTABLEVALUES](state, value: Array<TvsBuchungApiDto>) {
        state.tableList = value;
    },
    [ADDTABLEVALUES](state, value: Array<TvsBuchungApiDto>) {
        state.tableList = state.tableList.concat(value);
    },
    [MAPTABS](state, value: Array<KontoSaldoApiDto>) {
        state.tabList = value;
    },
    [HOMEMESSAGE](state, value: string) {
        state.homeMessage = value;
    },
    [APPLYFILTER](state, value: HomeFilterDto) {
        state.queryFilter = value;
    },
    [CLEARFILTER](state) {
        state.queryFilter = new HomeFilterDto();
    },
    [TOTAL_NUMBER_OF_RECORDS](state, value: number) {
        state.totalNumberOfRecords = value;
    },
    [OFFSET](state, value: number) {
        state.queryFilter.offset = value;
    },
    [LOADING](state, value: boolean) {
        state.loading = value;
    }
};
