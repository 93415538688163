import { BenutzerInfoApiDto } from '@/api-schema';
import BaseService from './baseService';

export class SecurityService extends BaseService {
    private RESOURCE_URL: string;
    private SECURITY_URL: string;
    constructor() {
        super();
        this.RESOURCE_URL = `${this.BASE_URL}permissions`;
        this.SECURITY_URL = `${this.BASE_URL}benutzerinfo`;

        console.debug('SecurityService::constructor', this.RESOURCE_URL);
    }

    getAll(): Promise<Array<string>> {
        return new Promise(resolve => {
            this.GET<Array<string>>(this.RESOURCE_URL, this.getConfig).then(response => {
                if (response !== undefined) {
                    resolve(response);
                } else {
                    resolve(new Array<string>());
                }
            });
        });
    }
    async getBenutzerInfo(): Promise<BenutzerInfoApiDto> {
        return super.GET<BenutzerInfoApiDto>(`${this.SECURITY_URL}`).then(response => {
            return response;
        });
    }
    recordAgbAcceptedDate(): Promise<any> {
        return super.PUT<any>(`${this.BASE_URL}teilnehmer/agb`, 0);
    }
}

export const securityService = new SecurityService();
