import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IRechnungenState } from './state';

import { NAME } from './';

import { MAPRECHNUNGEN, MAPRECHNUNGDETAIL, LOADING, OFFSET, ADDRECHNUNGEN, TOTAL_NUMBER_OF_RECORDS } from './mutations';
import { NEW_ERRORMESSAGES, CLOSE } from '@/store/modules/headerNotification/actions';

import { rechnungService } from '../services/RechnungService';

import { downloadService } from '@/services/downloadService';
import i18n from '@/plugins/i18n';

export const GET_ALL_RECHNUNGEN = 'getLieferantenRechnungen';
export const GET_RECHNUNG_DETAILS = 'getRechnungsDetails';
export const DOWNLOAD_BELEG = 'downloadBelegPdf';

export const actions: ActionTree<IRechnungenState, IRootState> = {
    [GET_ALL_RECHNUNGEN]({ commit, state, dispatch }: any, offset: number) {
        console.debug(NAME + '::' + GET_ALL_RECHNUNGEN);

        if (state.loading || (offset !== 0 && offset >= state.totalNumberOfRecords)) {
            return;
        }

        if (state.queryFilter.rechnungDatumVon != undefined && state.queryFilter.rechnungDatumBis != undefined) {
            const dateFrom = new Date(
                state.queryFilter.rechnungDatumVon.substring(6, 10) as any,
                state.queryFilter.rechnungDatumVon.substring(3, 5) as any,
                state.queryFilter.rechnungDatumVon.substring(0, 2) as any
            );
            const dateTo = new Date(
                state.queryFilter.rechnungDatumBis.substring(6, 10) as any,
                state.queryFilter.rechnungDatumBis.substring(3, 5) as any,
                state.queryFilter.rechnungDatumBis.substring(0, 2) as any
            );
            dispatch('headerNotification/' + CLOSE, {}, { root: true });
            if (dateFrom > dateTo) {
                dispatch(
                    'headerNotification/' + NEW_ERRORMESSAGES,
                    { errorMessages: i18n.t('errormessage.impossible.date') },
                    { root: true }
                );
                return;
            }
        }

        if (state.queryFilter.betragBis != undefined && state.queryFilter.betragVon != undefined) {
            if ((state.queryFilter.betragBis as number) < (state.queryFilter.betragVon as number)) {
                dispatch(
                    'headerNotification/' + NEW_ERRORMESSAGES,
                    { errorMessages: i18n.t('errormessage.impossible.betrag') },
                    { root: true }
                );
                return;
            }
        }

        commit(LOADING, true);
        commit(OFFSET, offset);
        rechnungService.getAll(state.queryFilter).then(response => {
            if (!offset || offset < 1) {
                commit(TOTAL_NUMBER_OF_RECORDS, response.totalNumberOfRecords);
                commit(MAPRECHNUNGEN, response.entries);
            } else {
                commit(ADDRECHNUNGEN, response.entries);
            }
            commit(LOADING, false);
        });
    },
    [GET_RECHNUNG_DETAILS]({ commit, state }: any, xmlDokumentGuid: string) {
        console.debug(NAME + '::' + GET_RECHNUNG_DETAILS);
        state.rechnungenDetails = [];

        rechnungService.getDetails(xmlDokumentGuid).then(response => {
            commit(MAPRECHNUNGDETAIL, response);
        });
    },
    [DOWNLOAD_BELEG]({ commit }: any, { id, dokumentName }: any) {
        console.debug(NAME + '::' + DOWNLOAD_BELEG);

        rechnungService.downloadBeleg(id).then(response => {
            downloadService.downloadFile(response, dokumentName);
        });
    }
};
