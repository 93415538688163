import { ZeitspanneFilterApiDto } from '@/api-schema';

export class RechnungFilterDto {
    constructor() {
        this.suchText = '';
        this.offset = 0;
        this.entriesToLoad = 25;
        this.sortBy = 'RechnungDatum';
        this.sortOrder = 'Descending';
    }
    betragBis?: string;
    betragVon?: string;
    rechnungDatumBis?: string; // date-time
    rechnungDatumVon?: string; // date-time
    zeitspanne?: ZeitspanneFilterApiDto;
    suchText: string;
    offset: number;
    entriesToLoad?: number;
    sortBy?: string;
    sortOrder?: string;
}
