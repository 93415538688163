import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IArchivState } from './state';
import i18n from '@/plugins/i18n';

import {
    MAPDOKUMENTE,
    MAPDOKUMENTTYPES,
    MAPFILETYPES,
    LOADING,
    ADDDOKUMENTE,
    TOTAL_NUMBER_OF_RECORDS,
    OFFSET,
    MAPSCHLAGWORTVORSCHLAEGE,
    MAPERSTELLTVON
} from './mutations';
import { SHOW, HIDE } from '@/store/modules/loadingSpinner/actions';
import { NAME } from './';
import { NEW_ERRORMESSAGES, CLOSE, NEW_SUCESS } from '@/store/modules/headerNotification/actions';

import { downloadService } from '@/services/downloadService';

import { archivService } from '../services/ArchivService';
import { DokumentEditApiDto } from '@/api-schema';

export const GET_ALL_DOKUMENTE = 'getDokumente';
export const GET_ALL_DOKUMENTTYPES = 'getAllDokumentTypes';
export const GET_ALL_FILETYPES = 'getAllFileTypes';
export const GET_ALL_ERSTELLTVON = 'getErstelltVon';
export const DOWNLOAD_DOKUMENT = 'downloadDokument';
export const DOWNLOAD_MULTIPLE_DOKUMENTE = 'downloadMultipleDokumente';
export const DOWNLOAD_ALL_DOKUMENTE = 'downloadAllDokumente';
export const OPEN_DOKUMENT = 'openPdfDokument';
export const GET_DOKUMENT = 'getDokumentMetadata';
export const EDIT_DOKUMENT_INFO = 'editDokumentInfo';
export const DELETE_DOKUMENT = 'deleteDokument';
export const GET_SCHLAGWORTE = 'getSchlagworte';
export const CREATE_SCHLAGWORT = 'createSchlagwort';
export const DELETE_SCHLAGWORT = 'deleteSchlagwort';

export const actions: ActionTree<IArchivState, IRootState> = {
    [GET_ALL_DOKUMENTE]({ commit, state, dispatch }: any, offset: number) {
        console.debug(NAME + '::' + GET_ALL_DOKUMENTE, offset);

        if (state.loading || (offset !== 0 && offset >= state.totalNumberOfRecords)) {
            return;
        }

        if (state.queryFilter.dateFrom != undefined && state.queryFilter.dateTo != undefined) {
            const dateFrom = new Date(
                state.queryFilter.dateFrom.substring(6, 10) as any,
                state.queryFilter.dateFrom.substring(3, 5) as any,
                state.queryFilter.dateFrom.substring(0, 2) as any
            );
            const dateTo = new Date(
                state.queryFilter.dateTo.substring(6, 10) as any,
                state.queryFilter.dateTo.substring(3, 5) as any,
                state.queryFilter.dateTo.substring(0, 2) as any
            );
            dispatch('headerNotification/' + CLOSE, {}, { root: true });
            if (dateFrom > dateTo) {
                dispatch(
                    'headerNotification/' + NEW_ERRORMESSAGES,
                    { errorMessages: i18n.t('errormessage.impossible.date') },
                    { root: true }
                );
                return;
            }
        }

        commit(LOADING, true);
        commit(OFFSET, offset);

        const searchQuery = Object.assign({}, state.queryFilter);
        if (searchQuery.dokumentTyp === '') {
            searchQuery.dokumentTyp = null;
        }
        if (searchQuery.fileTyp === '') {
            searchQuery.fileTyp = null;
        }
        if (searchQuery.erstelltVon === '') {
            searchQuery.erstelltVon = null;
        }

        archivService.getAll(searchQuery).then(response => {
            if (!offset || offset < 1) {
                commit(TOTAL_NUMBER_OF_RECORDS, response.totalNumberOfRecords);
                commit(MAPDOKUMENTE, response.entries);
            } else {
                commit(ADDDOKUMENTE, response.entries);
            }
            commit(LOADING, false);
        });
    },
    async [GET_DOKUMENT]({ commit }: any, id: number) {
        console.debug(NAME + '::' + GET_DOKUMENT);

        return archivService.getDokument(id).then(response => {
            return response;
        });
    },
    [GET_ALL_DOKUMENTTYPES]({ commit }: any) {
        console.debug(NAME + '::' + GET_ALL_DOKUMENTTYPES);

        archivService.getDokumentTypes().then(response => {
            commit(MAPDOKUMENTTYPES, response);
        });
    },
    [GET_ALL_FILETYPES]({ commit }: any) {
        console.debug(NAME + '::' + GET_ALL_FILETYPES);

        archivService.getFileTypes().then(response => {
            commit(MAPFILETYPES, response);
        });
    },
    [GET_ALL_ERSTELLTVON]({ commit }: any) {
        console.debug(NAME + '::' + GET_ALL_ERSTELLTVON);

        archivService.getErstelltVon().then(response => {
            commit(MAPERSTELLTVON, response);
        });
    },
    [DOWNLOAD_DOKUMENT]({ commit }: any, { id, dokumentName }: any) {
        console.debug(NAME + '::' + DOWNLOAD_DOKUMENT);

        archivService.downloadDokument(id).then(response => {
            downloadService.downloadFile(response, 'placeholder');
        });
    },
    [DOWNLOAD_ALL_DOKUMENTE]({ state, dispatch }: any, totalNumberOfRecords: number) {
        console.debug(NAME + '::' + DOWNLOAD_ALL_DOKUMENTE);

        dispatch('loadingSpinner/' + SHOW, {}, { root: true });
        if (state.queryFilter.dateFrom != undefined && state.queryFilter.dateTo != undefined) {
            const dateFrom = new Date(
                state.queryFilter.dateFrom.substring(6, 10) as any,
                state.queryFilter.dateFrom.substring(3, 5) as any,
                state.queryFilter.dateFrom.substring(0, 2) as any
            );
            const dateTo = new Date(
                state.queryFilter.dateTo.substring(6, 10) as any,
                state.queryFilter.dateTo.substring(3, 5) as any,
                state.queryFilter.dateTo.substring(0, 2) as any
            );
            dispatch('headerNotification/' + CLOSE, {}, { root: true });
            if (dateFrom > dateTo) {
                dispatch(
                    'headerNotification/' + NEW_ERRORMESSAGES,
                    { errorMessages: i18n.t('errormessage.impossible.date') },
                    { root: true }
                );
                return;
            }
        }

        const searchQuery = Object.assign({}, state.queryFilter);
        if (searchQuery.dokumentTyp === '') {
            searchQuery.dokumentTyp = null;
        }
        if (searchQuery.fileTyp === '') {
            searchQuery.fileTyp = null;
        }

        searchQuery.offset = 0;
        searchQuery.entriesToLoad = null;

        const onDownloadProgress: (progressEvent: ProgressEvent) => void = (progressEvent: ProgressEvent) => {
            let i = -1;
            let fileSizeInBytes = progressEvent.loaded;
            const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
            do {
                fileSizeInBytes = fileSizeInBytes / 1024;
                i++;
            } while (fileSizeInBytes > 1024);
            dispatch('loadingSpinner/' + SHOW, { text: i18n.t('archiv.loadingspinner.download') + ': ' + (Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]) }, { root: true });
        };

        archivService.downloadAllDokumente(searchQuery, onDownloadProgress).then(response => {
            downloadService.downloadFile(response, 'archive.zip');
            dispatch('headerNotification/' + NEW_SUCESS, { text: i18n.t('archiv.headermessage.downloadsuccess', [totalNumberOfRecords]) }, { root: true });
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
        });
    },
    [DOWNLOAD_MULTIPLE_DOKUMENTE]({ dispatch }: any, { ids }: any) {
        console.debug(NAME + '::' + DOWNLOAD_MULTIPLE_DOKUMENTE);

        const onDownloadProgress: (progressEvent: ProgressEvent) => void = (progressEvent: ProgressEvent) => {
            let i = -1;
            let fileSizeInBytes = progressEvent.loaded;
            const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
            do {
                fileSizeInBytes = fileSizeInBytes / 1024;
                i++;
            } while (fileSizeInBytes > 1024);
            dispatch('loadingSpinner/' + SHOW, { text: i18n.t('archiv.loadingspinner.download') + ': ' + (Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i]) }, { root: true });
        };

        dispatch('loadingSpinner/' + SHOW, {}, { root: true });
        archivService.downloadMultipleDokumente(ids, onDownloadProgress).then(response => {
            downloadService.downloadFile(response, 'archive.zip');
            dispatch('headerNotification/' + NEW_SUCESS, { text: i18n.t('archiv.headermessage.downloadsuccess', [ids.length]) }, { root: true });
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
        });
    },
    [OPEN_DOKUMENT]({ commit }: any, { id, dateiTyp, dokumentName }: any) {
        console.debug(NAME + '::' + OPEN_DOKUMENT);

        archivService.downloadDokument(id).then(response => {
            downloadService.openOrDownloadFile(response, dokumentName, ['image/jpeg', 'image/png', 'application/pdf']);
        });
    },
    [EDIT_DOKUMENT_INFO]({ dispatch }: any, dokumentInfo: DokumentEditApiDto) {
        console.debug(NAME + '::' + EDIT_DOKUMENT_INFO);

        dispatch('loadingSpinner/' + SHOW, {}, { root: true });
        archivService.editDokumentInfo(dokumentInfo).then(response => {
            dispatch(GET_ALL_DOKUMENTE, 0);
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
        });
    },
    [DELETE_DOKUMENT]({ dispatch }: any, id: number) {
        console.debug(NAME + '::' + DELETE_DOKUMENT);

        dispatch('loadingSpinner/' + SHOW, {}, { root: true });
        archivService.deleteDokument(id).then(response => {
            dispatch(GET_ALL_DOKUMENTE, 0);
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
        });
    },
    [GET_SCHLAGWORTE]({ commit }: any, suchText: string) {
        console.debug(NAME + '::' + GET_SCHLAGWORTE);

        archivService.getSchlagwortVorschlaege(suchText).then(response => {
            commit(MAPSCHLAGWORTVORSCHLAEGE, response);
        });
    },
    [CREATE_SCHLAGWORT]({ state, dispatch }: any, schlagwort: string) {
        console.debug(NAME + '::' + CREATE_SCHLAGWORT, schlagwort);

        dispatch(GET_SCHLAGWORTE, schlagwort);

        setTimeout(() => {
            if (state.schlagwortVorschlaege.includes(schlagwort)) {
                return;
            }

            archivService.addSchlagwortVorschlag(schlagwort).then(response => {
                console.log('Schlagwort Add Result: ', response);
            });
        }, 500);
    },
    [DELETE_SCHLAGWORT]({ dispatch }: any, schlagwort: string) {
        console.debug(NAME + '::' + DELETE_SCHLAGWORT);

        return new Promise((resolve) => {
            archivService.deleteSchlagwortVorschlag(schlagwort).then(response => {
                resolve(response);
            });
        });
    }
};
