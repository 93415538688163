import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IModalConfirmationState } from './state';
import { BUTTONTEXT, ONCONFIRMED, ONCONFIRMEDPARAMETER, TEXT, TITLE, VISIBLE } from './mutations';

export const SHOWDELAYED = 'showDelayed';
export const NEW = 'new';
export const CLOSE = 'close';

export const actions: ActionTree<IModalConfirmationState, IRootState> = {
    [NEW]({ commit }, { buttonText, onConfirmed, onConfirmedParameter, text, title }) {
        commit(BUTTONTEXT, buttonText);
        commit(ONCONFIRMED, onConfirmed);
        commit(ONCONFIRMEDPARAMETER, onConfirmedParameter);
        commit(TEXT, text);
        commit(TITLE, title);
        commit(VISIBLE, true);
    },
    [CLOSE]({ commit }) {
        commit(VISIBLE, false);
    }
};
