import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { ISecurityState } from './state';
import { NAME } from './';
import { $router } from '@/main';

import { securityService } from '@/services/securityService';
import { AuthServiceCreator } from '@/services/authService';
import { BENUTZERINFO, PERMISSIONS, LOGGEDIN, INACTIVITYWARNINGTIME, INACTIVITYLOGOUTTIME, CHANGEMODALAGBVISIBLE } from './mutations';
import { SHOW, HIDE } from '@/store/modules/loadingSpinner/actions';

export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const INACTIVITYLOGOUT = 'inactivityLogout';
export const GETTOKEN = 'getToken';
export const INITIALIZE = 'initialize';
export const GET_BENUTZERINFO = 'getBenutzerInformationen';
export const GET_PERMISSIONS = 'getPermissions';
export const CHECK_AGB_ACCEPTED = 'checkAgbAccepted';

export const RESET_INACTIVITYWARNINGTIME = 'resetinactivityWarningTime';
export const RESET_INACTIVITYLOGOUTTIME = 'resetinactivityLogoutTime';

export const UPDATE_INACTIVITYTIMES = 'updateInactivityTimes';

export enum LoginType {
    default,
    sms,
    totp
}
export const actions: ActionTree<ISecurityState, IRootState> = {
    async [LOGIN]({ dispatch, commit }: any, type: LoginType) {
        console.debug(NAME + '::' + LOGIN);
        const authService = await AuthServiceCreator.getAuthService();

        dispatch('loadingSpinner/' + SHOW, {}, { root: true });
        var loginFunction: Promise<boolean>;
        if (type == LoginType.sms) {
            loginFunction = authService.loginSms();
        } else if (type == LoginType.totp) {
            loginFunction = authService.loginTotp();
        } else {
            loginFunction = authService.loginTotp();

        }

        //Login führt ein Redirect aus
        return loginFunction.then(async loggedIn => {
            commit(LOGGEDIN, loggedIn);
            if (loggedIn) {
                await dispatch(GET_PERMISSIONS);
                await dispatch(GET_BENUTZERINFO);
                dispatch('loadingSpinner/' + HIDE, {}, { root: true });
            }
        });
    },

    async [LOGOUT]({ commit }: any) {
        console.debug(NAME + '::' + LOGOUT);
        const authService = await AuthServiceCreator.getAuthService();

        authService.logoutRedirect();
        commit(LOGGEDIN, false);
    },

    async [INACTIVITYLOGOUT]({ dispatch, commit }: any) {
        console.debug(NAME + '::' + INACTIVITYLOGOUT);
        const authService = await AuthServiceCreator.getAuthService();

        var showLogoutMessage = async () => {
            dispatch('headerNotification/newInfo', { text: 'inactivity.loggedout' }, { root: true });
            commit(LOGGEDIN, false);
            $router.push({ name: 'Login', params: {} });
        };

        await authService.logoutPopup()
            .then(showLogoutMessage)
            .catch(() => {
                authService.logoutRedirect();
            });
    },

    [CHECK_AGB_ACCEPTED]({ dispatch, commit }: any, isAccepted: boolean) {
        console.debug(NAME + '::' + CHECK_AGB_ACCEPTED);
        if (!isAccepted) {
            commit(CHANGEMODALAGBVISIBLE, false);
            dispatch(LOGOUT);
        } else {
            dispatch('loadingSpinner/' + SHOW, {}, { root: true });
            securityService.recordAgbAcceptedDate().then(response => {
                commit(CHANGEMODALAGBVISIBLE, false);
            });
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
        }

    },

    async[GETTOKEN]() {
        console.debug(NAME + '::' + GETTOKEN);
        const authService = await AuthServiceCreator.getAuthService();

        authService.getAuthorizationHeader();
    },

    async[INITIALIZE]({ commit, dispatch }: any) {
        console.debug(NAME + '::' + INITIALIZE);
        const authService = await AuthServiceCreator.getAuthService();
        await authService.isLoggedIn().then(async success => {
            if (success) {
                await dispatch(GET_PERMISSIONS);
                await dispatch(GET_BENUTZERINFO);
            }
            commit(LOGGEDIN, success);
        });
    },
    async[GET_BENUTZERINFO]({ commit, state }: any) {
        console.debug(NAME + '::' + GET_BENUTZERINFO);
        await securityService.getBenutzerInfo().then(response => {
            if (state.loggedIn) {
                if (response.rollen.includes('UserMain') || response.rollen.includes('UserBuha')) {
                    $router.push({ name: 'Home', params: {} });
                } else if (response.rollen.includes('UserStaff') || response.rollen.includes('UserView')) {
                    $router.push({ name: 'Lieferantenrechnungen', params: {} });
                } else if (response.rollen.includes('UserUpload')) {
                    $router.push({ name: 'Dokumentarchiv', params: {} });
                } else if (response.rollen.includes('SysAdmin')) {
                    $router.push({ name: 'TakeTeilnehmer', params: {} });
                } else {
                    $router.push({ name: 'Dokumentarchiv', params: {} });
                }
            }
            if (!response.istAgbAngenommen && !response.rollen.includes('SysAdmin') && !state.permissions?.includes('navigation.taketeilnehmer'.toLowerCase())) {
                commit(CHANGEMODALAGBVISIBLE, true);
            } else {
                commit(CHANGEMODALAGBVISIBLE, false);
            }
            commit(BENUTZERINFO, response);
        });
    },
    async[GET_PERMISSIONS]({ commit }: any) {
        console.debug(NAME + '::' + GET_PERMISSIONS);
        await securityService.getAll().then(response => {
            commit(PERMISSIONS, response);
        });
    },
    [RESET_INACTIVITYWARNINGTIME]({ commit, state }) {
        console.debug(NAME + '::' + RESET_INACTIVITYWARNINGTIME);
        commit(INACTIVITYWARNINGTIME, Date.now() + state.inactivityWarningTimeout * 60 * 1000);
    },

    [RESET_INACTIVITYLOGOUTTIME]({ commit, state }) {
        console.debug(NAME + '::' + RESET_INACTIVITYLOGOUTTIME);
        commit(INACTIVITYLOGOUTTIME, Date.now() + state.inactivityLogoutTimeout * 60 * 1000);
    },

    /**Inaktivität aus localstorage lesen, könnte von anderem Tab aktualisiert worden sein */
    [UPDATE_INACTIVITYTIMES]({ state, commit }) {
        const warningTime = parseInt(localStorage.getItem(INACTIVITYWARNINGTIME), 10);
        const logouttime = parseInt(localStorage.getItem(INACTIVITYLOGOUTTIME), 10);

        const updateTimesInStore = state.inactivityWarningTime != warningTime ||
            state.inactivitylogoutTime != logouttime;

        if (updateTimesInStore) {
            commit(INACTIVITYWARNINGTIME, warningTime.toString());
            commit(INACTIVITYLOGOUTTIME, logouttime.toString());
        }

    },
};
