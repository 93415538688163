import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

// VeeValidate
import VeeValidate from '@/plugins/VeeValidate';
new VeeValidate();

import FocusLock from 'vue-focus-lock';
Vue.component('FocusLock', FocusLock);

import VueScrollLoader from 'vue-scroll-loader';
Vue.use(VueScrollLoader);

import { AuthServiceCreator } from './services/authService';
import { NAME as ResourceStoreName } from './store/modules/resource';
import { LOAD_LANGUAGE } from './store/modules/resource/actions';
import { state as ResourceState } from './store/modules/resource/state';
import { NAME as SecurityStoreName } from './store/modules/security';
import { INITIALIZE } from './store/modules/security/actions';

export const $store = store;
export const $router = router;


AuthServiceCreator.getAuthService().then(async (authService) => {
    await store.dispatch(ResourceStoreName + '/' + LOAD_LANGUAGE, ResourceState.language);
    await store.dispatch(SecurityStoreName + '/' + INITIALIZE);
    new Vue({
        router,
        store,
        vuetify,
        i18n,
        provide: { authService },
        render: h => h(App)
    }).$mount('#app');
});
import axios from 'axios';

axios.interceptors.request.use(
    async config => {
        const authService = await AuthServiceCreator.getAuthService();
        const token = await authService.getAuthorizationHeader();
        if (token) {
            config.headers['Authorization'] = token;
        }
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        console.log(config.headers);
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


import { NEW_ERRORMESSAGES } from './store/modules/headerNotification/actions';
import { HIDE } from './store/modules/loadingSpinner/actions';
import { AxiosRequestConfigWithInterceptorParams } from './types/AxiosRequestConfigWithInterceptorParams';
axios.interceptors.response.use(
    Response => {
        return Response;
    },
    error => {
        if ((error.config as AxiosRequestConfigWithInterceptorParams)?.withErrorHandling) {
            if (error.response.data.status != 409) {
                if (i18n.te(error.response.data.detail)) {
                    $store.dispatch(
                        'headerNotification/' + NEW_ERRORMESSAGES,
                        { errorMessages: i18n.t(error.response.data.detail) },
                        { root: true }
                    );
                } else {
                    $store.dispatch(
                        'headerNotification/' + NEW_ERRORMESSAGES,
                        { errorMessages: i18n.t('errormessage.default', [error.response.data.status]) },
                        { root: true }
                    );
                }
                $store.dispatch('loadingSpinner/' + HIDE, {}, { root: true });
            }
        }
        return Promise.reject(error);
    }
);