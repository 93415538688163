import { BenutzerInfoSearchResultApiDto } from '@/api-schema';
import BaseService from '@/services/baseService';
import { BenutzerInfoSearchApiDto } from '@/types/BenutzerInfoSearchApiDto';
import { IBenutzerinfoService } from './IBenutzerinfoService';

export class BenutzerinfoService extends BaseService implements IBenutzerinfoService {
    private RESOURCE_URL: string;

    constructor() {
        super();
        this.RESOURCE_URL = `${this.BASE_URL}benutzerinfo`;

        console.debug('BenutzerinfoService::constructor', this.RESOURCE_URL);
    }

    async getByEmail(filter: BenutzerInfoSearchApiDto): Promise<BenutzerInfoSearchResultApiDto> {
        return super.GET<BenutzerInfoSearchResultApiDto>(`${this.RESOURCE_URL}/byemail${super.serializeObjectToQuerystring(filter)}`, super.getConfig, false).then(response => {
            return response;
        });
    }
}


export const benutzerinfoService = new BenutzerinfoService();