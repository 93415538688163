import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IProfilState } from './state';

import {
    MAPKUNDEDATA,
    MAPSERVICEDATA,
    MAPVERRECHNUNGDATA,
    MAPBENUTZERDATA,
    MAPBENUTZERDETAILS,
    MAPBELEGTERSPEICHER,
    MAPTEILNEHMERZUGRIFFE,
    MAPCREATETEILNEHMERZUGRIFFELIST,
    MAPTEILNEHMERFUNKTIONEN
} from './mutations';
import { NAME } from './';

import { profilService } from '../services/ProfilService';
import { CLOSE_MODAL, NEW_MODAL_ERROR, NEW_SUCESS } from '@/store/modules/headerNotification/actions';
import { TeilnehmerApiDto, BenutzerEditApiDto, BenutzerCreateApiDto, TeilnehmerZugriffPostApiDto, TeilnehmerFunktionApiDto } from '@/api-schema';
import { SubscribedServicesDto } from '@/types/subscribedServicesDto';
import { HIDE, SHOW } from '@/store/modules/loadingSpinner/actions';
import i18n from '@/plugins/i18n';

export const GET_KUNDE_BY_ID = 'getTeilnehmer';
export const GET_SERVICE_BY_ID = 'getTeilnehmerService';
export const GET_BENUTZER_BY_ID = 'getBenutzer';
export const GET_BENUTZER_DETAILS = 'getBenutzerDetails';
export const GET_DIAGRAMM_VALUES = 'getVerrechnungDiagramm';
export const EDIT_ADRESSE = 'editTeilnehmerAdresse';
export const EDIT_TEILNEHMERFUNKTION = 'editTeilnehmerfunktion';
export const GET_EDIT_TEILNEHMERFUNKTION = 'getEditTeilnehmerfunktion';
export const EDIT_ACCOUNT = 'editBenutzerAccount';
export const DELETE_ACCOUNT = 'deleteBenutzerAccount';
export const ADD_ACCOUNT = 'addBenutzerAccount';
export const GET_EDIT_ACCOUNT = 'getAccountToEdit';
export const EDIT_SERVICES = 'editTeilnehmerServices';
export const GET_ALL_SERVICES = 'getAllServices';
export const GET_ALL_ZUGRIFFE = 'getAllTeilnehmerZugriffe';
export const CREATE_TEILNEHMER_ZUGRIFF = 'createTeilnehmerZugriff';
export const GET_ZUGRIFF_CREATE_INFO = 'getTeilnehmerZugriffCreateInformation';
export const DELETE_TEILNEHMER_ZUGRIFF = 'deleteTeilnehmerZugriff';

export const actions: ActionTree<IProfilState, IRootState> = {
    [GET_ALL_ZUGRIFFE]({ commit }: any) {
        console.debug(NAME + '::' + GET_ALL_ZUGRIFFE);

        return profilService.getTeilnehmerZugriffe().then(response => {
            commit(MAPTEILNEHMERZUGRIFFE, response);
        });
    },
    async [GET_ZUGRIFF_CREATE_INFO]({ commit }: any) {
        console.debug(NAME + '::' + GET_ALL_ZUGRIFFE);

        return profilService.getTeilnehmerCreateInformation().then(response => {
            commit(MAPCREATETEILNEHMERZUGRIFFELIST, response);
            return;
        });
    },
    [CREATE_TEILNEHMER_ZUGRIFF]({ dispatch }: any, teilnehmer: TeilnehmerZugriffPostApiDto) {
        console.debug(NAME + '::' + CREATE_TEILNEHMER_ZUGRIFF);

        dispatch('loadingSpinner/' + SHOW, {}, { root: true });
        return profilService.createTeilnehmerZugriff(teilnehmer).then(response => {
            dispatch(GET_ALL_ZUGRIFFE);
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
        }).catch(response => {
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
        });
    },
    [DELETE_TEILNEHMER_ZUGRIFF]({ dispatch }: any, teilnehmerZugriffId: number) {
        console.debug(NAME + '::' + DELETE_TEILNEHMER_ZUGRIFF);

        dispatch('loadingSpinner/' + SHOW, {}, { root: true });
        return profilService.deleteTeilnehmerZugriff(teilnehmerZugriffId).then(response => {
            dispatch(GET_ALL_ZUGRIFFE);
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
        }).catch(response => {
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
        });
    },
    [EDIT_SERVICES]({ dispatch }: any, { speicherService, funktionServices, rowVersion }: any) {
        console.debug(NAME + '::' + EDIT_SERVICES, speicherService, funktionServices, rowVersion);

        return profilService
            .editServices(new SubscribedServicesDto(speicherService, funktionServices, rowVersion))
            .then(response => {
                if (response) {
                    dispatch(GET_SERVICE_BY_ID);
                    dispatch(
                        'headerNotification/' + NEW_SUCESS,
                        { text: i18n.t('einstellungen.success.editservices') },
                        { root: true }
                    );
                }
            });
    },
    [GET_ALL_SERVICES]({ dispatch }: any) {
        console.debug(NAME + '::' + GET_ALL_SERVICES);

        return profilService.getCompatibleServices().then(response => {
            return response;
        });
    },
    [EDIT_ADRESSE]({ dispatch }: any, teilnehmer: TeilnehmerApiDto) {
        console.debug(NAME + '::' + EDIT_ADRESSE, teilnehmer);

        teilnehmer.plz = Number(teilnehmer.plz);

        return profilService.requestEditAdresse(teilnehmer).then(response => {
            if (response) {
                dispatch(
                    'headerNotification/' + NEW_SUCESS,
                    { text: i18n.t('einstellungen.success.editaddress') },
                    { root: true }
                );
            }
        });
    },
    [GET_EDIT_TEILNEHMERFUNKTION]({ commit }: any) {
        console.debug(NAME + '::' + GET_EDIT_TEILNEHMERFUNKTION);

        profilService.getEditTeilnehmerfunktion().then(response => {
            commit(MAPTEILNEHMERFUNKTIONEN, response);
        });
    },
    [EDIT_TEILNEHMERFUNKTION]({ dispatch }: any, teilnehmerFunktion: TeilnehmerFunktionApiDto) {
        console.debug(NAME + '::' + EDIT_TEILNEHMERFUNKTION, teilnehmerFunktion);
        dispatch('loadingSpinner/' + SHOW, {}, { root: true });

        profilService.editTeilnehmerfunktion(teilnehmerFunktion).then(response => {
            if (response) {
                dispatch(
                    'headerNotification/' + NEW_SUCESS,
                    { text: i18n.t('einstellungen.success.funktion') },
                    { root: true }
                );
                dispatch(GET_EDIT_TEILNEHMERFUNKTION);
                dispatch('loadingSpinner/' + HIDE, {}, { root: true });
            }
        }).catch(response => {
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
        });
    },
    async [GET_EDIT_ACCOUNT]({ commit }: any, objectBenutzerId: string) {
        console.debug(NAME + '::' + GET_EDIT_ACCOUNT, objectBenutzerId);

        return profilService.getEditAccount(objectBenutzerId).then(response => {
            return response;
        });
    },
    [ADD_ACCOUNT]({ dispatch, state }: any, benutzer: BenutzerCreateApiDto) {
        console.debug(NAME + '::' + ADD_ACCOUNT, benutzer);
        dispatch('loadingSpinner/' + SHOW, {}, { root: true });
        dispatch(
            'headerNotification/' + CLOSE_MODAL, {},
            { root: true }
        );

        benutzer.sprache = state.kundeData.sprache;

        return profilService.addBenutzer(benutzer).then(response => {
            dispatch(GET_BENUTZER_BY_ID);
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
            return true;
        }).catch(response => {
            dispatch(
                'headerNotification/' + NEW_MODAL_ERROR,
                { text: i18n.t('einstellungen.errormessage.duplicateaccount') },
                { root: true }
            );
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
            return false;
        });
    },
    [EDIT_ACCOUNT]({ dispatch }: any, benutzer: BenutzerEditApiDto) {
        console.debug(NAME + '::' + EDIT_ACCOUNT, benutzer);

        return profilService.editBenutzer(benutzer).then(response => {
            if (response.isSuccess) {
                dispatch(GET_BENUTZER_BY_ID);
            }
        });
    },
    [DELETE_ACCOUNT]({ dispatch }: any, objectBenutzerId: string) {
        console.debug(NAME + '::' + DELETE_ACCOUNT, objectBenutzerId);

        return profilService.deleteBenutzer(objectBenutzerId).then(response => {
            if (response) {
                dispatch(GET_BENUTZER_BY_ID);
            }
        });
    },
    [GET_KUNDE_BY_ID]({ commit }: any) {
        console.debug(NAME + '::' + GET_KUNDE_BY_ID);

        return new Promise((resolve) => {
            profilService.getTeilnehmer().then(response => {
                commit(MAPKUNDEDATA, response);
                resolve(response);
            });
        });
    },
    [GET_SERVICE_BY_ID]({ commit }: any) {
        console.debug(NAME + '::' + GET_SERVICE_BY_ID);

        profilService.getTeilnehmerService().then(response => {
            commit(MAPSERVICEDATA, response.teilnehmerService);
            commit(MAPVERRECHNUNGDATA, response.serviceVerrechnung);
        });
    },
    [GET_DIAGRAMM_VALUES]({ commit }: any) {
        console.debug(NAME + '::' + GET_DIAGRAMM_VALUES);

        profilService.getVerrechnungDiagramm().then(response => {
            commit(MAPBELEGTERSPEICHER, response);
        }).catch(() => {
            console.log('VerrechnungDiagramm wurde nicht gefunden');
            commit(MAPBELEGTERSPEICHER, null);
        });
    },
    [GET_BENUTZER_BY_ID]({ commit, state, dispatch }: any) {
        console.debug(NAME + '::' + GET_BENUTZER_BY_ID);
        const lengthBeforeNewUser = state.benutzerData.length;

        profilService.getBenutzer().then(response => {
            if (lengthBeforeNewUser == 0 && response.length == 1) {
                dispatch(GET_KUNDE_BY_ID);
                dispatch(GET_SERVICE_BY_ID);
                dispatch(GET_DIAGRAMM_VALUES);
                dispatch(GET_EDIT_TEILNEHMERFUNKTION);
            }
            commit(MAPBENUTZERDATA, response);
        });
    },
    [GET_BENUTZER_DETAILS]({ commit }: any, id: string) {
        console.debug(NAME + '::' + GET_BENUTZER_DETAILS, id);

        profilService.getBenutzerDetails(id).then(response => {
            commit(MAPBENUTZERDETAILS, response, id);
        });
    }
};
