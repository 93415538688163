<template>
    <v-menu
        v-model="menu"
        transition="scale-transition"
        offset-y
        :allow-overflow="true"
        :bottom="true"
        :close-on-content-click="false"
        :disabled="disabled"
    >
        <template v-slot:activator="{ on, attrs }">
            <!-- Label -->
            <v-text-field
                v-model="labelDate"
                :label="$t(label)"
                persistent-hint
                prepend-icon="mdi-calendar"
                filled
                dense
                :disabled="disabled"
                v-bind="attrs"
                v-on="on"
                @blur="labelChanged"
            ></v-text-field>
        </template>
        <!-- Picker -->
        <v-date-picker v-model="isoDate" @input="menu = false" :locale="language" :show-current="true" :no-title="true">
            <!-- Buttons Ok and Cancel + Today (nicht default) -->
            <!-- Hint: wenn Save und Cancel auch angeboten werden müssen:
            <template slot-scope="{ save, cancel }">
                ...
                <v-row>
                    <v-btn flat color="primary" @click="cancel">Cancel</v-btn>
                    <v-btn flat color="primary" @click="save">OK</v-btn>
                </v-row>
            -->
            <template>
                <v-row align="center" justify="space-around">
                    <v-btn color="primary" @click="setDateToday" small>
                        {{ $t('today') }}
                    </v-btn>
                </v-row>
            </template>
        </v-date-picker>
    </v-menu>
</template>

<script lang="ts">
import { mixins } from 'vue-class-component';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { DateMixin } from '@/mixins/DateMixin';
import { namespace } from 'vuex-class';

import { NAME as NAME_RESOURCE } from '@/store/modules/resource';
const resourceModul = namespace(NAME_RESOURCE);
import { GET_LANGUAGE } from '@/store/modules/resource/getters';

@Component
export default class NowhowDatepicker extends mixins(DateMixin) {
    menu = false;
    labelDate = '';
    //v-date-picker accepts ISO 8601 date strings (YYYY-MM-DD)
    isoDate = '';

    @Prop({ required: false, type: Boolean, default: false })
    disabled!: boolean;

    @Prop({ required: false, type: String, default: '' })
    value!: string;

    @Prop({ required: false, type: String, default: '' })
    label!: string;

    @resourceModul.Getter(GET_LANGUAGE) language!: string;

    labelChanged() {
        //format Date to ISO 8601 date
        const date = this.tryParseSwissDate(this.labelDate);

        if (date.success && date.date) {
            this.isoDate = this.toIsoDateString(date.date);
        } else {
            this.isoDate = null;
        }
    }

    setDateToday() {
        this.isoDate = this.toIsoDateString(new Date());
        this.menu = false;
    }

    setValue(swissDateString: string) {
        const date = this.tryParseSwissDate(swissDateString);

        if (date.success && date.date) {
            this.isoDate = this.toIsoDateString(date.date);
            this.labelDate = this.toSwissDateString(date.date);
        } else {
            this.isoDate = '';
            this.labelDate = '';
        }
    }

    @Watch('isoDate')
    watchIsoDate(val: string) {
        const date = this.tryParseIsoDate(val);
        let formattedDate;

        if (date.success && date.date) {
            formattedDate = this.toSwissDateString(date.date);
        }
        this.setValue(formattedDate);

        this.$emit('change', formattedDate);
        this.$emit('input', formattedDate);
    }

    @Watch('value')
    valueChanged(newVal: string) {
        this.setValue(newVal);
    }
}
</script>
