import BaseService from './baseService';

export default class ResourceService extends BaseService {
    private RESOURCE_URL: string;
    constructor() {
        super();
        this.RESOURCE_URL = `${this.BASE_URL}ressourcen`;

        console.debug('ResourceService::constructor', this.RESOURCE_URL);
    }
    // eslint-disable-next-line
    async get(language: string): Promise<any> {
        // eslint-disable-next-line
        return super.GET(`${this.RESOURCE_URL}/${language}`).then((response: any) => {
            return response;
        });
    }
}

export const resourceService = new ResourceService();
