<template>
    <div class="fileupload">
        <div :class="css">
            <div class="fileupload-field" :id="'drop-' + id">
                <div class="empty-state__bg"></div>
                <div class="empty-state" aria-hidden="true">
                    <div class="empty-state__input">
                        <div class="empty-state__icon mar-t--50">
                            <i class="icon icon-087-upload icon--s4 blue" aria-hidden="true"></i>
                        </div>
                        <div class="empty-state__body">
                            <div v-if="multiple" class="body__cta fs-medium">
                                {{ $t('allg.dropzone.ziehen') }}
                            </div>
                            <div v-else class="body__cta fs-medium">
                                {{ $t('allg.dropzone.ziehen') }}
                            </div>
                        </div>
                        <div class="empty-state__footer mar-t--20">
                            <input
                                type="file"
                                :id="id"
                                :multiple="multiple"
                                :accept="acceptTypes"
                                :name="id"
                                :key="id"
                            />
                            <slot name="button">
                                <label class="v-btn v-btn--depressed theme--light v-size--default primary" :for="id">{{
                                    $t('allg.dropzone.durchsuchen')
                                }}</label>
                            </slot>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- List of files for upload -->
        <div :class="css">
            <div class="table table--responsive" v-if="!instantUpload && showFilelist">
                <div class="table__wrapper">
                    <table data-init="auto" class="table-vertical-align">
                        <tbody>
                            <template v-if="files && files.length > 0">
                                <tr v-for="(file, key) in files" :key="key">
                                    <td class="sc-gray-mid">{{ file.name }}</td>
                                    <td class="nowhow-table-max-50 sc-gray-mid t-right td-action">
                                        <i
                                            class="icon icon-022-close icon--s2"
                                            style="cursor:pointer;"
                                            @click="removeFile(key)"
                                        />
                                    </td>
                                </tr>
                            </template>
                            <template v-else>
                                <tr>
                                    <td class="sc-gray-mid">
                                        {{ $t('allg.dropzone.notselected') }}
                                    </td>
                                    <td class="nowhow-table-max-50 sc-gray-mid t-right td-action"></td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class Upload extends Vue {
    @Prop({ required: true, type: String })
    private id!: string;

    @Prop({ required: false, type: Boolean, default: false })
    private instantUpload!: boolean;

    @Prop({ required: false, type: Boolean, default: true })
    private multiple!: boolean;

    @Prop({ required: false, type: String, default: '' })
    private acceptTypes!: string;

    @Prop({ required: false, default: null })
    private value!: Array<File> | File | Blob;

    @Prop({ required: false, type: String, default: '' })
    private css!: string;

    @Prop({ required: false, type: Boolean, default: true })
    private showFilelist!: boolean;

    private fileUpload!: HTMLInputElement;
    private fileUploadDrop!: HTMLElement;
    private files!: Array<File>;

    mounted() {
        this.fileUpload = document.getElementById(this.id) as HTMLInputElement;
        this.fileUpload.addEventListener('change', this.addFiles);

        this.fileUploadDrop = document.getElementById('drop-' + this.id) as HTMLElement;
        this.fileUploadDrop.addEventListener('dragenter', this.dragenter);
        this.fileUploadDrop.addEventListener('dragleave', this.dragleave);
        this.fileUploadDrop.addEventListener('dragover', this.dragover);
        this.fileUploadDrop.addEventListener('drop', this.dropFiles);
        this.files = [];
    }

    beforeDestroy() {
        this.fileUpload.removeEventListener('change', this.addFiles);
        this.fileUploadDrop.removeEventListener('dragenter', this.dragenter);
        this.fileUploadDrop.removeEventListener('dragleave', this.dragleave);
        this.fileUploadDrop.removeEventListener('dragover', this.dragover);
        this.fileUploadDrop.removeEventListener('drop', this.dropFiles);
    }

    dropFiles($event: any) {
        console.debug('addFiles $event.dataTransfer.files', $event.dataTransfer.files);
        $event.preventDefault();
        this.addFilelist($event.dataTransfer.files);
    }
    addFiles($event: any) {
        console.debug('addFiles $event.target.files', $event.target.files);
        $event.preventDefault();
        this.addFilelist($event.target.files);
    }
    addFilelist(filelist: FileList) {
        //Check ob min 1 File in list, IE11 feuert event 2x ab einmal mit und einmal ohne File. So wir das File nicht mit leer überschrieben.
        if (filelist.length <= 0) {
            return;
        }

        if (!this.multiple) {
            this.files.pop();
        }
        // loop through files
        for (let i = 0; i < filelist.length; i++) {
            // get item
            const file = filelist.item(i);
            if (this.checkFileType(file)) {
                this.files.push(file);
            }
        }

        this.emitFile();
        this.fileUpload.value = ''; //Damit die gleiche Datei mehrmals hintereinander ausgewählt werden kann.
    }
    emitFile() {
        console.log('files', this.files);
        if (this.multiple) {
            this.$emit('input', this.files);
        } else {
            this.$emit('input', this.files[0]);
        }

        // files array zurücksetzen.
        if (this.instantUpload) {
            this.files = [];
        }
    }
    checkFileType(file: File) {
        if (this.acceptTypes === '') {
            return true;
        }

        let isacceptedFileType = false;
        const acceptTypeList = this.acceptTypes.split(',');
        const fileType = file.name.split('.').pop();
        console.debug('fileType', fileType);

        acceptTypeList.forEach(function(element) {
            const acceptFileType = element.replace('.', '');
            console.debug('acceptFileType', acceptFileType);

            if (fileType && acceptFileType.toLowerCase() === fileType.toLowerCase()) {
                isacceptedFileType = true;
            }
        });
        return isacceptedFileType;
    }
    dragenter(e: Event) {
        e.preventDefault();
    }
    dragleave(e: Event) {
        e.preventDefault();
    }
    dragover(e: Event) {
        e.preventDefault();
    }
    removeFile(key: number) {
        console.debug('removeFile ' + key);
        if (this.files == null || this.files.length <= 0) {
            return;
        }

        // Files von der Liste entfernen
        this.files.splice(key, 1);

        //Value von Input zurücksetzen
        if (!this.multiple || this.files.length === 0) {
            this.fileUpload.value = '';
        }

        this.emitFile();
    }
}
</script>

<style scoped>
.empty-state input[type='file'] {
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    z-index: 1000;
    opacity: 0;
    overflow: hidden;
    position: absolute;
}
.fileupload-field input {
    cursor: pointer;
}
.fileupload {
    position: relative;
    height: 100%;
}
.fileupload > * {
    padding: 0;
}
.fileupload div:first-of-type {
    margin-bottom: 0 !important;
}
.fileupload-field .empty-state,
.fileupload-field .empty-state__bg {
    height: 100%;
    width: 100%;
    margin-left: 0;
    border: 1px solid #dde3e7;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
}
</style>
