import { DokumentApiDto } from '@/api-schema';
import { DokumentFilterDto } from '@/types/DokumentFilterDto';

export interface IArchivState {
    dokumentList: Array<DokumentApiDto>;
    dokumentTypes: Array<object>;
    fileTypes: Array<object>;
    erstelltVon: Array<object>;
    queryFilter: DokumentFilterDto;
    loading: boolean;
    totalNumberOfRecords: number | null;
    schlagwortVorschlaege: Array<string>;
}

export const state: IArchivState = {
    loading: false,
    totalNumberOfRecords: null,
    dokumentList: [],
    dokumentTypes: [],
    fileTypes: [],
    erstelltVon: [],
    queryFilter: new DokumentFilterDto(),
    schlagwortVorschlaege: []
};
