import { ZeitspanneFilterApiDto } from '@/api-schema';

export class HomeFilterDto {
    constructor() {
        this.offset = 0;
        this.entriesToLoad = 15;
        this.tvsKontoNummer = '';
        this.suchText = '';
        this.sortOrder = 'Descending';
        this.sortBy = 'buchungDatum';
    }
    buchungDatumVon?: string; // date-time
    buchungDatumBis?: string; // date-time
    zeitspanne?: ZeitspanneFilterApiDto;
    betragBis?: string;
    betragVon?: string;
    suchText: string;
    tvsKontoNummer: string;
    offset: number;
    entriesToLoad?: number;
    sortBy?: string;
    sortOrder?: string;
}