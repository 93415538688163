<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <!--table hauptkonfigurationen -->
                <v-data-table
                    :headers="headers"
                    :items="dokumentList"
                    :loading="loading"
                    item-key="dokumentID"
                    :options.sync="options"
                    :server-items-length="totalNumberOfRecords"
                    hide-default-footer
                    class="elevation-1 nowhow-table"
                    sort-by="createdOn"
                    :must-sort="true"
                    :custom-sort="customSort"
                    show-select
                    v-model="selected"
                >
                    <!-- Header der Table(Filter anzeige, gefundene Elemente) -->
                    <template v-slot:top>
                        <TableHeader :filters="filters" @chipClosed="removeFilter" v-if="selected < 1">
                            <template>
                                {{ totalNumberOfRecords + ' ' + $t('archiv.table.header.items') }}
                                <v-text-field
                                    class="ml-5"
                                    v-if="showSearchBar"
                                    v-model="filterData.bezeichnung"
                                    @input="searchFieldInputChanged"
                                    prepend-inner-icon="mdi-magnify"
                                    append-icon="mdi-close"
                                    @click:append="searchBarClosed"
                                    :label="$t('archiv.table.header.searchlabel')"
                                    hide-details
                                    autofocus
                                ></v-text-field>
                                <v-spacer />
                                <v-tooltip top color="secondary">
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" icon color="primary">
                                            <v-icon @click="showSearchBar = !showSearchBar" v-if="!showSearchBar"
                                                >mdi-magnify</v-icon
                                            >
                                        </v-btn>
                                    </template>
                                    <div>
                                        <span>
                                            {{ $t('tableheader.tooltip.search') }}
                                        </span>
                                    </div>
                                </v-tooltip>
                                <v-tooltip top color="secondary">
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" icon color="primary" @click="downloadAllDokumenteWithFilter">
                                            <v-icon>mdi-download</v-icon>
                                        </v-btn>
                                    </template>
                                    <div>
                                        <span>
                                            {{ $t('tableheader.tooltip.download') }}
                                        </span>
                                    </div>
                                </v-tooltip>
                                <v-tooltip top color="secondary">
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-on="on"
                                            icon
                                            color="primary"
                                            @click="showFilterDrawer = !showFilterDrawer"
                                        >
                                            <v-icon>mdi-filter-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <div>
                                        <span>
                                            {{ $t('tableheader.tooltip.filter') }}
                                        </span>
                                    </div>
                                </v-tooltip>
                            </template>
                        </TableHeader>
                        <TableHeader :filters="filters" @chipClosed="removeFilter" :download-toolbar="true" v-else>
                            <template>
                                <div>
                                    {{
                                        selected.length +
                                            ' ' +
                                            $t('archiv.table.header.items') +
                                            ' (total ' +
                                            maximumFileSize +
                                            ')'
                                    }}
                                </div>
                                <v-spacer />
                                <div class="pr-3">
                                    <a @click="downloadMultipleDokumenteWrapper" style="color: white;">
                                        Download<v-icon color="white">mdi-download</v-icon>
                                    </a>
                                    <span class="pl-1 pr-1" style="font-size: 20px;">|</span>
                                    <a @click="selected = []" style="color: white;">
                                        Cancel
                                    </a>
                                </div>
                            </template>
                        </TableHeader>
                    </template>
                    <!-- Selbst definierte Tablespalten -->
                    <template v-slot:item.bezeichnung="{ item }">
                        <TextHighlights :text="item.bezeichnung" :search-text="filterData.bezeichnung" />
                    </template>
                    <template v-slot:item.bezeichnung="{ item }">
                        <a style="text-decoration: underline;">
                            <TextHighlights
                                :text="item.bezeichnung"
                                :search-text="filterData.bezeichnung"
                                @click="
                                    openDokument({
                                        id: item.dokumentGuid,
                                        dateiTyp: item.mimeTyp,
                                        dokumentName: item.bezeichnung
                                    })
                                "
                            />
                        </a>
                    </template>
                    <template v-slot:item.schlagworte="{ item }">
                        <TextHighlights :text="item.schlagworte" :search-text="filterData.bezeichnung" />
                    </template>
                    <template v-slot:item.dokumentTypCode="{ item }">
                        <span>
                            {{ $t(item.dokumentTypCode) }}
                        </span>
                    </template>
                    <template v-slot:item.link="{ item }">
                        <v-btn
                            icon
                            :href="item.link"
                            @click="downloadDokument({ id: item.dokumentGuid, dokumentName: item.bezeichnung })"
                        >
                            <v-icon color="primary" v-text="'mdi-download-outline'" />
                        </v-btn>
                    </template>
                    <template v-slot:item.burgermenu="{ item }">
                        <v-menu
                            v-if="hasAccess('Archiv.EigeneDokumente.Schreiben')"
                            offset-x
                            v-model="item.burgermenuActive"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon :href="item.burgermenu" v-bind="attrs" v-on="on">
                                    <v-icon color="primary" v-text="'mdi-menu'" />
                                </v-btn>
                            </template>
                            <v-list>
                                <EditDokumentDialog
                                    :dokument-id="item.dokumentID"
                                    :full-dokument-name="item.dateiName + '.' + item.dateiErweiterung"
                                    :dokument-typ="item.dokumentTypCode"
                                />
                                <ConfirmDialog
                                    v-if="item.dokumentTypCode == 'archiv.dokumenttyp.eigenesdok'"
                                    @confirm="deleteDokumentWrapper(item.dokumentID)"
                                    title="archiv.deletedialog.title"
                                    text="archiv.deletedialog.text"
                                >
                                    <template v-slot:activatorElement="{ on }">
                                        <v-list-item v-on="on">
                                            <v-list-item-title
                                                v-text="$t('archiv.table.burgermenu.delete')"
                                                @click="item.burgermenuActive = false"
                                            />
                                        </v-list-item>
                                    </template>
                                </ConfirmDialog>
                                <v-list-item
                                    @click="downloadDokument({ id: item.dokumentGuid, dokumentName: item.bezeichnung })"
                                >
                                    <v-list-item-title v-text="$t('archiv.table.burgermenu.download')" />
                                </v-list-item>
                            </v-list>
                        </v-menu>
                        <v-btn v-else icon disabled>
                            <v-icon color="grey" v-text="'mdi-menu'" />
                        </v-btn>
                    </template>
                </v-data-table>
                <scroll-loader class="mb-5" :loader-method="load">
                    <v-progress-circular indeterminate v-show="loading" />
                </scroll-loader>
            </v-col>
        </v-row>
        <!-- FilterMenü -->
        <FilterDrawer
            :visible.sync="showFilterDrawer"
            @cancel="cancelFilter()"
            @confirm="confirmFilter()"
            @clear="clearFilters(true)"
            @reset="clearFilters(false)"
        >
            <SingleFilter :title="$t('archiv.table.header.dokumenttyp')">
                <template>
                    <v-row>
                        <v-col sm="12">
                            <v-select
                                filled
                                dense
                                hide-details
                                :items="dokumentTypes"
                                v-model="filterData.dokumentTyp"
                            ></v-select>
                        </v-col>
                    </v-row>
                </template>
            </SingleFilter>
            <SingleFilter :title="$t('archiv.table.header.filetyp')">
                <template>
                    <v-row>
                        <v-col sm="12">
                            <v-select
                                filled
                                dense
                                hide-details
                                :items="fileTypes"
                                v-model="filterData.fileTyp"
                            ></v-select>
                        </v-col>
                    </v-row>
                </template>
            </SingleFilter>
            <SingleFilter :title="$t('archiv.table.header.erstelltvon')">
                <template>
                    <v-row>
                        <v-col sm="12">
                            <v-select
                                filled
                                dense
                                hide-details
                                :items="erstelltVon"
                                v-model="filterData.erstelltVon"
                            ></v-select>
                        </v-col>
                    </v-row>
                </template>
            </SingleFilter>
            <SingleFilter title="" class="py-0 mt-0">
                <template>
                    <v-radio-group class="pb-0 filterLine" row v-model="radios">
                        <h5 class="pb-3">{{ $t('archiv.filter.title.datum') }}</h5>
                        <v-col sm="12" class="pt-0">
                            <v-radio value="selectTime" :label="$t('archiv.filter.datum.radio.selecttime')"></v-radio>
                        </v-col>
                        <v-col sm="12" class="pt-0">
                            <v-radio value="allTime" :label="$t('archiv.filter.datum.radio.alltime')"></v-radio>
                        </v-col>
                        <v-col sm="12" class="pt-0">
                            <v-radio
                                value="specificTime"
                                :label="$t('archiv.filter.datum.radio.specifictime')"
                            ></v-radio>
                        </v-col>
                        <v-col sm="12" class="pb-0 pt-0">
                            <v-row>
                                <v-col class="pb-0" sm="6">
                                    <NowhowDatepicker
                                        v-model="filterData.dateFrom"
                                        :disabled="radios !== 'specificTime'"
                                        label="archiv.filter.datum.von"
                                    >
                                    </NowhowDatepicker>
                                </v-col>
                                <v-col class="pb-0" sm="6">
                                    <NowhowDatepicker
                                        v-model="filterData.dateTo"
                                        :disabled="radios !== 'specificTime'"
                                        label="archiv.filter.datum.bis"
                                    >
                                    </NowhowDatepicker>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-radio-group>
                </template>
            </SingleFilter>
        </FilterDrawer>
    </v-container>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Debounce } from 'vue-debounce-decorator';
import { DateMixin } from '@/mixins/DateMixin';

import { NAME as NAME_GENERAL } from '@/store/modules/general';
import { NAME as NAME_ARCHIV } from '../store';
import { NAME as NAME_SECURITY } from '@/store/modules/security';
import { NAME as NAME_HEADERNOTIFICATION } from '@/store/modules/headerNotification';
import { NAME as NAME_RESOURCE } from '@/store/modules/resource';

const generalModul = namespace(NAME_GENERAL);
const archivModul = namespace(NAME_ARCHIV);
const securityModul = namespace(NAME_SECURITY);
const headerModul = namespace(NAME_HEADERNOTIFICATION);
const resourceModul = namespace(NAME_RESOURCE);

import { GET_LANGUAGE } from '@/store/modules/resource/getters';
import { HASACCESS } from '@/store/modules/security/getters';
import { SETACCESSDENIED } from '@/store/modules/security/mutations';
import { NEW_ALERT } from '@/store/modules/headerNotification/actions';
import {
    GET_ALL_DOKUMENTE,
    GET_ALL_DOKUMENTTYPES,
    GET_ALL_FILETYPES,
    DOWNLOAD_DOKUMENT,
    OPEN_DOKUMENT,
    DELETE_DOKUMENT,
    DOWNLOAD_MULTIPLE_DOKUMENTE,
    DOWNLOAD_ALL_DOKUMENTE,
    GET_ALL_ERSTELLTVON
} from '../store/actions';
import {
    GETDOKUMENTE,
    GETDOKUMENTTYPES,
    GETFILETYPES,
    LOADING,
    TOTAL_NUMBER_OF_RECORDS,
    GETERSTELLTVON
} from '../store/getters';
import { CLEARFILTER, APPLYFILTER } from '../store/mutations';
import { SUBTITLE, FOOTERTITLE } from '@/store/modules/general/mutations';
import { DokumentApiDto, DokumentEditApiDto } from '../../../api-schema';

import SingleFilter from '@/components/SingleFilter.vue';
import FilterDrawer from '@/components/FilterDrawer.vue';
import TableHeader from '@/components/TableHeader.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import EditDokumentDialog from './EditDokumentDialog.vue';
import NowhowDatepicker from '@/components/NowhowDatepicker.vue';
import { DokumentFilterDto } from '../../../types/DokumentFilterDto';
import TextHighlights from '@/components/TextHighlight/TextHighlights.vue';
import { mixins } from 'vue-class-component';
import { TableHeaderFilterItem } from '@/types/TableHeaderFilterItem';
@Component({
    components: {
        SingleFilter,
        FilterDrawer,
        TableHeader,
        ConfirmDialog,
        EditDokumentDialog,
        NowhowDatepicker,
        TextHighlights
    }
})
export default class Archiv extends mixins(DateMixin) {
    @archivModul.Action(GET_ALL_DOKUMENTE) getFilteredDokumente!: Function;
    @archivModul.Action(GET_ALL_DOKUMENTTYPES) getDokumentTypes!: Function;
    @archivModul.Action(GET_ALL_FILETYPES) getFileTypes!: Function;
    @archivModul.Action(GET_ALL_ERSTELLTVON) getErstelltVon!: Function;
    @archivModul.Action(DOWNLOAD_DOKUMENT) downloadDokument!: Function;
    @archivModul.Action(DOWNLOAD_ALL_DOKUMENTE) downloadAllDokumente!: Function;
    @archivModul.Action(DOWNLOAD_MULTIPLE_DOKUMENTE) downloadMultipleDokumente!: Function;
    @archivModul.Action(OPEN_DOKUMENT) openDokument!: Function;
    @archivModul.Action(DELETE_DOKUMENT) deleteDokument!: Function;

    @archivModul.Getter(GETDOKUMENTE) dokumentList!: Array<DokumentApiDto>;
    @archivModul.Getter(GETDOKUMENTTYPES) dokumentTypes!: Array<object>;
    @archivModul.Getter(GETFILETYPES) fileTypes!: Array<object>;
    @archivModul.Getter(GETERSTELLTVON) erstelltVon!: Array<object>;
    @archivModul.Getter(LOADING) loading!: boolean;
    @archivModul.Getter(TOTAL_NUMBER_OF_RECORDS) totalNumberOfRecords!: number;

    @archivModul.Mutation(CLEARFILTER) clearFilter!: Function;
    @archivModul.Mutation(APPLYFILTER) applyFilters!: Function;

    @generalModul.Mutation(SUBTITLE) setSubTitle!: Function;
    @generalModul.Mutation(FOOTERTITLE) setFooterTitle!: Function;

    @securityModul.Getter(HASACCESS) hasAccess!: Function;
    @securityModul.Mutation(SETACCESSDENIED) setAccessDenied!: Function;
    @resourceModul.Getter(GET_LANGUAGE) language!: string;

    @headerModul.Action(NEW_ALERT) alertMessage!: Function;

    date = '';

    burgermenuActive = false;
    dialog = false;
    showSearchBar = false;
    searchField = '';
    showFilterDrawer = false;
    filters: Array<TableHeaderFilterItem> = [];
    filterData: DokumentFilterDto = new DokumentFilterDto();
    filterDataCopy: DokumentFilterDto = new DokumentFilterDto();
    timeFilter = 360;
    radios = 'selectTime';
    radiosCopy = '';
    options: any = { sortDesc: [true], sortBy: ['createdOn'] };
    //Liste der selektierten Dokumente
    selected: Array<DokumentApiDto> = [];
    maximumFileSize = '-';
    get headers(): Array<any> {
        return [
            {
                text: this.$t('archiv.table.header.datum'),
                value: 'createdOn'
            },
            {
                text: this.$t('archiv.table.header.dokumentname'),
                value: 'bezeichnung'
            },
            {
                text: this.$t('archiv.table.header.schlagworte'),
                value: 'schlagworte'
            },
            {
                text: this.$t('archiv.table.header.createdby'),
                value: 'erstelltVon'
            },
            {
                text: this.$t('archiv.table.header.dokumenttyp'),
                value: 'dokumentTypCode'
            },
            {
                text: this.$t('archiv.table.header.filetyp'),
                width: '95',
                value: 'dateiErweiterung'
            },
            {
                text: this.$t('archiv.table.header.download'),
                value: 'link',
                align: 'center',
                width: '10',
                sortable: false
            },
            {
                text: '',
                value: 'burgermenu',
                align: 'center',
                width: '10',
                sortable: false
            }
        ];
    }

    mounted() {
        if (!this.hasAccess('Navigation.Dokumentarchiv')) {
            this.setAccessDenied(true);
            this.alertMessage({ text: this.$t('errormessage.noaccesspage') });
        } else {
            this.setAccessDenied(false);
            this.filterData.zeitspanne = 'Last365Days';
            this.filters.push({
                key: 'archiv.filter.chip.title.datum',
                nameResourceKey: 'archiv.filter.chip.title.datum',
                value: this.$t('archiv.filter.chip.datum.selecttime').toString()
            });
            this.applyFilters(this.filterData);
            this.getFilteredDokumente(0);
            this.getDokumentTypes();
            this.getFileTypes();
            this.getErstelltVon();
        }
    }
    created() {
        this.setSubTitle(this.$t('archiv.title'));
        this.setFooterTitle('S300');
    }

    private clearFilters(closeFilter: boolean) {
        this.radios = 'selectTime';
        this.filterData = new DokumentFilterDto();
        this.filterData.zeitspanne = 'Last365Days';
        if (closeFilter) {
            this.clearFilter();
            this.filters = [];
            this.applyFilters(this.filterData);
            this.showFilterDrawer = false;
        }
    }

    private searchBarClosed() {
        this.showSearchBar = false;
        this.filterData.bezeichnung = undefined;
        this.filterData.schlagworte = undefined;
        this.applyFilters(this.filterData);
        this.getFilteredDokumente(0);
    }

    private deleteDokumentWrapper(dokumentID: number) {
        this.deleteDokument(dokumentID).then(() => {
            this.selected = [];
        });
    }

    private removeFilter(value: string) {
        this.filters = this.filters.filter(function(obj) {
            return obj.key !== value;
        });
        if (value === 'archiv.filter.chip.title.datum') {
            this.filterData.dateTo = undefined;
            this.filterData.dateFrom = undefined;
            this.filterData.zeitspanne = undefined;
            this.radios = 'allTime';
        } else if (value === 'archiv.table.header.dokumenttyp') {
            this.filterData.dokumentTyp = '';
        } else if (value === 'archiv.table.header.filetyp') {
            this.filterData.fileTyp = '';
        } else if (value === 'archiv.table.header.erstelltvon') {
            this.filterData.erstelltVon = '';
        }
        this.applyFilters(this.filterData);
        this.getFilteredDokumente(0);
    }

    private ShowActiveFilters() {
        this.filters = [];
        if (this.radios === 'specificTime') {
            this.filterData.zeitspanne = undefined;
            let filterValue = '';
            if (this.filterData.dateFrom && this.filterData.dateTo) {
                filterValue = this.filterData.dateFrom + ' - ' + this.filterData.dateTo;
            } else if (this.filterData.dateFrom) {
                filterValue = '<= ' + this.filterData.dateFrom;
            } else if (this.filterData.dateTo) {
                filterValue = '>= ' + this.filterData.dateTo;
            }

            this.filters = this.filters.concat({
                key: 'archiv.filter.chip.title.datum',
                nameResourceKey: 'archiv.filter.chip.title.datum',
                value: filterValue
            });
        }
        if (this.radios === 'allTime') {
            this.filterData.dateTo = undefined;
            this.filterData.dateFrom = undefined;
            this.filterData.zeitspanne = undefined;
        }
        if (this.radios === 'selectTime') {
            this.filterData.dateTo = undefined;
            this.filterData.dateFrom = undefined;
            this.filterData.zeitspanne = 'Last365Days';
            this.filters = this.filters.concat({
                key: 'archiv.filter.chip.title.datum',
                nameResourceKey: 'archiv.filter.chip.title.datum',
                value: this.$t('archiv.filter.chip.datum.selecttime').toString()
            });
        }
        if (this.filterData.dokumentTyp !== '') {
            this.filters = this.filters.concat({
                key: 'archiv.table.header.dokumenttyp',
                nameResourceKey: 'archiv.table.header.dokumenttyp',
                value: this.$t(this.filterData.dokumentTyp).toString()
            });
        }
        if (this.filterData.fileTyp !== '') {
            this.filters = this.filters.concat({
                key: 'archiv.table.header.filetyp',
                nameResourceKey: 'archiv.table.header.filetyp',
                value: this.$t(this.filterData.fileTyp).toString()
            });
        }
        if (this.filterData.erstelltVon !== '') {
            this.filters = this.filters.concat({
                key: 'archiv.table.header.erstelltvon',
                nameResourceKey: 'archiv.table.header.erstelltvon',
                value: this.$t(this.filterData.erstelltVon).toString()
            });
        }
    }

    private confirmFilter() {
        this.ShowActiveFilters();
        this.applyFilters(this.filterData);
        this.getFilteredDokumente(0);
        this.showFilterDrawer = false;
    }

    private cancelFilter() {
        this.filterData = Object.assign({}, this.filterDataCopy);
        this.radios = this.radiosCopy;
        this.showFilterDrawer = false;
    }

    @Watch('showFilterDrawer')
    onFilterDrawerOpened() {
        if (this.showFilterDrawer) {
            this.getDokumentTypes();
            this.getFileTypes();
            this.getErstelltVon();
            this.filterDataCopy = Object.assign({}, this.filterData);
            this.radiosCopy = this.radios;
        }
    }

    private customSort(items: any[]) {
        return items;
    }

    @Watch('options')
    onOptionsChanged() {
        this.filterData.sortBy = this.options.sortBy[0];
        this.filterData.sortOrder = this.options.sortDesc[0] ? 'Descending' : 'Ascending';
        this.applyFilters(this.filterData);
        this.getFilteredDokumente(0);
    }

    @Watch('language')
    onLanugageChanged() {
        this.ShowActiveFilters();
    }

    @Watch('selected')
    onSelectedFilesChanged() {
        let combinedFileSize = 0;
        this.selected.forEach(element => {
            combinedFileSize += element.groesseInBytes;
        });

        if (combinedFileSize > 0) {
            let i = -1;
            const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
            do {
                combinedFileSize = combinedFileSize / 1024;
                i++;
            } while (combinedFileSize > 1024);

            this.maximumFileSize = Math.max(combinedFileSize, 0.1).toFixed(1) + byteUnits[i];
        } else {
            this.maximumFileSize = '-';
        }
    }

    @Debounce(400)
    searchFieldInputChanged() {
        this.filterData.schlagworte = this.filterData.bezeichnung;
        this.applyFilters(this.filterData);
        this.getFilteredDokumente(0);
    }

    load() {
        this.getFilteredDokumente(this.dokumentList.length);
    }
    downloadMultipleDokumenteWrapper() {
        const selectedDokumenteGuids = this.selected.map(dokument => dokument.dokumentGuid);
        this.downloadMultipleDokumente({ ids: selectedDokumenteGuids });
    }
    downloadAllDokumenteWithFilter() {
        this.applyFilters(this.filterData);
        this.downloadAllDokumente(this.totalNumberOfRecords);
    }
}
</script>
