<template>
    <span v-on="$listeners">
        <template v-for="item in highlights">
            <template v-if="!item.isHighlighted">{{ item.text }}</template>
            <template v-else>
                <mark class="highlight" :key="item.index" :index="item.index" :text="item.text">{{ item.text }}</mark>
            </template>
        </template>
    </span>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import highlightChunks from './highlightChunks.js';

@Component
export default class TextHighlights extends Vue {
    /**
     * Text in dem gesucht werden soll und die Suchresultate markiert werden
     */
    @Prop({ required: false, type: [Number, String], default: '' })
    private text!: number | string;

    /**
     * Suchtext, dieser wird pro Leerschlag aufgetrennt und die einzelnen Wörter werden im Text gesucht und bei Treffer markiert
     */
    @Prop({ required: false, type: String, default: '' })
    private searchText!: string;

    /**
     * Casesensitiv für Suche
     */
    @Prop({ required: false, type: Boolean, default: false })
    caseSensitive: boolean;

    /**
     * Diakritische Zeichen bei der Suche unterscheiden (vgl. à â a)
     */
    @Prop({ required: false, type: Boolean, default: false })
    diacriticsSensitive: boolean;

    /**
     * Sollen nur geanze Wörter gesucht werden
     */
    @Prop({ required: false, type: Boolean, default: false })
    wholeWordMatch: boolean;

    /**
     * Soll der Suchtext durch Space in verschiedene einzelne Suchteile aufgetrennt werden
     */
    @Prop({ required: false, type: Boolean, default: true })
    splitBySpace: boolean;

    get searchTextSplittedBySpace() {
        return this.searchText.split(' ');
    }
    get highlights() {
        return highlightChunks(
            this.textAsString,
            this.splitBySpace ? this.searchTextSplittedBySpace : this.searchText,
            {
                caseSensitive: this.caseSensitive,
                diacriticsSensitive: this.diacriticsSensitive,
                wholeWordMatch: this.wholeWordMatch
            }
        );
    }

    get textAsString(): string {
        return this.text?.toString() ?? '';
    }
}
</script>

<style>
mark.highlight {
    background-color: #536dfe;
    color: white;
}
</style>
