import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { store } from '@/store';
import { AuthServiceCreator } from '@/services/authService';

import Home from '../areas/home/views/S200_Home.vue';
import Login from '../areas/home/views/Login.vue';
import Lieferantenrechnungen from '../areas/lieferantenrechnung/views/S250_Lieferantenrechnungen.vue';
import ProfilEinstellungen from '../areas/profileinstellungen/views/ProfilEinstellungen.vue';
import Registration from '../areas/registrierung/views/RegistrationForm.vue';
import Dokumentarchiv from '../areas/archiv/views/Dokumentarchiv.vue';
import Dokumentupload from '../areas/upload/views/Dokumentupload.vue';
import TeilnehmerAdmin from '../areas/administration/views/S900_TeilnehmerAdministration.vue';
import TakeTeilnehmer from '../areas/administration/views/S900_TakeTeilnehmer.vue';
import VetpointDaten from '../areas/administration/views/S920_VetpointDaten.vue';
import BenutzerSuche from '../areas/administration/views/S915_BenutzerSuche.vue';
import { LOGGEDIN } from '@/store/modules/security/mutations';
import { NAME as SecurityModule } from '@/store/modules/security';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    { path: '/', redirect: '/home' },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        props: route => ({ ...route.query, ...route.params })
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/lieferantenrechnungen',
        name: 'Lieferantenrechnungen',
        component: Lieferantenrechnungen,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/einstellungen',
        name: 'Einstellungen',
        component: ProfilEinstellungen,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/registration',
        name: 'Registration',
        component: Registration
    },
    {
        path: '/dokumentarchiv',
        name: 'Dokumentarchiv',
        component: Dokumentarchiv,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/dokumentupload',
        name: 'Dokumentupload',
        component: Dokumentupload,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/taketeilnehmer',
        name: 'TakeTeilnehmer',
        component: TakeTeilnehmer,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/teilnehmeradministration',
        name: 'TeilnehmerAdmin',
        component: TeilnehmerAdmin,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/benutzersuche',
        name: 'BenutzerSuche',
        component: BenutzerSuche,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/vetpointdaten',
        name: 'VetpointDaten',
        component: VetpointDaten,
        meta: {
            requiresAuth: true
        }
    },
    { path: '*', redirect: '/' }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const authService = await AuthServiceCreator.getAuthService();
        if (!(await authService.isLoggedIn())) {
            console.log('not logged in');
            store.commit(SecurityModule + '/' + LOGGEDIN, false);
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
