<template>
    <v-dialog v-model="visible" width="800">
        <template v-slot:activator="{}">
            <v-btn color="primary" @click="open">
                {{ $t('einstellungen.dialog.button') }}
            </v-btn>
        </template>
        <FocusLock>
            <form @submit.prevent="submit">
                <v-card>
                    <v-card-title class="headline font-weight-bold">
                        {{ $t('einstellungen.funktion.dialog.title') }}
                    </v-card-title>

                    <ValidationObserver ref="observer" validate v-slot="{ invalid }">
                        <v-card-text class="pb-0">
                            <h2 class="font-weight-bold">
                                {{ $t('einstellungen.funktion.dialog.title.lieferantenrechnung') }}
                            </h2>

                            <v-col sm="12" class="pt-0 multiline-checkbox">
                                <v-checkbox
                                    v-model="teilnehmerFunktionApiDtoCopy.hatRechnungenNurViaVetdata"
                                    :hide-details="true"
                                >
                                    <template v-slot:label>
                                        <b>
                                            {{
                                                $t(
                                                    'einstellungen.funktion.dialog.verzichtpostszustellunglieferantenrechnung'
                                                )
                                            }}
                                        </b>
                                        <br />
                                        {{
                                            $t(
                                                'einstellungen.funktion.dialog.verzichtpostszustellunglieferantenrechnung.hinweis'
                                            )
                                        }}
                                    </template>
                                </v-checkbox>
                            </v-col>
                            <v-col sm="12" class="pt-0 multiline-checkbox">
                                <v-checkbox
                                    v-model="emailSendenLieferantenRechnungnen"
                                    @change="checkboxUpdated"
                                    :hide-details="true"
                                >
                                    <template v-slot:label>
                                        {{ $t('einstellungen.funktion.dialog.zustellungemaillieferantenrechnung') }}
                                    </template>
                                </v-checkbox>
                                <ValidationProvider
                                    name="LieferantenEmail"
                                    :rules="
                                        getValidation('teilnehmerFunktionApiDto.emailLieferantenRechnungnen') +
                                            '|' +
                                            emailValidation
                                    "
                                    v-slot="{ errors }"
                                >
                                    <v-text-field
                                        :disabled="!emailSendenLieferantenRechnungnen"
                                        :label="$t('einstellungen.funktion.dialog.emaillieferantenrechnung') + '*'"
                                        placeholder="..."
                                        v-model="teilnehmerFunktionApiDtoCopy.emailLieferantenRechnungnen"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-card-text>

                        <v-card-text class="py-0">
                            <h2 class="font-weight-bold">
                                {{ $t('einstellungen.funktion.dialog.title.tvsdokumente') }}
                            </h2>
                            <v-col sm="12" class="py-0 multiline-checkbox">
                                <v-checkbox v-model="teilnehmerFunktionApiDtoCopy.hatTvsDokumenteNurDigital">
                                    <template v-slot:label>
                                        <b>
                                            {{ $t('einstellungen.funktion.dialog.zustellunglieferantenrechnung') }}
                                        </b>
                                        <br />
                                        {{ $t('einstellungen.funktion.dialog.zustellunglieferantenrechnung.hinweis') }}
                                    </template>
                                </v-checkbox>
                            </v-col>
                        </v-card-text>

                        <v-card-actions class="ml-4">
                            <v-btn :disabled="invalid" color="primary" type="submit" class="modalbutton mb-3">
                                {{ $t('components.dialog.save') }}
                            </v-btn>
                            <v-btn @click="visible = false" class="modalbutton mb-3">
                                {{ $t('components.dialog.cancel') }}
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </form>
        </FocusLock>
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { namespace } from 'vuex-class';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { NAME as NAME_EINSTELLUNGEN } from '../store';
const profilModul = namespace(NAME_EINSTELLUNGEN);

import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
const validationModul = namespace(NAME_VALIDATION);

import { EDIT_TEILNEHMERFUNKTION } from '../store/actions';
import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';
import { TeilnehmerFunktionApiDto } from '@/api-schema';
@Component({
    components: {
        ValidationProvider,
        ValidationObserver
    }
})
export default class EditFunktionenDialog extends Vue {
    @Prop({ required: true, type: Object })
    teilnehmerFunktionApiDto!: TeilnehmerFunktionApiDto | null;

    visible = false;
    teilnehmerFunktionApiDtoCopy: TeilnehmerFunktionApiDto;

    emailSendenLieferantenRechnungnen = false;
    @profilModul.Action(EDIT_TEILNEHMERFUNKTION) edit!: Function;
    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;

    $refs!: {
        observer: any;
    };

    onSave() {
        this.edit(this.teilnehmerFunktionApiDtoCopy).then(() => {
            this.visible = false;
        });
    }

    get emailValidation() {
        return this.emailSendenLieferantenRechnungnen ? 'required' : '';
    }
    submit() {
        this.edit(this.teilnehmerFunktionApiDtoCopy).then(() => {
            this.visible = false;
        });
    }

    open() {
        this.$emit('open');

        this.teilnehmerFunktionApiDtoCopy = Object.assign({}, this.teilnehmerFunktionApiDto);
        this.emailSendenLieferantenRechnungnen = this.teilnehmerFunktionApiDto.emailLieferantenRechnungnen?.length > 0;

        this.visible = true;
    }

    checkboxUpdated(newValue: boolean) {
        if (!newValue) {
            this.teilnehmerFunktionApiDtoCopy.emailLieferantenRechnungnen = null;
        }
    }
}
</script>

<style>
.multiline-checkbox .v-input--selection-controls .v-input__slot > .v-label {
    display: block;
}
</style>
