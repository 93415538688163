<template>
    <v-dialog v-model="visible" width="500">
        <template v-slot:activator="{}">
            <v-btn class="px-1 mr-2" color="primary" @click="open">
                {{ $t('einstellungen.teilnehmerzugriff.dialog.title') }}
            </v-btn>
        </template>
        <FocusLock>
            <form @submit.prevent="submit">
                <v-card>
                    <v-card-title class="headline font-weight-bold mb-2">
                        {{ $t('einstellungen.teilnehmerzugriff.dialog.title') }}
                    </v-card-title>

                    <modal-header-notification></modal-header-notification>

                    <v-card-text class="pb-0 pt-0">
                        <v-select
                            :items="teilnehmerZugriffCreateList"
                            :label="$t('benutzerrollen.sapraxisgruppe')"
                            v-model="selectedTeilnehmer"
                        ></v-select>
                    </v-card-text>

                    <v-card-actions class="ml-4">
                        <v-btn color="primary" type="submit" class="modalbutton mb-3">
                            {{ $t('components.dialog.save') }}
                        </v-btn>
                        <v-btn @click="visible = false" class="modalbutton mb-3">
                            {{ $t('components.dialog.cancel') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </form>
        </FocusLock>
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Ref } from 'vue-property-decorator';
import { TeilnehmerZugriffApiDto, TeilnehmerZugriffCreateApiDto, TeilnehmerZugriffPostApiDto } from '@/api-schema';

import { namespace } from 'vuex-class';

import { NAME as NAME_EINSTELLUNGEN } from '../store';
const profilModul = namespace(NAME_EINSTELLUNGEN);

import { NAME as NAME_HEADERNOTIFICATION } from '@/store/modules/headerNotification';
const headerNotificationModul = namespace(NAME_HEADERNOTIFICATION);

import { CREATE_TEILNEHMER_ZUGRIFF, GET_ZUGRIFF_CREATE_INFO } from '../store/actions';
import { GETCREATETEILNEHMERZUGRIFFELIST, GETTEILNEHMERZUGRIFFE } from '../store/getters';
import { CLOSE_MODAL, NEW_MODAL_ERROR } from '@/store/modules/headerNotification/actions';

import modalHeaderNotification from '@/components/ModalHeaderNotification.vue';
@Component({
    components: {
        modalHeaderNotification
    }
})
export default class AddTeilnehmerZugriffDialog extends Vue {
    private visible = false;
    private selectedTeilnehmer: number;

    @profilModul.Action(CREATE_TEILNEHMER_ZUGRIFF) add!: Function;
    @profilModul.Action(GET_ZUGRIFF_CREATE_INFO) getTeilnehmerZugriffCreateInfo!: Function;
    @headerNotificationModul.Action(CLOSE_MODAL) closeHeader!: Function;
    @headerNotificationModul.Action(NEW_MODAL_ERROR) errorModal!: Function;

    @profilModul.Getter(GETCREATETEILNEHMERZUGRIFFELIST) teilnehmerZugriffCreateList!: Array<any>;
    @profilModul.Getter(GETTEILNEHMERZUGRIFFE) teilnehmerZugriffe!: Array<TeilnehmerZugriffApiDto>;

    private onSave() {
        const teilnehmer: TeilnehmerZugriffPostApiDto = {
            zugriffFuerTeilnehmerId: this.selectedTeilnehmer,
            benutzerRolle: 'SaPraxisGruppe'
        };
        this.add(teilnehmer).then(response => {
            this.visible = false;
        });
    }

    private open() {
        this.closeHeader();
        this.$emit('open');
        this.getTeilnehmerZugriffCreateInfo().then(() => {
            this.selectedTeilnehmer = null;
            this.visible = true;
        });
    }

    submit() {
        const selectedTeilnehmer = this.teilnehmerZugriffCreateList.find(z => z.value == this.selectedTeilnehmer);
        let alreadyExists = false;
        for (var i = 0; i < this.teilnehmerZugriffe.length; i++) {
            if (this.teilnehmerZugriffe[i].teilnehmerName == selectedTeilnehmer.text) {
                alreadyExists = true;
                break;
            }
        }
        if (!alreadyExists) {
            this.onSave();
        } else {
            this.errorModal({ text: this.$t('einstellungen.teilnehmerzugriff.dialog.error.duplicate') });
        }
    }
}
</script>
