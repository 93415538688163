import { AxiosRequestConfigWithInterceptorParams } from '@/types/AxiosRequestConfigWithInterceptorParams';
import "core-js/stable";
import axios, { AxiosResponse } from 'axios';
import qs from 'qs';
const CancelToken = axios.CancelToken;
const source = CancelToken.source();
export const axiosCancelToken = source;

export default class BaseService {
    protected BASE_URL: string | undefined = process.env.BASE_URL + 'api/';

    protected deleteConfig: AxiosRequestConfigWithInterceptorParams = {
        headers: {
            __RequestVarificationToken: this._getCsrfToken()
        },

        cancelToken: axiosCancelToken.token,
        withErrorHandling: true,
    };

    protected getConfig: AxiosRequestConfigWithInterceptorParams = {
        cancelToken: source.token,
        withErrorHandling: true,
    };

    protected postConfig: AxiosRequestConfigWithInterceptorParams = {
        headers: {
            'Content-Type': 'application/json',
            __RequestVarificationToken: this._getCsrfToken()
        },
        cancelToken: source.token,
        withErrorHandling: true,
    };

    protected putConfig: AxiosRequestConfigWithInterceptorParams = {
        headers: {
            'Content-Type': 'application/json',
            __RequestVarificationToken: this._getCsrfToken()
        },
        cancelToken: source.token,
        withErrorHandling: true,
    };

    /**
     * Konfiguration für Upload
     *
     * Funktion um den Uploadvortschritt zu ermitteln:
     * <code>
     *  onUploadProgress: function (progressEvent: ProgressEvent) {
     *      this.uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
     *  }.bind(this)
     * </code>
     * */
    protected uploadConfig: AxiosRequestConfigWithInterceptorParams = {
        headers: {
            'Content-Type': 'multipart/form-data',
            __RequestVarificationToken: this._getCsrfToken()
        },
        cancelToken: source.token,
        withErrorHandling: true,

    };

    protected getDownloadConfig: AxiosRequestConfigWithInterceptorParams = {
        headers: {
            'X-Requested-With': 'XMLHttpRequest'
        },
        cancelToken: source.token,
        withErrorHandling: true,
        responseType: 'blob'
    };

    protected async DELETE(url: string, config = this.deleteConfig, withErrorHandling = true): Promise<AxiosResponse> {
        const encodedUrl = encodeURI(url);
        console.log('API Call:=', url);
        config.withErrorHandling = withErrorHandling;

        return axios.delete(encodedUrl, config).then(
            response => {
                this.logResponse(response);
                return response.data;
            }
        );
    }

    protected async GET<T>(url: string, config = this.getConfig, withErrorHandling = true): Promise<T> {
        const encodedUrl = encodeURI(url);
        console.log('API Call:=', url);
        config.withErrorHandling = withErrorHandling;

        return axios.get(encodedUrl, config).then(
            response => {
                this.logResponse(response);
                return response.data;
            }
        );
    }

    protected async POST<T>(url: string, data: T, config = this.postConfig, withErrorHandling = true): Promise<AxiosResponse> {
        const encodedUrl = encodeURI(url);
        console.log('API Call:=', url, 'data', data);
        config.withErrorHandling = withErrorHandling;

        return axios.post(encodedUrl, data, config).then(
            response => {
                this.logResponse(response);
                return response.data;
            }
        );
    }

    protected async PUT<T>(url: string, data: T, config = this.putConfig, withErrorHandling = true): Promise<AxiosResponse> {
        const encodedUrl = encodeURI(url);
        console.log('API Call:=', url, 'data', data);
        config.withErrorHandling = withErrorHandling;

        return axios.put(encodedUrl, data, config).then(
            response => {
                this.logResponse(response);
                return response.data;
            }
        );
    }

    protected async DOWNLOAD<T>(url: string, config = this.getDownloadConfig, withErrorHandling = true): Promise<any> {
        const encodedUrl = encodeURI(url);
        console.log('API Call:=', url);
        config.withErrorHandling = withErrorHandling;

        return axios.get(encodedUrl, config).then(
            response => {
                this.logResponse(response);
                return response;
            }
        );
    }

    protected serializeObjectToQuerystring(obj: any) {
        return qs.stringify(obj, { addQueryPrefix: true, encode: false });
    }

    _buildFormData(formData: any, data: any, parentKey: any = null) {
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                this._buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
            });
        } else {
            const value = data == null ? '' : data;
            formData.append(parentKey, value);
        }
    }

    logResponse(response: AxiosResponse<any>) {
        console.log(response.config.url, response);
    }

    private _getCsrfToken(): string {
        //get Token
        const elements = document.getElementsByName('__RequestVarificationToken');
        if (elements.length > 0) {
            return (elements[0] as HTMLInputElement).value;
        }

        return '';
    }
}
