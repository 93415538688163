import { TakeTeilnehmerApiDto, TeilnehmerApiDto, TeilnehmerFunktionApiDto } from '@/api-schema';

export class TakeTeilnehmerDto implements TakeTeilnehmerApiDto {
    constructor(rolle: string, nummer: number) {
        this.benutzerRolle = rolle;
        this.tvsKundennummer = nummer;
    }
    benutzerRolle: string;
    teilnehmerId?: number;
    tvsKundennummer?: number;

}

export class RegisterDto implements TeilnehmerApiDto {
    constructor() {
        this.anredeTypCode = '';
        this.titelTypCode = '';
        this.name = '';
        this.vorname = '';
        this.firma = '';
        this.strasse = '';
        this.zusatz = '';
        this.ort = '';
        this.telefon = '';
        this.telefonMobil = '';
        this.sprache = '';
        this.email = '';
    }
    kundenNummer: number | undefined;
    anredeTypCode: string;
    titelTypCode: string;
    name: string;
    vorname: string;
    firma: string;
    strasse: string;
    zusatz: string;
    plz: number | undefined;
    ort: string;
    telefon: string;
    telefonMobil: string;
    sprache: string;
    email: string;
}

export class TeilnehmerFunktionDto implements TeilnehmerFunktionApiDto {
    constructor() {
        this.emailLieferantenRechnungnen = '';
    }
    emailLieferantenRechnungnen?: string | null; // email
    hatRechnungenNurViaVetdata?: boolean;
    hatTvsDokumenteNurDigital?: boolean;
    rowVersion?: string | null;
}

export class TeilnehmerBenutzerDto {
    constructor() {
        this.id = '';
        this.email = '';
        this.vorname = '';
        this.nachname = '';
        this.fullName = '';
        this.teilnehmerId = -1;
    }
    id?: string;
    email?: string;
    vorname?: string;
    nachname?: string;
    fullName?: string;
    teilnehmerId?: number;
}
