export default function mergeRange([...ranges]) {
    if (!ranges.length) {
        return [];
    }

    ranges.sort((fir, sec) => {
        if (fir[0] !== sec[0]) {
            return fir[0] - sec[0];
        }
        return fir[1] - sec[1];
    });

    const merged = [];

    let curStart = ranges[0][0];
    let curEnd = ranges[0][1];

    ranges.shift();

    ranges.forEach(([start, end]) => {
        if (start >= curEnd) {
            merged.push([curStart, curEnd]);
            curStart = start;
            curEnd = end;
        } else if (end > curEnd) {
            curEnd = end;
        }
    });

    merged.push([curStart, curEnd]);

    return merged;
}
