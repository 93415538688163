import { MutationTree } from 'vuex';
import { ITakeTeilnehmerState } from './state';
import { ArtikelApiDto, BenutzerInfoSearchResultApiDto, TakeTeilnehmerApiDto, TeilnehmerListApiDto, VetdataVerarbeitungLogApiDto } from '@/api-schema';
import { ArtikelFilterDto } from '@/types/ArtikelFilterDto';
import { TeilnehmerFilterDto } from '@/types/TeilnehmerFilterDto';
import { BenutzerInfoSearchApiDto } from '@/types/BenutzerInfoSearchApiDto';

export const MAPTakeTeilnehmerDATA = 'mapTakeTeilnehmerApiDtoData';
export const ADD_TEILNEHMERLIST = 'addTeilnehmerList';
export const MAP_TEILNEHMERLIST = 'mapTeilnehmerList';
export const CLEAR_ARTIKELFILTER = 'clearArtikelFilter';
export const APPLY_ARTIKELFILTER = 'mapArtikelFilter';
export const CLEAR_TEILNEHMERFILTER = 'clearTeilnehmerFilter';
export const APPLY_TEILNEHMERFILTER = 'mapTeilnehmerFilter';
export const CLEAR_BENUTZERFILTER = 'clearBenutzerFilter';
export const APPLY_BENUTZERFILTER = 'mapBenutzerFilter';
export const LOADING_BENUTZER = 'mapBenutzerLoading';
export const MAPBENUTZERDATA = 'mapBenutzerData';
export const LOADING_TEILNEHMER = 'mapTeilnehmerLoading';
export const LOADING_ARTIKEL = 'mapArtikelLoading';
export const OFFSET_ARTIKEL = 'mapArtikelOffset';
export const OFFSET_TEILNEHMER = 'mapTeilnehmerOffset';
export const TOTAL_NUMBER_OF_RECORDS_TEILNEHMER = 'totalNumberOfRecordsTeilnehmer';
export const TOTAL_NUMBER_OF_RECORDS_ARTIKEL = 'totalNumberOfRecordsArtikel';
export const MAPARTIKEL = 'mapArtikelList';
export const ADDARTIKEL = 'addArtikelToList';
export const MAP_VETDATA_LOG = 'mapVetdataLog';

export const mutations: MutationTree<ITakeTeilnehmerState> = {
    [MAPTakeTeilnehmerDATA](state, value: TakeTeilnehmerApiDto) {
        state.teilnehmer = value;
    },
    [ADD_TEILNEHMERLIST](state, value: Array<TeilnehmerListApiDto>) {
        state.teilnehmerList = state.teilnehmerList.concat(value);
    },
    [MAP_TEILNEHMERLIST](state, value: Array<TeilnehmerListApiDto>) {
        state.teilnehmerList = value;
    },
    [CLEAR_ARTIKELFILTER](state) {
        state.artikelQueryFilter = new ArtikelFilterDto();
    },
    [APPLY_ARTIKELFILTER](state, value: ArtikelFilterDto) {
        state.artikelQueryFilter.searchQuery = value.searchQuery;
        state.artikelQueryFilter.sortBy = value.sortBy;
        state.artikelQueryFilter.sortOrder = value.sortOrder;
        state.artikelQueryFilter.offset = 0;
    },
    [CLEAR_TEILNEHMERFILTER](state) {
        state.teilnehmerFilter = new TeilnehmerFilterDto();
    },
    [APPLY_TEILNEHMERFILTER](state, value: TeilnehmerFilterDto) {
        state.teilnehmerFilter.status = value.status;
        state.teilnehmerFilter.suchText = value.suchText;
        state.teilnehmerFilter.hatRechnungenNurViaVetdata = value.hatRechnungenNurViaVetdata;
        state.teilnehmerFilter.hatTvsDokumenteNurDigital = value.hatTvsDokumenteNurDigital;
        state.teilnehmerFilter.suchText = value.suchText;
        state.teilnehmerFilter.sortBy = value.sortBy;
        state.teilnehmerFilter.sortOrder = value.sortOrder;
        state.teilnehmerFilter.offset = 0;
    },
    [CLEAR_BENUTZERFILTER](state) {
        state.benutzerFilter = new BenutzerInfoSearchApiDto();
        state.benutzerInfoSearchResultApiDto = null;
    },
    [APPLY_BENUTZERFILTER](state, value: BenutzerInfoSearchApiDto) {
        state.benutzerFilter = value;
    },
    [OFFSET_TEILNEHMER](state, value: number) {
        state.teilnehmerFilter.offset = value;
    },
    [LOADING_BENUTZER](state, value: boolean) {
        state.benutzerLoading = value;
    },
    [MAPBENUTZERDATA](state, benutzerInfoSearchResultApiDto: BenutzerInfoSearchResultApiDto) {
        state.benutzerInfoSearchResultApiDto = benutzerInfoSearchResultApiDto;
    },
    [LOADING_TEILNEHMER](state, value: boolean) {
        state.teilnehmerLoading = value;
    },
    [LOADING_ARTIKEL](state, value: boolean) {
        state.artikelLoading = value;
    },
    [OFFSET_ARTIKEL](state, value: number) {
        state.artikelQueryFilter.offset = value;
    },
    [TOTAL_NUMBER_OF_RECORDS_TEILNEHMER](state, value: number) {
        state.teilnehmerTotalNumberOfRecords = value;
    },
    [TOTAL_NUMBER_OF_RECORDS_ARTIKEL](state, value: number) {
        state.artikelTotalNumberOfRecords = value;
    },
    [MAPARTIKEL](state, value: Array<ArtikelApiDto>) {
        state.artikelList = value;
    },
    [ADDARTIKEL](state, value: Array<ArtikelApiDto>) {
        state.artikelList = state.artikelList.concat(value);
    },
    [MAP_VETDATA_LOG](state, value: VetdataVerarbeitungLogApiDto) {
        state.vetdataLog = value;
    }
};
