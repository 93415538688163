<template>
    <v-row class="ml-0">
        <v-col sm="12">
            <h5 v-text="title" class="pb-1" />
            <slot />
        </v-col>
    </v-row>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SingleFilter extends Vue {
    @Prop({ required: true, type: String })
    private title!: string;
}
</script>
