<template>
    <v-list nav dense>
        <v-list-item>
            <v-list-item-action v-if="$vuetify.breakpoint.lgAndUp">
                <v-app-bar-nav-icon
                    @click="$emit('mini-variant-changed', !isMiniVariant)"
                    style="font-size: 24px; height: 24px; width: 24px;"
                />
            </v-list-item-action>
            <v-list-item-content>
                <router-link :to="{ name: 'Home' }">
                    <div style="height:32px; width:127px">
                        <v-img v-if="!isMiniVariant" src="../assets/vetdata-Logo.jpg"></v-img>
                    </div>
                </router-link>
            </v-list-item-content>
        </v-list-item>
        <v-divider class="mb-2"></v-divider>
        <template v-if="loggedIn">
            <v-list-item
                link
                @click="setAccessDenied(false)"
                v-if="hasAccess('Navigation.Kontobrowser')"
                :to="{ name: 'Home' }"
            >
                <v-list-item-action>
                    <v-icon>mdi-home-account</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.tvskonto') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item
                link
                @click="setAccessDenied(false)"
                v-if="hasAccess('Navigation.Lieferantenrechnung')"
                :to="{ name: 'Lieferantenrechnungen' }"
            >
                <v-list-item-action>
                    <v-icon>mdi-format-list-bulleted</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.lieferantenrechnung') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item
                link
                @click="setAccessDenied(false)"
                v-if="hasAccess('Navigation.Dokumentarchiv')"
                :to="{ name: 'Dokumentarchiv' }"
            >
                <v-list-item-action>
                    <v-icon>mdi-magnify</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.dokumentarchiv') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item
                link
                @click="setAccessDenied(false)"
                v-if="hasAccess('Navigation.Dokumentupload')"
                :to="{ name: 'Dokumentupload' }"
            >
                <v-list-item-action>
                    <v-icon>mdi-upload</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.dokumentupload') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item
                link
                @click="setAccessDenied(false)"
                v-if="hasAccess('Navigation.Benutzereinstellungen')"
                :to="{ name: 'Einstellungen' }"
            >
                <v-list-item-action>
                    <v-icon>mdi-account-cog</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.einstellungen') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- ToDo: umhängen nachdem der richtige S900 umgesetzt wurde -->
            <template v-if="hasAccess('Navigation.TeilnehmerAdministration')">
                <v-list-item
                    link
                    @click="setAccessDenied(false)"
                    v-if="hasAccess('Navigation.TeilnehmerAdministration')"
                    :to="{ name: 'TeilnehmerAdmin' }"
                >
                    <v-list-item-action>
                        <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.teilnehmeradmin') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-if="hasAccess('Navigation.BenutzerSuche')">
                <v-list-item
                    link
                    @click="setAccessDenied(false)"
                    v-if="hasAccess('Navigation.BenutzerSuche')"
                    :to="{ name: 'BenutzerSuche' }"
                >
                    <v-list-item-action>
                        <v-icon>mdi-account-search</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.benutzerSuche') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-if="hasAccess('Navigation.VetpointDaten')">
                <v-list-item
                    link
                    @click="setAccessDenied(false)"
                    v-if="hasAccess('Navigation.VetpointDaten')"
                    :to="{ name: 'VetpointDaten' }"
                >
                    <v-list-item-action>
                        <v-icon>mdi-format-list-bulleted</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('vetpointdaten.title') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
            <template v-if="hasAccess('Navigation.TakeTeilnehmer')">
                <v-list-item
                    link
                    @click="setAccessDenied(false)"
                    v-if="hasAccess('Navigation.TakeTeilnehmer')"
                    :to="{ name: 'TakeTeilnehmer' }"
                >
                    <v-list-item-action>
                        <v-icon>mdi-account-convert</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.TakeTeilnehmer') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </template>
    </v-list>
</template>

<script lang="ts">
import { Component, Prop, Vue, Inject } from 'vue-property-decorator';

import { namespace } from 'vuex-class';

import { NAME as NAME_SECURITY } from '@/store/modules/security';
const securityModul = namespace(NAME_SECURITY);

import { ISLOGGEDIN, HASACCESS } from '@/store/modules/security/getters';
import { LOGOUT } from '@/store/modules/security/actions';
import { SETACCESSDENIED } from '@/store/modules/security/mutations';

@Component
export default class Navigation extends Vue {
    @Prop({ required: false, type: Boolean, default: false })
    private isMiniVariant!: boolean;

    @securityModul.Action(LOGOUT) logout!: Function;
    @securityModul.Getter(ISLOGGEDIN) loggedIn!: boolean;
    @securityModul.Getter(HASACCESS) hasAccess!: Function;
    @securityModul.Mutation(SETACCESSDENIED) setAccessDenied!: Function;
}
</script>
