import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { IHeaderNotificationState } from './state';

export const GET_VISIBLE = 'getVisible';
export const GET_TYPE = 'getType';
export const GET_TEXT = 'getText';
export const GET_CLOSEDELAY = 'getCloseDelay';
export const GET_CLOSEAFTERDELAY = 'getCloseAfterDelay';
export const GET_DISMISSIBLE = 'getDismissible';
export const GET_MODAL_VISIBLE = 'getModalVisible';
export const GET_MODAL_TYPE = 'getModalType';
export const GET_MODAL_TEXT = 'getModalText';
export const GET_MODAL_CLOSEDELAY = 'getModalCloseDelay';
export const GET_MODAL_CLOSEAFTERDELAY = 'getModalCloseAfterDelay';
export const GET_MODAL_DISMISSIBLE = 'getModalDismissible';

export const getters: GetterTree<IHeaderNotificationState, IRootState> = {
    [GET_VISIBLE]: state => state.visible,
    [GET_TYPE]: state => state.type,
    [GET_TEXT]: state => state.text,
    [GET_CLOSEAFTERDELAY]: state => state.closeAfterDelay,
    [GET_CLOSEDELAY]: state => state.closeDelay,
    [GET_DISMISSIBLE]: state => state.isDismissible,
    [GET_MODAL_VISIBLE]: state => state.modalVisible,
    [GET_MODAL_TYPE]: state => state.modalType,
    [GET_MODAL_TEXT]: state => state.modalText,
    [GET_MODAL_CLOSEAFTERDELAY]: state => state.modalCloseAfterDelay,
    [GET_MODAL_CLOSEDELAY]: state => state.modalCloseDelay,
    [GET_MODAL_DISMISSIBLE]: state => state.modalIsDismissible
};
