import BaseService from './baseService';

export default class ConfigService extends BaseService {
    private RESOURCE_URL: string;
    constructor() {
        super();
        this.RESOURCE_URL = `${this.BASE_URL}startup`;

        console.debug('ConfigService::constructor', this.RESOURCE_URL);
    }
    // eslint-disable-next-line
    async get(): Promise<any> {
        // eslint-disable-next-line
        return super.GET(`${this.RESOURCE_URL}/FrontendConfig`).then((response: any) => {
            return response;
        });
    }
}

export const configService = new ConfigService();
