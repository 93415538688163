import { MutationTree } from 'vuex';
import { IValidationState } from './state';

export const SET_RULES = 'setValidationRules';
export const SET_ALLOWED_FILETYPES = 'setAllowedFileTypes';

export const mutations: MutationTree<IValidationState> = {
    [SET_RULES]: (state, value: Record<string, string>) => {
        state.validationRules = value;
    },
    [SET_ALLOWED_FILETYPES]: (state, value: Array<string>) => {
        state.allowedFileTypes = value;
    }
};
