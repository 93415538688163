<template>
    <v-alert
        class="modalHeaderNotification"
        :value="alertVisible"
        @input="setVisible"
        :type="alertType"
        :dismissible="isDismissible"
    >
        {{ $t(alertText) }}
    </v-alert>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NAME as NAME_HEADERNOTIFICATION } from '@/store/modules/headerNotification';
const headerNotificationModul = namespace(NAME_HEADERNOTIFICATION);
import {
    GET_MODAL_VISIBLE,
    GET_MODAL_TYPE,
    GET_MODAL_TEXT,
    GET_MODAL_CLOSEDELAY,
    GET_MODAL_CLOSEAFTERDELAY,
    GET_MODAL_DISMISSIBLE
} from '@/store/modules/headerNotification/getters';

import { SET_MODAL_VISIBLE } from '@/store/modules/headerNotification/mutations';

import { CLOSE_MODAL } from '@/store/modules/headerNotification/actions';

@Component
export default class ModalHeaderNotification extends Vue {
    @headerNotificationModul.Getter(GET_MODAL_VISIBLE) alertVisible!: boolean;
    @headerNotificationModul.Getter(GET_MODAL_TYPE) alertType!: string;
    @headerNotificationModul.Getter(GET_MODAL_TEXT) alertText!: string;
    @headerNotificationModul.Getter(GET_MODAL_CLOSEDELAY) closeDelay!: number;
    @headerNotificationModul.Getter(GET_MODAL_CLOSEAFTERDELAY) closeAfterDelay!: boolean;
    @headerNotificationModul.Getter(GET_MODAL_DISMISSIBLE) isDismissible!: boolean;

    @headerNotificationModul.Action(CLOSE_MODAL) close!: Function;

    @headerNotificationModul.Mutation(SET_MODAL_VISIBLE) setVisible!: Function;

    @Watch('alertVisible')
    onVisibleChangedOpen() {
        if (this.alertVisible) {
            if (this.closeAfterDelay && this.closeDelay > 0) {
                setTimeout(() => {
                    this.close();
                }, this.closeDelay);
            }
        }
    }
}
</script>

<style></style>
