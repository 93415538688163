import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { IValidationState } from './state';

export const GET_VALIDATION_KEY = 'getValidationWithKey';
export const GET_ALLOWED_FILETYPES = 'getAllowedFileTypes';

export const getters: GetterTree<IValidationState, IRootState> = {
    [GET_VALIDATION_KEY]: state => (key: string): string | null => {
        const validation = state.validationRules[key];
        return validation || null;
    },
    [GET_ALLOWED_FILETYPES]: state => state.allowedFileTypes,
};
