import { Vue, Component } from 'vue-property-decorator';

@Component
export class DateMixin extends Vue {
    public toSwissDateString(date: Date): string {
        let dd = date.getDate().toString();
        let mm = (date.getMonth() + 1).toString(); //Januar ist 0!

        const yyyy = date.getFullYear();
        //führende nullen anfügen
        if (dd.length < 2) {
            dd = '0' + dd;
        }
        if (mm.length < 2) {
            mm = '0' + mm;
        }
        const swissDateString = dd + '.' + mm + '.' + yyyy;
        return swissDateString;
    }
    public toIsoDateString(date: Date): string {
        return date.toISOString().split('T')[0];
    }

    public parseSwissDate(swissDate: string): Date | null {
        const parseResult = this.tryParseSwissDate(swissDate);
        if (parseResult.success && parseResult.date) {
            return parseResult.date;
        }
        return null;
    }

    public tryParseSwissDate(swissDate: string): DateResult {
        if (swissDate) {
            const splittedDate = swissDate.split('.');
            if (
                splittedDate.length === 3 &&
                this.isIntNumber(splittedDate[0]) &&
                this.isIntNumber(splittedDate[1]) &&
                this.isIntNumber(splittedDate[2])
            ) {
                //Monat beginnt mit 0 und nicht 1
                const date = new Date(
                    Date.UTC(
                        this.getNumber(splittedDate[2]),
                        this.getNumber(splittedDate[1]) - 1,
                        this.getNumber(splittedDate[0])
                    )
                );
                return { success: true, date: date };
            }
        }
        return { success: false };
    }

    public parseIsoDate(isoDate: string): Date | null {
        const parseResult = this.tryParseIsoDate(isoDate);
        if (parseResult.success && parseResult.date) {
            return parseResult.date;
        }
        return null;
    }

    public tryParseIsoDate(isoDate: string): DateResult {
        if (isoDate) {
            //Monat beginnt mit 0 und nicht 1
            const date = new Date(isoDate);
            return { success: true, date: date };
        }
        return { success: false };
    }

    private getNumber(numberAsString: string): number {
        if (this.isIntNumber(numberAsString)) {
            return parseInt(numberAsString);
        }
        return 0;
    }

    private isIntNumber(numberAsString: string): boolean {
        const parsed = parseInt(numberAsString);
        if (isNaN(parsed)) {
            return false;
        }
        return true;
    }
}

export interface DateResult {
    success: boolean;
    date?: Date | null;
}
