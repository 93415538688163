<template>
    <v-dialog v-model="visible" width="800">
        <template v-slot:activator="{}">
            <v-btn color="primary" @click="open">
                {{ $t('einstellungen.dialog.button') }}
            </v-btn>
        </template>
        <FocusLock>
            <form @submit.prevent="submit">
                <v-card>
                    <v-card-title class="headline font-weight-bold mb-2">
                        {{ $t('einstellungen.servicedialog.titel') }}
                    </v-card-title>

                    <v-card-text class="pb-0">
                        <v-row>
                            <v-col sm="5" class="py-0">
                                <h3>{{ $t('einstellungen.servicedialog.subtitel.basis') }}</h3>
                            </v-col>
                            <v-col sm="3" class="py-0 text-center">{{
                                $t('einstellungen.servicedialog.table.speicherplatz')
                            }}</v-col>
                            <v-col sm="2" class="py-0">{{ $t('einstellungen.servicedialog.table.kosten') }}</v-col>
                            <v-col sm="2" class="py-0">{{ $t('einstellungen.servicedialog.table.kosten') }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="6" class="py-1">
                                {{ $t('einstellungen.servicedialog.table.basis') }}
                            </v-col>
                            <v-col sm="2" class="py-0">{{ $t('einstellungen.servicedialog.table.limit') }}</v-col>
                            <v-col sm="2" class="py-0">{{ $t('einstellungen.servicedialog.table.monatrate') }}</v-col>
                            <v-col sm="2" class="py-0">{{ $t('einstellungen.servicedialog.table.upgrade') }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="6" class="py-1">
                                {{ userServices.basisService.beschreibungDe }}
                            </v-col>
                            <v-col sm="2" class="py-1">{{ userServices.basisService.limiteGigaByte }} GB</v-col>
                            <v-col sm="2" class="py-1">{{ userServices.basisService.preisProMonat.toFixed(2) }}</v-col>
                            <v-col sm="2" class="py-1">0.00</v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="12" class="pb-0">
                                <h3>{{ $t('einstellungen.servicedialog.subtitle.speicher') }}</h3>
                            </v-col>
                        </v-row>
                        <v-row v-if="checkNumberOfServices">
                            <v-radio-group v-model="speicher" style="flex: auto;">
                                <v-row v-for="(row, index) in this.services.speicherServices" :key="index">
                                    <v-col sm="6" class="py-1">
                                        <v-radio :value="row.serviceId" :label="row.beschreibungDe"></v-radio>
                                    </v-col>
                                    <v-col sm="2" class="py-1 listvalues">{{ row.limiteGigaByte }} GB</v-col>
                                    <v-col sm="2" class="py-1 listvalues">{{ row.preisProMonat.toFixed(2) }}</v-col>
                                    <v-col
                                        v-if="
                                            speicher === row.serviceId &&
                                                speicher === userServices.speicherService.serviceId
                                        "
                                        sm="2"
                                        class="py-1 listvalues"
                                        >0.00</v-col
                                    >
                                    <v-col v-else-if="speicher === row.serviceId" sm="2" class="py-1 listvalues">{{
                                        row.preisProRata.toFixed(2)
                                    }}</v-col>
                                    <v-col v-else sm="2" class="py-1 listvalues"></v-col>
                                </v-row>
                            </v-radio-group>
                        </v-row>
                        <v-row v-else>
                            <v-col sm="12" class="py-1 pt-2 pb-0">
                                {{ $t('einstellungen.servicedialog.speicher.empty') }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col sm="8" class="pt-8">
                                <h3>
                                    {{ $t('einstellungen.servicedialog.total') }}
                                    <span v-if="userVerrechnung != null" style="font-size: 0.9em;">
                                        {{
                                            $t('einstellungen.servicedialog.totaldatumvon') +
                                                ' ' +
                                                userVerrechnung.verrechnungVon +
                                                $t('einstellungen.servicedialog.totaldatumbis') +
                                                ' ' +
                                                userVerrechnung.verrechnungBis
                                        }})
                                    </span>
                                </h3>
                            </v-col>
                            <v-col sm="2" class="pt-8">{{ totalMonatKosten.toFixed(2) }}</v-col>
                            <v-col sm="2" class="pt-8">{{ totalRataKosten.toFixed(2) }}</v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="ml-4">
                        <v-btn color="primary" type="submit" class="modalbutton mb-3">
                            {{ $t('components.dialog.save') }}
                        </v-btn>
                        <v-btn @click="visible = false" class="modalbutton mb-3">
                            {{ $t('components.dialog.cancel') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </form>
        </FocusLock>
    </v-dialog>
</template>

<script lang="ts">
import { ServiceEditApiDto, ServiceVerrechnungApiDto, TeilnehmerServiceApiDto } from '@/api-schema';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import { namespace } from 'vuex-class';

import { NAME as NAME_EINSTELLUNGEN } from '../store';
import { NAME as NAME_HEADER } from '@/store/modules/headerNotification';

const profilModul = namespace(NAME_EINSTELLUNGEN);
const headerModul = namespace(NAME_HEADER);

import { EDIT_SERVICES, GET_ALL_SERVICES } from '../store/actions';
import { NEW_ALERT } from '@/store/modules/headerNotification/actions';

@Component
export default class EditServiceDialog extends Vue {
    @Prop({ required: true, type: Object })
    private userServices!: TeilnehmerServiceApiDto;

    @Prop({ required: false, type: Object, default: null })
    private userVerrechnung!: ServiceVerrechnungApiDto;

    private visible = false;
    private services: ServiceEditApiDto = {};
    private speicher = 0;
    private funktionen: Array<number> = [];
    private totalMonatKosten = 0;
    private totalRataKosten = 0;

    @profilModul.Action(EDIT_SERVICES) edit!: Function;
    @profilModul.Action(GET_ALL_SERVICES) getServices!: Function;

    @headerModul.Action(NEW_ALERT) alertMessage!: Function;

    private onSave() {
        this.edit({
            speicherService: this.speicher,
            funktionServices: this.funktionen,
            rowVersion: this.services.rowVersion
        }).then(() => {
            this.visible = false;
        });
    }

    private open() {
        this.$emit('open');
        this.getServices().then((response: any) => {
            this.services = Object.assign({}, response);
            if (this.userServices.speicherService) {
                this.speicher = this.userServices.speicherService.serviceId;
            }
            this.funktionen = [];
            if (this.userServices.funktionService) {
                this.funktionen.push(this.userServices.funktionService.serviceId);
            }
            if(this.services.speicherServices.length > 0) {
            this.visible = true;
            } else {
                this.alertMessage({ text: 'einstellungen.errormessage.speicherupgrade'});
            }
            this.totalMonatKosten = 0;
            this.totalRataKosten = 0;
        });
    }

    submit() {
        this.onSave();
    }

    get checkNumberOfServices(): boolean {
        if (this.services.speicherServices) {
            if (this.services.speicherServices.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    @Watch('speicher')
    onSpeicherServiceChanged(newValue: number, value: number) {
        let selectedService;
        let service;
        // eslint-disable-next-line prefer-const
        selectedService = this.services.speicherServices.find(service => service.serviceId === newValue);
        if (value !== 0) {
            service = this.services.speicherServices.find(service => service.serviceId === value);
        }
        if (selectedService.serviceId !== this.userServices.speicherService.serviceId) {
            this.totalMonatKosten += selectedService.preisProMonat;
            this.totalRataKosten += selectedService.preisProRata;
        }
        if (service !== undefined) {
            if (service.serviceId !== this.userServices.speicherService.serviceId) {
                this.totalMonatKosten -= service.preisProMonat;
                this.totalRataKosten -= service.preisProRata;
            }
        }
    }

    @Watch('funktionen')
    onFunktionServicesChanged(newValues: Array<number>, oldValues: Array<number>) {
        oldValues.forEach(value => {
            if (value !== this.userServices.funktionService.serviceId) {
                const temp = this.services.funktionServices.find(service => service.serviceId === value);
                this.totalMonatKosten -= temp.preisProMonat;
                this.totalRataKosten -= temp.preisProRata;
            }
        });
        newValues.forEach(value => {
            if (value !== this.userServices.funktionService.serviceId) {
                const temp = this.services.funktionServices.find(service => service.serviceId === value);
                this.totalMonatKosten += temp.preisProMonat;
                this.totalRataKosten += temp.preisProRata;
            }
        });
    }
}
</script>
<style scoped>
.listvalues {
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.6);
}
</style>
