import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { IProfilState } from './state';

export const GETKUNDEDATA = 'getKundeData';
export const GETSERVICEDATA = 'getServiceData';
export const GETVERRECHNUNGDATA = 'getVerrechnungData';
export const GETBENUTZERDATA = 'getBenutzerData';
export const GETBENUTZERDETAILS = 'getBenutzerDetails';
export const GETBELEGTERSPEICHER = 'getSpeicherData';
export const GETTEILNEHMERFUNKTION = 'getTeilnehmerFunktion';
export const GETTEILNEHMERZUGRIFFE = 'getTeilnehmerZugriffe';
export const GETCREATETEILNEHMERZUGRIFFELIST = 'getCreateTeilnehmerZugriffeList';

export const getters: GetterTree<IProfilState, IRootState> = {
    [GETKUNDEDATA]: state => state.kundeData,
    [GETSERVICEDATA]: state => state.serviceData,
    [GETVERRECHNUNGDATA]: state => state.verrechnungData,
    [GETBENUTZERDATA]: state => state.benutzerData,
    [GETBENUTZERDETAILS]: state => state.benutzerDetails,
    [GETBELEGTERSPEICHER]: state => state.speicherData,
    [GETTEILNEHMERFUNKTION]: state => state.teilnehmerFunktionData,
    [GETTEILNEHMERZUGRIFFE]: state => state.teilnehmerZugriffe,
    [GETCREATETEILNEHMERZUGRIFFELIST]: state => state.createTeilnehmerList
};
