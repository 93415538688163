<template>
    <v-dialog v-model="visible" width="500">
        <template v-slot:activator="{}">
            <v-btn color="primary" @click="open">
                {{ $t('einstellungen.dialog.button') }}
            </v-btn>
        </template>
        <FocusLock>
            <form @submit.prevent="submit">
                <v-card>
                    <v-card-title class="headline font-weight-bold mb-2">
                        {{ $t('einstellungen.adressedialog.title') }}
                    </v-card-title>
                    <ValidationObserver ref="observer" validate v-slot="{ invalid }">
                        <v-card-text class="pb-0">
                            <ValidationProvider
                                name="Firma"
                                :rules="getValidation('teilnehmerEditApiDto.firma')"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    autofocus
                                    :label="$t('einstellungen.adressedialog.firma') + '*'"
                                    :placeholder="$t('einstellungen.adressedialog.firma')"
                                    v-model="teilnehmerCopy.firma"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider
                                name="Strasse"
                                :rules="getValidation('teilnehmerEditApiDto.strasse')"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    :label="$t('einstellungen.adressedialog.strasse') + '*'"
                                    :placeholder="$t('einstellungen.adressedialog.strasse')"
                                    v-model="teilnehmerCopy.strasse"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                            <v-text-field
                                :label="$t('einstellungen.adressedialog.zusatz')"
                                :placeholder="$t('einstellungen.adressedialog.zusatz')"
                                v-model="teilnehmerCopy.zusatz"
                            ></v-text-field>
                            <ValidationProvider
                                name="Plz"
                                :rules="getValidation('teilnehmerEditApiDto.plz')"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    :label="$t('einstellungen.adressedialog.plz') + '*'"
                                    :placeholder="$t('einstellungen.adressedialog.plz')"
                                    v-model="teilnehmerCopy.plz"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider
                                name="Ort"
                                :rules="getValidation('teilnehmerEditApiDto.ort')"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    label="Ort*"
                                    placeholder="Ort"
                                    v-model="teilnehmerCopy.ort"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider
                                name="Telefon"
                                :rules="getValidation('teilnehmerEditApiDto.telefon') + '|valid_swiss_telephone_number'"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    :label="$t('einstellungen.adressedialog.telefon') + '*'"
                                    :placeholder="$t('einstellungen.adressedialog.telefon')"
                                    v-model="teilnehmerCopy.telefon"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider
                                name="Email"
                                :rules="getValidation('teilnehmerEditApiDto.email')"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    :label="$t('einstellungen.adressedialog.email') + '*'"
                                    :placeholder="$t('einstellungen.adressedialog.email')"
                                    v-model="teilnehmerCopy.email"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                            <v-text-field
                                :label="$t('einstellungen.adressedialog.bemerkung')"
                                :placeholder="$t('einstellungen.adressedialog.bemerkung')"
                                v-model="teilnehmerCopy.bemerkungen"
                            ></v-text-field>
                        </v-card-text>

                        <v-card-actions class="ml-4">
                            <v-btn :disabled="invalid" color="primary" type="submit" class="modalbutton mb-3">
                                {{ $t('components.dialog.save') }}
                            </v-btn>
                            <v-btn @click="visible = false" class="modalbutton mb-3">
                                {{ $t('components.dialog.cancel') }}
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </form>
        </FocusLock>
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { namespace } from 'vuex-class';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { NAME as NAME_EINSTELLUNGEN } from '../store';
const profilModul = namespace(NAME_EINSTELLUNGEN);

import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
const validationModul = namespace(NAME_VALIDATION);

import { EDIT_ADRESSE } from '../store/actions';
import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';

import { TeilnehmerApiDto, TeilnehmerEditApiDto } from '@/api-schema';

@Component({
    components: {
        ValidationProvider,
        ValidationObserver
    }
})
export default class EditAdresseDialog extends Vue {
    @Prop({ required: true, type: Object })
    private editTeilnehmer!: TeilnehmerApiDto;

    private visible = false;
    private teilnehmerCopy: TeilnehmerEditApiDto = {
        bemerkungen: '',
        email: '',
        firma: '',
        ort: '',
        strasse: '',
        telefon: '',
        zusatz: '',
        rowVersion: ''
    };

    @profilModul.Action(EDIT_ADRESSE) edit!: Function;
    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;

    $refs!: {
        observer: any;
    };

    private onSave() {
        this.edit(this.teilnehmerCopy).then(() => {
            this.visible = false;
            this.$emit('success');
        });
    }

    submit() {
        this.$refs.observer.validate();
        this.onSave();
    }

    private open() {
        this.$emit('open');
        this.teilnehmerCopy.kundenNummer = this.editTeilnehmer.kundenNummer;
        this.teilnehmerCopy.email = this.editTeilnehmer.email;
        this.teilnehmerCopy.firma = this.editTeilnehmer.firma;
        this.teilnehmerCopy.ort = this.editTeilnehmer.ort;
        this.teilnehmerCopy.strasse = this.editTeilnehmer.strasse;
        this.teilnehmerCopy.telefon = this.editTeilnehmer.telefon;
        this.teilnehmerCopy.zusatz = this.editTeilnehmer.zusatz;
        this.teilnehmerCopy.plz = this.editTeilnehmer.plz;
        this.teilnehmerCopy.rowVersion = this.editTeilnehmer.rowVersion;

        this.visible = true;
        setTimeout(() => this.$refs.observer.validate(), 50);
    }
}
</script>
