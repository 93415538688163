<template>
    <!-- stateless wird gesetzt, damit durch click ausserhalb des drawer, der drawer nicht geschlossen werden kann. -->
    <!-- https://github.com/vuetifyjs/vuetify/blob/89e833a145a6d585eabdf881809f1a99f217239a/packages/vuetify/src/components/VNavigationDrawer/VNavigationDrawer.ts#L198 -->
    <v-navigation-drawer
        @input="toggle"
        :value="visible"
        stateless
        fixed
        :permanent="!closeable"
        temporary
        right
        width="400"
    >
        <v-container>
            <v-row>
                <v-col>
                    <v-toolbar light short flat>
                        <h3>{{ $t('filter.title') }}</h3>
                        <v-spacer />
                        <a @click="reset">{{ $t('filter.reset') }}</a>
                    </v-toolbar>
                </v-col>
            </v-row>

            <slot />

            <v-row class="mar-l-0 mar-t-10 pt-3">
                <v-col sm="12">
                    <v-btn @click="confirm" class="mr-8 ml-3" color="primary">{{ $t('filter.confirm') }}</v-btn>
                    <v-btn @click="cancel">{{ $t('filter.cancel') }}</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-navigation-drawer>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class FilterDrawer extends Vue {
    @Prop({ required: true, type: Boolean })
    private visible!: boolean;
    @Prop({ required: false, type: Boolean, default: true })
    private closeable!: boolean;

    private cancel() {
        this.$emit('cancel');
    }
    private confirm() {
        this.$emit('confirm');
    }
    private toggle(value: boolean) {
        this.$emit('update:visible', value);
    }
    private reset() {
        this.$emit('reset');
    }
}
</script>
