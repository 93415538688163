<template>
    <v-dialog v-model="visible" width="500">
        <template v-slot:activator="{}">
            <v-list-item @click="open">
                <v-list-item-title v-text="$t('archiv.table.burgermenu.edit')" @click="burgermenuActive = false" />
            </v-list-item>
        </template>
        <FocusLock>
            <form @submit.prevent="submit">
                <v-card>
                    <v-card-title class="headline font-weight-bold mb-2">
                        {{ $t('einstellungen.edituserdialog.title') }}
                    </v-card-title>

                    <ValidationObserver ref="observer" validate v-slot="{ invalid }">
                        <v-card-text class="pb-0">
                            <v-text-field
                                :label="$t('einstellungen.addaccount.email') + '*'"
                                placeholder="example@test.com"
                                v-model="benutzerCopy.loginId"
                                disabled
                                readonly
                            ></v-text-field>
                            <v-select
                                v-model="benutzerCopy.rollen"
                                :items="benutzerRollen"
                                :label="$t('einstellungen.addaccount.rolle')"
                            >
                                <template v-slot:selection="{ item }">
                                    <span>
                                        {{ item.text }}
                                    </span>
                                </template>
                                <template v-slot:item="{ item, attrs, on }">
                                    <v-list-item v-on="on" v-bind="attrs">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-row no-gutters align="center">
                                                    <span>
                                                        <b>{{ item.text }}:</b>
                                                        {{ item.beschreibung }}
                                                    </span>
                                                </v-row>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>
                            <ValidationProvider
                                name="Vorname"
                                :rules="getValidation('benutzerCreateApiDto.vorname')"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    :label="$t('einstellungen.addaccount.vorname') + '*'"
                                    placeholder="..."
                                    v-model="benutzerCopy.vorname"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider
                                name="Nachname"
                                :rules="getValidation('benutzerCreateApiDto.nachname')"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    :label="$t('einstellungen.addaccount.nachname') + '*'"
                                    placeholder="..."
                                    v-model="benutzerCopy.nachname"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider
                                name="MobilNummer"
                                :rules="
                                    getValidation('benutzerCreateApiDto.mobilNummer') + '|valid_swiss_telephone_number'
                                "
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    :label="$t('einstellungen.addaccount.mobilnummer') + '*'"
                                    placeholder="+41 77 111 22 33"
                                    v-model="benutzerCopy.mobilNummer"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                        </v-card-text>

                        <v-card-actions class="ml-4">
                            <v-btn
                                color="primary"
                                :disabled="invalid || requiredFieldsFilled"
                                type="submit"
                                class="modalbutton mb-3"
                            >
                                {{ $t('components.dialog.save') }}
                            </v-btn>
                            <v-btn @click="visible = false" class="modalbutton mb-3">
                                {{ $t('components.dialog.cancel') }}
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </form>
        </FocusLock>
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { namespace } from 'vuex-class';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { NAME as NAME_EINSTELLUNGEN } from '../store';
const profilModul = namespace(NAME_EINSTELLUNGEN);

import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
const validationModul = namespace(NAME_VALIDATION);

import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';
import { EDIT_ACCOUNT, GET_EDIT_ACCOUNT } from '../store/actions';

import { BenutzerEditApiDto } from '@/api-schema';

@Component({
    components: {
        ValidationProvider,
        ValidationObserver
    }
})
export default class EditAccountDialog extends Vue {
    @Prop({ required: true, type: String })
    private editBenutzerId!: string;

    private visible = false;
    private benutzerCopy: BenutzerEditApiDto = {};

    @profilModul.Action(EDIT_ACCOUNT) edit!: Function;
    @profilModul.Action(GET_EDIT_ACCOUNT) getAccount!: Function;

    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;

    $refs!: {
        observer: any;
    };

    get benutzerRollen(): Array<any> {
        return [
            {
                text: this.$t('benutzerrollen.usermain'),
                beschreibung: this.$t('benutzerrollen.usermain.helptext'),
                value: 'UserMain'
            },
            {
                text: this.$t('benutzerrollen.userstaff'),
                beschreibung: this.$t('benutzerrollen.userstaff.helptext'),
                value: 'UserStaff'
            },
            {
                text: this.$t('benutzerrollen.userview'),
                beschreibung: this.$t('benutzerrollen.userview.helptext'),
                value: 'UserView'
            },
            {
                text: this.$t('benutzerrollen.userupload'),
                beschreibung: this.$t('benutzerrollen.userupload.helptext'),
                value: 'UserUpload'
            },
            {
                text: this.$t('benutzerrollen.userbuha'),
                beschreibung: this.$t('benutzerrollen.userbuha.helptext'),
                value: 'UserBuha'
            }
        ];
    }

    private onSave() {
        this.edit(this.benutzerCopy).then(() => {
            this.visible = false;
            this.$emit('success');
        });
    }

    private open() {
        this.$emit('open');
        this.getAccount(this.editBenutzerId).then((response: any) => {
            this.benutzerCopy = Object.assign({}, response);
            this.visible = true;
        });
    }

    submit() {
        this.$refs.observer.validate();
        this.onSave();
    }

    get requiredFieldsFilled(): boolean {
        if (this.benutzerCopy.nachname !== undefined || this.benutzerCopy.vorname !== undefined) {
            return this.benutzerCopy.nachname.length === 0 || this.benutzerCopy.vorname.length === 0;
        }
        return true;
    }
}
</script>
