<template>
    <v-col>
        <v-col sm="10">
            <h3 v-text="$t('einstellungen.service.title')" />
        </v-col>
        <v-row>
            <v-col class="pl-5" sm="10">
                <v-row class="borderbottom tableheader">
                    <v-col sm="8" class="py-0">{{ $t('einstellungen.service.table.service') }}</v-col>
                    <v-col sm="2" class="py-0">{{ $t('einstellungen.service.table.speicherlimite') }}</v-col>
                    <v-col sm="2" class="py-0">{{
                        $t('einstellungen.service.table.cost') + '/' + $t('einstellungen.service.table.month')
                    }}</v-col>
                </v-row>
                <ServiceRow :service="teilnehmerService.basisService" />
                <ServiceRow v-if="teilnehmerService.funktionService" :service="teilnehmerService.funktionService" />
                <ServiceRow v-if="teilnehmerService.speicherService" :service="teilnehmerService.speicherService" />
                <v-row class="borderbottom">
                    <v-col v-if="teilnehmerService.speicherService != null && verrechnung != null" sm="8" class="py-0">
                        <span style="font-weight: bold;">{{ $t('einstellungen.service.table.total') }} </span>
                        ({{ $t('einstellungen.service.table.ablaufdatum') }} {{ verrechnung.verrechnungBis }})
                    </v-col>
                    <v-col v-else sm="8" class="py-0">
                        <span style="font-weight: bold;">{{ $t('einstellungen.service.table.total') }} </span>
                    </v-col>
                    <v-col sm="2" class="py-0" />
                    <v-col sm="2" class="py-0" style="text-align: center;">{{ calculatePreisProMonat() }}</v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-col>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TeilnehmerServiceApiDto, ServiceVerrechnungApiDto } from '@/api-schema';

import ServiceRow from './ServiceRow.vue';
@Component({
    components: {
        ServiceRow
    }
})
export default class ServiceEinstellungen extends Vue {
    @Prop({ required: true, type: Object })
    private teilnehmerService!: TeilnehmerServiceApiDto;
    @Prop({ required: false, type: Object, default: null })
    private verrechnung!: ServiceVerrechnungApiDto;

    private calculatePreisProMonat(): string {
        if (!this.teilnehmerService.basisService) {
            return '-';
        }
        let preisProMonat = this.teilnehmerService.basisService.preisProMonat;
        if (this.teilnehmerService.funktionService != null) {
            preisProMonat += this.teilnehmerService.funktionService.preisProMonat;
        }
        if (this.teilnehmerService.speicherService != null) {
            preisProMonat += this.teilnehmerService.speicherService.preisProMonat;
        }
        return preisProMonat.toFixed(2).toString();
    }
}
</script>
