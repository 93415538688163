import * as Msal from '@azure/msal-browser';
import { I18N_LANGUAGE } from '@/store/modules/resource/state';

export class AuthConfiguration {
    private clientId: string;
    private tenantName: string;
    private singInPolicy: string;
    private forgotPasswordPolicy: string;
    private postLogoutRedirectUri: string;

    constructor(clientId: string, tenantName: string, singInPolicy: string, forgotPasswordPolicy: string, postLogoutRedirectUri: string) {
        this.clientId = clientId;
        this.tenantName = tenantName;
        this.singInPolicy = singInPolicy;
        this.forgotPasswordPolicy = forgotPasswordPolicy;
        this.postLogoutRedirectUri = postLogoutRedirectUri;
    }

    /**
     * Config object to be passed to MSAL on creation.
     * For a full list of msal.js configuration parameters,
     * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_configuration_.html
     * */
    get msalConfig(): Msal.Configuration {
        return {
            auth: {
                // redirectUri: 'http://localhost:6869/home', //defaults to application start page
                postLogoutRedirectUri: this.postLogoutRedirectUri,
                clientId: this.clientId,
                // authority: `https://${this.tenantName}.b2clogin.com/${this.tenantName}.onmicrosoft.com/${this.singInPolicy}`,
                // authority: `https://${this.tenantName}.b2clogin.com/${this.tenantName}/`,
                // protocolMode: Msal.ProtocolMode.AAD,
                knownAuthorities: [`${this.tenantName}.b2clogin.com`]
            },
            cache: {
                cacheLocation: 'localStorage', // This configures where your cache will be stored
                storeAuthStateInCookie: false // Set this to "true" to save cache in cookies
            }
        };
    }

    /**
     * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
     * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
     * @param signInPolicySuffix Suffix des SignInFlows
    */

    getloginRequest(account: Msal.AccountInfo, signInPolicySuffix: string): Msal.RedirectRequest {
        return {
            scopes: ['openid', 'profile'],
            authority: `https://${this.tenantName}.b2clogin.com/${this.tenantName}.onmicrosoft.com/${this.singInPolicy + signInPolicySuffix}`,
            extraQueryParameters: {
                'ui_locales': localStorage.getItem(I18N_LANGUAGE) || navigator.language.slice(0, 2)
            },
            account: account
        };
    }

    // Add here scopes for access token to be used at the API endpoints.
    gettokenRequest(account: Msal.AccountInfo): Msal.SilentRequest {
        return {
            // folgende config gibt kein token zurück
            // scopes: ['openid', 'profile'],

            // folgende config führt zu fehler da user.read nicht public ist
            // https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=javascript2
            // scopes: ['user.read'],

            scopes: [`https://${this.tenantName}.onmicrosoft.com/api/token.read`],
            // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
            authority: `https://${this.tenantName}.b2clogin.com/${this.tenantName}.onmicrosoft.com/${account.idTokenClaims.tfp}`,
            account: account
        };
    }

    getLogoutRequest(account: Msal.AccountInfo, idToken?: string): Msal.EndSessionRequest {
        return {
            account: account,
            idTokenHint: idToken,
            authority: `https://${this.tenantName}.b2clogin.com/${this.tenantName}.onmicrosoft.com/${account.idTokenClaims.tfp}`,
            postLogoutRedirectUri: this.postLogoutRedirectUri
        };
    }
}
