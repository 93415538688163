<template>
    <span>
        <v-card flat>
            <v-card-text>
                <v-row v-if="title">
                    <v-col>
                        <h3 v-text="title" />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pt-1 pb-1">
                        Filter:
                        <v-chip
                            v-for="(item, key) in filters"
                            :key="key"
                            close
                            @click:close="chipClosed(item.key)"
                            filter
                            small
                            color="secondary"
                            class="ma-1"
                            >{{ $t(item.nameResourceKey) }}: {{ item.value }}</v-chip
                        >
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-toolbar v-if="!downloadToolbar" light short flat>
            <slot />
        </v-toolbar>
        <v-toolbar v-else style="background-color: #0d47a1; color: white;" light short flat>
            <slot />
        </v-toolbar>
        <v-divider />
    </span>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TableHeaderFilterItem } from '@/types/TableHeaderFilterItem';

@Component
export default class TableHeader extends Vue {
    @Prop({ required: false, type: Array, default: () => [] })
    private filters!: Array<TableHeaderFilterItem>;

    @Prop({ required: false, type: String, default: null })
    private title!: string;

    @Prop({ required: false, type: Boolean, default: false })
    private downloadToolbar!: boolean;

    private chipClosed(value: string) {
        this.$emit('chipClosed', value);
    }
}
</script>
