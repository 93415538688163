import Vue from 'vue';
import i18n from '@/plugins/i18n';

import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import {
    is,
    between,
    regex,
    required,
    email,
    min,
    max,
    numeric,
    double,
    confirmed,
    min_value,
    max_value
} from 'vee-validate/dist/rules';

/** Validierungsfunktionen für Vee-Validate
 * @devdoc Der Name des Feldes wird aktuell nicht in allen Meldung ausgegeben. Falls gewünscht, müssen die Ressourcen-Texte einen Platzhalter beinhalten

*/
export default class VeeValidate {
    constructor() {
        this.extendIs();
        this.extendRegex();
        this.extendBetween();
        this.extendValidTelephoneNumber();
        this.extendValidPassword();
        this.extendValidUrlProtocol();
        this.extendRequired();
        this.extendEmail();
        this.extendMin();
        this.extendMax();
        this.extendMinValue();
        this.extendMaxValue();
        this.extendNumeric();
        this.extendDouble();
        this.extendDecimalNumber();
        this.extendConfirmed();

        //Mit Angabe eines Custom-Labels
        this.extendLabeledMinValue();
        this.extendLabeledMaxValue();

        this.initializeComponents();
    }

    private extendIs() {
        extend('is', {
            ...is,
            message: (_field_, args) => i18n.t('allg.validate.is', [i18n.t(_field_), args.other]) as string
        });
    }

    /** Validierung mit einem Regex
     */
    private extendRegex() {
        extend('regex', {
            ...regex,
            message: (_field_, args) => i18n.t('allg.validate.invalidchars', [i18n.t(_field_), args.regex]) as string
        });
    }

    /** Validiert eine Telefonnummer
     */
    private extendValidTelephoneNumber() {
        extend('valid_swiss_telephone_number', {
            ...regex,
            validate(value) {
                const reg = new RegExp('^(((\\+|00)\\d{2})|0)\\s?(\\d{2})\\s?(\\d{3})\\s?(\\d{2})\\s?(\\d{2})\\s?(\\d{1,2})?$');
                return reg.test(value);
            },
            message: (_field_) => i18n.t('allg.validate.phone', [i18n.t(_field_)]) as string
        });
    }

    /** Validiert Passwort
    */
    private extendValidPassword() {
        extend('valid_password_format', {
            ...regex,
            validate(value) {
                //see: https://stackoverflow.com/questions/5950756/regex-for-checking-that-at-least-3-of-4-different-character-groups-exist
                var pattern = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])|(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&+%*<>/=?_.,:;\\-])|(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%&+%*<>/=?_.,:;\\-])|(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%&+%*<>/=?_.,:;\\-])).{8,64}$/;
                return pattern.test(value);
            },
            message: (_field_) => i18n.t('allg.validate.passwordformat', [i18n.t(_field_)]) as string
        });
    }

    /** Validiert das Protokoll einer URL
     */
    private extendValidUrlProtocol() {
        extend('validUrlProtocol', {
            ...regex,
            validate(value) {
                const reg = new RegExp('^(http|https)://');
                return reg.test(value);
            },
            message: (_field_) => i18n.t('allg.validate.url', [i18n.t(_field_)]) as string
        });
    }

    private extendMin() {
        //HINT: Für Textfelder wird die Angabe als Textlänge angenommen, für numerische Felder als Wert.
        extend('min', {
            ...min,
            message: (_field_, args) => i18n.t('allg.validate.minlength', [i18n.t(_field_), args.length]) as string
        });
    }

    private extendMax() {
        //HINT: Für Textfelder wird die Angabe als Textlänge angenommen, für numerische Felder als Wert.
        extend('max', {
            ...max,
            message: (_field_, args) => i18n.t('allg.validate.maxlength', [i18n.t(_field_), args.length]) as string
        });
    }

    private extendMinValue() {
        extend('min_value', {
            ...min_value,
            message: (_field_, args) => i18n.t('allg.validate.minvalue', [i18n.t(_field_), args.min]) as string
        });
    }

    private extendMaxValue() {
        extend('max_value', {
            ...max_value,
            message: (_field_, args) => i18n.t('allg.validate.maxvalue', [i18n.t(_field_), args.max]) as string
        });
    }

    private extendNumeric() {
        extend('numeric', {
            ...numeric,
            message: (_field_, args) => i18n.t('allg.validate.number', [i18n.t(_field_), args.length]) as string
        });
    }

    /** Valdiert einen Double-Wert */
    private extendDouble() {
        extend('double', {
            ...double,
            message: (_field_, args) => i18n.t('allg.validate.number', [i18n.t(_field_), args.length]) as string
        });
    }

    /** Validiert eine Dezimalzahl, mit zusätlich erlaubtem Ganzzahligem Wert (ohne Dezimalstellen) */
    private extendDecimalNumber() {
        extend('decimalNumber', {
            message: (_field_, args) => i18n.t('allg.validate.number', [i18n.t(_field_), args.length]) as string,
            validate: (value, params) => {
                const isDouble = double.validate(value, params);
                if (isDouble === false) {
                    //Als numeric (Ganzzahl)
                    return numeric.validate(value);
                }
                return isDouble;
            }
        });
    }
    private extendRequired() {
        extend('required', {
            ...required,
            message: (_field_) => i18n.t('allg.validate.required', [i18n.t(_field_)]) as string
        });
    }

    private extendEmail() {
        extend('email', {
            ...email,
            message: (_field_) => i18n.t('allg.validate.email', [i18n.t(_field_)]) as string
        });
    }

    private extendConfirmed() {
        extend('confirmed', {
            ...confirmed,
            message: (_field_, args) =>
                i18n.t('allg.validate.passwordnotequal', [i18n.t(_field_), args.other]) as string
        });
    }

    /** Validierung auf einen Maximalwert, mit Angabe eines eigenen Labels (ohne Platzhalter) für die Fehlermeldung.
     */
    private extendLabeledMaxValue() {
        extend('max_value_labeled', {
            ...max_value,
            message: (_field_, args) => i18n.t(args.label, [i18n.t(_field_), args.max]) as string,
            params: ['max', 'label']
        });
    }

    /** Validierung auf einen Minimalwert, mit Angabe eines eigenen Labels (ohne Platzhalter) für die Fehlermeldung.
     */
    private extendLabeledMinValue() {
        extend('min_value_labeled', {
            ...min_value,
            message: (_field_, args) => i18n.t(args.label, [i18n.t(_field_), args.max]) as string,
            params: ['min', 'label']
        });
    }

    private extendBetween() {
        extend('between', {
            ...between,
            message: (_field_, args) => i18n.t('allg.validate.between', [i18n.t(_field_), args.min, args.max]) as string
        });
    }
    private initializeComponents() {
        Vue.component('ValidationObserver', ValidationObserver);
        Vue.component('ValidationProvider', ValidationProvider);
    }
}
