import { ZeitspanneFilterApiDto } from '@/api-schema';
export class DokumentFilterDto {
    constructor() {
        this.bezeichnung = '';
        this.dokumentTyp = '';
        this.fileTyp = '';
        this.erstelltVon = '';
        this.offset = 0;
        this.entriesToLoad = 25;
        this.sortOrder = 'Descending';
        this.sortBy = 'createdOn';
    }
    dateFrom?: string; // date-time
    dateTo?: string; // date-time
    zeitspanne?: ZeitspanneFilterApiDto;
    dokumentTyp?: string;
    fileTyp?: string;
    erstelltVon?: string;
    bezeichnung: string;
    schlagworte?: string;
    offset: number;
    entriesToLoad?: number;
    sortBy?: string;
    sortOrder?: string;
}
