<template>
    <v-row v-if="service" class="borderbottom">
        <v-col sm="8" class="py-0">
            <span style="font-weight: bold;">{{ this.service.beschreibungDe }}</span>
        </v-col>
        <v-col sm="2" class="py-0">{{ this.service.limiteGigaByte }} GB</v-col>
        <v-col sm="2" class="py-0" style="text-align: center;">{{ this.service.preisProMonat.toFixed(2) }}</v-col>
    </v-row>
    <v-row v-else class="borderbottom">
        <v-col sm="8" class="py-0">
            <span style="font-weight: bold;">{{ $t('einstellungen.services.noservicesadded') }}</span>
        </v-col>
        <v-col sm="2" class="py-0">-</v-col>
        <v-col sm="2" class="py-0" style="text-align: center;">0.00</v-col>
    </v-row>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ServiceApiDto } from '@/api-schema';

@Component
export default class ServiceRow extends Vue {
    @Prop({ required: true, type: Object })
    private service!: ServiceApiDto;
}
</script>
