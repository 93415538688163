import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { ITakeTeilnehmerState } from './state';

import {
    LOADING_ARTIKEL,
    LOADING_TEILNEHMER,
    OFFSET_ARTIKEL,
    OFFSET_TEILNEHMER,
    TOTAL_NUMBER_OF_RECORDS_ARTIKEL,
    TOTAL_NUMBER_OF_RECORDS_TEILNEHMER,
    MAPARTIKEL,
    ADDARTIKEL,
    MAP_VETDATA_LOG,
    ADD_TEILNEHMERLIST,
    MAP_TEILNEHMERLIST,
    LOADING_BENUTZER,
    MAPBENUTZERDATA
} from './mutations';
import { NAME } from './';

import { takeTeilnehmerService } from '../services/TakeTeilnehmerService';
import { benutzerinfoService } from '../services/BenutzerinfoService';
import { NEW_SUCESS } from '@/store/modules/headerNotification/actions';
import { GET_PERMISSIONS, GET_BENUTZERINFO } from '@/store/modules/security/actions';
import { SHOW, HIDE } from '@/store/modules/loadingSpinner/actions';
import { TakeTeilnehmerApiDto } from '@/api-schema';
import { TeilnehmerFilterDto } from '@/types/TeilnehmerFilterDto';
import { downloadService } from '@/services/downloadService';
import { BenutzerInfoSearchApiDto } from '@/types/BenutzerInfoSearchApiDto';

export const TAKESPECIFIC = 'TakeSpecific';
export const RESET = 'Reset';
export const GET_TEILNEHMER = 'GetTeilnehmer';
export const GET_NEXT_TEILNEHMER = 'GetNextTeilnehmer';
export const GET_ARTIKEL = 'GetArtikel';
export const GET_VETDATA_LOG = 'GetVetdataLog';
export const START_VETPOINT_IMPORT = 'startVetpointImport';
export const DOWNLOAD_TEILNEHMER = 'DownloadTeilnehmer';
export const GET_BENUTZER = 'GetBenutzer';

export const actions: ActionTree<ITakeTeilnehmerState, IRootState> = {
    async [TAKESPECIFIC]({ dispatch }: any, teilnehmer: TakeTeilnehmerApiDto) {
        console.debug(NAME + '::' + TAKESPECIFIC);

        dispatch('loadingSpinner/' + SHOW, {}, { root: true });
        takeTeilnehmerService.takeSpecific(teilnehmer).then(async () => {
            //Reload Permissions
            await dispatch('security/' + GET_PERMISSIONS, {}, { root: true });
            dispatch('security/' + GET_BENUTZERINFO, {}, { root: true });
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
            dispatch(
                'headerNotification/' + NEW_SUCESS,
                {
                    text:
                        'Rolle wurde erfolgreich gewechselt mit TeilnehmerId: ' +
                        teilnehmer.teilnehmerId +
                        ' bzw. tvsKundennummer: ' +
                        teilnehmer.tvsKundennummer +
                        ' und Rolle: ' +
                        teilnehmer.benutzerRolle
                },
                { root: true }
            );
        });
    },

    async [RESET]({ dispatch }: any) {
        console.debug(NAME + '::' + RESET);

        dispatch('loadingSpinner/' + SHOW, {}, { root: true });
        takeTeilnehmerService.reset().then(async () => {
            //Reload Permissions
            await dispatch('security/' + GET_PERMISSIONS, {}, { root: true });
            dispatch('security/' + GET_BENUTZERINFO, {}, { root: true });
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
            dispatch('headerNotification/' + NEW_SUCESS, { text: 'Rolle erfolgreich zurückgesetzt' }, { root: true });
        });
    },

    async [GET_NEXT_TEILNEHMER]({ commit, state }: any) {
        console.debug(NAME + '::' + GET_NEXT_TEILNEHMER);

        if (state.teilnehmerLoading || (state.teilnehmerFilter.offset !== 0 && state.teilnehmerFilter.offset >= state.teilnehmerTotalNumberOfRecords)) {
            return;
        }

        commit(LOADING_TEILNEHMER, true);
        commit(OFFSET_TEILNEHMER, state.teilnehmerList.length);


        await takeTeilnehmerService.getTeilnehmer(state.teilnehmerFilter).then(response => {
            commit(TOTAL_NUMBER_OF_RECORDS_TEILNEHMER, response.totalNumberOfRecords);
            commit(ADD_TEILNEHMERLIST, response.entries);
            commit(LOADING_TEILNEHMER, false);
        });
    },
    async [GET_TEILNEHMER]({ commit, state }: any) {
        console.debug(NAME + '::' + GET_TEILNEHMER);

        if (state.teilnehmerLoading || (state.teilnehmerFilter.offset !== 0 && state.teilnehmerFilter.offset >= state.teilnehmerTotalNumberOfRecords)) {
            return;
        }

        commit(LOADING_TEILNEHMER, true);
        commit(OFFSET_TEILNEHMER, 0);

        await takeTeilnehmerService.getTeilnehmer(state.teilnehmerFilter).then(response => {
            commit(TOTAL_NUMBER_OF_RECORDS_TEILNEHMER, response.totalNumberOfRecords);
            commit(MAP_TEILNEHMERLIST, response.entries);
            commit(LOADING_TEILNEHMER, false);
        });
    },
    async [DOWNLOAD_TEILNEHMER]({ dispatch, commit, state }: any) {
        dispatch('loadingSpinner/' + SHOW, {}, { root: true });

        //Lokale Kopie damit der State unverändert bleibt
        const filter: TeilnehmerFilterDto = JSON.parse(JSON.stringify(state.teilnehmerFilter));
        filter.offset = 0;
        filter.entriesToLoad = null;

        await takeTeilnehmerService.downloadTeilnehmer(filter).then(response => {
            downloadService.downloadFile(response, 'export.csv');
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
        });
    },

    async [GET_ARTIKEL]({ commit, state }: any, offset: number) {
        console.debug(NAME + '::' + GET_ARTIKEL, offset);

        if (state.artikelLoading || (offset !== 0 && offset >= state.artikelTotalNumberOfRecords)) {
            return;
        }

        commit(LOADING_ARTIKEL, true);
        commit(OFFSET_ARTIKEL, offset);

        const searchQuery = Object.assign({}, state.artikelQueryFilter);

        await takeTeilnehmerService.getArtikel(searchQuery).then(response => {
            if (!offset || offset < 1) {
                commit(TOTAL_NUMBER_OF_RECORDS_ARTIKEL, response.totalNumberOfRecords);
                commit(MAPARTIKEL, response.entries);
            } else {
                commit(ADDARTIKEL, response.entries);
            }
            commit(LOADING_ARTIKEL, false);
        });
    },
    async [GET_VETDATA_LOG]({ commit }: any) {
        console.debug(NAME + '::' + GET_VETDATA_LOG);

        takeTeilnehmerService.getVetdataLog().then(response => {
            commit(MAP_VETDATA_LOG, response);
        });
    },
    async [START_VETPOINT_IMPORT]({ dispatch }: any) {
        console.debug(NAME + '::' + START_VETPOINT_IMPORT);

        dispatch('loadingSpinner/' + SHOW, {}, { root: true });
        await takeTeilnehmerService.startVetpointImport().then(response => {
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
            dispatch('headerNotification/' + NEW_SUCESS, { text: 'Vetpoint Import erfolreich abgeschlossen' }, { root: true });
            dispatch(GET_VETDATA_LOG);
            dispatch(GET_ARTIKEL, 0);
        });
        dispatch('loadingSpinner/' + HIDE, {}, { root: true });
    },
    async [GET_BENUTZER]({ dispatch, commit, state }: any) {
        console.debug(NAME + '::' + GET_BENUTZER);

        commit(LOADING_BENUTZER, true);
        const searchQuery: BenutzerInfoSearchApiDto = Object.assign({}, state.benutzerFilter);
        await benutzerinfoService.getByEmail(searchQuery).then(response => {
            commit(MAPBENUTZERDATA, response);
        }).catch(() => {
            commit(MAPBENUTZERDATA, null);
        }).finally(() => {
            commit(LOADING_BENUTZER, false);
        });
    }
};
