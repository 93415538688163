<template>
    <v-container fluid>
        <v-row class="pl-7">
            <v-col sm="12">
                <v-row>
                    <h2 v-text="$t('login.subtitle')" />
                </v-row>
                <v-row>
                    <div style="width: 1000px">
                        <h4>
                            {{ $t('login.description.one') + $t('login.description.two') }}
                        </h4>
                    </div>
                </v-row>
                <v-row v-if="loggedIn && permissions.length > 0">
                    <v-btn small color="primary" @click="logout">{{ $t('login.button.logout') }}</v-btn>
                </v-row>
                <v-row v-else class="my-9">
                    <v-col class="px-0" cols="12">
                        <v-row>
                            <v-col class="pr-0" sm="4" md="2" lg="1" xl="1">
                                <v-btn small color="primary" @click="handleLoginTotp">{{
                                    $t('login.button.login')
                                }}</v-btn>
                            </v-col>

                            <v-col sm="8" class="pl-0">
                                <!-- eslint-disable vue/no-v-html -->
                                <p v-html="$t('login.description.login.totp')" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="px-0" cols="12">
                        <v-row>
                            <v-col class="pr-0" sm="4" md="2" lg="1" xl="1">
                                <v-btn small color="primary" @click="handleLoginSms">{{
                                    $t('login.button.login')
                                }}</v-btn>
                            </v-col>

                            <v-col sm="8" class="pl-0">
                                <!-- eslint-disable vue/no-v-html -->
                                <p v-html="$t('login.description.login.sms')" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col class="px-0" cols="12" v-if="$te('login.description.2fa.text')">
                        <v-row>
                            <v-col class="pr-0" cols="12">
                                <!-- eslint-disable vue/no-v-html -->
                                <span v-html="$t('login.description.2fa.text')" />&nbsp; 
                                <span v-html="$t('login.description.2fa.link')" />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col xl="1">
                        <v-row class="my-9">
                            <v-btn small :to="{ name: 'Registration' }" link color="primary">{{
                                $t('login.button.registration')
                            }}</v-btn>
                        </v-row>
                        <v-row class="my-9">
                            <v-btn class="mt-2" small color="primary" @click="forwardToVetdata">www.vetdata.ch</v-btn>
                        </v-row>
                    </v-col>
                    <v-col xl="4">
                        <v-row class="my-9">
                            <p v-text="$t('login.description.registration')" />
                        </v-row>
                        <v-row class="my-9">
                            <p v-text="$t('login.vetdata.title')" />
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';

import { namespace } from 'vuex-class';

import { NAME as NAME_SECURITY } from '@/store/modules/security';
const securityModul = namespace(NAME_SECURITY);

import { NAME as NAME_GENERAL } from '@/store/modules/general';
const generalModul = namespace(NAME_GENERAL);

import { NAME as NAME_HEADERNOTIFICATION } from '@/store/modules/headerNotification';
const headerNotificationModul = namespace(NAME_HEADERNOTIFICATION);

import { ISLOGGEDIN, PERMISSIONS } from '@/store/modules/security/getters';
import { LOGIN, LOGOUT, GET_BENUTZERINFO, GET_PERMISSIONS, LoginType } from '@/store/modules/security/actions';
import { SUBTITLE, FOOTERTITLE } from '@/store/modules/general/mutations';
import { NEW_ALERT, NEW_INFO } from '@/store/modules/headerNotification/actions';

enum AutoLogin {
    undefined,
    totp,
    sms
}

@Component
export default class Login extends Vue {
    private returnUrl = '/';
    private autologin = AutoLogin.undefined;

    @securityModul.Action(LOGIN) login!: Function;
    @securityModul.Action(LOGOUT) logout!: Function;
    @securityModul.Action(GET_BENUTZERINFO) getBenutzerInfo!: Function;
    @securityModul.Action(GET_PERMISSIONS) getPermissions!: Function;
    @securityModul.Getter(ISLOGGEDIN) loggedIn!: boolean;
    @securityModul.Getter(PERMISSIONS) permissions!: Array<string>;

    @generalModul.Mutation(SUBTITLE) setSubTitle!: Function;
    @generalModul.Mutation(FOOTERTITLE) setFooterTitle!: Function;

    @headerNotificationModul.Action(NEW_ALERT) newAlert!: Function;
    @headerNotificationModul.Action(NEW_INFO) newInfo!: Function;

    created() {
        // get return url from route parameters or default to '/'
        this.returnUrl = typeof this.$route.query.returnUrl === 'string' ? this.$route.query.returnUrl : '/';

        if (typeof this.$route.query.autologin === 'string') {
            if (this.$route.query.autologin.toLowerCase() === 'totp') {
                this.autologin = AutoLogin.totp;
            } else if (this.$route.query.autologin.toLowerCase() === 'sms') {
                this.autologin = AutoLogin.sms;
            }
        }

        if (this.loggedIn) {
            this.$router.push(this.returnUrl);
        }
        this.setSubTitle('Login');
        this.setFooterTitle('S010');
    }

    mounted() {
        if (!this.loggedIn && this.autologin != AutoLogin.undefined) {
            if (this.autologin === AutoLogin.totp) {
                this.handleLoginTotp();
            } else if (this.autologin === AutoLogin.sms) {
                this.handleLoginSms();
            }
        }
    }

    handleLoginSms() {
        this.login(LoginType.sms);
    }
    handleLoginTotp() {
        this.login(LoginType.totp);
    }

    forwardToVetdata() {
        location.replace('https://www.vetdata.ch');
    }

    @Watch('loggedIn')
    onPropertyChanged(newVal: boolean) {
        //Redirect if loggedIn
        if (newVal) {
            this.$router.push(this.returnUrl);
        }
    }
}
</script>

<style></style>
