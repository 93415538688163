import { HomeFilterDto } from '@/types/HomeFilterDto';
import { TvsBuchungApiDto, KontoSaldoApiDto } from '@/api-schema';

export interface IHomeState {
    homeMessage: string;
    queryFilter: HomeFilterDto;
    tableList: Array<TvsBuchungApiDto>;
    tabList: Array<KontoSaldoApiDto>;
    loading: boolean;
    totalNumberOfRecords: number | null;
}

export const state: IHomeState = {
    homeMessage: '',
    queryFilter: new HomeFilterDto(),
    tableList: [],
    tabList: [],
    loading: false,
    totalNumberOfRecords: null
};
