import clone from 'clone';
import { IArchivService } from './IArchivService';
import BaseServie from '@/services/baseService';
import { AxiosResponse } from 'axios';
import { DokumentEditApiDto, DokumentApiDtoSearchResponseApi, SchlagwortUploadApiDto } from '@/api-schema';
import { DokumentFilterDto } from '@/types/DokumentFilterDto';

export class ArchivService extends BaseServie implements IArchivService {
    private RESOURCE_URL: string;
    private SCHLAGWORT_URL: string;
    constructor() {
        super();
        this.RESOURCE_URL = `${this.BASE_URL}dokumente`;
        this.SCHLAGWORT_URL = `${this.RESOURCE_URL}/schlagworte`;


        console.debug('ArchivService::constructor', this.RESOURCE_URL);
    }
    async getErstelltVon(): Promise<Array<string>> {
        return super.GET<Array<string>>(`${this.RESOURCE_URL}/erstelltvon`).then(response => {
            return response;
        });
    }
    async deleteDokument(id: number): Promise<object> {
        return super.DELETE(`${this.RESOURCE_URL}/${id}`).then(response => {
            return response;
        });
    }
    async downloadDokument(id: string): Promise<AxiosResponse<File>> {
        return super.DOWNLOAD<File>(`${this.RESOURCE_URL}/download/` + id).then(response => {
            return response;
        });
    }
    async downloadAllDokumente(filter: DokumentFilterDto, onDownloadProgress?: (progressEvent: any) => void): Promise<any> {
        const config = clone(this.getDownloadConfig);
        if (onDownloadProgress) {
            config.onDownloadProgress = onDownloadProgress;
        }

        return super.DOWNLOAD<File>(`${this.RESOURCE_URL}/downloadAll/` + super.serializeObjectToQuerystring(filter), config).then(response => {
            return response;
        });
    }
    downloadMultipleDokumente(downloadGuids: Array<string>, onDownloadProgress?: (progressEvent: any) => void): Promise<any> {
        const config = clone(this.getDownloadConfig);
        if (onDownloadProgress) {
            config.onDownloadProgress = onDownloadProgress;
        }

        return super.DOWNLOAD<File>(`${this.RESOURCE_URL}/downloadmultiple/` + super.serializeObjectToQuerystring({ downloadGuids: downloadGuids }), config).then(response => {
            return response;
        });
    }
    async getAll(filter: DokumentFilterDto): Promise<DokumentApiDtoSearchResponseApi> {
        return super
            .GET<DokumentApiDtoSearchResponseApi>(`${this.RESOURCE_URL}/` + super.serializeObjectToQuerystring(filter))
            .then(response => {
                return response;
            });
    }
    async getDokumentTypes(): Promise<Array<string>> {
        return super.GET<Array<string>>(`${this.RESOURCE_URL}/dokumenttypes`).then(response => {
            return response;
        });
    }
    async getFileTypes(): Promise<Array<string>> {
        return super.GET<Array<string>>(`${this.RESOURCE_URL}/filetypes`).then(response => {
            return response;
        });
    }
    async getDokument(id: number): Promise<DokumentEditApiDto> {
        return super.GET<DokumentEditApiDto>(`${this.RESOURCE_URL}/${id}`).then(response => {
            return response;
        });
    }
    async editDokumentInfo(dokumentInfo: DokumentEditApiDto): Promise<AxiosResponse> {
        return super.PUT<DokumentEditApiDto>(`${this.RESOURCE_URL}`, dokumentInfo).then(response => {
            return response;
        });
    }
    async getSchlagwortVorschlaege(suchText: string): Promise<string[]> {
        return super.GET<Array<string>>(`${this.SCHLAGWORT_URL}/${suchText}`).then(response => {
            return response;
        });
    }

    async addSchlagwortVorschlag(schlagwort: string): Promise<AxiosResponse<any>> {
        const schlagwortUploadObject = { schlagwort: schlagwort };
        return super.POST<SchlagwortUploadApiDto>(`${this.SCHLAGWORT_URL}`, schlagwortUploadObject);
    }

    async deleteSchlagwortVorschlag(schlagwort: string): Promise<AxiosResponse<any>> {
        return super.DELETE(`${this.SCHLAGWORT_URL}/${schlagwort}`).then(response => {
            return response;
        });
    }
}

export const archivService = new ArchivService();
