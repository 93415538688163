import { IProfilService } from './IProfilService';
import BaseService from '@/services/baseService';
import {
    TeilnehmerApiDto,
    BelegterSpeicherApiDto,
    BenutzerEditApiDto,
    ServiceEditApiDto,
    BenutzerCreateApiDto,
    BenutzerDetailApiDto,
    TeilnehmerFunktionApiDto,
    ServiceDataApiDto,
    TeilnehmerZugriffPostApiDto,
    TeilnehmerZugriffApiDto,
    TeilnehmerZugriffCreateApiDto
} from '@/api-schema';
import { TeilnehmerBenutzerDto } from '@/types/UserDtos';
import { AxiosResponse } from 'axios';
import { SubscribedServicesDto } from '@/types/subscribedServicesDto';

export class ProfilService extends BaseService implements IProfilService {
    private RESOURCE_URL: string;
    private SERVICE_URL: string;
    private FUNKTION_URL: string;
    private BENUTZER_URL: string;
    private ZUGRIFF_URL: string;
    constructor() {
        super();
        this.RESOURCE_URL = `${this.BASE_URL}teilnehmer`;
        this.SERVICE_URL = `${this.RESOURCE_URL}/teilnehmerservice`;
        this.FUNKTION_URL = `${this.RESOURCE_URL}/teilnehmerfunktion`;
        this.BENUTZER_URL = `${this.RESOURCE_URL}/teilnehmerbenutzer`;
        this.ZUGRIFF_URL = `${this.BASE_URL}teilnehmerzugriff`;

        console.debug('ProfilService::constructor', this.RESOURCE_URL);
    }
    async getTeilnehmerZugriffe(): Promise<Array<TeilnehmerZugriffApiDto>> {
        return super.GET<Array<TeilnehmerZugriffApiDto>>(`${this.ZUGRIFF_URL}`).then(response => {
            return response;
        });
    }
    async createTeilnehmerZugriff(createApiDto: TeilnehmerZugriffPostApiDto): Promise<any> {
        return super.POST<TeilnehmerZugriffPostApiDto>(`${this.ZUGRIFF_URL}`, createApiDto);
    }
    async getTeilnehmerCreateInformation(): Promise<Array<TeilnehmerZugriffCreateApiDto>> {
        return super.GET<Array<TeilnehmerZugriffCreateApiDto>>(`${this.ZUGRIFF_URL}/create`).then(response => {
            return response;
        });
    }
    async deleteTeilnehmerZugriff(teilnehmerZugriffId: number): Promise<any> {
        return super.DELETE(`${this.ZUGRIFF_URL}/${teilnehmerZugriffId}`).then(response => {
            return response;
        });
    }
    async editTeilnehmerfunktion(teilnehmerFunktionApiDto: TeilnehmerFunktionApiDto): Promise<boolean> {
        return super.PUT<TeilnehmerFunktionApiDto>(`${this.FUNKTION_URL}`, teilnehmerFunktionApiDto).then(() => {
            return true;
        });
    }
    async getEditTeilnehmerfunktion(): Promise<TeilnehmerFunktionApiDto> {
        return super.GET<TeilnehmerFunktionApiDto>(`${this.FUNKTION_URL}`).then(response => {
            return response;
        });
    }
    async editServices(services: SubscribedServicesDto): Promise<any> {
        return super.PUT<SubscribedServicesDto>(`${this.SERVICE_URL}/upgradeInfo`, services).then(response => {
            return response;
        });
    }

    async getCompatibleServices(): Promise<ServiceEditApiDto> {
        return super.GET<ServiceEditApiDto>(`${this.SERVICE_URL}/upgradeInfo`).then(response => {
            return response;
        });
    }

    async getEditAccount(objectBenutzerId: string): Promise<BenutzerEditApiDto> {
        return super.GET<BenutzerEditApiDto>(`${this.BENUTZER_URL}/${objectBenutzerId}`).then(response => {
            return response;
        });
    }

    async addBenutzer(benutzer: BenutzerCreateApiDto): Promise<any> {
        return super.POST<BenutzerCreateApiDto>(`${this.BENUTZER_URL}`, benutzer);
    }

    async editBenutzer(benutzer: BenutzerEditApiDto): Promise<any> {
        return super.PUT<BenutzerEditApiDto>(`${this.BENUTZER_URL}`, benutzer);
    }

    async deleteBenutzer(objectBenutzerId: string): Promise<any> {
        return super.DELETE(`${this.BENUTZER_URL}/${objectBenutzerId}`);
    }

    async requestEditAdresse(teilnehmer: TeilnehmerApiDto): Promise<boolean | AxiosResponse<any>> {
        return super.PUT<TeilnehmerApiDto>(`${this.RESOURCE_URL}`, teilnehmer).then(response => {
            return response;
        });
    }

    async getVerrechnungDiagramm(): Promise<BelegterSpeicherApiDto> {
        return super.GET<BelegterSpeicherApiDto>(`${this.SERVICE_URL}/diagramm`, super.getConfig, false).then(response => {
            return response;
        });
    }
    async getTeilnehmer(): Promise<TeilnehmerApiDto> {
        return super.GET<TeilnehmerApiDto>(`${this.RESOURCE_URL}`).then(response => {
            return response;
        });
    }
    async getTeilnehmerService(): Promise<ServiceDataApiDto> {
        return super.GET<ServiceDataApiDto>(`${this.SERVICE_URL}`).then(response => {
            return response;
        });
    }
    async getBenutzer(): Promise<Array<TeilnehmerBenutzerDto>> {
        return super.GET<Array<TeilnehmerBenutzerDto>>(`${this.BENUTZER_URL}`).then(response => {
            return response;
        });
    }
    async getBenutzerDetails(id: string): Promise<BenutzerDetailApiDto> {
        return super.GET<BenutzerDetailApiDto>(`${this.BENUTZER_URL}/${id}/detail`).then(response => {
            return response;
        });
    }
}

export const profilService = new ProfilService();
