import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// RootState
export interface IRootState {
    version: string;
}
export const RootState: IRootState = {
    version: '0.0.1'
};

import general, { NAME as GENERAL } from '@/store/modules/general';
import resource, { NAME as RESOURCE } from '@/store/modules/resource';
import validation, { NAME as VALIDATION } from '@/store/modules/validation';
import security, { NAME as SECURITY } from '@/store/modules/security';
import headerNotification, { NAME as HEADERNOTIFICATION } from '@/store/modules/headerNotification';
import loadingSpinner, { NAME as LOADINGSPINNER } from '@/store/modules/loadingSpinner';
import modalConfirmation, { NAME as MODALCONFIRMATION } from '@/store/modules/modalConfirmation';
// AREAS
import home, { NAME as HOME } from '@/areas/home/store';
import einstellungen, { NAME as EINSTELLUNGEN } from '@/areas/profileinstellungen/store';
import registration, { NAME as REGISTRATION } from '@/areas/registrierung/store';
import archiv, { NAME as ARCHIV } from '@/areas/archiv/store';
import rechnungen, { NAME as RECHNUNGEN } from '@/areas/lieferantenrechnung/store';
import upload, { NAME as UPLOAD } from '@/areas/upload/store';
import administration, { NAME as ADMINISTRATION } from '@/areas/administration/store';

export const store = new Vuex.Store({
    state: RootState,
    mutations: {},
    actions: {},
    modules: {
        [GENERAL]: general,
        [RESOURCE]: resource,
        [VALIDATION]: validation,
        [SECURITY]: security,
        [HEADERNOTIFICATION]: headerNotification,
        [LOADINGSPINNER]: loadingSpinner,
        [MODALCONFIRMATION]: modalConfirmation,
        // AREAS
        [HOME]: home,
        [EINSTELLUNGEN]: einstellungen,
        [REGISTRATION]: registration,
        [ARCHIV]: archiv,
        [RECHNUNGEN]: rechnungen,
        [UPLOAD]: upload,
        [ADMINISTRATION]: administration
    }
});

export default store;
