<template>
    <v-container fluid>
        <ValidationObserver validate v-slot="{ invalid }">
            <v-form @submit.prevent="createTeilnehmerIfValid(teilnehmer)">
                <v-row>
                    <v-col sm="12">
                        <v-card>
                            <v-col sm="12">
                                <h3 v-text="$t('registration.description')" />
                            </v-col>
                            <v-col class="py-0" sm="4">
                                <ValidationProvider
                                    name="TVS-Nummer"
                                    :rules="getValidation('teilnehmerApiDto.kundenNummer')"
                                    v-slot="{ errors }"
                                >
                                    <v-text-field
                                        :label="$t('registration.label.tvsnummer') + '*'"
                                        :placeholder="$t('registration.placeholder.tvsnummer')"
                                        v-model.number="teilnehmer.kundenNummer"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </ValidationProvider>
                                <ValidationProvider
                                    name="Anrede"
                                    :rules="getValidation('teilnehmerApiDto.anrede')"
                                    v-slot="{ errors }"
                                >
                                    <v-select
                                        :items="anredeList"
                                        :label="$t('registration.label.anrede') + '*'"
                                        v-model="teilnehmer.anredeTypCode"
                                        :error-messages="errors"
                                    ></v-select>
                                </ValidationProvider>
                                <v-select
                                    :items="titelList"
                                    v-model="teilnehmer.titelTypCode"
                                    :label="$t('registration.label.titel')"
                                ></v-select>
                                <ValidationProvider
                                    name="Vorname"
                                    :rules="getValidation('teilnehmerApiDto.vorname')"
                                    v-slot="{ errors }"
                                >
                                    <v-text-field
                                        :label="$t('registration.label.vorname') + '*'"
                                        v-model="teilnehmer.vorname"
                                        :placeholder="$t('registration.label.vorname')"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </ValidationProvider>
                                <ValidationProvider
                                    name="Nachname"
                                    :rules="getValidation('teilnehmerApiDto.name')"
                                    v-slot="{ errors }"
                                >
                                    <v-text-field
                                        :label="$t('registration.label.nachname') + '*'"
                                        :placeholder="$t('registration.label.nachname')"
                                        v-model="teilnehmer.name"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </ValidationProvider>
                                <v-text-field
                                    :label="$t('registration.label.firma')"
                                    :placeholder="$t('registration.label.firma')"
                                    v-model="teilnehmer.firma"
                                ></v-text-field>
                                <ValidationProvider
                                    name="Strasse"
                                    :rules="getValidation('teilnehmerApiDto.strasse')"
                                    v-slot="{ errors }"
                                >
                                    <v-text-field
                                        :label="$t('registration.label.strasse') + '*'"
                                        :placeholder="$t('registration.label.strasse')"
                                        v-model="teilnehmer.strasse"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </ValidationProvider>
                                <v-text-field
                                    :label="$t('registration.label.adresszusatz')"
                                    :placeholder="$t('registration.label.adresszusatz')"
                                    v-model="teilnehmer.zusatz"
                                />
                                <ValidationProvider
                                    name="Plz"
                                    :rules="getValidation('teilnehmerApiDto.plz')"
                                    v-slot="{ errors }"
                                >
                                    <v-text-field
                                        :label="$t('registration.label.plz') + '*'"
                                        :placeholder="$t('registration.label.plz')"
                                        v-model.number="teilnehmer.plz"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </ValidationProvider>
                                <v-text-field
                                    :label="$t('registration.label.ort')"
                                    :placeholder="$t('registration.label.ort')"
                                    v-model="teilnehmer.ort"
                                ></v-text-field>
                                <ValidationProvider
                                    name="Telefon"
                                    :rules="getValidation('teilnehmerApiDto.telefon') + '|valid_swiss_telephone_number'"
                                    v-slot="{ errors }"
                                >
                                    <v-text-field
                                        :label="$t('registration.label.telefon') + '*'"
                                        placeholder="+41 31 111 22 33"
                                        v-model="teilnehmer.telefon"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </ValidationProvider>
                                <ValidationProvider
                                    name="TelefonMobil"
                                    :rules="
                                        getValidation('teilnehmerApiDto.telefonMobil') + '|valid_swiss_telephone_number'
                                    "
                                    v-slot="{ errors }"
                                >
                                    <v-text-field
                                        :label="$t('registration.label.telefonmobil') + '*'"
                                        placeholder="+41 77 111 22 33"
                                        v-model="teilnehmer.telefonMobil"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </ValidationProvider>
                                <ValidationProvider
                                    name="Sprache"
                                    :rules="getValidation('teilnehmerApiDto.sprache')"
                                    v-slot="{ errors }"
                                >
                                    <v-select
                                        :items="spracheList"
                                        :label="$t('registration.label.sprache') + '*'"
                                        v-model="teilnehmer.sprache"
                                        :error-messages="errors"
                                    ></v-select>
                                </ValidationProvider>
                                <ValidationProvider
                                    name="Email"
                                    :rules="getValidation('teilnehmerApiDto.email')"
                                    v-slot="{ errors }"
                                >
                                    <v-text-field
                                        :label="$t('registration.label.email') + '*'"
                                        :placeholder="$t('registration.label.email')"
                                        v-model="teilnehmer.email"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-row class="pl-3">
                                <v-col sm="2 pt-0 pb-5">
                                    <v-btn
                                        type="submit"
                                        :disabled="invalid || requiredFieldsFilled"
                                        large
                                        color="primary"
                                        >{{ $t('registration.button.confirm') }}</v-btn
                                    >
                                </v-col>
                                <v-col sm="2 pt-0 pb-5">
                                    <v-btn large color="primary" link @click="openVetdataInNewTab">{{
                                        $t('registration.button.cancel')
                                    }}</v-btn>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-form>
        </ValidationObserver>
    </v-container>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { RegisterDto } from '@/types/UserDtos';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { NAME as NAME_REGISTRATION } from '../store';
import { NAME as NAME_GENERAL } from '@/store/modules/general';
import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
import { NAME as NAME_RESOURCE } from '@/store/modules/resource';

const resourceModul = namespace(NAME_RESOURCE);
const registrationModul = namespace(NAME_REGISTRATION);
const generalModul = namespace(NAME_GENERAL);
const validationModul = namespace(NAME_VALIDATION);

import { REQUEST_CREATE_TEILNEHMER } from '../store/actions';
import { SUBTITLE, FOOTERTITLE } from '@/store/modules/general/mutations';
import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';
import { GET_LANGUAGE } from '@/store/modules/resource/getters';

@Component({
    components: {
        ValidationProvider,
        ValidationObserver
    }
})
export default class Registration extends Vue {
    @generalModul.Mutation(SUBTITLE) setSubTitle!: Function;
    @generalModul.Mutation(FOOTERTITLE) setFooterTitle!: Function;

    @resourceModul.Getter(GET_LANGUAGE) language!: string;

    @registrationModul.Action(REQUEST_CREATE_TEILNEHMER) createTeilnehmerIfValid!: Function;

    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;

    get anredeList(): Array<any> {
        return [
            { value: 'Herr', text: this.$t('registration.anrede.herr') },
            { value: 'Frau', text: this.$t('registration.anrede.frau') },
            { value: 'Herren', text: this.$t('registration.anrede.herren') },
            { value: 'HerrUndFrau', text: this.$t('registration.anrede.herrundfrau') },
            { value: 'Firma', text: this.$t('registration.anrede.firma') }
        ];
    }
    get titelList(): Array<any> {
        return [
            { value: '', text: '-' },
            { value: 'MedVet', text: this.$t('registration.titel.medvet') },
            { value: 'DrMedVet', text: this.$t('registration.titel.drmedvet') },
            { value: 'ProfDr', text: this.$t('registration.titel.profdr') },
            { value: 'DresMedVet', text: this.$t('registration.titel.dresmedvet') }
        ];
    }
    get spracheList(): Array<any> {
        return [
            { value: 'D', text: this.$t('registration.sprache.deutsch') },
            { value: 'F', text: this.$t('registration.sprache.franzoesisch') }
        ];
    }
    teilnehmer: RegisterDto = new RegisterDto();

    created() {
        this.setSubTitle(this.$t('registration.title'));
        this.setFooterTitle('S030');
        this.teilnehmer = new RegisterDto();
    }

    get requiredFieldsFilled(): boolean {
        let invalidKn = false;
        if (this.teilnehmer.kundenNummer === undefined) {
            invalidKn = true;
        } else if (this.teilnehmer.kundenNummer.toString() === '') {
            invalidKn = true;
        }
        let invalidPlz = false;
        if (this.teilnehmer.plz === undefined) {
            invalidPlz = true;
        } else if (this.teilnehmer.plz.toString() === '') {
            invalidPlz = true;
        }
        return (
            invalidKn ||
            invalidPlz ||
            this.teilnehmer.telefon.length === 0 ||
            this.teilnehmer.telefonMobil.length === 0 ||
            this.teilnehmer.email.length === 0 ||
            this.teilnehmer.anredeTypCode.length === 0 ||
            this.teilnehmer.vorname.length === 0 ||
            this.teilnehmer.name.length === 0 ||
            this.teilnehmer.strasse.length === 0 ||
            this.teilnehmer.sprache.length === 0
        );
    }

    private openVetdataInNewTab() {
        if (this.language.toLowerCase() == 'de') {
            window.open(config.VUE_APP_AGB_LINK_GERMAN);
        } else if (this.language.toLowerCase() == 'fr') {
            window.open(config.VUE_APP_AGB_LINK_FRENCH);
        }
    }
}
</script>
