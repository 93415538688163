<template>
    <v-row>
        <v-col>
            <v-col sm="12">
                <v-row>
                    <v-col sm="10">
                        <h3 class="pl-3" v-text="$t('einstellungen.teilnehmerzugriff.title')" />
                    </v-col>
                    <v-col style="overflow: hidden;" sm="2">
                        <AddTeilnehmerZugriffDialog />
                    </v-col>
                </v-row>
            </v-col>
            <v-data-table
                :headers="headers"
                :items="teilnehmerZugriffList"
                item-key="teilnehmerName"
                disable-pagination
                hide-default-footer
                class="elevation-1 nowhow-table"
                :must-sort="true"
            >
                <template v-slot:item.berechtigteRolle="{ item }">
                    <span>
                        {{ $t('benutzerrollen.' + item.berechtigteRolle.toLowerCase()) }}
                    </span>
                </template>
                <template v-slot:item.action="{ item }">
                    <ConfirmDialog
                        @confirm="deleteTeilnehmerZugriff(item.teilnehmerZugriffId)"
                        title="einstellungen.teilnehmerzugriff.deleteTitle"
                        text="einstellungen.teilnehmerzugriff.deleteText"
                    >
                        <template v-slot:activatorElement="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon color="primary" v-text="'mdi-delete'" />
                            </v-btn>
                        </template>
                    </ConfirmDialog>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TeilnehmerZugriffApiDto } from '@/api-schema';

import { NAME as NAME_EINSTELLUNGEN } from '../store';
const profilModul = namespace(NAME_EINSTELLUNGEN);
import { DELETE_TEILNEHMER_ZUGRIFF } from '../store/actions';

import ConfirmDialog from '@/components/ConfirmDialog.vue';
import AddTeilnehmerZugriffDialog from './AddTeilnehmerZugriffDialog.vue';
@Component({
    components: {
        ConfirmDialog,
        AddTeilnehmerZugriffDialog
    }
})
export default class TeilnehmerZugriffListe extends Vue {
    @Prop({ required: true, type: Array })
    private teilnehmerZugriffList!: Array<TeilnehmerZugriffApiDto>;

    @profilModul.Action(DELETE_TEILNEHMER_ZUGRIFF) deleteTeilnehmerZugriff!: Function;

    get headers(): Array<any> {
        return [
            {
                text: this.$t('einstellungen.teilnehmerzugriff.table.teilnehmer'),
                value: 'teilnehmerName'
            },
            {
                text: this.$t('einstellungen.teilnehmerzugriff.table.berechtigung'),
                value: 'berechtigteRolle'
            },
            {
                text: this.$t(''),
                value: 'action',
                align: 'center',
                width: '10',
                sortable: false
            }
        ];
    }
}
</script>
