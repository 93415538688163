export class SubscribedServicesDto {
    constructor(speicherId: number, funktionIds: Array<number>, rowVersion: string) {
        this.speicherService = speicherId;
        this.funktionServices = funktionIds;
        this.rowVersion = rowVersion;
    }
    speicherService: number;
    funktionServices: Array<number>;
    rowVersion: string;
}
