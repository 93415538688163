import { ArtikelApiDto, BenutzerInfoSearchResultApiDto, TakeTeilnehmerApiDto, TeilnehmerListApiDto, VetdataVerarbeitungLogApiDto } from '@/api-schema';
import { ArtikelFilterDto } from '@/types/ArtikelFilterDto';
import { BenutzerInfoSearchApiDto } from '@/types/BenutzerInfoSearchApiDto';
import { TeilnehmerFilterDto } from '@/types/TeilnehmerFilterDto';

export interface ITakeTeilnehmerState {
    teilnehmer: TakeTeilnehmerApiDto;
    teilnehmerFilter: TeilnehmerFilterDto;
    teilnehmerList: Array<TeilnehmerListApiDto>;
    teilnehmerTotalNumberOfRecords: number | null;
    teilnehmerLoading: boolean,
    artikelLoading: boolean;
    artikelTotalNumberOfRecords: number | null;
    artikelQueryFilter: ArtikelFilterDto;
    artikelList: Array<ArtikelApiDto>;
    vetdataLog: VetdataVerarbeitungLogApiDto;
    benutzerFilter: BenutzerInfoSearchApiDto;
    benutzerInfoSearchResultApiDto: BenutzerInfoSearchResultApiDto;
    benutzerTotalNumberOfRecords: number | null;
    benutzerLoading: boolean;
}

export const state: ITakeTeilnehmerState = {
    teilnehmer: {
        teilnehmerId: null,
        tvsKundennummer: null,
        benutzerRolle: ''
    },
    teilnehmerFilter: new TeilnehmerFilterDto(),
    teilnehmerList: [],
    teilnehmerTotalNumberOfRecords: null,
    teilnehmerLoading: false,
    artikelLoading: false,
    artikelTotalNumberOfRecords: null,
    artikelQueryFilter: new ArtikelFilterDto(),
    artikelList: [],
    vetdataLog: {},
    benutzerFilter: new BenutzerInfoSearchApiDto(),
    benutzerInfoSearchResultApiDto: null,
    benutzerTotalNumberOfRecords: null,
    benutzerLoading: false
};
