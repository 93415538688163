//i18n-setup.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const dateTimeFormats: VueI18n.DateTimeFormats = {
    de: {
        short: {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        },
        medium: {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        },
        long: {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }
    }
};

export default new VueI18n({
    dateTimeFormats,
    fallbackLocale: config.VUE_APP_I18N_FALLBACK_LANGUAGE,
    silentTranslationWarn: true
});
