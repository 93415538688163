import BaseService from './baseService';

export class ValidationService extends BaseService {
    private RESOURCE_URL: string;
    constructor() {
        super();
        this.RESOURCE_URL = `${this.BASE_URL}validation`;

        console.debug('ValidationService::constructor', this.RESOURCE_URL);
    }

    async getAll(): Promise<any> {
        return super.GET<Map<string, string>>(`${this.RESOURCE_URL}`).then((response: any) => {
            return response;
        });
    }

    async getAllowedFileTypes(): Promise<any> {
        return super.GET<Array<string>>(`${this.RESOURCE_URL}/AllowedFileTypes`).then((response: Array<string>) => {
            return response;
        });
    }
}

export const validationService = new ValidationService();
