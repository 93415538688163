import { MutationTree } from 'vuex';
import { ISecurityState } from './state';

export const LOGGEDIN = 'loggedIn';
export const BENUTZERINFO = 'setBenutzerInfo';
export const PERMISSIONS = 'setPermissions';
export const SETACCESSDENIED = 'setAccessDenied';

export const PERMISSION = 'permissions';
export const INACTIVITYWARNINGTIME = 'inactivityWarningTime';
export const INACTIVITYLOGOUTTIME = 'inactivitylogoutTime';
export const INACTIVITYWARNINGTIMEOUT = 'inactivityWarningTimeout';
export const INACTIVITYLOGOUTTIMEOUT = 'inactivitylogoutTimeout';
export const CHANGEMODALAGBVISIBLE = 'changeModalAgbVisible';

export const mutations: MutationTree<ISecurityState> = {
    [LOGGEDIN]: (state, loggedIn) => {
        state.loggedIn = loggedIn;
    },
    [INACTIVITYWARNINGTIME]: (state, inactivityWarningTime) => {
        state.inactivityWarningTime = inactivityWarningTime;
        localStorage.setItem(INACTIVITYWARNINGTIME, inactivityWarningTime);
    },
    [INACTIVITYLOGOUTTIME]: (state, inactivitylogoutTime) => {
        state.inactivitylogoutTime = inactivitylogoutTime;
        localStorage.setItem(INACTIVITYLOGOUTTIME, inactivitylogoutTime);
    },
    [INACTIVITYWARNINGTIMEOUT]: (state, inactivityWarningTimeout) => {
        state.inactivityWarningTimeout = inactivityWarningTimeout;
    },
    [INACTIVITYLOGOUTTIMEOUT]: (state, inactivityLogoutTimeout) => {
        state.inactivityLogoutTimeout = inactivityLogoutTimeout;
    },
    [BENUTZERINFO]: (state, benutzerInfo) => {
        state.benutzerInfo = benutzerInfo;
    },
    [PERMISSIONS]: (state, permissions) => {
        state.permissions = permissions;
        localStorage.setItem(PERMISSION, permissions?.join(';') || '');
    },
    [SETACCESSDENIED]: (state, value) => {
        state.accessDenied = value;
    },
    [CHANGEMODALAGBVISIBLE]: (state, visibility) => {
        state.modalAgbVisible = visibility;
    }
};
