<template>
    <v-app v-cloak id="inspire">
        <loading-spinner></loading-spinner>

        <v-navigation-drawer v-if="$vuetify.breakpoint.lgAndUp && isLoggedIn" app :mini-variant="isMiniVariant">
            <navigation :is-mini-variant="isMiniVariant" @mini-variant-changed="miniVariantChanged" />
        </v-navigation-drawer>

        <v-navigation-drawer v-else-if="isLoggedIn" app v-model="drawer">
            <navigation />
        </v-navigation-drawer>

        <v-app-bar app dark class="primary white--text">
            <div v-if="!isLoggedIn" class="topLeftIcon">
                <v-img src="@/assets/vetdata-Logo.jpg" height="64"></v-img>
            </div>
            <v-app-bar-nav-icon
                v-else-if="$vuetify.breakpoint.mdAndDown"
                @click="isMiniVariant = !isMiniVariant"
                @click.stop="drawer = !drawer"
            />
            <v-toolbar-title>
                <h3>{{ $t(subTitle) }}</h3>
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <auto-logout v-if="isLoggedIn"></auto-logout>

            <div
                v-if="isLoggedIn"
                @click="openMailToFeedback({ lang: language, senderInfo: benutzerInfo, screen: footerTitle })"
                class="pr-4 pt-1"
            >
                <u class="feedbackEmailLink">{{ $t('Feedback Email') }}</u>
            </div>
            <div class="pr-3" style="width: 70px;">
                <v-select
                    class="language-select"
                    @change="changeLanguage"
                    :items="languages"
                    hide-details
                    dense
                    :value="language.toUpperCase()"
                ></v-select>
            </div>
            <v-tooltip v-if="isLoggedIn" bottom color="secondary">
                <template v-slot:activator="{ on }">
                    <div v-on="on">
                        <v-btn style="cursor: default;" icon class="mr-3">
                            <v-icon x-large>mdi-account-cog</v-icon>
                        </v-btn>
                        <label class="mr-6" v-if="!$vuetify.breakpoint.xs">
                            TVS-Nr:
                            <b>{{ benutzerInfo.tvsKundenNummer }}</b>
                        </label>
                    </div>
                </template>
                <div>
                    <span>
                        <label v-if="$vuetify.breakpoint.xs">
                            TVS-Nr:
                            <b>{{ benutzerInfo.tvsKundenNummer }}</b>
                            <br />
                        </label>
                        <label>{{ benutzerInfo.anzeigeName }}</label>
                        <br />
                        <label v-if="benutzerInfo.rollen">{{
                            this.$t('benutzerrollen.' + this.benutzerInfo.rollen.toString().toLowerCase()).toString()
                        }}</label>
                    </span>
                </div>
            </v-tooltip>
            <v-btn icon link :to="{ name: 'Login' }" @click="logout" v-if="isLoggedIn && permissions.length > 0">
                <v-icon>mdi-logout</v-icon>
            </v-btn>
            <header-notification></header-notification>
        </v-app-bar>
        <v-main class="accent">
            <agb-confirm-dialog @openagblink="openAgbInNewTab" />

            <v-row v-if="(isLoggedIn && !hasAccess('Navigation.GuiAccess') && permissions.length > 0) || accessDenied">
                <v-col class="pl-9">
                    <v-row>
                        <h2>Keinen Zugriff</h2>
                    </v-row>
                    <v-row>
                        <p>Sie besitzen nicht die nötigen Rechte um auf diese Seite zuzugreifen.</p>
                    </v-row>
                    <v-row>
                        <v-btn small color="primary" @click="logout">{{ $t('login.button.logout') }}</v-btn>
                    </v-row>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col>
                    <router-view />
                </v-col>
            </v-row>
        </v-main>
        <v-footer color="grey" app>
            <span class="white--text">
                <div class="d-inline mr-10">[{{ footerTitle }}]</div>
                <div class="d-inline mr-10">{{ timestamp }}</div>
                <div class="d-inline mr-10">© {{ currentYear }}</div>
                <!-- Version und System -->
                <div class="d-inline mr-10">{{ AppTitle }} V{{ AppVersion }} {{ AppEnviroment }}</div>
            </span>
        </v-footer>
    </v-app>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';

import { DateMixin } from '@/mixins/DateMixin';

import { NAME as NAME_GENERAL } from '@/store/modules/general';
const generalModul = namespace(NAME_GENERAL);
import { TITLE, FOOTERTITLE } from '@/store/modules/general/getters';
import { FEEDBACK_MAILTO, LOAD_FRONTENDCONFIGS } from './store/modules/general/actions';

import { NAME as NAME_RESOURCE } from '@/store/modules/resource';
const resourceModul = namespace(NAME_RESOURCE);
import { LOAD_LANGUAGE } from '@/store/modules/resource/actions';
import { GET_LANGUAGE } from '@/store/modules/resource/getters';

import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
const validationModul = namespace(NAME_VALIDATION);
import { LOAD_ALLOWED_FILETYPES, LOAD_VALIDATION } from '@/store/modules/validation/actions';

import { NAME as NAME_SECURITY } from '@/store/modules/security';
const securityModul = namespace(NAME_SECURITY);
import { LOGOUT, GET_BENUTZERINFO, GET_PERMISSIONS } from '@/store/modules/security/actions';
import { ISLOGGEDIN, BENUTZERINFO, HASACCESS, PERMISSIONS, ACCESSDENIED } from '@/store/modules/security/getters';

import navigation from './components/Navigation.vue';
import loadingSpinner from './components/LoadingSpinner.vue';
import headerNotification from './components/HeaderNotification.vue';
import AgbConfirmDialog from './components/S110_AGBConfirmDialog.vue';
import autoLogout from './components/AutoLogout.vue';
@Component({
    components: {
        navigation,
        loadingSpinner,
        headerNotification,
        AgbConfirmDialog,
        autoLogout
    }
})
export default class App extends mixins(DateMixin) {
    isMiniVariant = false;
    languages: Array<string> = ['DE', 'FR'];
    drawer: string | null = null;
    timestamp = '';
    currentYear = '';

    @generalModul.Getter(TITLE) subTitle!: Function;
    @generalModul.Getter(FOOTERTITLE) footerTitle!: Function;
    @generalModul.Action(LOAD_FRONTENDCONFIGS) loadFrontendConfigs!: Function;
    @generalModul.Action(FEEDBACK_MAILTO) openMailToFeedback!: Function;

    @resourceModul.Action(LOAD_LANGUAGE) loadLanguage!: Function;
    @resourceModul.Getter(GET_LANGUAGE) language!: string;

    @validationModul.Action(LOAD_VALIDATION) loadValidation!: Function;
    @validationModul.Action(LOAD_ALLOWED_FILETYPES) loadAlowedFileTypes!: Function;

    @securityModul.Getter(ISLOGGEDIN) isLoggedIn!: boolean;
    @securityModul.Getter(BENUTZERINFO) benutzerInfo!: object;
    @securityModul.Getter(HASACCESS) hasAccess!: Function;
    @securityModul.Getter(PERMISSIONS) permissions: Array<string>;
    @securityModul.Getter(ACCESSDENIED) accessDenied!: boolean;
    @securityModul.Action(LOGOUT) logout!: Function;
    @securityModul.Action(GET_BENUTZERINFO) getBenutzerInfo!: Function;
    @securityModul.Action(GET_PERMISSIONS) getPermissions!: Function;

    async created() {
        await this.loadFrontendConfigs();

        this.loadValidation();
        this.loadAlowedFileTypes();
        setInterval(this.getNow, 1000);
        console.log('title:', config.VUE_APP_TITLE);
        console.log('enviroment:', config.VUE_APP_ENVIROMENT);
        console.log('version:', process.env.VUE_APP_VERSION);
    }

    private changeLanguage(l: string) {
        this.loadLanguage(l.toLowerCase());
    }

    private getNow() {
        const today = new Date();
        this.currentYear = today.getFullYear().toString();
        const date = this.toSwissDateString(today);
        let timebinder = ':';
        if (today.getMinutes() < 10) {
            timebinder += '0';
        }
        const time = today.getHours() + timebinder + today.getMinutes();
        const dateTime = date + ' ' + time;
        this.timestamp = dateTime;
    }

    private get AppVersion() {
        return process.env.VUE_APP_VERSION;
    }
    private get AppEnviroment() {
        return config.VUE_APP_ENVIROMENT;
    }
    private get AppTitle() {
        return config.VUE_APP_TITLE;
    }

    private miniVariantChanged(isMiniVariant: boolean) {
        this.isMiniVariant = isMiniVariant;
    }

    private openAgbInNewTab() {
        if (this.language.toLowerCase() == 'de') {
            window.open(config.VUE_APP_AGB_LINK_GERMAN);
        } else if (this.language.toLowerCase() == 'fr') {
            window.open(config.VUE_APP_AGB_LINK_FRENCH);
        }
    }

    @Watch('subTitle')
    private subTitleChanged(newVal: string) {
        document.title = config.VUE_APP_TITLE + ' - ' + newVal;
    }
}
</script>
<style>
[v-cloak] {
    display: none;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}
.headerNotification {
    position: absolute !important;
    margin-top: 129px;
    margin-left: -16px;
    width: 100%;
    z-index: 1;
}
.modalHeaderNotification {
    width: 100%;
    z-index: 1;
}
.topLeftIcon {
    height: 64px;
    width: 255px;
    margin-right: 25px;
    margin-left: -16px;
}
.feedbackEmailLink {
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
}
.modalbutton:focus {
    border: 3px solid black;
}
.filterLine {
    border-bottom: 1px solid gray;
}
.v-data-table td.text-start {
    overflow-wrap: break-word;
    max-width: 400px;
}
</style>
