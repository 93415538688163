export class ArtikelFilterDto {
    constructor() {
        this.searchQuery = '';
        this.offset = 0;
        this.entriesToLoad = 25;
        this.sortBy = 'produktnummer';
        this.sortOrder = 'Descending';
    }
    searchQuery: string;
    offset: number;
    entriesToLoad?: number;
    sortBy?: string;
    sortOrder?: string;
}
