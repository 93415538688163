export interface IModalConfirmationState {
    buttonText: string;
    onConfirmed: Function;
    onConfirmedParameter: Record<string, any>;
    text: string;
    title: string;
    visible: boolean;
}

export const state: IModalConfirmationState = {
    buttonText: '',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onConfirmed: function() {},
    onConfirmedParameter: {},
    text: '',
    title: '',
    visible: false
};
