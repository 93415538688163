import { Module } from 'vuex';
import { IRootState } from '@/store';

import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { IModalConfirmationState, state } from './state';

export const NAME = 'modalConfirmation';

export const store: Module<IModalConfirmationState, IRootState> = {
    actions,
    getters,
    modules: {},
    mutations,
    namespaced: true,
    state
};

export default store;
