import { MutationTree } from 'vuex';
import { IRegistrationState } from './state';
import { TeilnehmerApiDto } from '@/api-schema';

export const MAPREGISTERDATA = 'mapRegistrierungData';

export const mutations: MutationTree<IRegistrationState> = {
    [MAPREGISTERDATA](state, value: TeilnehmerApiDto) {
        state.registrierungData = value;
    }
};
