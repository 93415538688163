<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <!--table hauptkonfigurationen -->
                <v-data-table
                    :headers="headers"
                    :items="teilnehmerList"
                    :loading="loading"
                    :server-items-length="totalNumberOfRecords"
                    :custom-sort="customSort"
                    :must-sort="true"
                    :options.sync="options"
                    item-key="kundenNummer"
                    hide-default-footer
                    class="elevation-1 nowhow-table"
                    sort-by="kundenNummer"
                >
                    <!-- Header der Table(Filter anzeige, gefundene Elemente) -->
                    <template v-slot:top>
                        <TableHeader :filters="filters" @chipClosed="removeFilter">
                            <template>
                                {{ totalNumberOfRecords + ' ' + $t('archiv.table.header.items') }}
                                <v-text-field
                                    class="ml-5"
                                    v-if="showSearchBar"
                                    v-model="filterData.suchText"
                                    @input="searchFieldInputChanged"
                                    prepend-inner-icon="mdi-magnify"
                                    append-icon="mdi-close"
                                    @click:append="searchBarClosed"
                                    :label="$t('suche')"
                                    hide-details
                                    autofocus
                                ></v-text-field>
                                <v-spacer />
                                <v-tooltip v-if="!showSearchBar" top color="secondary">
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" icon color="primary">
                                            <v-icon @click="showSearchBar = !showSearchBar">mdi-magnify</v-icon>
                                        </v-btn>
                                    </template>
                                    <div>
                                        <span>
                                            {{ $t('tableheader.tooltip.search') }}
                                        </span>
                                    </div>
                                </v-tooltip>
                                <v-tooltip top color="secondary">
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" icon color="primary" @click="downloadTeilnehmer">
                                            <v-icon>mdi-download</v-icon>
                                        </v-btn>
                                    </template>
                                    <div>
                                        <span>
                                            {{ $t('tableheader.tooltip.download') }}
                                        </span>
                                    </div>
                                </v-tooltip>
                                <v-tooltip top color="secondary">
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-on="on"
                                            icon
                                            color="primary"
                                            @click="showFilterDrawer = !showFilterDrawer"
                                        >
                                            <v-icon>mdi-filter-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <div>
                                        <span>
                                            {{ $t('tableheader.tooltip.filter') }}
                                        </span>
                                    </div>
                                </v-tooltip>
                            </template>
                        </TableHeader>
                    </template>

                    <template v-slot:item.kundenNummer="{ item }">
                        <TextHighlights :text="item.kundenNummer" :search-text="filterData.suchText" />
                    </template>
                    <template v-slot:item.firma="{ item }">
                        <TextHighlights :text="item.firma" :search-text="filterData.suchText" />
                    </template>
                    <template v-slot:item.plz="{ item }">
                        <TextHighlights :text="item.plz + ' ' + item.ort" :search-text="filterData.suchText" />
                    </template>

                    <template v-slot:item.status="{ item }">
                        {{ $t(item.teilnehmerStatusCode) }}
                    </template>

                    <template v-slot:item.burgermenu="{ item }">
                        <v-btn icon :href="item.burgermenu" @click="initTakeSpecific(item.kundenNummer)">
                            <v-icon color="primary" v-text="'mdi-account-cog'" />
                        </v-btn>
                    </template>
                </v-data-table>

                <scroll-loader class="mb-5" :loader-method="load">
                    <v-progress-circular indeterminate v-show="loading" />
                </scroll-loader>
            </v-col>
        </v-row>
        <!-- FilterMenü -->
        <FilterDrawer
            :visible.sync="showFilterDrawer"
            @cancel="cancelFilter()"
            @confirm="confirmFilter()"
            @reset="resetFilter()"
        >
            <SingleFilter :title="$t('taketeilnehmer.table.header.status')">
                <template>
                    <v-row>
                        <v-col sm="12">
                            <v-select filled dense hide-details :items="status" v-model="filterData.status"></v-select>
                        </v-col>
                    </v-row>
                </template>
            </SingleFilter>
            <SingleFilter :title="$t('taketeilnehmer.table.header.hatRechnungenNurViaVetdata')">
                <template>
                    <v-row>
                        <v-col sm="12">
                            <v-select
                                filled
                                dense
                                hide-details
                                :items="hatRechnungenNurViaVetdata"
                                v-model="filterData.hatRechnungenNurViaVetdata"
                            ></v-select>
                        </v-col>
                    </v-row>
                </template>
            </SingleFilter>
            <SingleFilter :title="$t('taketeilnehmer.table.header.hatTvsDokumenteNurDigital')">
                <template>
                    <v-row>
                        <v-col sm="12">
                            <v-select
                                filled
                                dense
                                hide-details
                                :items="hatTvsDokumenteNurDigital"
                                v-model="filterData.hatTvsDokumenteNurDigital"
                            ></v-select>
                        </v-col>
                    </v-row>
                </template>
            </SingleFilter>
        </FilterDrawer>
    </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Debounce } from 'vue-debounce-decorator';
import { TakeTeilnehmerApiDto, TeilnehmerListApiDto } from '@/api-schema';

import { NAME as NAME_TAKETEILNEHMER } from '../store';
import { NAME as NAME_SECURITY } from '@/store/modules/security';
import { NAME as NAME_HEADERNOTIFICATION } from '@/store/modules/headerNotification';
import { NAME as NAME_GENERAL } from '@/store/modules/general';

const takeTeilnehmerModul = namespace(NAME_TAKETEILNEHMER);
const securityModul = namespace(NAME_SECURITY);
const headerModul = namespace(NAME_HEADERNOTIFICATION);
const generalModul = namespace(NAME_GENERAL);

import { SUBTITLE, FOOTERTITLE } from '@/store/modules/general/mutations';
import { TAKESPECIFIC, RESET, GET_TEILNEHMER, GET_NEXT_TEILNEHMER, DOWNLOAD_TEILNEHMER } from '../store/actions';
import { GET_TEILNEHMER_DATA, GET_TEILNEHMER_LOADING, GET_TEILNEHMER_TOTAL_NUMBER_OF_RECORDS } from '../store/getters';
import { HASACCESS } from '@/store/modules/security/getters';
import { SETACCESSDENIED } from '@/store/modules/security/mutations';
import { NEW_ALERT } from '@/store/modules/headerNotification/actions';

import { TeilnehmerFilterDto } from '@/types/TeilnehmerFilterDto';
import { TakeTeilnehmerDto } from '@/types/UserDtos';

import TableHeader from '@/components/TableHeader.vue';
import SingleFilter from '@/components/SingleFilter.vue';
import FilterDrawer from '@/components/FilterDrawer.vue';
import TextHighlights from '@/components/TextHighlight/TextHighlights.vue';
import { APPLY_TEILNEHMERFILTER, CLEAR_TEILNEHMERFILTER } from '../store/mutations';
import { TableHeaderFilterItem } from '@/types/TableHeaderFilterItem';
@Component({
    components: {
        TableHeader,
        SingleFilter,
        FilterDrawer,
        TextHighlights
    }
})
export default class TakeTeilnehmer extends Vue {
    @generalModul.Mutation(SUBTITLE) setSubTitle!: Function;
    @generalModul.Mutation(FOOTERTITLE) setFooterTitle!: Function;

    @takeTeilnehmerModul.Action(TAKESPECIFIC) takeSpecific!: Function;
    @takeTeilnehmerModul.Action(RESET) reset!: Function;
    @takeTeilnehmerModul.Action(GET_TEILNEHMER) getTeilnehmer!: Function;
    @takeTeilnehmerModul.Action(GET_NEXT_TEILNEHMER) getNextTeilnehmer!: Function;
    @takeTeilnehmerModul.Action(DOWNLOAD_TEILNEHMER) downloadTeilnehmer!: Function;
    @takeTeilnehmerModul.Getter(GET_TEILNEHMER_DATA) teilnehmerList!: Array<TeilnehmerListApiDto>;
    @takeTeilnehmerModul.Getter(GET_TEILNEHMER_LOADING) loading!: boolean;
    @takeTeilnehmerModul.Getter(GET_TEILNEHMER_TOTAL_NUMBER_OF_RECORDS) totalNumberOfRecords!: number;
    @takeTeilnehmerModul.Mutation(CLEAR_TEILNEHMERFILTER) clearFilter!: Function;
    @takeTeilnehmerModul.Mutation(APPLY_TEILNEHMERFILTER) applyFilters!: Function;

    @securityModul.Getter(HASACCESS) hasAccess!: Function;
    @securityModul.Mutation(SETACCESSDENIED) setAccessDenied!: Function;

    @headerModul.Action(NEW_ALERT) alertMessage!: Function;

    filters: Array<TableHeaderFilterItem> = [];
    showSearchBar = true;
    showFilterDrawer = false;

    options: any = { sortDesc: [true], sortBy: ['kundenNummer'] };

    filterData: TeilnehmerFilterDto = new TeilnehmerFilterDto();
    filterDataCopy: TeilnehmerFilterDto = new TeilnehmerFilterDto();

    status = [
        { text: this.$t('taketeilnehmer.status.aktiv'), value: 'taketeilnehmer.status.aktiv' },
        { text: this.$t('taketeilnehmer.status.inaktiv'), value: 'taketeilnehmer.status.inaktiv' },
        { text: this.$t('taketeilnehmer.status.pendent'), value: 'taketeilnehmer.status.pendent' },
        { text: this.$t('taketeilnehmer.status.gesperrt'), value: 'taketeilnehmer.status.gesperrt' },
        { text: this.$t('taketeilnehmer.status.geloescht'), value: 'taketeilnehmer.status.geloescht' }
    ];

    hatRechnungenNurViaVetdata = [
        { text: this.$t('filter.ja'), value: true },
        { text: this.$t('filter.nein'), value: false }
    ];

    hatTvsDokumenteNurDigital = [
        { text: this.$t('filter.ja'), value: true },
        { text: this.$t('filter.nein'), value: false }
    ];

    created() {
        this.setSubTitle(this.$t('taketeilnehmer.subtitle'));
        this.setFooterTitle('S900');
        if (!this.hasAccess('Navigation.TakeTeilnehmer')) {
            this.setAccessDenied(true);
            this.alertMessage({ text: this.$t('errormessage.noaccesspage') });
        } else {
            this.setAccessDenied(false);
        }

        this.filterData.status = 'taketeilnehmer.status.aktiv';
        this.applyFilters(this.filterData);
        this.getTeilnehmer();
        this.showFilterDrawerChanged(true);
    }

    get headers(): Array<any> {
        return [
            {
                text: this.$t('TVS-Nr'),
                value: 'kundenNummer'
            },
            {
                text: this.$t('taketeilnehmer.table.firma'),
                value: 'firma'
            },
            {
                text: this.$t('taketeilnehmer.table.plz'),
                value: 'plz'
            },
            {
                text: this.$t('taketeilnehmer.table.header.status'),
                value: 'status'
            },
            {
                text: '',
                value: 'burgermenu',
                align: 'center',
                width: '10',
                sortable: false
            }
        ];
    }

    @Debounce(400)
    private searchFieldInputChanged() {
        this.applyFilters(this.filterData);
        this.getTeilnehmer();
    }

    private searchBarClosed() {
        this.showSearchBar = false;
        this.filterData.suchText = '';
        this.applyFilters(this.filterData);
        this.getTeilnehmer();
    }

    private cancelFilter() {
        this.filterData = Object.assign({}, this.filterDataCopy);
        this.showFilterDrawer = false;
        this.applyFilters(this.filterData);
        this.getTeilnehmer();
    }

    private resetFilter() {
        this.filterData = new TeilnehmerFilterDto();
        this.filterData.status = 'taketeilnehmer.status.aktiv';
        this.applyFilters(this.filterData);
    }

    private confirmFilter() {
        this.showFilterDrawer = false;
        this.applyFilters(this.filterData);
        this.getTeilnehmer();
    }

    private load() {
        this.applyFilters(this.filterData);
        this.getNextTeilnehmer();
    }

    private removeFilter(value: string) {
        this.filters = this.filters.filter(function (obj) {
            return obj.key !== value;
        });

        if (value === 'taketeilnehmer.table.header.status') {
            this.filterData.status = '';
        }
        if (value === 'taketeilnehmer.table.header.hatRechnungenNurViaVetdata') {
            this.filterData.hatRechnungenNurViaVetdata = undefined;
        }
        if (value === 'taketeilnehmer.table.header.hatTvsDokumenteNurDigital') {
            this.filterData.hatTvsDokumenteNurDigital = undefined;
        }

        this.applyFilters(this.filterData);
        this.getTeilnehmer();
    }
    private initTakeSpecific(kundenNummer: number) {
        const teilnehmer: TakeTeilnehmerApiDto = new TakeTeilnehmerDto('UserMain', kundenNummer);
        this.takeSpecific(teilnehmer);
    }

    private customSort(items: any[]) {
        return items;
    }

    @Watch('options')
    onOptionsChanged() {
        this.filterData.sortBy = this.options.sortBy[0];
        this.filterData.sortOrder = this.options.sortDesc[0] ? 'Descending' : 'Ascending';
        this.applyFilters(this.filterData);
        this.getTeilnehmer();
    }

    @Watch('showFilterDrawer')
    showFilterDrawerChanged(newVal) {
        if (newVal) {
            this.filterDataCopy = Object.assign({}, this.filterData);
        }

        this.filters = [];
        if (this.filterData.status !== '') {
            this.filters = this.filters.concat({
                key: 'taketeilnehmer.table.header.status',
                nameResourceKey: 'taketeilnehmer.table.header.status',
                value: this.$t(this.filterData.status).toString()
            });
        }
        if (this.filterData.hatRechnungenNurViaVetdata !== undefined) {
            this.filters = this.filters.concat({
                key: 'taketeilnehmer.table.header.hatRechnungenNurViaVetdata',
                nameResourceKey: 'taketeilnehmer.table.header.hatRechnungenNurViaVetdata',
                value: this.$t(this.filterData.hatRechnungenNurViaVetdata ? 'filter.ja' : 'filter.nein').toString()
            });
        }
        if (this.filterData.hatTvsDokumenteNurDigital !== undefined) {
            this.filters = this.filters.concat({
                key: 'taketeilnehmer.table.header.hatTvsDokumenteNurDigital',
                nameResourceKey: 'taketeilnehmer.table.header.hatTvsDokumenteNurDigital',
                value: this.$t(this.filterData.hatTvsDokumenteNurDigital ? 'filter.ja' : 'filter.nein').toString()
            });
        }
    }
}
</script>
