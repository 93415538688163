<template>
    <v-container fluid>
        <v-card class="pt-8 mb-4">
            <v-row class="pl-7">
                <v-col class="pt-2" sm="8">
                    <v-btn color="primary" @click="startVetpointImport">
                        {{ $t('vetpointdaten.import.button') }}
                    </v-btn>
                    <v-col class="pb-2 pl-1" v-if="vetdataLog.anzahlVerarbeiteteArtikel == 0" sm="4">-</v-col>
                    <v-col
                        class="pb-2 pl-1"
                        v-else-if="!vetdataLog.istErfolgreich && vetdataLog.anzahlVerarbeiteteArtikel != 0"
                        sm="4"
                        >{{
                            $t('vetpointdaten.import.letzterrun') +
                                ' ' +
                                vetdataLog.verarbeitetAm +
                                ', ' +
                                $t('vetpointdaten.import.status.laufend')
                        }}</v-col
                    >
                    <v-col class="pb-2 pl-1" v-else sm="9">{{
                        $t('vetpointdaten.import.letzterrun') +
                            ' ' +
                            vetdataLog.verarbeitetAm +
                            ', ' +
                            $t('vetpointdaten.import.status.abgeschlossen') +
                            ', ' +
                            vetdataLog.fehlerMeldung
                    }}</v-col>
                </v-col>
            </v-row>
        </v-card>
        <v-row>
            <v-col>
                <!--table hauptkonfigurationen -->
                <v-data-table
                    :headers="headers"
                    :items="artikelList"
                    :loading="loading"
                    item-key="produktnummer"
                    :options.sync="options"
                    :server-items-length="totalNumberOfRecords"
                    hide-default-footer
                    class="elevation-1 nowhow-table"
                    sort-by="produktnummer"
                    :must-sort="true"
                    :custom-sort="customSort"
                >
                    <!-- Header der Table(Filter anzeige, gefundene Elemente) -->
                    <template v-slot:top>
                        <TableHeader :filters="filters">
                            <template>
                                <v-text-field
                                    class="ml-5"
                                    v-if="showSearchBar"
                                    v-model="filterData.searchQuery"
                                    @input="searchFieldInputChanged"
                                    prepend-inner-icon="mdi-magnify"
                                    append-icon="mdi-close"
                                    @click:append="searchBarClosed"
                                    :label="$t('vetpointdaten.suchfeld.title')"
                                    hide-details
                                    autofocus
                                ></v-text-field>
                                <v-spacer />
                                <v-tooltip top color="secondary">
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" icon color="primary">
                                            <v-icon @click="showSearchBar = !showSearchBar" v-if="!showSearchBar"
                                                >mdi-magnify</v-icon
                                            >
                                        </v-btn>
                                    </template>
                                    <div>
                                        <span>
                                            {{ $t('tableheader.tooltip.search') }}
                                        </span>
                                    </div>
                                </v-tooltip>
                            </template>
                        </TableHeader>
                    </template>
                    <!-- Selbst definierte Tablespalten -->
                    <template v-slot:item.produktnummer="{ item }">
                        <TextHighlights :text="item.produktnummer" :search-text="filterData.searchQuery" />
                    </template>
                    <template v-slot:item.produktname="{ item }">
                        <TextHighlights :text="item.produktname" :search-text="filterData.searchQuery" />
                    </template>
                    <template v-slot:item.swissmedicnummer="{ item }">
                        <TextHighlights :text="item.swissmedicnummer" :search-text="filterData.searchQuery" />
                    </template>
                    <template v-slot:item.atcvetcode="{ item }">
                        <TextHighlights :text="item.atcvetcode" :search-text="filterData.searchQuery" />
                    </template>
                </v-data-table>
                <scroll-loader class="mb-5" :loader-method="load">
                    <v-progress-circular indeterminate v-show="loading" />
                </scroll-loader>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Debounce } from 'vue-debounce-decorator';

import { ArtikelFilterDto } from '@/types/ArtikelFilterDto';
import { ArtikelApiDto, VetdataVerarbeitungLogApiDto } from '@/api-schema';

import { NAME as NAME_TAKETEILNEHMER } from '../store';
const takeTeilnehmerModul = namespace(NAME_TAKETEILNEHMER);

import { NAME as NAME_SECURITY } from '@/store/modules/security';
const securityModul = namespace(NAME_SECURITY);

import { NAME as NAME_GENERAL } from '@/store/modules/general';
const generalModul = namespace(NAME_GENERAL);

import { NAME as NAME_HEADERNOTIFICATION } from '@/store/modules/headerNotification';
const headerModul = namespace(NAME_HEADERNOTIFICATION);

import { SUBTITLE, FOOTERTITLE } from '@/store/modules/general/mutations';
import { HASACCESS } from '@/store/modules/security/getters';
import { SETACCESSDENIED } from '@/store/modules/security/mutations';
import { NEW_ALERT } from '@/store/modules/headerNotification/actions';
import { APPLY_ARTIKELFILTER, CLEAR_ARTIKELFILTER } from '../store/mutations';
import {
    GET_ARTIKEL_LOADING,
    GET_ARTIKEL_DATA,
    GET_ARTIKEL_TOTAL_NUMBER_OF_RECORDS,
    GETVETDATALOG
} from '../store/getters';
import { GET_ARTIKEL, GET_VETDATA_LOG, START_VETPOINT_IMPORT } from '../store/actions';
import TableHeader from '@/components/TableHeader.vue';
import TextHighlights from '@/components/TextHighlight/TextHighlights.vue';
@Component({
    components: {
        TableHeader,
        TextHighlights
    }
})
export default class VetpointDaten extends Vue {
    @takeTeilnehmerModul.Action(GET_ARTIKEL) getFilteredArtikel!: Function;
    @takeTeilnehmerModul.Action(GET_VETDATA_LOG) getVetdataImportLog!: Function;
    @takeTeilnehmerModul.Action(START_VETPOINT_IMPORT) startVetpointImport!: Function;

    @takeTeilnehmerModul.Getter(GET_ARTIKEL_LOADING) loading!: boolean;
    @takeTeilnehmerModul.Getter(GET_ARTIKEL_DATA) artikelList!: Array<ArtikelApiDto>;
    @takeTeilnehmerModul.Getter(GET_ARTIKEL_TOTAL_NUMBER_OF_RECORDS) totalNumberOfRecords!: number;
    @takeTeilnehmerModul.Getter(GETVETDATALOG) vetdataLog!: VetdataVerarbeitungLogApiDto;

    @takeTeilnehmerModul.Mutation(CLEAR_ARTIKELFILTER) clearFilter!: Function;
    @takeTeilnehmerModul.Mutation(APPLY_ARTIKELFILTER) applyFilters!: Function;

    @generalModul.Mutation(SUBTITLE) setSubTitle!: Function;
    @generalModul.Mutation(FOOTERTITLE) setFooterTitle!: Function;

    @securityModul.Getter(HASACCESS) hasAccess!: Function;
    @securityModul.Mutation(SETACCESSDENIED) setAccessDenied!: Function;

    @headerModul.Action(NEW_ALERT) alertMessage!: Function;

    get headers(): Array<any> {
        return [
            {
                text: this.$t('vetpointdaten.table.produktnummer'),
                value: 'produktnummer'
            },
            {
                text: this.$t('vetpointdaten.table.produktname'),
                value: 'produktname'
            },
            {
                text: this.$t('vetpointdaten.table.swissmedicnummer'),
                value: 'swissmedicnummer'
            },
            {
                text: this.$t('vetpointdaten.table.atcvetcode'),
                value: 'atcvetcode'
            },
            {
                text: this.$t('vetpointdaten.table.verfuegbarkeit'),
                value: 'verfuegbarkeit'
            }
        ];
    }
    options: any = { sortDesc: [true], sortBy: ['produktnummer'] };
    filterData: ArtikelFilterDto = new ArtikelFilterDto();
    showSearchBar = false;

    mounted() {
        if (!this.hasAccess('Navigation.VetpointDaten')) {
            this.setAccessDenied(true);
            this.alertMessage({ text: this.$t('errormessage.noaccesspage') });
        } else {
            this.setAccessDenied(false);
            this.applyFilters(this.filterData);
            this.getVetdataImportLog();
            this.getFilteredArtikel(0);
        }
    }
    created() {
        this.setSubTitle(this.$t('vetpointdaten.title'));
        this.setFooterTitle('S920');
    }

    private customSort(items: any[]) {
        return items;
    }

    private searchBarClosed() {
        this.showSearchBar = false;
        this.filterData.searchQuery = '';
        this.applyFilters(this.filterData);
        this.getFilteredArtikel(0);
    }

    @Debounce(400)
    private searchFieldInputChanged() {
        this.applyFilters(this.filterData);
        this.getFilteredArtikel(0);
    }

    private load() {
        this.getFilteredArtikel(this.artikelList.length);
    }

    @Watch('options')
    onOptionsChanged() {
        this.filterData.sortBy = this.options.sortBy[0];
        this.filterData.sortOrder = this.options.sortDesc[0] ? 'Descending' : 'Ascending';
        this.applyFilters(this.filterData);
        this.getFilteredArtikel(0);
    }
}
</script>
