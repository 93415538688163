import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IValidationState } from './state';
import { NAME } from './';

import { SET_ALLOWED_FILETYPES, SET_RULES } from './mutations';
import { validationService } from '@/services/validationService';

export const LOAD_VALIDATION = 'loadValidations';
export const LOAD_ALLOWED_FILETYPES = 'loadAllowedFileTypes';

export const actions: ActionTree<IValidationState, IRootState> = {
    async [LOAD_VALIDATION]({ commit, state }: any) {
        console.debug(NAME + '::' + LOAD_VALIDATION);
        return validationService.getAll().then(response => {
            commit(SET_RULES, response);
        });
    },
    async [LOAD_ALLOWED_FILETYPES]({ commit, state }: any) {
        console.debug(NAME + '::' + LOAD_ALLOWED_FILETYPES);
        return validationService.getAllowedFileTypes().then(response => {
            commit(SET_ALLOWED_FILETYPES, response);
        });
    }
};
