import * as Msal from '@azure/msal-browser';
import { AuthConfiguration } from '@/config/authConfig';
import BaseService from './baseService';
import AsyncLock from 'async-lock';

interface IAuthService {
    loginSms(): Promise<boolean>;
    loginTotp(): Promise<boolean>;

    getAuthorizationHeader(): Promise<string>;

    logoutPopup(): Promise<void>;

    logoutRedirect(): void;

    isLoggedIn(): Promise<boolean>;

    init(): Promise<void>;
}

class AzureAuthService extends BaseService implements IAuthService {
    private app!: Msal.PublicClientApplication;
    private idToken: string;
    private authConfiguration!: AuthConfiguration;
    private RESOURCE_URL: string;
    private lockKey = 'AzureAuthServiceLock';
    private lock = new AsyncLock();

    constructor() {
        super();
        this.RESOURCE_URL = `${this.BASE_URL}startup`;
        console.debug('AzureAuthService::constructor', this.RESOURCE_URL);
    }

    async init(): Promise<void> {
        await super.GET(this.RESOURCE_URL).then(async (response: any) => {
            console.log('Auth, init done, setup msal');
            this.authConfiguration = new AuthConfiguration(
                response.ClientId,
                response.Name,
                response.SignInPolicy,
                response.PasswordResetPolicy,
                response.PostLogoutRedirectUri
            );
            this.app = new Msal.PublicClientApplication(this.authConfiguration.msalConfig);

            //Callback-Funktion die nach dem Login via Azure B2C aufgerufen wird. Login wird via Redirect ausgeführt
            await this.app.handleRedirectPromise().then(this.authCallback);
            // await this.app.handleRedirectPromise().then(() => this.authCallback);
            console.log('Auth, setup msal done');
        });
    }

    async authCallback(authResult: Msal.AuthenticationResult) {
        //handle redirect response
        console.debug('authCallback');
        console.debug('authCallback authResult', authResult);
        // console.debug('authCallback this.app', this.app);
        if (this?.app != null) {

            if (authResult !== null) {
                this.app.setActiveAccount(authResult.account);
            } else {
                // need to call getAccount here?
                const currentAccounts = this.app.getAllAccounts();
                if (!currentAccounts || currentAccounts.length < 1) {
                    return;
                } else if (currentAccounts.length > 1) {
                    // Add choose account code here
                } else if (currentAccounts.length === 1) {
                    const activeAccount = currentAccounts[0];
                    this.app.setActiveAccount(activeAccount);
                }
            }
        }
    }


    async loginSms(): Promise<boolean> {
        this.app.loginRedirect(this.authConfiguration.getloginRequest(this.getAccount(), "_SMS_Only"));
        return false;
    }

    async loginTotp(): Promise<boolean> {
        this.app.loginRedirect(this.authConfiguration.getloginRequest(this.getAccount(), "_TOTP"));
        return false;
    }

    async getAuthorizationHeader(): Promise<string> {
        console.log('get token');
        console.log('get token currentAccount', this.getAccount());
        let token = '';

        if (await this.isLoggedIn()) {
            await this.lock.acquire(this.lockKey, async () => {
                await this.app.acquireTokenSilent(this.authConfiguration.gettokenRequest(this.getAccount())).then(
                    tokenResponse => {
                        console.log('acquireTokenSilent Response', tokenResponse);
                        console.log('access_token acquired at: ' + new Date().toString());
                        token = tokenResponse.accessToken;
                        this.idToken = tokenResponse.idToken;
                    },
                    async error => {
                        console.log('acquireTokenSilent error', error);
                        if (error instanceof Msal.InteractionRequiredAuthError) {
                            // wenn Token nicht geladen werden kann einfach mittels Redirect laden
                            if (error.errorMessage.includes('AADB2C90077')) {
                                await this.app.acquireTokenRedirect(this.authConfiguration.gettokenRequest(this.getAccount()));
                            }
                        } else {
                            await this.loginTotp();
                        }
                    }
                );
            });
        }
        return `Bearer ${token}`;
    }

    logoutPopup(): Promise<void> {
        return this.app.logoutPopup(this.authConfiguration.getLogoutRequest(this.getAccount(), this.idToken));
    }

    logoutRedirect(): void {
        this.app.logoutRedirect(this.authConfiguration.getLogoutRequest(this.getAccount(), this.idToken));
    }

    getAccount(): Msal.AccountInfo {
        const currentAccounts = this.app.getAllAccounts();
        if (!currentAccounts || currentAccounts.length === 0) {
            // No user signed in
            return;
        } else if (currentAccounts.length > 1) {
            // More than one user signed in, find desired user with getAccountByUsername(username)
        } else {
            return currentAccounts[0];
        }
    }

    async isLoggedIn(): Promise<boolean> {
        const hasAccount = this.getAccount() != null;
        if (hasAccount) {
            return this.app.acquireTokenSilent(this.authConfiguration.gettokenRequest(this.getAccount())).then(
                () => {
                    console.log('Auth, isLoggedIn done:', true);
                    return true;
                },
                () => {
                    console.log('Auth, isLoggedIn done:', false);
                    return false;
                }
            );
        }

        return Promise.resolve(false);
    }
}

class BasicAuthService implements IAuthService {
    private loggedIn = false;

    async login(): Promise<boolean> {
        this.loggedIn = true;
        return true;
    }
    async loginSms(): Promise<boolean> {
        return this.login();
    }
    async loginTotp(): Promise<boolean> {
        return this.login();
    }

    async getAuthorizationHeader(): Promise<string> {
        //Tokenvalue: UserMain:1 (rolle:teilnehmerNummer)
        // const token = 'VXNlck1haW46MQ==';
        //Tokenvalue: SysAdmin:0 (rolle:teilnehmerNummer)
        const token = 'U3lzQWRtaW46MA==';
        return `Basic ${token}`;
    }

    logoutPopup(): Promise<void> {
        this.loggedIn = false;
        return Promise.resolve();
    }

    logoutRedirect(): void {
        this.loggedIn = false;
        window.location.reload();
    }

    isLoggedIn(): Promise<boolean> {
        return Promise.resolve(this.loggedIn);
    }

    init(): Promise<void> {
        return Promise.resolve();
    }
}

class AuthServiceCreator {
    private static authServiceInstance: IAuthService;

    static async getAuthService(): Promise<IAuthService> {
        if (this.authServiceInstance != null) {
            return this.authServiceInstance;
        }

        if (config.VUE_APP_AUTHENTICATION === 'basic') {
            console.log('use BasicAuthService for Authentication');
            this.authServiceInstance = new BasicAuthService();
        } else if (config.VUE_APP_AUTHENTICATION === 'azure') {
            console.log('use AzureAuthService for Authentication');
            this.authServiceInstance = new AzureAuthService();
        } else {
            throw Error('No authorizationmode configured');
        }

        await this.authServiceInstance.init();
        return this.authServiceInstance;
    }
}


export { AuthServiceCreator, IAuthService };
