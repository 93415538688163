import BaseService from '@/services/baseService';
import { AxiosResponse } from 'axios';
import { ITakeTeilnehmerService } from './ITakeTeilnehmerService';
import { ArtikelApiDtoSearchResponseApi, TakeTeilnehmerApiDto, TeilnehmerListApiDtoSearchResponseApi, VetdataVerarbeitungLogApiDto } from '@/api-schema';
import { ArtikelFilterDto } from '@/types/ArtikelFilterDto';
import { TeilnehmerFilterDto } from '@/types/TeilnehmerFilterDto';

export class TakeTeilnehmerService extends BaseService implements ITakeTeilnehmerService {
    private RESOURCE_URL: string;
    private ARTIKEL_URL: string;

    constructor() {
        super();
        this.RESOURCE_URL = `${this.BASE_URL}taketeilnehmer`;
        this.ARTIKEL_URL = `${this.BASE_URL}artikel`;

        console.debug('TakeTeilnehmerService::constructor', this.RESOURCE_URL);
    }

    async takeSpecific(teilnehmer: TakeTeilnehmerApiDto): Promise<boolean | AxiosResponse<any>> {
        // set property to null if not a number
        if (Number(teilnehmer.teilnehmerId) <= 0) {
            teilnehmer.teilnehmerId = null;
        }
        if (Number(teilnehmer.tvsKundennummer) <= 0) {
            teilnehmer.tvsKundennummer = null;
        }

        return super.PUT<TakeTeilnehmerApiDto>(`${this.RESOURCE_URL}`, teilnehmer).then(response => {
            return response;
        });
    }
    async reset(): Promise<boolean | AxiosResponse<any>> {
        return super.PUT(`${this.RESOURCE_URL}/reset`, null).then(response => {
            return response;
        });
    }
    async getTeilnehmer(filter: TeilnehmerFilterDto): Promise<TeilnehmerListApiDtoSearchResponseApi> {
        return super.GET<TeilnehmerListApiDtoSearchResponseApi>(`${this.RESOURCE_URL}${super.serializeObjectToQuerystring(filter)}`).then(response => {
            return response;
        });
    }

    async downloadTeilnehmer(filter: TeilnehmerFilterDto): Promise<AxiosResponse<File>> {
        return super.DOWNLOAD<TeilnehmerListApiDtoSearchResponseApi>(`${this.RESOURCE_URL}/export${super.serializeObjectToQuerystring(filter)}`).then(response => {
            return response;
        });
    }

    async getArtikel(filter: ArtikelFilterDto): Promise<ArtikelApiDtoSearchResponseApi> {
        return super
            .GET<ArtikelApiDtoSearchResponseApi>(`${this.ARTIKEL_URL}/` + super.serializeObjectToQuerystring(filter))
            .then(response => {
                return response;
            });
    }

    async getVetdataLog(): Promise<VetdataVerarbeitungLogApiDto> {
        return super.GET<VetdataVerarbeitungLogApiDto>(`${this.ARTIKEL_URL}/log`).then(response => {
            return response;
        });
    }

    async startVetpointImport(): Promise<any> {
        return super.PUT(`${this.ARTIKEL_URL}`, null).then(response => {
            return response;
        });
    }
}

export const takeTeilnehmerService = new TakeTeilnehmerService();
