<template>
    <v-col>
        <v-col sm="12">
            <h3 v-text="$t('einstellungen.basis.title')" />
        </v-col>

        <v-col sm="12">
            <v-row class="pb-5">
                <v-col sm="2">
                    <v-row>
                        <v-col sm="12" class="py-0">
                            {{ $t('einstellungen.basis.teilnehmer') }}
                        </v-col>
                    </v-row>
                </v-col>
                <v-col sm="3">
                    <v-row>
                        <v-col sm="12" class="py-0">
                            {{ this.kundenDaten.firma }}
                        </v-col>
                        <v-col sm="12" class="py-0">
                            {{ this.kundenDaten.strasse }}
                        </v-col>
                        <v-col sm="12" class="py-0">
                            {{ this.kundenDaten.plz + ' ' + this.kundenDaten.ort }}
                        </v-col>
                    </v-row>
                </v-col>
                <v-col sm="6">
                    <v-row>
                        <v-col sm="3" class="py-0"> {{ $t('einstellungen.basis.teilnehmernummer') }} </v-col>
                        <v-col v-if="kundenDaten.teilnehmerStatus" sm="9" class="py-0">
                            {{
                                this.kundenDaten.kundenNummer +
                                '  (' +
                                $t('einstellungen.basis.active') +
                                ' ' +
                                this.kundenDaten.ersteAktivierungAm +
                                ')'
                            }}
                        </v-col>
                        <v-col v-else sm="9" class="py-0">
                            {{ this.kundenDaten.kundenNummer + '  (' + $t('einstellungen.basis.inaktiv') + ')' }}
                        </v-col>
                        <v-col sm="3" class="py-0"> {{ $t('einstellungen.basis.telefon') }} </v-col>
                        <v-col sm="9" class="py-0"> {{ this.kundenDaten.telefon }} </v-col>
                        <v-col sm="3" class="py-0"> {{ $t('einstellungen.basis.email') }} </v-col>
                        <v-col sm="9" class="py-0"> {{ this.kundenDaten.email }} </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row :no-gutters="true">
                <v-col cols="2">
                    <EditAdresseDialog :edit-teilnehmer="this.kundenDaten" @success="$emit('success')" />
                </v-col>
                <v-col>
                    <p v-if="kundenDaten.istMutiertInVetdata" class="font-italic font-weight-thin mb-0">
                        <v-icon color="primary" size="small"> mdi-information </v-icon>
                        {{ $t('einstellungen.basis.modifiziert') }}
                    </p>
                </v-col>
            </v-row>
        </v-col>
    </v-col>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { TeilnehmerApiDto } from '@/api-schema';

import EditAdresseDialog from './EditAdresseDialog.vue';
@Component({
    components: {
        EditAdresseDialog
    }
})
export default class BasisEinstellungen extends Vue {
    @Prop({ required: true, type: Object })
    private kundenDaten!: TeilnehmerApiDto;
}
</script>
