<template>
    <v-container fluid>
        <h1>Einnehmen eines anderen Teilnehmerkontexts</h1>
        <span
            >Es kann entweder die TeilnehmerId oder die TvsKundennummer angegeben werden. Wird die TvsKundennummer
            angegeben, so wird der erste Teilnehmer mit dieser Nummer genommen.</span
        >
        <ValidationObserver v-if="hasAccess('TakeTeilnehmer.TakeSpecific')" validate v-slot="{ invalid }">
            <v-form @submit.prevent="takeSpecific(teilnehmer)">
                <v-row>
                    <v-col class="col-sm-12 col" sm="4">
                        <v-card>
                            <v-col class="py-0" sm="4">
                                <ValidationProvider name="TeilnehmerId" rules="numeric" v-slot="{ errors }">
                                    <v-text-field
                                        label="TeilnehmerId"
                                        placeholder="TeilnehmerId"
                                        v-model.number="teilnehmer.teilnehmerId"
                                        :error-messages="errors"
                                        :disabled="teilnehmer.tvsKundennummer > 0"
                                    ></v-text-field>
                                </ValidationProvider>
                                <ValidationProvider
                                    name="TvsKundennummer"
                                    rules="between:1000,9999|numeric"
                                    v-slot="{ errors }"
                                >
                                    <v-text-field
                                        label="TvsKundennummer"
                                        placeholder="TvsKundennummer"
                                        v-model.number="teilnehmer.tvsKundennummer"
                                        :error-messages="errors"
                                        :disabled="teilnehmer.teilnehmerId > 0"
                                    ></v-text-field>
                                </ValidationProvider>

                                <ValidationProvider name="Rolle" v-slot="{ errors }">
                                    <v-select
                                        :items="rolleItems"
                                        label="Rolle"
                                        v-model="teilnehmer.benutzerRolle"
                                        :error-messages="errors"
                                    ></v-select>
                                </ValidationProvider>
                            </v-col>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row class="pl-3">
                    <v-col sm="2 pt-0 pb-5">
                        <v-btn type="submit" :disabled="invalid" large color="primary">
                            Set User
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </ValidationObserver>
        <h3>Reset</h3>
        <v-row class="pl-3">
            <v-col sm="2 pb-5">
                <v-btn @click="reset" type="secondary" large color="secondary">Reset</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TakeTeilnehmerApiDto } from '@/api-schema';

import { NAME as NAME_TAKETEILNEHMER } from '../store';
import { NAME as NAME_SECURITY } from '@/store/modules/security';
import { NAME as NAME_HEADERNOTIFICATION } from '@/store/modules/headerNotification';
import { NAME as NAME_GENERAL } from '@/store/modules/general';

const takeTeilnehmerModul = namespace(NAME_TAKETEILNEHMER);
const securityModul = namespace(NAME_SECURITY);
const headerModul = namespace(NAME_HEADERNOTIFICATION);
const generalModul = namespace(NAME_GENERAL);

import { SUBTITLE, FOOTERTITLE } from '@/store/modules/general/mutations';
import { TAKESPECIFIC, RESET } from '../store/actions';
import { HASACCESS } from '@/store/modules/security/getters';
import { SETACCESSDENIED } from '@/store/modules/security/mutations';
import { NEW_ALERT } from '@/store/modules/headerNotification/actions';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

@Component({
    components: {
        ValidationProvider,
        ValidationObserver
    }
})
export default class TakeTeilnehmer extends Vue {
    teilnehmer: TakeTeilnehmerApiDto;
    rolleItems: Array<string>;

    @generalModul.Mutation(SUBTITLE) setSubTitle!: Function;
    @generalModul.Mutation(FOOTERTITLE) setFooterTitle!: Function;

    @takeTeilnehmerModul.Action(TAKESPECIFIC) takeSpecific!: Function;
    @takeTeilnehmerModul.Action(RESET) reset!: Function;

    @securityModul.Getter(HASACCESS) hasAccess!: Function;
    @securityModul.Mutation(SETACCESSDENIED) setAccessDenied!: Function;

    @headerModul.Action(NEW_ALERT) alertMessage!: Function;

    created() {
        this.setSubTitle('Teilnehmer-Wechsel');
        this.setFooterTitle('S900');
        if (!this.hasAccess('Navigation.TakeTeilnehmer')) {
            this.setAccessDenied(true);
            this.alertMessage({ text: 'Sie haben keinen Zugriff auf diese Site!' });
        } else {
            this.setAccessDenied(false);
        }
        this.teilnehmer = {
            teilnehmerId: null,
            tvsKundennummer: null,
            benutzerRolle: ''
        };
        this.teilnehmer.benutzerRolle = 'UserMain';

        this.rolleItems = [
            'UserMain',
            'UserStaff',
            'UserView',
            'UserUpload',
            'UserBuha',
            'SaTapSw',
            'SaBhSw',
            'SysAdmin'
        ];
    }
}
</script>

<style></style>
