<template>
    <ValidationObserver ref="observer" v-slot="{ invalid }">
        <v-dialog v-model="visible" width="500">
            <template v-slot:activator="{}">
                <v-btn class="px-1" color="primary" @click="open">
                    {{ $t('einstellungen.benutzerliste.button') }}
                </v-btn>
            </template>
            <FocusLock>
                <form @submit.prevent="submit">
                    <v-card>
                        <v-card-title class="headline font-weight-bold mb-2">
                            {{ $t('einstellungen.addaccount.title') }}
                        </v-card-title>

                        <modal-header-notification></modal-header-notification>

                        <v-card-text class="pb-0 pt-0">
                            <ValidationProvider
                                name="Email"
                                :rules="getValidation('benutzerCreateApiDto.email')"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    autofocus
                                    :label="$t('einstellungen.addaccount.email') + '*'"
                                    placeholder="example@test.com"
                                    v-model="benutzer.email"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider
                                name="Passwort"
                                :rules="getValidation('benutzerCreateApiDto.passwort') + '|valid_password_format'"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    :label="$t('einstellungen.addaccount.password') + '*'"
                                    type="password"
                                    v-model="benutzer.passwort"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider
                                name="Vorname"
                                :rules="getValidation('benutzerCreateApiDto.vorname')"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    :label="$t('einstellungen.addaccount.vorname') + '*'"
                                    placeholder="..."
                                    v-model="benutzer.vorname"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                            <ValidationProvider
                                name="Nachname"
                                :rules="getValidation('benutzerCreateApiDto.nachname')"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    :label="$t('einstellungen.addaccount.nachname') + '*'"
                                    placeholder="..."
                                    v-model="benutzer.nachname"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                            <v-select
                                v-model="benutzer.rolle"
                                :items="benutzerRollen"
                                :label="$t('einstellungen.addaccount.rolle')"
                            >
                                <template v-slot:selection="{ item }">
                                    <span>
                                        {{ item.text }}
                                    </span>
                                </template>
                                <template v-slot:item="{ item, attrs, on }">
                                    <v-list-item v-on="on" v-bind="attrs">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-row no-gutters align="center">
                                                    <span>
                                                        <b>{{ item.text }}:</b>
                                                        {{ item.beschreibung }}
                                                    </span>
                                                </v-row>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>
                            <ValidationProvider
                                name="MobilNummer"
                                :rules="
                                    getValidation('benutzerCreateApiDto.mobilNummer') + '|valid_swiss_telephone_number'
                                "
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    :label="$t('einstellungen.addaccount.mobilnummer') + '*'"
                                    placeholder="+41 77 111 22 33"
                                    v-model="benutzer.mobilNummer"
                                    :error-messages="errors"
                                ></v-text-field>
                            </ValidationProvider>
                        </v-card-text>

                        <v-card-actions class="ml-4">
                            <v-btn
                                color="primary"
                                :disabled="invalid || requiredFieldsFilled"
                                type="submit"
                                class="modalbutton mb-3"
                            >
                                {{ $t('components.dialog.save') }}
                            </v-btn>
                            <v-btn @click="visible = false" class="modalbutton mb-3">
                                {{ $t('components.dialog.cancel') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </form>
            </FocusLock>
        </v-dialog>
    </ValidationObserver>
</template>

<script lang="ts">
import { BenutzerCreateApiDto } from '@/api-schema';
import Vue from 'vue';
import { Component, Prop, Ref } from 'vue-property-decorator';

import { namespace } from 'vuex-class';

import { NAME as NAME_EINSTELLUNGEN } from '../store';
const profilModul = namespace(NAME_EINSTELLUNGEN);

import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
const validationModul = namespace(NAME_VALIDATION);

import { NAME as NAME_HEADERNOTIFICATION } from '@/store/modules/headerNotification';
const headerNotificationModul = namespace(NAME_HEADERNOTIFICATION);

import { ADD_ACCOUNT } from '../store/actions';
import { GET_VALIDATION_KEY } from '@/store/modules/validation/getters';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import modalHeaderNotification from '@/components/ModalHeaderNotification.vue';
import { CLOSE_MODAL } from '@/store/modules/headerNotification/actions';
@Component({
    components: {
        ValidationProvider,
        ValidationObserver,
        modalHeaderNotification
    }
})
export default class AddAccountDialog extends Vue {
    private visible = false;
    private benutzer: BenutzerCreateApiDto = {
        email: '',
        mobilNummer: '',
        nachname: '',
        vorname: '',
        passwort: '',
        sprache: ''
    };
    get benutzerRollen(): Array<object> {
        return [
            {
                text: this.$t('benutzerrollen.usermain'),
                beschreibung: this.$t('benutzerrollen.usermain.helptext'),
                value: 'UserMain'
            },
            {
                text: this.$t('benutzerrollen.userstaff'),
                beschreibung: this.$t('benutzerrollen.userstaff.helptext'),
                value: 'UserStaff'
            },
            {
                text: this.$t('benutzerrollen.userview'),
                beschreibung: this.$t('benutzerrollen.userview.helptext'),
                value: 'UserView'
            },
            {
                text: this.$t('benutzerrollen.userupload'),
                beschreibung: this.$t('benutzerrollen.userupload.helptext'),
                value: 'UserUpload'
            },
            {
                text: this.$t('benutzerrollen.userbuha'),
                beschreibung: this.$t('benutzerrollen.userbuha.helptext'),
                value: 'UserBuha'
            }
        ];
    }
    @profilModul.Action(ADD_ACCOUNT) add!: Function;

    @validationModul.Getter(GET_VALIDATION_KEY) getValidation!: (key: string) => string;

    @headerNotificationModul.Action(CLOSE_MODAL) closeHeader!: Function;

    $refs!: {
        observer: any;
    };

    private onSave() {
        this.add(this.benutzer).then(response => {
            if (response) {
                this.visible = false;
            }
        });
    }

    private open() {
        this.closeHeader();
        this.$emit('open');
        this.benutzer = Object.assign(
            {},
            {
                email: '',
                passwort: '',
                nachname: '',
                vorname: '',
                rolle: '',
                mobilNummer: ''
            }
        );

        this.$refs.observer.reset();

        this.visible = true;
    }

    submit() {
        this.$refs.observer.validate();
        this.onSave();
    }

    get requiredFieldsFilled(): boolean {
        return (
            this.benutzer.email.length === 0 ||
            this.benutzer.passwort.length === 0 ||
            this.benutzer.nachname.length === 0 ||
            this.benutzer.vorname.length === 0 ||
            this.benutzer.mobilNummer.length === 0
        );
    }
}
</script>
