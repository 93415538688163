import { BenutzerInfoApiDto } from '@/api-schema';
import { INACTIVITYLOGOUTTIME, INACTIVITYWARNINGTIME, PERMISSION } from './mutations';

export interface ISecurityState {
    loggedIn: boolean;
    inactivityWarningTime: number;
    inactivitylogoutTime: number;
    //Zeit, nach der dem User eine Warnung angezeigt werden soll
    inactivityWarningTimeout: number,
    //Zeit, nach der dem User abgemeldet werden soll
    inactivityLogoutTimeout: number,
    benutzerInfo: BenutzerInfoApiDto;
    permissions: Array<string>;
    accessDenied: boolean;
    modalAgbVisible: boolean;
}

export const state: ISecurityState = {
    loggedIn: false,
    inactivityWarningTime: parseInt(localStorage.getItem(INACTIVITYWARNINGTIME), 10),
    inactivitylogoutTime: parseInt(localStorage.getItem(INACTIVITYLOGOUTTIME), 10),
    inactivityWarningTimeout: 28,
    inactivityLogoutTimeout: 30,
    benutzerInfo: new Object(),
    permissions: [],
    accessDenied: false,
    modalAgbVisible: false
};
