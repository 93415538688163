import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { IRechnungenState } from './state';

export const LIEFERANTENRECHNUNGEN = 'getLieferantenRechnungen';
export const LIEFERANTENRECHNUNGDETAILS = 'getLieferantenRechnungDetails';
export const LOADING = 'getLoading';
export const TOTAL_NUMBER_OF_RECORDS = 'getTotalNumberOfRecords';

export const getters: GetterTree<IRechnungenState, IRootState> = {
    [LIEFERANTENRECHNUNGEN]: state => state.rechnungList,
    [LIEFERANTENRECHNUNGDETAILS]: state => state.rechnungenDetails,
    [LOADING]: state => state.loading,
    [TOTAL_NUMBER_OF_RECORDS]: state => state.totalNumberOfRecords,
};
