<template>
    <v-container fluid>
        <v-row>
            <v-col sm="12">
                <v-card>
                    <basis-einstellungen :kunden-daten="this.kundenData" @success="getTeilnehmer" />
                </v-card>
            </v-col>
            <v-col v-if="kundenData.teilnehmerStatus" sm="12">
                <v-card>
                    <service-einstellungen :teilnehmer-service="this.serviceData" :verrechnung="this.verrechnungData" />
                    <speicher-diagramm
                        v-if="speicherData"
                        :belegter-speicher="this.speicherData"
                        :maximaler-speicher="calculateMaxSpeicher()"
                    />
                    <v-col class="pl-6 pt-0 pb-6" sm="12">
                        <EditServiceDialog :user-services="this.serviceData" :user-verrechnung="this.verrechnungData" />
                    </v-col>
                </v-card>
            </v-col>
            <v-col v-if="kundenData.teilnehmerStatus" sm="12">
                <v-card>
                    <FunktionEinstellungen :teilnehmer-funktion-api-dto="this.teilnehmerFunktionData" />
                </v-card>
            </v-col>
            <v-col sm="12">
                <v-card>
                    <benutzer-liste :benutzer-list="this.benutzerData" />
                </v-card>
            </v-col>
            <v-col sm="12">
                <v-card>
                    <teilnehmer-zugriff-liste :teilnehmer-zugriff-list="this.teilnehmerZugriffe" />
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { NAME as NAME_EINSTELLUNGEN } from '../store';
import { NAME as NAME_GENERAL } from '@/store/modules/general';
import { NAME as NAME_SECURITY } from '@/store/modules/security';
import { NAME as NAME_HEADERNOTIFICATION } from '@/store/modules/headerNotification';

const profilModul = namespace(NAME_EINSTELLUNGEN);
const generalModul = namespace(NAME_GENERAL);
const securityModul = namespace(NAME_SECURITY);
const headerModul = namespace(NAME_HEADERNOTIFICATION);

import { HASACCESS } from '@/store/modules/security/getters';
import { SETACCESSDENIED } from '@/store/modules/security/mutations';
import { NEW_ALERT } from '@/store/modules/headerNotification/actions';
import {
    GET_KUNDE_BY_ID,
    GET_SERVICE_BY_ID,
    GET_BENUTZER_BY_ID,
    GET_DIAGRAMM_VALUES,
    GET_EDIT_TEILNEHMERFUNKTION,
    GET_ALL_ZUGRIFFE
} from '../store/actions';
import {
    GETKUNDEDATA,
    GETSERVICEDATA,
    GETVERRECHNUNGDATA,
    GETBENUTZERDATA,
    GETBELEGTERSPEICHER,
    GETTEILNEHMERFUNKTION,
    GETTEILNEHMERZUGRIFFE
} from '../store/getters';
import { SUBTITLE, FOOTERTITLE } from '@/store/modules/general/mutations';
import {
    TeilnehmerApiDto,
    TeilnehmerServiceApiDto,
    ServiceVerrechnungApiDto,
    BelegterSpeicherApiDto,
    TeilnehmerFunktionApiDto,
    TeilnehmerZugriffApiDto
} from '@/api-schema';
import { TeilnehmerBenutzerDto } from '@/types/UserDtos';

import BasisEinstellungen from './BasisEinstellungen.vue';
import ServiceEinstellungen from './ServiceEinstellungen.vue';
import BenutzerListe from './BenutzerListe.vue';
import SpeicherDiagramm from './SpeicherDiagramm.vue';
import FunktionEinstellungen from './FunktionEinstellungen.vue';
import EditServiceDialog from './EditServiceDialog.vue';
import TeilnehmerZugriffListe from './TeilnehmerZugriffListe.vue';
@Component({
    components: {
        BasisEinstellungen,
        ServiceEinstellungen,
        BenutzerListe,
        SpeicherDiagramm,
        FunktionEinstellungen,
        EditServiceDialog,
        TeilnehmerZugriffListe
    }
})
export default class Profil extends Vue {
    @profilModul.Action(GET_KUNDE_BY_ID) getTeilnehmer!: Function;
    @profilModul.Action(GET_SERVICE_BY_ID) getService!: Function;
    @profilModul.Action(GET_BENUTZER_BY_ID) getBenutzer!: Function;
    @profilModul.Action(GET_DIAGRAMM_VALUES) getDiagramm!: Function;
    @profilModul.Action(GET_EDIT_TEILNEHMERFUNKTION) getEditTeilnehmerfunktion!: Function;
    @profilModul.Action(GET_ALL_ZUGRIFFE) getTeilnehmerZugriffe!: Function;

    @profilModul.Getter(GETKUNDEDATA) kundenData!: TeilnehmerApiDto;
    @profilModul.Getter(GETSERVICEDATA) serviceData!: TeilnehmerServiceApiDto;
    @profilModul.Getter(GETVERRECHNUNGDATA) verrechnungData!: ServiceVerrechnungApiDto;
    @profilModul.Getter(GETBENUTZERDATA) benutzerData!: Array<TeilnehmerBenutzerDto>;
    @profilModul.Getter(GETBELEGTERSPEICHER) speicherData!: BelegterSpeicherApiDto;
    @profilModul.Getter(GETTEILNEHMERFUNKTION) teilnehmerFunktionData!: TeilnehmerFunktionApiDto;
    @profilModul.Getter(GETTEILNEHMERZUGRIFFE) teilnehmerZugriffe!: Array<TeilnehmerZugriffApiDto>;

    @generalModul.Mutation(SUBTITLE) setSubTitle!: Function;
    @generalModul.Mutation(FOOTERTITLE) setFooterTitle!: Function;

    @securityModul.Getter(HASACCESS) hasAccess!: Function;
    @securityModul.Mutation(SETACCESSDENIED) setAccessDenied!: Function;

    @headerModul.Action(NEW_ALERT) alertMessage!: Function;

    mounted() {
        this.setSubTitle(this.$t('einstellungen.subtitle'));
        this.setFooterTitle('S400');
        if (!this.hasAccess('Navigation.Benutzereinstellungen')) {
            this.setAccessDenied(true);
            this.alertMessage({ text: this.$t('errormessage.noaccesspage') });
        } else {
            this.setAccessDenied(false);
            this.getTeilnehmer().then(response => {
                if (response.teilnehmerStatus) {
                    this.getService();
                    this.getDiagramm();
                    this.getEditTeilnehmerfunktion();
                }
            });
            this.getBenutzer();
            this.getTeilnehmerZugriffe();
        }
    }

    private calculateMaxSpeicher(): number {
        let maximalerSpeicher = this.serviceData.basisService.limiteGigaByte;
        if (this.serviceData.speicherService) {
            maximalerSpeicher += this.serviceData.speicherService.limiteGigaByte;
        }
        return maximalerSpeicher * 1073741824;
    }
}
</script>
<style>
.borderbottom {
    border-bottom: solid 1px black;
    margin: 0;
}
.tableheader {
    font-weight: bold;
    color: grey;
}
</style>
