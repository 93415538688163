<template>
    <v-card class="mb-2">
        <v-row>
            <v-col sm="4">
                <h3 class="mt-5 pl-3">
                    <v-btn icon link @click="remove">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ uploadFile.datei.name }}
                </h3>
            </v-col>
            <v-col sm="1">
                <h3 class="mt-5">{{ uploadFile.getReadableFileSizeString() }}</h3>
            </v-col>
            <v-col sm="3">
                <v-select class="mt-5" :items="typen" v-model="uploadFile.dokumentTyp" dense hide-details></v-select>
            </v-col>
            <v-col sm="3">
                <!-- <v-text-field
                    v-model="uploadFile.schlagworte"
                    :placeholder="$t('upload.table.schlagworte')"
                ></v-text-field> -->
                <v-combobox
                    class="mt-3"
                    v-model="uploadFile.schlagwortVorschlaege"
                    :items="schlagwortSelection"
                    :search-input.sync="schlagwortSearch"
                    :label="$t('upload.table.schlagworte')"
                    :hide-no-data="!schlagwortSearch"
                    @keyup.space="addNewSchlagwortFromRow(schlagwortSearch)"
                    @blur="addNewSchlagwortFromRow(schlagwortSearch)"
                    append-icon=""
                    hide-selected
                    solo
                    multiple
                    dense
                >
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                        <v-chip v-bind="attrs" :input-value="selected" label small>
                            <span class="pr-2">
                                {{ item }}
                            </span>
                            <v-icon small @click="parent.selectItem(item)">
                                x
                            </v-icon>
                        </v-chip>
                    </template>
                    <template v-slot:item="{ index, item }">
                        <v-text-field
                            v-if="item === schlagwortEdit"
                            v-model="schlagwortEdit"
                            autofocus
                            flat
                            background-color="transparent"
                            hide-details
                            solo
                        ></v-text-field>
                        <div>
                            {{ item }}
                        </div>
                        <v-spacer></v-spacer>
                        <v-list-item-action @click.stop>
                            <v-btn icon @click.stop.prevent="deleteSchlagwortFromRow(index, item)">
                                <v-icon>{{ item !== schlagwortEdit ? 'mdi-delete' : 'mdi-check' }}</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </template>
                </v-combobox>
            </v-col>
        </v-row>
    </v-card>
</template>
<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Debounce } from 'vue-debounce-decorator';
import { DokumentUploadDto } from '@/types/DokumentUploadDto';

import { NAME as NAME_UPLOAD } from '../store';
const uploadModul = namespace(NAME_UPLOAD);
import { GET_SCHLAGWORTE, CREATE_SCHLAGWORT, DELETE_SCHLAGWORT } from '../store/actions';
import { SCHLAGWORTVORSCHLAEGE } from '../store/getters';

@Component
export default class UploadRow extends Vue {
    @Prop({ required: false, type: Object })
    private uploadFile!: DokumentUploadDto;

    @Prop({ required: false, type: Array })
    private typen!: Array<object>;

    @uploadModul.Action(GET_SCHLAGWORTE) getSchlagworte!: Function;
    @uploadModul.Action(CREATE_SCHLAGWORT) createSchlagwort!: Function;
    @uploadModul.Action(DELETE_SCHLAGWORT) deleteSchlagwort!: Function;
    @uploadModul.Getter(SCHLAGWORTVORSCHLAEGE) schlagwortSelection!: Array<string>;

    schlagwortSearch = '';
    schlagwortEdit = '';

    remove() {
        this.$emit('remove', this.uploadFile.uuid);
    }

    addNewSchlagwortFromRow(item) {
        const itemText = item.trim();
        if (itemText === '') {
            this.schlagwortSearch = '';
            return;
        }
        this.uploadFile.schlagwortVorschlaege.push(itemText);
        this.uploadFile.schlagworte = this.uploadFile.schlagwortVorschlaege.join(' ');
        if (!this.schlagwortSelection.includes(itemText)) {
            this.createSchlagwort(item);
        }
        this.schlagwortSearch = '';
    }

    deleteSchlagwortFromRow(index, item) {
        this.deleteSchlagwort(item).then(response => {
            this.getSchlagworte(this.schlagwortSearch);
        });
    }

    @Watch('uploadFile.schlagwortVorschlaege')
    onSelectedSchlagworteChanged(newVal: any, oldVal: any) {
        this.uploadFile.schlagworte = this.uploadFile.schlagwortVorschlaege.join(' ');
        if (newVal.length === oldVal.length || newVal.length < oldVal.length) {
            return;
        }

        if (this.schlagwortSelection.includes(newVal[newVal.length - 1])) {
            return;
        }

        //Dieser Teil wird nur Ausgeführt wenn ein neues Schlagwort hinzugefügt wird.
        if (newVal[newVal.length - 1] != undefined) {
            this.createSchlagwort(newVal[newVal.length - 1]);
        }
    }

    @Watch('schlagwortSearch')
    @Debounce(400)
    onSearchStringChanged() {
        if (this.schlagwortSearch != null) {
            const multiSchlagworte = this.schlagwortSearch.split(' ');
            if (multiSchlagworte.length > 1) {
                multiSchlagworte.forEach(element => {
                    this.addNewSchlagwortFromRow(element);
                });
            }
            if (this.schlagwortSearch.trim() != '') {
                this.getSchlagworte(this.schlagwortSearch);
            }
        }
    }
}
</script>
