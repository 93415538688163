export class TeilnehmerFilterDto {
    constructor() {
        this.status = '';
        this.suchText = '';
        this.hatRechnungenNurViaVetdata = undefined;
        this.hatTvsDokumenteNurDigital = undefined;
        this.offset = 0;
        this.entriesToLoad = 25;
        this.sortBy = 'produktnummer';
        this.sortOrder = 'Descending';
    }
    status?: string;
    suchText?: string;
    hatRechnungenNurViaVetdata?: boolean;
    hatTvsDokumenteNurDigital?: boolean;
    offset: number;
    entriesToLoad?: number;
    sortBy?: string;
    sortOrder?: string;
}