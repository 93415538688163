import { MutationTree } from 'vuex';
import { IUploadState } from './state';
import i18n from '@/plugins/i18n';

export const MAPDOKUMENTTYPEN = 'mapDokumenttypes';
export const MAPSCHLAGWORTVORSCHLAEGE = 'mapSchlagwortVorschlaege';

export const mutations: MutationTree<IUploadState> = {
    [MAPDOKUMENTTYPEN](state, values: Array<string>) {
        const result: Array<object> = [];
        values.forEach((val) => {
            result.push({ text: i18n.t(val).toString(), value: val });
        });
        state.dokumentTypes = result;
    },
    [MAPSCHLAGWORTVORSCHLAEGE](state, values: Array<string>) {
        state.schlagwortVorschlaege = values;
    }
};
