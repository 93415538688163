import {
    TeilnehmerApiDto,
    TeilnehmerServiceApiDto,
    ServiceVerrechnungApiDto,
    BelegterSpeicherApiDto,
    BenutzerDetailApiDto,
    TeilnehmerFunktionApiDto,
    TeilnehmerZugriffApiDto,
} from '@/api-schema';
import { TeilnehmerFunktionDto, RegisterDto, TeilnehmerBenutzerDto } from '@/types/UserDtos';

export interface IProfilState {
    kundeData: TeilnehmerApiDto;
    serviceData: TeilnehmerServiceApiDto;
    verrechnungData: ServiceVerrechnungApiDto;
    speicherData: BelegterSpeicherApiDto;
    benutzerData: Array<TeilnehmerBenutzerDto>;
    benutzerDetails: BenutzerDetailApiDto;
    teilnehmerFunktionData: TeilnehmerFunktionApiDto;
    teilnehmerZugriffe: Array<TeilnehmerZugriffApiDto>;
    createTeilnehmerList: Array<any>;
}

export const state: IProfilState = {
    kundeData: new RegisterDto(),
    serviceData: new Object(),
    verrechnungData: new Object(),
    speicherData: null,
    benutzerData: [],
    benutzerDetails: new Object(),
    teilnehmerFunktionData: new TeilnehmerFunktionDto(),
    teilnehmerZugriffe: [],
    createTeilnehmerList: []
};
