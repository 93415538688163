import { TvsLieferantRechnungApiDto, TvsLieferantRechnungDetailApiDto } from '@/api-schema';
import { RechnungFilterDto } from '@/types/RechnungFilterDto';

export interface IRechnungenState {
    rechnungList: Array<TvsLieferantRechnungApiDto>;
    rechnungenDetails: Array<TvsLieferantRechnungDetailApiDto>;
    queryFilter: RechnungFilterDto;
    loading: boolean;
    totalNumberOfRecords: number | null;
}

export const state: IRechnungenState = {
    loading: false,
    totalNumberOfRecords: null,
    rechnungList: [],
    rechnungenDetails: [],
    queryFilter: new RechnungFilterDto()
};
