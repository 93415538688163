import { MutationTree } from 'vuex';
import { IGeneralState } from './state';

export const SUBTITLE = 'subTitle';
export const FOOTERTITLE = 'footerTitle';

export const mutations: MutationTree<IGeneralState> = {
    [SUBTITLE]: (state, subTitle) => {
        state.subTitle = subTitle;
    },
    [FOOTERTITLE]: (state, footerTitle) => {
        state.footerTitle = footerTitle;
    }
};
