import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IGeneralState } from './state';
import { NAME } from './';
import { configService } from '@/services/configService';
import { INACTIVITYLOGOUTTIMEOUT, INACTIVITYWARNINGTIMEOUT } from '../security/mutations';
import { NAME as SecurityName } from '../security';

export const LOAD_FRONTENDCONFIGS = 'load_frontend_configs';
export const FEEDBACK_MAILTO = 'feedbackMailTo';

export const actions: ActionTree<IGeneralState, IRootState> = {
    async [LOAD_FRONTENDCONFIGS]({ commit }: any) {
        console.debug(NAME + '::' + LOAD_FRONTENDCONFIGS);

        return configService.get().then(response => {
            commit(SecurityName + '/' + INACTIVITYWARNINGTIMEOUT, response.InactivityWarningTimeout, { root: true });
            commit(SecurityName + '/' + INACTIVITYLOGOUTTIMEOUT, response.InactivityLogoutTimeout, { root: true });
        });
    },
    async [FEEDBACK_MAILTO]({ state }: any, { lang, senderInfo, screen }: any) {
        console.debug(NAME + '::' + FEEDBACK_MAILTO, lang, senderInfo, screen);

        //Folgender Block von Code ist teils von toSwissDateString() in DateMixin kopiert, um das Datum wie gewünscht zu formatieren
        const date = new Date();
        let dd = date.getDate().toString();
        let mm = (date.getMonth() + 1).toString(); //Januar ist 0!
        const yyyy = date.getFullYear();

        const hh = date.getHours().toString();
        const min = date.getMinutes().toString();
        const ss = date.getSeconds().toString();

        //führende nullen anfügen
        if (dd.length < 2) {
            dd = '0' + dd;
        }
        if (mm.length < 2) {
            mm = '0' + mm;
        }
        const mailDateString = dd + '.' + mm + '.' + yyyy + ', ' + hh + ':' + min + ':' + ss;

        var mailToLink = "mailto:";

        //An wen das Mail geschickt wird
        mailToLink += "admin@vetdata.ch?";

        if(lang.toLowerCase() === "de") {
            //Das Subject für die Mail
            mailToLink += "subject=Feedback zur VETdata-Applikation";

            //Der Body der Mail
            mailToLink += "&body=";
            var body = "VETdata Feedback ("+ config.VUE_APP_ENVIROMENT +")\n" +
                       "TVS-Nr: " + senderInfo.tvsKundenNummer + "\n" +
                       "Benutzer: " + senderInfo.anzeigeName + "\n" +
                       "Dialog: " + screen + "\n" +
                       "Zeitpunkt: " + mailDateString + "\n" +
                       "Mein Feedback: ... " + "\n\n" +
                       "Bitte fügen Sie wenn sinnvoll einen \"Screenshot\" des VETdata-Dialoges bei.";

            //Dies muss gemacht werden, damit notationen wie \n erkannt werden.
            mailToLink += encodeURIComponent(body);
        }
        else if(lang.toLowerCase() === "fr") {
            //Das Subject für die Mail
            mailToLink += "subject=Commentaire sur l’application VETdata";

            //Der Body der Mail
            mailToLink += "&body=";
            var body = "VETdata Commentaire("+ config.VUE_APP_ENVIROMENT +")" + "\n" +
                       "Numéro OGV: " + senderInfo.tvsKundenNummer + "\n" +
                       "Utilisateur: " + senderInfo.anzeigeName + "\n" +
                       "Dialogue: " + screen + "\n" +
                       "Date: " + mailDateString + "\n" +
                       "Mon commentaire: ... " + "\n\n" +
                       "Si necessaire, joignez-nous une «capture d’écran» du dialogue actuel.";

            //Dies muss gemacht werden, damit notationen wie \n erkannt werden.
            mailToLink += encodeURIComponent(body);
        }

        window.location.href = mailToLink;
    },
};
