import { v4 as uuidv4 } from 'uuid';
export class DokumentUploadDto {
    constructor(datei: any) {
        this.datei = datei;
        this.dokumentTyp = 'archiv.dokumenttyp.eigenesdok';
        this.schlagworte = '';
        this.schlagwortVorschlaege = [];
        this.uuid = uuidv4();
    }
    dokumentTyp: string;
    schlagworte: string;
    schlagwortVorschlaege: Array<string>;
    // Einduetige ID für das Dokument, wird für die Verwantung der Dokumente im Frontend verwendet
    uuid: string;
    datei: File;

    public getReadableFileSizeString() {
        let i = -1;
        let fileSizeInBytes = this.datei.size;
        const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
            fileSizeInBytes = fileSizeInBytes / 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }
}
