import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { ISecurityState } from './state';

export const ISLOGGEDIN = 'IsLoggedIn';
export const BENUTZERINFO = 'benutzerInfo';
export const HASACCESS = 'hasAccess';
export const PERMISSIONS = 'permissions';
export const ACCESSDENIED = 'accessDenied';

export const INACTIVITYWARNINGTIMEGetter = 'inactivityWarningTime';
export const INACTIVITYLOGOUTTIMEGetter = 'inactivitylogoutTime';
export const GETMODALAGBVISIBLE = 'getAgbModalVisible';

export const getters: GetterTree<ISecurityState, IRootState> = {
    [ACCESSDENIED]: state => state.accessDenied,
    [ISLOGGEDIN]: state => state.loggedIn,
    [INACTIVITYWARNINGTIMEGetter]: state => state.inactivityWarningTime,
    [INACTIVITYLOGOUTTIMEGetter]: state => state.inactivitylogoutTime,
    [BENUTZERINFO]: state => state.benutzerInfo,
    [HASACCESS]: state => permission => {
        return state.permissions?.includes(permission.toLowerCase()) || false;
    },
    [PERMISSIONS]: state => state.permissions,
    [GETMODALAGBVISIBLE]: state => state.modalAgbVisible
};
