import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { IHomeState } from './state';

export const HOMEMESSAGE = 'homeMessage';
export const TABLEVALUES = 'getTableValues';
export const TABVALUES = 'getTabValues';
export const LOADING = 'getLoading';
export const TOTAL_NUMBER_OF_RECORDS = 'getTotalNumberOfRecords';

export const getters: GetterTree<IHomeState, IRootState> = {
    [HOMEMESSAGE]: state => state.homeMessage,
    [TABLEVALUES]: state => state.tableList,
    [TABVALUES]: state => state.tabList,
    [LOADING]: state => state.loading,
    [TOTAL_NUMBER_OF_RECORDS]: state => state.totalNumberOfRecords
};
