import { IRechnungService } from './IRechnungService';
import BaseServie from '@/services/baseService';
import { RechnungFilterDto } from '@/types/RechnungFilterDto';
import { TvsLieferantRechnungApiDtoSearchResponseApi, TvsLieferantRechnungDetailApiDto } from '@/api-schema';
import { AxiosResponse } from 'axios';

export class RechnungService extends BaseServie implements IRechnungService {
    private RESOURCE_URL: string;
    private DOKUMENTE_URL: string;
    constructor() {
        super();
        this.RESOURCE_URL = `${this.BASE_URL}rechnungen`;
        this.DOKUMENTE_URL = `${this.BASE_URL}dokumente`;

        console.debug('ArchivService::constructor', this.RESOURCE_URL);
    }

    async getAll(filter: RechnungFilterDto): Promise<TvsLieferantRechnungApiDtoSearchResponseApi> {
        return super
            .GET<TvsLieferantRechnungApiDtoSearchResponseApi>(
                `${this.RESOURCE_URL}` + super.serializeObjectToQuerystring(filter)
            )
            .then(response => {
                return response;
            });
    }

    async getDetails(xmlDokumentGuid: string): Promise<Array<TvsLieferantRechnungDetailApiDto>> {
        return super.GET<Array<object>>(`${this.RESOURCE_URL}/` + xmlDokumentGuid).then(response => {
            return response;
        });
    }

    async downloadBeleg(id: string): Promise<AxiosResponse<File>> {
        return super.DOWNLOAD<File>(`${this.DOKUMENTE_URL}/download/` + id).then(response => {
            return response;
        });
    }
}

export const rechnungService = new RechnungService();
