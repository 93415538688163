<template>
    <v-row>
        <v-col sm="10">
            <BarChart id="speicherBarGraph" :chart-data="chartdata" :options="options" />
            <div class="pl-8 pb-2 pt-4">
                <span style="font-weight: bold">{{ $t('einstellungen.diagramm.tvsbelegung') }}</span>
                {{ getReadableFileSizeString(belegterSpeicher.tvsBelegterSpeicher) }}
            </div>
        </v-col>
        <v-col sm="2" style="margin: auto">
            <br />
            <h4>{{ getReadableFileSizeString(maximalerSpeicher) }}</h4>
            <p>100%</p>
        </v-col>
    </v-row>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BelegterSpeicherApiDto } from '@/api-schema';

import ServiceRow from './ServiceRow.vue';
import BarChart from '@/components/BarChart.vue';

@Component({
    components: {
        ServiceRow,
        BarChart
    }
})
export default class SpeicherDiagramm extends Vue {
    @Prop({ required: true, type: Object })
    private belegterSpeicher!: BelegterSpeicherApiDto;

    @Prop({ required: true, type: Number })
    private maximalerSpeicher!: number;

    mounted() {
        const ctx = document.getElementById('speicherBarGraph');
        ctx.style.height = '100px';
    }

    get chartdata(): any {
        return {
            labels: [''],
            datasets: [
                {
                    label: 'Eigene Dateien',
                    borderColor: 'black',
                    borderWidth: { top: 1, right: 0, bottom: 1, left: 0 },
                    backgroundColor: '#5CA3F8',
                    data: [
                        this.belegterSpeicher.eigenerBelegterSpeicher,
                        (this.belegterSpeicher.eigenerBelegterSpeicher / this.maximalerSpeicher) * 100
                    ]
                },
                {
                    label: 'Frei',
                    borderColor: 'black',
                    borderWidth: '1',
                    backgroundColor: 'white',
                    data: [
                        this.maximalerSpeicher -
                            this.belegterSpeicher.eigenerBelegterSpeicher -
                            this.belegterSpeicher.tvsBelegterSpeicher,
                        ((this.maximalerSpeicher -
                            this.belegterSpeicher.eigenerBelegterSpeicher -
                            this.belegterSpeicher.tvsBelegterSpeicher) /
                            this.maximalerSpeicher) *
                            100
                    ]
                }
            ]
        };
    }
    get options(): any {
        return {
            indexAxis: 'y',
            title: { display: false },
            maintainAspectRatio: false,
            responsive: true,

            plugins: {
                datalabels: {
                    display: false
                },
                tooltip: {
                    enabled: false
                },
                legend: {
                    labels: {
                        generateLabels(chart) {
                            const data = chart.data;
                            if (data.datasets.length) {
                                return data.datasets.map((dataset, i) => {
                                    let j = -1;
                                    let fileSizeInBytes = dataset.data[0];
                                    const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
                                    do {
                                        fileSizeInBytes = fileSizeInBytes / 1024;
                                        j++;
                                    } while (fileSizeInBytes > 1024);
                                    const fileSizeResult: any =
                                        Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[j];
                                    const meta = chart.getDatasetMeta(i);
                                    const style = meta.controller.getStyle(i);
                                    return {
                                        text:
                                            dataset.label +
                                            ': ' +
                                            fileSizeResult +
                                            '(' +
                                            dataset.data[1].toFixed(2) +
                                            '%)',
                                        fillStyle: style.backgroundColor,
                                        strokeStyle: style.borderColor,
                                        lineWidth: style.borderWidth,

                                        // Extra data used for toggling the correct item
                                        index: i
                                    };
                                });
                            }
                            return [];
                        }
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: false
                    },
                    ticks: {
                        display: false
                    }
                },
                y: {
                    stacked: true,
                    grid: {
                        display: false
                    }
                }
            }
        };
    }
    public getReadableFileSizeString(size: number) {
        let i = -1;
        let fileSizeInBytes = size;
        const byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
        do {
            fileSizeInBytes = fileSizeInBytes / 1024;
            i++;
        } while (fileSizeInBytes > 1024);

        return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }
}
</script>
