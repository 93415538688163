import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import Colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi' // default - only for display purposes
    },
    theme: {
        themes: {
            light: {
                primary: Colors.blue.darken4, // #304FFE
                secondary: Colors.lightGreen.lighten1, // #536DFE
                accent: Colors.indigo.lighten5 // #E8EAF6
            }
        }
    }
});
