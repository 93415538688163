<template>
    <v-col>
        <v-col class="pb-0" sm="12">
            <h3 v-text="$t('einstellungen.funktion.title')" />
        </v-col>
        <v-col class="pt-0" sm="12">
            <v-row>
                <v-col class="py-6" sm="12">
                    <v-icon>{{ rechnungIcon }}</v-icon>
                    {{ $t('einstellungen.funktion.zustellunglieferantenrechnung') }}
                    <br />
                    <v-icon>{{ rechnungEmailIcon }}</v-icon>
                    {{ $t('einstellungen.funktion.emaillieferantenrechnungnen') }}
                    {{ teilnehmerFunktionApiDto.emailLieferantenRechnungnen }}
                    <br />
                    <v-icon>{{ tvsIcon }}</v-icon>
                    {{ $t('einstellungen.funktion.zustellungtvsdokumente') }}
                </v-col>
            </v-row>
            <EditFunktionenDialog :teilnehmer-funktion-api-dto="teilnehmerFunktionApiDto" />
        </v-col>
    </v-col>
</template>
<script lang="ts">
import { TeilnehmerFunktionApiDto } from '@/api-schema';
import { Component, Prop, Vue } from 'vue-property-decorator';

import EditFunktionenDialog from './EditFunktionenDialog.vue';
@Component({
    components: {
        EditFunktionenDialog
    }
})
export default class FunktionEinstellungen extends Vue {
    @Prop({ required: true, type: Object })
    teilnehmerFunktionApiDto!: TeilnehmerFunktionApiDto | null;

    get tvsIcon() {
        return this.teilnehmerFunktionApiDto.hatTvsDokumenteNurDigital
            ? 'mdi-checkbox-marked-circle-outline'
            : 'mdi-checkbox-blank-circle-outline';
    }
    get rechnungIcon() {
        return this.teilnehmerFunktionApiDto.hatRechnungenNurViaVetdata
            ? 'mdi-checkbox-marked-circle-outline'
            : 'mdi-checkbox-blank-circle-outline';
    }
    get rechnungEmailIcon() {
        return this.teilnehmerFunktionApiDto.emailLieferantenRechnungnen?.length > 0
            ? 'mdi-checkbox-marked-circle-outline'
            : 'mdi-checkbox-blank-circle-outline';
    }
}
</script>

<style scoped>
i.v-icon {
    cursor: not-allowed;
}
</style>
