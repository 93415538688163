import { MutationTree } from 'vuex';
import { IHeaderNotificationState } from './state';

export const SET_VISIBLE = 'setVisible';
export const SET_TYPE = 'setType';
export const SET_TEXT = 'setText';
export const SET_ERRORS = 'setErrorMessages';
export const SET_CLOSEAFTERDELAY = 'setCloseAfterDelay';
export const SET_DISMISSIBLE = 'setDismissible';
export const SET_MODAL_VISIBLE = 'setModalVisible';
export const SET_MODAL_TYPE = 'setModalType';
export const SET_MODAL_TEXT = 'setModalText';
export const SET_MODAL_ERRORS = 'setEModalrrorMessages';
export const SET_MODAL_CLOSEAFTERDELAY = 'setModalCloseAfterDelay';
export const SET_MODAL_DISMISSIBLE = 'setModalDismissible';

export const mutations: MutationTree<IHeaderNotificationState> = {
    [SET_VISIBLE]: (state, visible: boolean) => {
        state.visible = visible;
    },
    [SET_TYPE]: (state, type: string) => {
        state.type = type;
    },
    [SET_TEXT]: (state, text: string) => {
        state.text = text;
    },
    [SET_ERRORS]: (state, errorMessages: any) => {
        if(typeof(errorMessages) == typeof(String)) {
            state.text = errorMessages;
        } else {
        let text = '';
        for (const key in errorMessages) {
            if (key in errorMessages) {
                text += errorMessages[key];
                text += '<br />';
            }
        }
        state.text = text;
        }
    },
    [SET_CLOSEAFTERDELAY]: (state, closeAfterDelay) => {
        state.closeAfterDelay = closeAfterDelay;

        if (!closeAfterDelay) {
            state.closeDelay = 0;
        } else {
            state.closeDelay = 5000;
        }
    },
    [SET_DISMISSIBLE]: (state, value: boolean) => {
        state.isDismissible = value;
    },
    [SET_MODAL_VISIBLE]: (state, visible: boolean) => {
        state.modalVisible = visible;
    },
    [SET_MODAL_TYPE]: (state, type: string) => {
        state.modalType = type;
    },
    [SET_MODAL_TEXT]: (state, text: string) => {
        state.modalText = text;
    },
    [SET_MODAL_ERRORS]: (state, errorMessages: any) => {
        if(typeof(errorMessages) == typeof(String)) {
            state.modalText = errorMessages;
        } else {
        let text = '';
        for (const key in errorMessages) {
            if (key in errorMessages) {
                text += errorMessages[key];
                text += '<br />';
            }
        }
        state.modalText = text;
        }
    },
    [SET_MODAL_CLOSEAFTERDELAY]: (state, closeAfterDelay) => {
        state.modalCloseAfterDelay = closeAfterDelay;

        if (!closeAfterDelay) {
            state.modalCloseDelay = 0;
        } else {
            state.modalCloseDelay = 5000;
        }
    },
    [SET_MODAL_DISMISSIBLE]: (state, value: boolean) => {
        state.modalIsDismissible = value;
    }
};
