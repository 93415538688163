import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IHeaderNotificationState } from './state';
import { NAME } from '.';

import { SET_TEXT, SET_TYPE, SET_VISIBLE, SET_ERRORS, SET_CLOSEAFTERDELAY, SET_MODAL_VISIBLE, SET_MODAL_TYPE, SET_MODAL_TEXT, SET_MODAL_CLOSEAFTERDELAY } from './mutations';

export const NEW = 'new';
export const NEW_SUCESS = 'newSuccess';
export const NEW_INFO = 'newInfo';
export const NEW_ALERT = 'newAlert';
export const NEW_ERRORMESSAGES = 'newErrorMessages';
export const CLOSE = 'close';
export const NEW_MODAL_SUCESS = 'newModalSucess';
export const NEW_MODAL_ERROR = 'newModalError';
export const CLOSE_MODAL = 'closeModal';

export const actions: ActionTree<IHeaderNotificationState, IRootState> = {
    async [NEW]({ commit }: any, { type, text }: any) {
        console.debug(NAME + '::' + NEW);
        commit(SET_VISIBLE, true);
        commit(SET_TYPE, type);
        commit(SET_TEXT, text);
        commit(SET_CLOSEAFTERDELAY, type === 'success');
    },
    async [NEW_SUCESS]({ commit }: any, { text }: any) {
        console.debug(NAME + '::' + NEW);
        commit(SET_VISIBLE, true);
        commit(SET_TYPE, 'success');
        commit(SET_TEXT, text);
        commit(SET_CLOSEAFTERDELAY, true);
    },
    async [NEW_INFO]({ commit }: any, { text }: any) {
        console.debug(NAME + '::' + NEW);
        commit(SET_VISIBLE, true);
        commit(SET_TYPE, 'info');
        commit(SET_TEXT, text);
        commit(SET_CLOSEAFTERDELAY, false);
    },
    async [NEW_ALERT]({ commit }: any, { text }: any) {
        console.debug(NAME + '::' + NEW);
        commit(SET_VISIBLE, true);
        commit(SET_TYPE, 'warning');
        commit(SET_TEXT, text);
        commit(SET_CLOSEAFTERDELAY, true);
    },
    async [NEW_ERRORMESSAGES]({ commit }: any, { errorMessages }: any) {
        console.debug(NAME + '::' + NEW_ERRORMESSAGES);
        commit(SET_VISIBLE, true);
        commit(SET_TYPE, 'error');
        commit(SET_TEXT, errorMessages);
        commit(SET_CLOSEAFTERDELAY, false);
    },
    async [CLOSE]({ commit }) {
        console.debug(NAME + '::' + CLOSE);
        commit(SET_VISIBLE, false);
        commit(SET_TYPE, 'error');
        commit(SET_TEXT, 'placeholder');
        commit(SET_CLOSEAFTERDELAY, false);
    },
    async [NEW_MODAL_SUCESS]({ commit, dispatch }: any, { text }: any) {
        console.debug(NAME + '::' + NEW_MODAL_SUCESS);
        commit(SET_MODAL_VISIBLE, true);
        commit(SET_MODAL_TYPE, 'success');
        commit(SET_MODAL_TEXT, text);
        commit(SET_MODAL_CLOSEAFTERDELAY, true);
        dispatch(CLOSE);
    },
    async [NEW_MODAL_ERROR]({ commit, dispatch }: any, { text }: any) {
        console.debug(NAME + '::' + NEW_MODAL_ERROR);
        commit(SET_MODAL_VISIBLE, true);
        commit(SET_MODAL_TYPE, 'error');
        commit(SET_MODAL_TEXT, text);
        commit(SET_MODAL_CLOSEAFTERDELAY, false);
        dispatch(CLOSE);
    },
    async [CLOSE_MODAL]({ commit }) {
        console.debug(NAME + '::' + CLOSE_MODAL);
        commit(SET_MODAL_VISIBLE, false);
        commit(SET_MODAL_TYPE, 'error');
        commit(SET_MODAL_TEXT, 'placeholder');
        commit(SET_MODAL_CLOSEAFTERDELAY, false);
    },
};
