import { MutationTree } from 'vuex';
import { IRechnungenState } from './state';
import { TvsLieferantRechnungApiDto, TvsLieferantRechnungDetailApiDto } from '@/api-schema';
import { RechnungFilterDto } from '@/types/RechnungFilterDto';

export const MAPRECHNUNGEN = 'mapLieferantenRechnungen';
export const ADDRECHNUNGEN = 'addDokumentToList';
export const MAPRECHNUNGDETAIL = 'mapRechnungsDetails';
export const CLEARFILTER = 'clearFilter';
export const APPLYFILTER = 'mapRechnungFilter';
export const LOADING = 'mapLoading';
export const TOTAL_NUMBER_OF_RECORDS = 'mapTotalNumberOfRecords';
export const OFFSET = 'mapOffset';

export const mutations: MutationTree<IRechnungenState> = {
    [MAPRECHNUNGEN](state, value: Array<TvsLieferantRechnungApiDto>) {
        state.rechnungList = value;
    },
    [ADDRECHNUNGEN](state, value: Array<TvsLieferantRechnungApiDto>) {
        state.rechnungList = state.rechnungList.concat(value);
    },
    [MAPRECHNUNGDETAIL](state, value: Array<TvsLieferantRechnungDetailApiDto>) {
        state.rechnungenDetails = value;
    },
    [APPLYFILTER](state, value: RechnungFilterDto) {
        state.queryFilter.betragBis = value.betragBis;
        state.queryFilter.betragVon = value.betragVon;
        state.queryFilter.zeitspanne = value.zeitspanne;
        state.queryFilter.rechnungDatumBis = value.rechnungDatumBis;
        state.queryFilter.rechnungDatumVon = value.rechnungDatumVon;
        state.queryFilter.sortBy = value.sortBy;
        state.queryFilter.sortOrder = value.sortOrder;
        state.queryFilter.suchText = value.suchText;
    },
    [CLEARFILTER](state) {
        state.queryFilter = new RechnungFilterDto();
    },
    [TOTAL_NUMBER_OF_RECORDS](state, value: number) {
        state.totalNumberOfRecords = value;
    },
    [OFFSET](state, value: number) {
        state.queryFilter.offset = value;
    },
    [LOADING](state, value: boolean) {
        state.loading = value;
    }
};
