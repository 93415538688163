import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IUploadState } from './state';
import { $router } from '@/main';

import { NAME } from './';

import { MAPDOKUMENTTYPEN, MAPSCHLAGWORTVORSCHLAEGE } from './mutations';
import { NEW_SUCESS } from '@/store/modules/headerNotification/actions';
import { SHOW, HIDE } from '@/store/modules/loadingSpinner/actions';

import { uploadService } from '../services/UploadService';
import { DokumentUploadDto } from '@/types/DokumentUploadDto';

export const UPLOAD_DOKUMENT = 'uploadDokument';
export const UPLOAD_DOKUMENTE = 'uploadDokumente';
export const GET_DOKUMENT_TYPEN = 'getDokumentTypes';
export const CREATE_SCHLAGWORT = 'addSchlagwort';
export const GET_SCHLAGWORTE = 'getSchlagworte';
export const DELETE_SCHLAGWORT = 'deleteSchlagwort';

export const actions: ActionTree<IUploadState, IRootState> = {
    [UPLOAD_DOKUMENT]({ dispatch, commit }: any, dokumente: Array<DokumentUploadDto>) {
        console.debug(NAME + '::' + UPLOAD_DOKUMENT);

        dispatch('loadingSpinner/' + SHOW, {}, { root: true });

        let counter = 0;
        dokumente.forEach(element => {
            uploadService.uploadDokument(element).then(response => {
                counter++;
                if (counter === dokumente.length) {
                    $router.push({ name: 'Dokumentarchiv', params: {} });
                    dispatch('loadingSpinner/' + HIDE, {}, { root: true });
                    dispatch('headerNotification/' + NEW_SUCESS, { text: 'upload.erfolgreich' }, { root: true });
                }
            });
        });
    },
    [GET_DOKUMENT_TYPEN]({ commit, state }: any) {
        console.debug(NAME + '::' + UPLOAD_DOKUMENT);

        uploadService.getDokumentTypen().then(response => {
            commit(MAPDOKUMENTTYPEN, response);
        });
    },
    [UPLOAD_DOKUMENTE]({ dispatch, commit }: any, dokumente: Array<DokumentUploadDto>) {
        console.debug(NAME + '::' + UPLOAD_DOKUMENTE);

        const onUploadProgress: (progressEvent: ProgressEvent) => void = (progressEvent: ProgressEvent) => {
            const uploadPercentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);

            // Uploadvortschritt nur während des Uploads anzeigen, sobald Upload abgeschossen ist werden die Dokumente auf dem Server verarbeitet
            if (uploadPercentage < 100) {
                dispatch('loadingSpinner/' + SHOW, { text: uploadPercentage + '%' }, { root: true });
            } else {
                dispatch('loadingSpinner/' + SHOW, { text: 'upload.serververarbeitung' }, { root: true });
            }
        };

        dispatch('loadingSpinner/' + SHOW, {}, { root: true });

        uploadService.uploadDokumente(dokumente, onUploadProgress).then(response => {
            $router.push({ name: 'Dokumentarchiv', params: {} });
            dispatch('loadingSpinner/' + HIDE, {}, { root: true });
            dispatch('headerNotification/' + NEW_SUCESS, { text: 'upload.erfolgreich' }, { root: true });
        });
    },
    [GET_SCHLAGWORTE]({ commit }: any, suchText: string) {
        console.debug(NAME + '::' + GET_SCHLAGWORTE);

        uploadService.getSchlagwortVorschlaege(suchText).then(response  => {
            commit(MAPSCHLAGWORTVORSCHLAEGE, response);
        });
    },
    [CREATE_SCHLAGWORT]({ state, dispatch }: any, schlagwort: string) {
        console.debug(NAME + '::' + CREATE_SCHLAGWORT, schlagwort);

        dispatch(GET_SCHLAGWORTE, schlagwort);

        setTimeout(() => {
            if(state.schlagwortVorschlaege.includes(schlagwort)){
                return;
            }

            uploadService.addSchlagwortVorschlag(schlagwort).then(response  => {
                console.log("Schlagwort Add Result: ", response);
            });
        }, 500);
    },
    [DELETE_SCHLAGWORT]({ dispatch }: any, schlagwort: string) {
        console.debug(NAME + '::' + DELETE_SCHLAGWORT);

        return new Promise((resolve) => {
            uploadService.deleteSchlagwortVorschlag(schlagwort).then(response  => {
                resolve(response);
            });
        });
    }
};
