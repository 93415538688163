<template>
    <v-row>
        <v-col>
            <v-col sm="12">
                <v-row>
                    <v-col sm="10">
                        <h3 class="pl-3" v-text="$t('einstellungen.benutzer.title')" />
                    </v-col>
                    <v-col sm="1">
                        <AddAccountDialog />
                    </v-col>
                </v-row>
            </v-col>
            <v-data-table
                :headers="headers"
                :items="benutzerList"
                item-key="id"
                show-expand
                single-expand
                disable-pagination
                :expanded.sync="expanded"
                hide-default-footer
                class="elevation-1 nowhow-table"
                :options.sync="options"
                @item-expanded="itemExpanded"
                @click:row="expandRow"
                :must-sort="true"
            >
                <!-- Selbst definierte Tablespalten -->
                <template v-slot:item.burgermenu="{ item }">
                    <v-menu offset-x v-model="item.burgermenuActive">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon :href="item.burgermenu" v-bind="attrs" v-on="on">
                                <v-icon color="primary" v-text="'mdi-menu'" />
                            </v-btn>
                        </template>
                        <v-list>
                            <EditAccountDialog @success="expanded = []" :edit-benutzer-id="item.id" />
                            <ConfirmDialog
                                @confirm="deleteBenutzer(item.id)"
                                title="einstellungen.deletedialog.title"
                                text="einstellungen.deletedialog.text"
                            >
                                <template v-slot:activatorElement="{ on }">
                                    <v-list-item v-on="on">
                                        <v-list-item-title
                                            v-text="$t('table.burgermenu.delete')"
                                            @click="item.burgermenuActive = false"
                                        />
                                    </v-list-item>
                                </template>
                            </ConfirmDialog>
                        </v-list>
                    </v-menu>
                </template>

                <template v-slot:expanded-item="{}">
                    <td></td>
                    <td class="pl-8 py-2" :colspan="1">
                        <v-row v-if="benutzerDetails.rollen !== undefined">{{
                            $t('einstellungen.benutzer.rollen') +
                                ' ' +
                                $t('benutzerrollen.' + benutzerDetails.rollen.toString().toLowerCase())
                        }}</v-row>
                        <v-row v-else>{{ $t('einstellungen.benutzer.rollen') + '  -' }}</v-row>
                    </td>
                    <td class="pl-8 py-2" :colspan="4">
                        <v-row v-if="benutzerDetails.mobilNummer != null">{{
                            $t('einstellungen.benutzer.mobile') + ' ' + benutzerDetails.mobilNummer
                        }}</v-row>
                        <v-row v-else>{{ $t('einstellungen.benutzer.mobile') + '  -' }}</v-row>
                    </td>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TeilnehmerBenutzerDto } from '@/types/UserDtos';

import { NAME as NAME_EINSTELLUNGEN } from '../store';
const profilModul = namespace(NAME_EINSTELLUNGEN);

import { GET_BENUTZER_DETAILS, DELETE_ACCOUNT } from '../store/actions';
import { GETBENUTZERDETAILS } from '../store/getters';

import EditAccountDialog from './EditAccountDialog.vue';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import AddAccountDialog from './AddAccountDialog.vue';
import { BenutzerDetailApiDto } from '@/api-schema';
@Component({
    components: {
        EditAccountDialog,
        ConfirmDialog,
        AddAccountDialog
    }
})
export default class BenutzerListe extends Vue {
    @Prop({ required: true, type: Array })
    private benutzerList!: Array<TeilnehmerBenutzerDto>;

    @profilModul.Action(GET_BENUTZER_DETAILS) getBenutzerDetails!: Function;
    @profilModul.Action(DELETE_ACCOUNT) deleteBenutzer!: Function;
    @profilModul.Getter(GETBENUTZERDETAILS) benutzerDetails!: BenutzerDetailApiDto;

    expanded: Array<TeilnehmerBenutzerDto> = [];
    private itemExpanded(teilnehmer: any) {
        this.getBenutzerDetails(teilnehmer.item.id);
    }
    public expandRow(row: TeilnehmerBenutzerDto) {
        if (this.expanded.includes(row)) {
            this.expanded = [];
        } else {
            this.getBenutzerDetails(row.id);
            this.expanded = [];
            this.expanded.push(row);
        }
    }

    options: any = { sortDesc: [false], sortBy: ['email'] };

    get headers(): Array<any> {
        return [
            {
                text: this.$t('einstellungen.benutzer.table.id'),
                value: 'email'
            },
            {
                text: this.$t('einstellungen.benutzer.table.name'),
                value: 'nachname'
            },
            {
                text: this.$t('einstellungen.benutzer.table.vorname'),
                value: 'vorname'
            },
            {
                text: this.$t('einstellungen.benutzer.table.action'),
                value: 'burgermenu',
                align: 'center',
                width: '10',
                sortable: false
            }
        ];
    }
}
</script>
