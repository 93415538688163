export interface IHeaderNotificationState {
    visible: boolean;
    type: string;
    text: string;
    closeAfterDelay: boolean;
    closeDelay: number;
    isDismissible: boolean;
    modalVisible: boolean;
    modalType: string;
    modalText: string;
    modalCloseAfterDelay: boolean;
    modalCloseDelay: number;
    modalIsDismissible: boolean;
}

export const state: IHeaderNotificationState = {
    visible: false,
    type: 'error',
    text: 'placeholder',
    closeAfterDelay: false,
    closeDelay: 0,
    isDismissible: true,
    modalVisible: false,
    modalType: 'error',
    modalText: 'placeholder',
    modalCloseAfterDelay: false,
    modalCloseDelay: 0,
    modalIsDismissible: true
};
