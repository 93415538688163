import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IRegistrationState } from './state';
import { $router } from '@/main';

import { } from './mutations';
import { NAME } from './';

import { registrationService } from '../services/RegistrationService';
import { NEW_SUCESS } from '@/store/modules/headerNotification/actions';
import { RegisterDto } from '@/types/UserDtos';

export const REQUEST_CREATE_TEILNEHMER = 'requestCreateTeilnehmer';

export const actions: ActionTree<IRegistrationState, IRootState> = {
    [REQUEST_CREATE_TEILNEHMER]({ dispatch }: any, teilnehmer: RegisterDto) {
        console.debug(NAME + '::' + REQUEST_CREATE_TEILNEHMER);
        if (teilnehmer.sprache === '') {
            teilnehmer.sprache = 'D';
        }
        registrationService.requestCreateTeilnehmer(teilnehmer).then(response => {
            if (response) {
                $router.push({ name: 'Login', params: {} });
                dispatch(
                    'headerNotification/' + NEW_SUCESS,
                    { text: 'registration.message.success' },
                    { root: true }
                );
            } else {
                return null;
            }
        });
    }
};
