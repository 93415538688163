<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <!--table hauptkonfigurationen -->
                <v-data-table
                    :headers="headers"
                    :items="benutzerAsList"
                    :loading="loading"
                    :custom-sort="customSort"
                    :must-sort="true"
                    :options.sync="options"
                    item-key="email"
                    hide-default-footer
                    class="elevation-1 nowhow-table"
                    sort-by="email"
                >
                    <!-- Header der Table(Filter anzeige, gefundene Elemente) -->
                    <template v-slot:top>
                        <TableHeader>
                            <template>
                                <v-text-field
                                    v-model="filterData.userSignInEmail"
                                    class="col-5"
                                    prepend-inner-icon="mdi-magnify"
                                    append-icon="mdi-close"
                                    @keyup.enter="searchEnter"
                                    @click:append="clearSearch"
                                    :label="$t('suche')"
                                    hide-details
                                    autofocus
                                ></v-text-field>
                                <v-btn class="ml-1" small link color="primary" @click="searchEnter">{{
                                    $t('benutzerSuche.search')
                                }}</v-btn>
                            </template>
                        </TableHeader>
                    </template>

                    <template v-slot:item.kundenNummer="{ item }">
                        {{ item.kundenNummer }}
                    </template>
                    <template v-slot:item.firma="{ item }">
                        {{ item.firma }}
                    </template>
                    <template v-slot:item.plz="{ item }">
                        {{ item.plz }}
                    </template>
                    <template v-slot:item.ort="{ item }">
                        {{ item.ort }}
                    </template>
                    <template v-slot:item.userSignInEmail="{ item }">
                        {{ item.userSignInEmail }}
                    </template>
                    <template v-slot:item.rollen="{ item }">
                        <span v-if="item.rollen">
                            {{ item.rollen.join(',') }}
                        </span>
                    </template>
                    <template v-slot:item.burgermenu="{ item }">
                        <v-btn
                            icon
                            :href="item.burgermenu"
                            alt="test"
                            @click="initTakeSpecific(item.kundenNummer, item.rollen)"
                        >
                            <v-icon color="primary" v-text="'mdi-account-cog'" />
                        </v-btn>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator';
import { namespace } from 'vuex-class';

import { NAME as NAME_GENERAL } from '@/store/modules/general';
import { NAME as NAME_SECURITY } from '@/store/modules/security';
import { NAME as NAME_HEADERNOTIFICATION } from '@/store/modules/headerNotification';
import { NAME as NAME_TAKETEILNEHMER } from '../store';

import { SUBTITLE, FOOTERTITLE } from '@/store/modules/general/mutations';
import { HASACCESS } from '@/store/modules/security/getters';
import { SETACCESSDENIED } from '@/store/modules/security/mutations';
import { NEW_ALERT } from '@/store/modules/headerNotification/actions';
import { BenutzerInfoSearchResultApiDto, TakeTeilnehmerApiDto } from '@/api-schema';
import { GET_BENUTZER, TAKESPECIFIC } from '../store/actions';
import { GET_BENUTZER_DATA, GET_BENUTZER_LOADING, GET_BENUTZER_TOTAL_NUMBER_OF_RECORDS } from '../store/getters';
import { BenutzerInfoSearchApiDto } from '@/types/BenutzerInfoSearchApiDto';
import { TakeTeilnehmerDto } from '@/types/UserDtos';
import { APPLY_BENUTZERFILTER, CLEAR_BENUTZERFILTER } from '../store/mutations';

const generalModul = namespace(NAME_GENERAL);
const securityModul = namespace(NAME_SECURITY);
const headerModul = namespace(NAME_HEADERNOTIFICATION);
const takeTeilnehmerModul = namespace(NAME_TAKETEILNEHMER);

import TableHeader from '@/components/TableHeader.vue';
@Component({
    components: {
        TableHeader
    }
})
export default class BenutzerSuche extends Vue {
    @generalModul.Mutation(SUBTITLE) setSubTitle!: Function;
    @generalModul.Mutation(FOOTERTITLE) setFooterTitle!: Function;

    @securityModul.Getter(HASACCESS) hasAccess!: Function;
    @securityModul.Mutation(SETACCESSDENIED) setAccessDenied!: Function;

    @headerModul.Action(NEW_ALERT) alertMessage!: Function;

    @takeTeilnehmerModul.Getter(GET_BENUTZER_DATA) benutzer: BenutzerInfoSearchResultApiDto;
    @takeTeilnehmerModul.Getter(GET_BENUTZER_LOADING) loading!: boolean;
    @takeTeilnehmerModul.Action(GET_BENUTZER) getBenutzer!: Function;
    @takeTeilnehmerModul.Action(TAKESPECIFIC) takeSpecific!: Function;
    @takeTeilnehmerModul.Getter(GET_BENUTZER_TOTAL_NUMBER_OF_RECORDS) totalNumberOfRecords!: number;

    @takeTeilnehmerModul.Mutation(CLEAR_BENUTZERFILTER) clearFilter!: Function;
    @takeTeilnehmerModul.Mutation(APPLY_BENUTZERFILTER) applyFilters!: Function;

    filters = [];
    showFilterDrawer = false;

    options: any = { sortDesc: [true], sortBy: ['teilnehmerId'] };

    filterData: BenutzerInfoSearchApiDto = new BenutzerInfoSearchApiDto();

    private initTakeSpecific(kundenNummer: number, rollen?: Array<string>) {
        const rolle = rollen && rollen.length > 0 ? rollen[0] : 'UserMain';
        const teilnehmer: TakeTeilnehmerApiDto = new TakeTeilnehmerDto(rolle, kundenNummer);
        this.takeSpecific(teilnehmer);
    }

    get benutzerAsList(): Array<BenutzerInfoSearchResultApiDto> {
        return this.benutzer ? [this.benutzer] : [];
    }

    get headers(): Array<any> {
        return [
            {
                text: this.$t('benutzerSuche.table.teilnehmerId'),
                value: 'kundenNummer'
            },
            {
                text: this.$t('benutzerSuche.table.firma'),
                value: 'firma'
            },
            {
                text: this.$t('benutzerSuche.table.plz'),
                value: 'plz'
            },
            {
                text: this.$t('benutzerSuche.table.ort'),
                value: 'ort'
            },
            {
                text: this.$t('benutzerSuche.table.email'),
                value: 'userSignInEmail'
            },
            {
                text: this.$t('benutzerSuche.table.rollen'),
                value: 'rollen'
            },
            {
                text: '',
                value: 'burgermenu',
                align: 'center',
                width: '10',
                sortable: false
            }
        ];
    }

    created() {
        this.clearSearch();
        this.setSubTitle(this.$t('benutzerSuche.subtitle'));
        this.setFooterTitle('S915');
        if (!this.hasAccess('Navigation.TakeTeilnehmer')) {
            this.setAccessDenied(true);
            this.alertMessage({ text: this.$t('errormessage.noaccesspage') });
        } else {
            this.setAccessDenied(false);
        }
    }

    private clearSearch() {
        this.filterData.userSignInEmail = '';
        this.applyFilters(this.filterData);
        this.clearFilter();
    }

    private removeFilter(value: string) {
        this.filters = this.filters.filter(function(obj) {
            return obj.key !== value;
        });

        this.applyFilters(this.filterData);
        this.getBenutzer();
    }

    private customSort(items: any[]) {
        return items;
    }

    private searchEnter() {
        this.applyFilters(this.filterData);
        this.getBenutzer();
    }
}
</script>

<style></style>
