<template>
    <v-dialog v-model="visible" persistent max-width="600">
        <template v-slot:activator="scope">
            <slot name="activatorElement" v-bind="scope">
                <v-btn v-on="scope.on" color="primary" v-text="$t('components.confirmDialog.activator')" />
            </slot>
        </template>
        <FocusLock>
            <v-card>
                <v-card-title class="headline font-weight-bold mb-2" v-text="$t(title)" />
                <v-card-text v-text="$t(text)" />
                <v-card-actions class="ml-4 mt-0">
                    <v-btn
                        ref="submitButton"
                        @click="confirm"
                        color="primary"
                        class="modalbutton mb-3"
                        v-text="$t('components.confirmdialog.confirm')"
                    />
                    <v-btn
                        @click="visible = false"
                        class="modalbutton mb-3"
                        v-text="$t('components.confirmdialog.cancel')"
                    />
                </v-card-actions>
            </v-card>
        </FocusLock>
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class ConfirmDialog extends Vue {
    @Prop({ required: true, type: String })
    private title!: string;
    @Prop({ required: true, type: String })
    private text!: string;

    $refs!: {
        submitButton: any;
    };

    private visible = false;

    private confirm() {
        this.visible = false;
        this.$emit('confirm');
    }

    @Watch('visible')
    onConfirmDialogOpen() {
        if (this.visible) {
            //Der Dialog wird nach öffnen ganz fokussiert, daher muss ein kurzes Timeout gesetzt werden, statt $nextTick
            setTimeout(() => {
                this.$refs.submitButton.$el.focus();
            }, 20);
        }
    }
}
</script>
