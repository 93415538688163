import { IRegistrationService } from './IRegistrationService';
import BaseService from '@/services/baseService';
import { AxiosResponse } from 'axios';
import { RegisterDto } from '@/types/UserDtos';

export class RegistrationService extends BaseService implements IRegistrationService {
    private RESOURCE_URL: string;
    constructor() {
        super();
        this.RESOURCE_URL = `${this.BASE_URL}register`;

        console.debug('RegistrationService::constructor', this.RESOURCE_URL);
    }
    async requestCreateTeilnehmer(teilnehmer: RegisterDto): Promise<AxiosResponse<boolean>> {
        return super.POST<RegisterDto>(`${this.RESOURCE_URL}`, teilnehmer).then(response => {
            return response;
        });
    }
}

export const registrationService = new RegistrationService();
