import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IResourceState } from './state';
import { NAME } from './';

import { LANGUAGE, NEW_LOADED_LANGUAGE } from './mutations';
import { resourceService } from '@/services/resourceService';

export const LOAD_LANGUAGE = 'loadLanguage';

export const actions: ActionTree<IResourceState, IRootState> = {
    // eslint-disable-next-line
    async [LOAD_LANGUAGE]({ commit, state }: any, language: string) {
        console.debug(NAME + '::' + LOAD_LANGUAGE);

        // If the language was already loaded
        if (state.loadedLanguages.includes(language)) {
            console.log(NAME + '::' + LOAD_LANGUAGE + ' language already loaded, no request to server needed');
            return Promise.resolve(commit(LANGUAGE, language));
        }

        return resourceService.get(language).then(response => {
            commit(NEW_LOADED_LANGUAGE, { language: language, value: response });
            commit(LANGUAGE, language);
        });
    },
};
