import { TeilnehmerApiDto } from '@/api-schema';

export interface IRegistrationState {
    registrierungData: TeilnehmerApiDto;
}

export const state: IRegistrationState = {
    registrierungData: {
        kundenNummer: 0,
        plz: 0,
        anredeTypCode: '',
        titelTypCode: '',
        name: '',
        vorname: '',
        firma: '',
        strasse: '',
        zusatz: '',
        ort: '',
        telefon: '',
        telefonMobil: '',
        sprache: '',
        email: '',
    }
};
