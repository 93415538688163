<template>
    <v-container fluid>
        <v-row>
            <v-col sm="12">
                <v-row>
                    <v-col sm="4">
                        <h3 class="pl-3">{{ $t('upload.table.dateiname') }}</h3>
                    </v-col>
                    <v-col sm="1">
                        <h3>{{ $t('upload.table.groesse') }}</h3>
                    </v-col>
                    <v-col sm="3">
                        <h3>{{ $t('upload.table.typ') }}</h3>
                    </v-col>
                    <v-col sm="3">
                        <h3>{{ $t('upload.table.schlagworte') }}</h3>
                    </v-col>
                </v-row>
                <div v-for="(row, index) in uploadFiles" :key="index">
                    <UploadRow :upload-file="row" :typen="dokumentTypen" @remove="removeFile" />
                </div>
                <v-card>
                    <v-row>
                        <v-col sm="4">
                            <!-- ShowFileList ist false da sie hier selbst erstellt wird -->
                            <!-- instantupload true, da fileliste extern verwaltet wird -->
                            <Upload
                                id="uploadZone"
                                :show-filelist="false"
                                :multiple="true"
                                :instant-upload="true"
                                @input="saveFileInArrayDropzone"
                                class="pl-3"
                            >
                                <template v-slot:button><VEmpty /></template>
                            </Upload>
                        </v-col>
                        <v-col sm="1" />
                        <v-col sm="3">
                            <v-select
                                class="mt-5"
                                v-model="setDokumentTyp"
                                :items="dokumentTypen"
                                dense
                                hide-details
                            ></v-select>
                        </v-col>
                        <v-col sm="3">
                            <v-combobox
                                class="mt-3"
                                v-model="selectedSchlagworte"
                                :items="schlagwortSelection"
                                :search-input.sync="schlagwortSearchString"
                                :label="$t('upload.table.schlagworte')"
                                :hide-no-data="!schlagwortSearchString"
                                @keyup.space="addNewSchlagwort(schlagwortSearchString)"
                                @blur="addNewSchlagwort(schlagwortSearchString)"
                                append-icon=""
                                hide-selected
                                solo
                                multiple
                                dense
                            >
                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                    <v-chip v-bind="attrs" :input-value="selected" label small>
                                        <span class="pr-2">
                                            {{ item }}
                                        </span>
                                        <v-icon small @click="parent.selectItem(item)"> x </v-icon>
                                    </v-chip>
                                </template>
                                <template v-slot:item="{ index, item }">
                                    <v-text-field
                                        v-if="item === schlagwortEditText"
                                        v-model="schlagwortEditText"
                                        autofocus
                                        flat
                                        background-color="transparent"
                                        hide-details
                                        solo
                                    ></v-text-field>
                                    <div>
                                        {{ item }}
                                    </div>
                                    <v-spacer></v-spacer>
                                    <v-list-item-action @click.stop>
                                        <v-btn icon @click.stop.prevent="initDeleteSchlagwort(index, item)">
                                            <v-icon>{{
                                                item !== schlagwortEditText ? 'mdi-delete' : 'mdi-check'
                                            }}</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </template>
                            </v-combobox>
                        </v-col>
                    </v-row>
                </v-card>
                <input id="fileUpload" type="file" @input="saveFileInArrayButton" multiple hidden />
                <v-row class="mt-5">
                    <v-col sm="2 pt-0 pb-5">
                        <v-btn v-if="uploadFiles.length < 1" large color="primary" @click="chooseFiles()">{{
                            $t('upload.button.auswaehlen')
                        }}</v-btn>
                        <v-btn v-else large color="primary" @click="uploadDokumenteWrapper">
                            {{ $t('upload.button.confirm') }}</v-btn
                        >
                    </v-col>
                    <v-col sm="8 pt-0 pb-5">
                        <i>{{ $t('upload.remarks') }}</i> <br />
                        <i>{{ allowedFileTypes.join(', ') }}</i>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Debounce } from 'vue-debounce-decorator';

import { NAME as NAME_UPLOAD } from '../store';
import { NAME as NAME_GENERAL } from '@/store/modules/general';
import { NAME as NAME_HEADER } from '@/store/modules/headerNotification';
import { NAME as NAME_SECURITY } from '@/store/modules/security';
import { NAME as NAME_RESOURCE } from '@/store/modules/resource';

const uploadModul = namespace(NAME_UPLOAD);
const generalModul = namespace(NAME_GENERAL);
const headerModul = namespace(NAME_HEADER);
const securityModul = namespace(NAME_SECURITY);
const resourceModul = namespace(NAME_RESOURCE);

import { GET_LANGUAGE } from '@/store/modules/resource/getters';
import { HASACCESS } from '@/store/modules/security/getters';
import { SETACCESSDENIED } from '@/store/modules/security/mutations';
import { SUBTITLE, FOOTERTITLE } from '@/store/modules/general/mutations';
import {
    GET_DOKUMENT_TYPEN,
    UPLOAD_DOKUMENT,
    UPLOAD_DOKUMENTE,
    GET_SCHLAGWORTE,
    CREATE_SCHLAGWORT,
    DELETE_SCHLAGWORT
} from '../store/actions';
import { DOKUMENTTYPEN, SCHLAGWORTVORSCHLAEGE } from '../store/getters';
import { NEW_ERRORMESSAGES, CLOSE } from '@/store/modules/headerNotification/actions';

import { NAME as NAME_VALIDATION } from '@/store/modules/validation';
const validationModul = namespace(NAME_VALIDATION);
import { GET_ALLOWED_FILETYPES } from '@/store/modules/validation/getters';

import UploadRow from './UploadRow.vue';
import { DokumentUploadDto } from '@/types/DokumentUploadDto';
import Upload from '@/components/Upload.vue';
import VEmpty from '@/components/VEmpty.vue';
import i18n from '@/plugins/i18n';
@Component({
    components: {
        UploadRow,
        Upload,
        VEmpty
    }
})
export default class DokumentUpload extends Vue {
    @generalModul.Mutation(SUBTITLE) setSubTitle!: Function;
    @generalModul.Mutation(FOOTERTITLE) setFooterTitle!: Function;

    @uploadModul.Action(GET_DOKUMENT_TYPEN) getDokumentTypen!: Function;
    @uploadModul.Action(UPLOAD_DOKUMENT) uploadDokument!: Function;
    @uploadModul.Action(UPLOAD_DOKUMENTE) uploadDokumente!: Function;
    @uploadModul.Action(GET_SCHLAGWORTE) getSchlagworte!: Function;
    @uploadModul.Action(CREATE_SCHLAGWORT) createSchlagwort!: Function;
    @uploadModul.Action(DELETE_SCHLAGWORT) deleteSchlagwort!: Function;
    @uploadModul.Getter(DOKUMENTTYPEN) dokumentTypen!: Array<object>;
    @uploadModul.Getter(SCHLAGWORTVORSCHLAEGE) schlagwortSelection!: Array<string>;

    @headerModul.Action(NEW_ERRORMESSAGES) alertMessage!: Function;
    @headerModul.Action(CLOSE) closeHeadermessage!: Function;

    @securityModul.Getter(HASACCESS) hasAccess!: Function;
    @securityModul.Mutation(SETACCESSDENIED) setAccessDenied!: Function;
    @resourceModul.Getter(GET_LANGUAGE) language!: string;

    @validationModul.Getter(GET_ALLOWED_FILETYPES) allowedFileTypes!: Array<string>;

    uploadFiles: Array<DokumentUploadDto> = [];
    setDokumentTyp = 'archiv.dokumenttyp.eigenesdok';
    setSchlagworte = '';

    schlagwortSearchString = '';
    schlagwortEditText = '';
    selectedSchlagworte = [];

    created() {
        this.setSubTitle(this.$t('upload.title'));
        this.setFooterTitle('S310');
    }

    mounted() {
        if (!this.hasAccess('Navigation.Dokumentupload')) {
            this.setAccessDenied(true);
            this.alertMessage({ text: this.$t('errormessage.noaccesspage') });
        } else {
            this.setAccessDenied(false);
            this.getDokumentTypen();
        }
    }

    get sizeOfAllFilesTooLarge() {
        const sizeOfAllFiles = this.uploadFiles.map(doc => doc.datei.size).reduce((a, b) => a + b);
        return sizeOfAllFiles > 52428800 * 4;
    }

    uploadDokumenteWrapper() {
        this.closeHeadermessage();

        if (this.sizeOfAllFilesTooLarge) {
            this.alertMessage({ errorMessages: i18n.t('upload.errormessage.maxuploadlimit') });
        } else {
            this.uploadDokumente(this.uploadFiles);
        }
    }

    chooseFiles() {
        const element = document.getElementById('fileUpload');
        if (element !== null) {
            element.click();
        }
    }

    saveFileInArrayButton() {
        const element = document.getElementById('fileUpload') as HTMLInputElement;
        if (element.files !== null) {
            this.saveFileInArrayDropzone(Array.from(element.files));
            element.files = null;
        }
    }

    saveFileInArrayDropzone(files: Array<File>) {
        this.closeHeadermessage();
        if (files.some(file => file.size > 52428800)) {
            var wrongSizeFiles = files.filter(file => file.size > 52428800);
            this.alertMessage({
                errorMessages:
                    i18n.t('upload.errormessage.maxfilesize') + ' ' + wrongSizeFiles.map(file => file.name).join(', ')
            });
        } else if (files.some(file => !this.checkFileType(file))) {
            var wrongTypeFiles = files.filter(file => !this.checkFileType(file));
            this.alertMessage({
                errorMessages: i18n.t('errormessage.dokument.typ', [
                    wrongTypeFiles.map(file => this.getFileType(file)).join(', ')
                ])
            });
        } else {
            files.forEach(file => {
                const temp = new DokumentUploadDto(file);
                temp.dokumentTyp = this.setDokumentTyp;
                temp.schlagworte = this.setSchlagworte;
                temp.schlagwortVorschlaege = this.selectedSchlagworte;
                this.uploadFiles.push(temp);
            });
        }
        this.setDokumentTyp = 'archiv.dokumenttyp.eigenesdok';
        this.setSchlagworte = '';
        this.selectedSchlagworte = [];
    }
    getFileType(file: File) {
        return file.name.split('.').pop();
    }
    checkFileType(file: File) {
        let isacceptedFileType = false;
        const fileType = this.getFileType(file);

        this.allowedFileTypes.forEach(function (element) {
            const acceptFileType = element.replace('.', '');

            if (fileType && acceptFileType.toLowerCase() === fileType.toLowerCase()) {
                isacceptedFileType = true;
            }
        });
        return isacceptedFileType;
    }

    removeFile(uuid: string) {
        this.uploadFiles = this.uploadFiles.filter((file: DokumentUploadDto) => {
            return file.uuid !== uuid;
        });
    }

    addNewSchlagwort(item: string) {
        const itemText = item.trim();
        if (itemText === '') {
            this.schlagwortSearchString = '';
            return;
        }
        this.selectedSchlagworte.push(itemText);
        this.setSchlagworte = this.selectedSchlagworte.join(' ');
        if (!this.schlagwortSelection.includes(itemText)) {
            this.createSchlagwort(item);
        }
        this.schlagwortSearchString = '';
    }

    initDeleteSchlagwort(index, item) {
        this.deleteSchlagwort(item).then(response => {
            if (response.isSuccess) {
                const index = this.schlagwortSelection.indexOf(item);
                if (index > -1) {
                    this.schlagwortSelection.splice(index, 1);
                }
            }
        });
    }

    @Watch('language')
    onLanugageChanged() {
        this.getDokumentTypen();
    }

    @Watch('selectedSchlagworte')
    onSelectedSchlagworteChanged(newVal: any, oldVal: any) {
        this.setSchlagworte = this.selectedSchlagworte.join(' ');
        if (newVal.length === oldVal.length || newVal.length < oldVal.length) {
            return;
        }

        if (this.schlagwortSelection.includes(newVal[newVal.length - 1])) {
            return;
        }

        //Dieser Teil wird nur Ausgeführt wenn ein neues Schlagwort hinzugefügt wird.
        if (newVal[newVal.length - 1] != undefined) {
            this.createSchlagwort(newVal[newVal.length - 1]);
        }
    }

    @Watch('schlagwortSearchString')
    @Debounce(400)
    onSearchStringChanged() {
        if (this.schlagwortSearchString != null) {
            const multiSchlagworte = this.schlagwortSearchString.split(' ');
            if (multiSchlagworte.length > 1) {
                multiSchlagworte.forEach(element => {
                    this.addNewSchlagwort(element);
                });
            }
            if (this.schlagwortSearchString.trim() != '') {
                this.getSchlagworte(this.schlagwortSearchString);
            }
        }
    }
}
</script>
