import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { IArchivState } from './state';

export const GETDOKUMENTE = 'getDokumentList';
export const GETEDITDOKUMENT = 'getEditDokument';
export const GETDOKUMENTTYPES = 'getDokumentTypes';
export const GETFILETYPES = 'getFileTypes';
export const GETERSTELLTVON = 'getErstelltVon';
export const LOADING = 'getLoading';
export const TOTAL_NUMBER_OF_RECORDS = 'getTotalNumberOfRecords';
export const SCHLAGWORTVORSCHLAEGE = 'getSchlagwortVorschlaege';

export const getters: GetterTree<IArchivState, IRootState> = {
    [GETDOKUMENTE]: state => state.dokumentList,
    [TOTAL_NUMBER_OF_RECORDS]: state => state.totalNumberOfRecords,
    [GETDOKUMENTTYPES]: state => state.dokumentTypes,
    [GETERSTELLTVON]: state => state.erstelltVon,
    [GETFILETYPES]: state => state.fileTypes,
    [LOADING]: state => state.loading,
    [SCHLAGWORTVORSCHLAEGE]: state => state.schlagwortVorschlaege
};
