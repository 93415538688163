import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { ITakeTeilnehmerState } from './state';

export const GETTAKETEILNEHMERDATA = 'getTakeTeilnehmerData';
export const GET_TEILNEHMER_DATA = 'getTeilnehmerList';
export const GET_TEILNEHMER_LOADING = 'getTeilnehmerLoading';
export const GET_ARTIKEL_LOADING = 'getArtikelLoading';
export const GET_ARTIKEL_DATA = 'getArtikelData';
export const GET_TEILNEHMER_TOTAL_NUMBER_OF_RECORDS = 'getTeilnehmerTotalNumberOfRecords';
export const GET_ARTIKEL_TOTAL_NUMBER_OF_RECORDS = 'getArtikelTotalNumberOfRecords';
export const GET_BENUTZER_DATA = 'GetBenutzerData';
export const GET_BENUTZER_LOADING = 'GetBenutzerLoading';
export const GET_BENUTZER_TOTAL_NUMBER_OF_RECORDS = 'getBenutzerTotalNumberOfRecords';
export const GETVETDATALOG = 'getVetdataLog';

export const getters: GetterTree<ITakeTeilnehmerState, IRootState> = {
    [GETTAKETEILNEHMERDATA]: state => state.teilnehmer,
    [GET_TEILNEHMER_DATA]: state => state.teilnehmerList,
    [GET_TEILNEHMER_LOADING]: state => state.teilnehmerLoading,
    [GET_TEILNEHMER_TOTAL_NUMBER_OF_RECORDS]: state => state.teilnehmerTotalNumberOfRecords,
    [GET_ARTIKEL_LOADING]: state => state.artikelLoading,
    [GET_ARTIKEL_DATA]: state => state.artikelList,
    [GET_ARTIKEL_TOTAL_NUMBER_OF_RECORDS]: state => state.artikelTotalNumberOfRecords,
    [GETVETDATALOG]: state => state.vetdataLog,
    [GET_BENUTZER_DATA]: state => state.benutzerInfoSearchResultApiDto,
    [GET_BENUTZER_LOADING]: state => state.benutzerLoading,
    [GET_BENUTZER_TOTAL_NUMBER_OF_RECORDS]: state => state.benutzerTotalNumberOfRecords
};
