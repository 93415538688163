import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { IUploadState } from './state';

export const DOKUMENTTYPEN = 'getDokumentTypes';
export const SCHLAGWORTVORSCHLAEGE = 'getSchlagwortVorschlaege';

export const getters: GetterTree<IUploadState, IRootState> = {
    [DOKUMENTTYPEN]: state => state.dokumentTypes,
    [SCHLAGWORTVORSCHLAEGE]: state => state.schlagwortVorschlaege
};