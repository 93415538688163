<template>
    <v-container fluid>
        <v-row>
            <v-col sm="12">
                <v-card>
                    <v-tabs v-model="tab" color="primary" background-color="grey lighten-3">
                        <v-tab class="font-weight-bold" v-for="(row, index) in tabList" :key="index">
                            {{ $t(row.kontoTypCode) }}
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item v-for="(row, index) in tabList" :key="index">
                            <v-card flat>
                                <v-card-text class="pb-0">
                                    <v-row>
                                        <v-col class="pb-0" sm="12">{{
                                            $t('kontobrowser.kontonummer') + '  ' + row.kontoNummer
                                        }}</v-col>
                                        <v-col v-if="row.saldoVorschau !== null" class="pt-2 pb-0" sm="12"
                                            >{{ $t('kontobrowser.saldovorschau') }}
                                        </v-col>
                                        <v-col
                                            v-if="
                                                row.saldoVorschau !== null &&
                                                    row.saldoVorschau.toString().charAt(0) !== '-'
                                            "
                                            sm="12"
                                            class="py-0 display-1"
                                        >
                                            {{ commafy(row.saldoVorschau.toFixed(2)) }}
                                        </v-col>
                                        <v-col
                                            v-else-if="row.saldoVorschau !== null"
                                            sm="12"
                                            class="py-0 display-1 red--text"
                                        >
                                            {{ commafy(row.saldoVorschau.toFixed(2)) }}
                                        </v-col>
                                        <v-col class="pt-2 pb-0" sm="12"
                                            >{{ $t('kontobrowser.saldoletzterfak') + '\n' }}
                                            <br />
                                            {{ '(' + row.kontoSaldoDatum + '):' }}</v-col
                                        >
                                        <v-col
                                            v-if="row.kontoSaldo.toString().charAt(0) !== '-'"
                                            sm="12"
                                            class="pt-0 display-1"
                                        >
                                            {{ commafy(row.kontoSaldo.toFixed(2)) }}</v-col
                                        >
                                        <v-col v-else sm="12" class="pt-0 display-1 red--text">
                                            {{ commafy(row.kontoSaldo.toFixed(2)) }}</v-col
                                        >
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-divider class="fat-divider my-3" />
                            <v-card flat>
                                <v-card-text>
                                    <v-row>
                                        <v-col sm="12">
                                            <v-row>
                                                <v-col class="pa-0">
                                                    <v-data-table
                                                        :headers="headers"
                                                        :items="tableList"
                                                        item-key="id"
                                                        hide-default-footer
                                                        :server-items-length="totalNumberOfRecords"
                                                        :options.sync="options"
                                                        class="elevation-1 nowhow-table no-border-table"
                                                        sort-by="buchungDatum"
                                                        :must-sort="true"
                                                        :custom-sort="customSort"
                                                    >
                                                        <template v-slot:top>
                                                            <TableHeader :filters="filters" @chipClosed="removeFilter">
                                                                <template>
                                                                    <v-text-field
                                                                        class="ml-5"
                                                                        v-if="showSearchBar"
                                                                        v-model="filterData.suchText"
                                                                        prepend-inner-icon="mdi-magnify"
                                                                        append-icon="mdi-close"
                                                                        @input="searchFieldInputChanged"
                                                                        @click:append="searchBarClosed"
                                                                        :label="$t('kontobrowser.suchfeld')"
                                                                        hide-details
                                                                        autofocus
                                                                    ></v-text-field>
                                                                    <v-spacer />
                                                                    <v-tooltip top color="secondary">
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn v-on="on" icon color="primary">
                                                                                <v-icon
                                                                                    v-if="!showSearchBar"
                                                                                    @click="
                                                                                        showSearchBar = !showSearchBar
                                                                                    "
                                                                                    >mdi-magnify</v-icon
                                                                                >
                                                                            </v-btn>
                                                                        </template>
                                                                        <div>
                                                                            <span>
                                                                                {{ $t('tableheader.tooltip.search') }}
                                                                            </span>
                                                                        </div>
                                                                    </v-tooltip>
                                                                    <v-tooltip top color="secondary">
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn
                                                                                v-on="on"
                                                                                icon
                                                                                color="primary"
                                                                                @click="
                                                                                    showFilterDrawer = !showFilterDrawer
                                                                                "
                                                                            >
                                                                                <v-icon>mdi-filter-outline</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <div>
                                                                            <span>
                                                                                {{ $t('tableheader.tooltip.filter') }}
                                                                            </span>
                                                                        </div>
                                                                    </v-tooltip>
                                                                </template>
                                                            </TableHeader>
                                                        </template>

                                                        <template v-slot:item.buchungBetrag="{ item }">
                                                            <span
                                                                v-if="item.buchungTypCode === 'Soll'"
                                                                class="red--text"
                                                                >-{{ commafy(item.buchungBetrag.toFixed(2)) }}</span
                                                            >
                                                            <span v-else>{{
                                                                commafy(item.buchungBetrag.toFixed(2))
                                                            }}</span>
                                                        </template>
                                                        <template v-slot:item.buchungText="{ item }">
                                                            <a
                                                                v-if="item.dokumentGuid !== null"
                                                                style="text-decoration: underline;"
                                                            >
                                                                <TextHighlights
                                                                    :text="item.buchungText"
                                                                    :search-text="filterData.suchText"
                                                                    @click="openRechnung(item.dokumentGuid)"
                                                                />
                                                            </a>
                                                            <TextHighlights
                                                                v-else
                                                                :text="item.buchungText"
                                                                :search-text="filterData.suchText"
                                                            />
                                                        </template>
                                                        <template v-slot:item.kontoSaldo="{ item }">
                                                            <span v-if="item.kontoSaldo < 0" class="red--text">{{
                                                                commafy(item.kontoSaldo.toFixed(2))
                                                            }}</span>
                                                            <span v-else>{{
                                                                commafy(item.kontoSaldo.toFixed(2))
                                                            }}</span>
                                                        </template>
                                                        <template v-slot:item.link="{ item }">
                                                            <v-btn
                                                                v-if="item.dokumentGuid !== null"
                                                                icon
                                                                @click="
                                                                    download({
                                                                        id: item.dokumentGuid,
                                                                        dokumentName: item.dokumentName
                                                                    })
                                                                "
                                                                :href="item.link"
                                                            >
                                                                <v-icon color="primary" v-text="'mdi-file-pdf'" />
                                                            </v-btn>
                                                        </template>
                                                    </v-data-table>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card>
            </v-col>
        </v-row>
        <ValidationObserver validate v-slot="{ invalid }">
            <FilterDrawer
                :closeable="!invalid"
                :visible.sync="showFilterDrawer"
                @cancel="cancelFilter()"
                @confirm="confirmFilter()"
                @clear="clearAllFilters(true)"
                @reset="clearAllFilters(false)"
            >
                <SingleFilter title="">
                    <template>
                        <v-radio-group class="mt-0" row v-model="radios">
                            <h5 class="pb-0">{{ $t('archiv.filter.title.datum') }}</h5>
                            <v-col sm="12">
                                <v-radio value="specificTime" :label="$t('kontobrowser.filter.specifictime')"></v-radio>
                            </v-col>
                            <v-col sm="12" class="pt-0">
                                <v-row>
                                    <v-col sm="6">
                                        <NowhowDatepicker
                                            v-model="filterData.buchungDatumVon"
                                            :disabled="radios !== 'specificTime'"
                                            label="archiv.filter.datum.von"
                                        >
                                        </NowhowDatepicker>
                                    </v-col>
                                    <v-col sm="6">
                                        <NowhowDatepicker
                                            v-model="filterData.buchungDatumBis"
                                            :disabled="radios !== 'specificTime'"
                                            label="archiv.filter.datum.bis"
                                        >
                                        </NowhowDatepicker>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col sm="12" class="pt-0">
                                <v-radio value="selectTime" :label="$t('kontobrowser.filter.selecttime')"></v-radio>
                            </v-col>
                            <v-col sm="12" class="pt-0 pb-8">
                                <v-select
                                    :disabled="radios !== 'selectTime'"
                                    filled
                                    dense
                                    hide-details
                                    :items="ddTimeItems"
                                    v-model="filterData.zeitspanne"
                                ></v-select>
                            </v-col>
                            <v-col sm="12" class="pt-0 pb-0">
                                <v-radio value="allTime" :label="$t('kontobrowser.filter.alltime')"></v-radio>
                            </v-col>
                        </v-radio-group>
                    </template>
                    <template>
                        <h5 class="pb-2">{{ $t('kontobrowser.filter.title.betrag') }}</h5>
                        <v-row class="pb-6 filterLine">
                            <v-col sm="6">
                                <ValidationProvider name="betragvon" v-slot="{ errors }">
                                    <v-text-field
                                        v-model="filterData.betragVon"
                                        @keypress="checkInputCharacter($event)"
                                        hide-details
                                        dense
                                        filled
                                        :label="$t('archiv.filter.datum.von')"
                                        :error-messages="errors"
                                    ></v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col sm="6">
                                <ValidationProvider name="betragbis" v-slot="{ errors }">
                                    <v-text-field
                                        v-model="filterData.betragBis"
                                        @keypress="checkInputCharacter($event)"
                                        :error-messages="errors"
                                        hide-details
                                        dense
                                        filled
                                        :label="$t('archiv.filter.datum.bis')"
                                    ></v-text-field>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </template>
                </SingleFilter>
            </FilterDrawer>
        </ValidationObserver>
    </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { mixins } from 'vue-class-component';
import { Debounce } from 'vue-debounce-decorator';
import { DateMixin } from '@/mixins/DateMixin';

import { NAME as NAME_HOME } from '../store';
import { NAME as NAME_GENERAL } from '@/store/modules/general';
import { NAME as NAME_SECURITY } from '@/store/modules/security';
import { NAME as NAME_HEADERNOTIFICATION } from '@/store/modules/headerNotification';
import { NAME as NAME_RESOURCE } from '@/store/modules/resource';

const homeModul = namespace(NAME_HOME);
const generalModul = namespace(NAME_GENERAL);
const securityModul = namespace(NAME_SECURITY);
const headerModul = namespace(NAME_HEADERNOTIFICATION);
const resourceModul = namespace(NAME_RESOURCE);

import { HomeFilterDto } from '@/types/HomeFilterDto';

import { GET_LANGUAGE } from '@/store/modules/resource/getters';
import { LOAD_VALUES, LOAD_TAB, DOWNLOAD_RECHNUNG, OPEN_RECHNUNG } from '../store/actions';
import { APPLYFILTER, CLEARFILTER } from '../store/mutations';
import { SUBTITLE, FOOTERTITLE } from '@/store/modules/general/mutations';
import { TABLEVALUES, TABVALUES, TOTAL_NUMBER_OF_RECORDS, LOADING } from '../store/getters';
import { HASACCESS } from '@/store/modules/security/getters';
import { SETACCESSDENIED } from '@/store/modules/security/mutations';
import { NEW_ALERT } from '@/store/modules/headerNotification/actions';

import { ZeitspanneFilterApiDto, KontoSaldoApiDto, TvsBuchungApiDto } from '@/api-schema';

import { ValidationProvider, ValidationObserver } from 'vee-validate';
import NowhowDatepicker from '@/components/NowhowDatepicker.vue';
import SingleFilter from '@/components/SingleFilter.vue';
import FilterDrawer from '@/components/FilterDrawer.vue';
import TableHeader from '@/components/TableHeader.vue';
import TextHighlights from '@/components/TextHighlight/TextHighlights.vue';
import { TableHeaderFilterItem } from '@/types/TableHeaderFilterItem';
@Component({
    components: {
        SingleFilter,
        FilterDrawer,
        TableHeader,
        NowhowDatepicker,
        ValidationObserver,
        ValidationProvider,
        TextHighlights
    }
})
export default class Home extends mixins(DateMixin) {
    filters: Array<TableHeaderFilterItem> = [];
    private expanded = [];
    private showFilterDrawer = false;
    private showSearchBar = false;
    private tab = null;
    private radios = 'selectTime';
    private radiosCopy = '';
    options: any = { sortDesc: [true], sortBy: ['buchungDatum'] };
    filterData: HomeFilterDto = new HomeFilterDto();
    filterDataCopy: HomeFilterDto = new HomeFilterDto();
    get ddTimeItems(): Array<object> {
        return [
            {
                text: this.$t('kontobrowser.filter.timeitems.letztezeit'),
                value: 'Last60Days' as ZeitspanneFilterApiDto
            },
            {
                text: this.$t('kontobrowser.filter.timeitems.aktuelljahr'),
                value: 'CurrentYear' as ZeitspanneFilterApiDto
            },
            { text: this.$t('kontobrowser.filter.timeitems.vorjahr'), value: 'LastYear' as ZeitspanneFilterApiDto }
        ];
    }
    get headers(): Array<any> {
        return [
            {
                text: this.$t('kontobrowser.table.datum'),
                value: 'buchungDatum'
            },
            {
                text: this.$t('kontobrowser.table.Kontobewegung'),
                value: 'buchungText'
            },
            {
                text: this.$t('kontobrowser.table.betrag'),
                value: 'buchungBetrag',
                align: 'right',
                sortable: false
            },
            {
                text: this.$t('kontobrowser.table.saldo'),
                value: 'kontoSaldo',
                align: 'right',
                sortable: false
            },
            {
                text: '',
                value: 'link',
                align: 'center',
                width: '150',
                sortable: false
            }
        ];
    }

    @generalModul.Mutation(SUBTITLE) setSubTitle!: Function;
    @generalModul.Mutation(FOOTERTITLE) setFooterTitle!: Function;

    @homeModul.Action(LOAD_VALUES) load!: Function;
    @homeModul.Action(LOAD_TAB) getTabs!: Function;
    @homeModul.Action(DOWNLOAD_RECHNUNG) download!: Function;
    @homeModul.Action(OPEN_RECHNUNG) openRechnung!: Function;
    @homeModul.Mutation(APPLYFILTER) applyFilters!: Function;
    @homeModul.Mutation(CLEARFILTER) clearFilter!: Function;
    @homeModul.Getter(TABLEVALUES) tableList!: Array<TvsBuchungApiDto>;
    @homeModul.Getter(TABVALUES) tabList!: Array<KontoSaldoApiDto>;
    @homeModul.Getter(LOADING) loading!: boolean;
    @homeModul.Getter(TOTAL_NUMBER_OF_RECORDS) totalNumberOfRecords!: number | null;

    @securityModul.Getter(HASACCESS) hasAccess!: Function;
    @securityModul.Mutation(SETACCESSDENIED) setAccessDenied!: Function;
    @resourceModul.Getter(GET_LANGUAGE) language!: string;

    @headerModul.Action(NEW_ALERT) alertMessage!: Function;

    created() {
        this.checkRedirectNeeded();
        if (this.hasAccess('Navigation.Kontobrowser')) {
            this.setAccessDenied(false);
            this.getTabs();
            this.setSubTitle(this.$t('kontobrowser.subtitle'));
            this.setFooterTitle('S200');
        }
    }

    mounted() {
        if (this.hasAccess('Navigation.Kontobrowser')) {
            this.filterData.zeitspanne = 'Last60Days';
            this.filters.push({
                key: 'archiv.filter.chip.title.datum',
                nameResourceKey: 'archiv.filter.chip.title.datum',
                value: this.$t('kontobrowser.filter.timeitems.letztezeit').toString()
            });
            this.applyFilters(this.filterData);
        }
    }

    @Debounce(400)
    searchFieldInputChanged() {
        this.applyFilters(this.filterData);
        this.load(0);
    }

    private ShowActiveFilters() {
        this.filters = [];
        if (this.radios === 'allTime') {
            this.filterData.buchungDatumVon = undefined;
            this.filterData.buchungDatumBis = undefined;
            this.filterData.zeitspanne = undefined;
        }
        if (this.radios === 'specificTime') {
            this.filterData.zeitspanne = undefined;

            let filterValue = '';
            if (this.filterData.buchungDatumVon && this.filterData.buchungDatumBis) {
                filterValue = this.filterData.buchungDatumVon + ' - ' + this.filterData.buchungDatumBis;
            } else if (this.filterData.buchungDatumVon) {
                filterValue = '<= ' + this.filterData.buchungDatumVon;
            } else if (this.filterData.buchungDatumBis) {
                filterValue = '>= ' + this.filterData.buchungDatumBis;
            }

            this.filters = this.filters.concat({
                key: 'archiv.filter.chip.title.datum',
                nameResourceKey: 'archiv.filter.chip.title.datum',
                value: filterValue
            });
        }
        if (this.radios === 'selectTime') {
            this.filterData.buchungDatumVon = undefined;
            this.filterData.buchungDatumBis = undefined;
            if (this.filterData.zeitspanne === 'Last60Days') {
                this.filters = this.filters.concat({
                    key: 'archiv.filter.chip.title.datum',
                    nameResourceKey: 'archiv.filter.chip.title.datum',
                    value: this.$t('kontobrowser.filter.timeitems.letztezeit').toString()
                });
            } else if (this.filterData.zeitspanne === 'CurrentYear') {
                this.filters = this.filters.concat({
                    key: 'archiv.filter.chip.title.datum',
                    nameResourceKey: 'archiv.filter.chip.title.datum',
                    value: this.$t('kontobrowser.filter.timeitems.aktuelljahr').toString()
                });
            } else if (this.filterData.zeitspanne === 'LastYear') {
                this.filters = this.filters.concat({
                    key: 'archiv.filter.chip.title.datum',
                    nameResourceKey: 'archiv.filter.chip.title.datum',
                    value: this.$t('kontobrowser.filter.timeitems.vorjahr').toString()
                });
            }
        }
        if (this.filterData.betragVon !== undefined && this.filterData.betragBis) {
            this.filters = this.filters.concat({
                key: 'kontobrowser.chip.betrag',
                nameResourceKey: 'kontobrowser.chip.betrag',
                value: this.filterData.betragVon + ' - ' + this.filterData.betragBis
            });
        }
    }

    private removeFilter(value: string) {
        this.filters = this.filters.filter(function(obj) {
            return obj.key !== value;
        });
        if (value === 'archiv.filter.chip.title.datum') {
            this.filterData.buchungDatumVon = undefined;
            this.filterData.buchungDatumBis = undefined;
            this.filterData.zeitspanne = undefined;
            this.radios = 'allTime';
        } else if (value === 'kontobrowser.chip.betrag') {
            this.filterData.betragVon = undefined;
            this.filterData.betragBis = undefined;
        }
        this.applyFilters(this.filterData);
        this.load(0);
    }

    private searchBarClosed() {
        this.showSearchBar = false;
        this.filterData.suchText = undefined;
        this.applyFilters(this.filterData);
        this.load(0);
    }

    private clearAllFilters(closeFilter: boolean) {
        this.radios = 'selectTime';
        this.filterData = new HomeFilterDto();
        this.filterData.buchungDatumVon = undefined;
        this.filterData.buchungDatumBis = undefined;
        this.filterData.zeitspanne = 'Last60Days';
        this.filterData.tvsKontoNummer = this.tabList[this.tab].kontoNummer.toString();
        if (closeFilter) {
            this.filters = [];
            this.clearFilter();
            this.applyFilters(this.filterData);
            this.showFilterDrawer = false;
        }
    }

    commafy(num: number) {
        const isNegativ = num.toString().charAt(0) === '-';
        const str = num.toString().split('.');
        if (isNegativ) {
            str[0] = str[0].slice(1, str[0].length);
        }
        for (let i = 3; i < str[0].length; i += 4) {
            str[0] = str[0].slice(0, str[0].length - i) + "'" + str[0].slice(str[0].length - i, str[0].length);
        }
        if (isNegativ) {
            return '-' + str.join('.');
        } else {
            return str.join('.');
        }
    }

    private customSort(items: any[]) {
        return items;
    }

    checkInputCharacter(evt: any) {
        evt = evt ? evt : window.event;
        const charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
            evt.preventDefault();
        } else {
            return true;
        }
    }

    private checkRedirectNeeded() {
        if (this.hasAccess('Navigation.Kontobrowser')) {
            return true;
        } else if (this.hasAccess('Navigation.Lieferantenrechnung')) {
            this.$router.push({ name: 'Lieferantenrechnungen' });
        } else if (this.hasAccess('Navigation.Dokumentarchiv')) {
            this.$router.push({ name: 'Dokumentarchiv' });
        } else if (this.hasAccess('Navigation.TakeTeilnehmer')) {
            this.$router.push({ name: 'TakeTeilnehmer' });
        }
    }

    private confirmFilter() {
        this.ShowActiveFilters();
        this.applyFilters(this.filterData);
        this.load(0);
        this.showFilterDrawer = false;
    }

    private cancelFilter() {
        this.filterData = Object.assign({}, this.filterDataCopy);
        this.radios = this.radiosCopy;
        this.showFilterDrawer = false;
    }

    @Watch('options')
    onOptionsChanged() {
        this.filterData.sortBy = this.options.sortBy[0];
        this.filterData.sortOrder = this.options.sortDesc[0] ? 'Descending' : 'Ascending';
        this.applyFilters(this.filterData);
        this.load(0);
    }

    @Watch('showFilterDrawer')
    onFilterDrawerOpened() {
        if (this.showFilterDrawer) {
            this.filterDataCopy = Object.assign({}, this.filterData);
            this.radiosCopy = this.radios;
        }
    }

    @Watch('tab')
    onTabChanged() {
        this.filters = [];
        this.clearAllFilters(true);
        this.filterData.tvsKontoNummer = this.tabList[this.tab].kontoNummer.toString();
        this.ShowActiveFilters();
        this.applyFilters(this.filterData);
        this.load(0);
    }

    @Watch('language')
    onLanugageChanged() {
        this.ShowActiveFilters();
    }
}
</script>
