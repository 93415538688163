import { MutationTree } from 'vuex';
import { IModalConfirmationState } from './state';

export const BUTTONTEXT = 'buttonText';
export const ONCONFIRMED = 'onConfirmed';
export const ONCONFIRMEDPARAMETER = 'onConfirmedParameter';
export const TEXT = 'text';
export const TITLE = 'title';
export const VISIBLE = 'visible';

export const mutations: MutationTree<IModalConfirmationState> = {
    [BUTTONTEXT](state, buttonText) {
        state.buttonText = buttonText;
    },
    [ONCONFIRMED](state, onConfirmed) {
        state.onConfirmed = onConfirmed;
    },
    [ONCONFIRMEDPARAMETER](state, onConfirmedParameter) {
        state.onConfirmedParameter = onConfirmedParameter;
    },
    [TEXT](state, text) {
        state.text = text;
    },
    [TITLE](state, title) {
        state.title = title;
    },
    [VISIBLE](state, visible) {
        state.visible = visible;
    }
};
