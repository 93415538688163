import { MutationTree } from 'vuex';
import { IArchivState } from './state';
import { DokumentApiDto } from '@/api-schema';
import { DokumentFilterDto } from '@/types/DokumentFilterDto';
import i18n from '@/plugins/i18n';

export const MAPDOKUMENTE = 'mapDokumentList';
export const ADDDOKUMENTE = 'addDokumentToList';
export const MAPDOKUMENTTYPES = 'mapDokumentTypes';
export const MAPFILETYPES = 'mapFileTypes';
export const MAPERSTELLTVON = 'mapErstelltVon';
export const CLEARFILTER = 'clearFilter';
export const APPLYFILTER = 'mapFilter';
export const LOADING = 'mapLoading';
export const TOTAL_NUMBER_OF_RECORDS = 'mapTotalNumberOfRecords';
export const OFFSET = 'mapOffset';
export const MAPSCHLAGWORTVORSCHLAEGE = 'mapSchlagwortVorschlaege';

export const mutations: MutationTree<IArchivState> = {
    [MAPDOKUMENTE](state, value: Array<DokumentApiDto>) {
        state.dokumentList = value;
    },
    [TOTAL_NUMBER_OF_RECORDS](state, value: number) {
        state.totalNumberOfRecords = value;
    },
    [OFFSET](state, value: number) {
        state.queryFilter.offset = value;
    },
    [ADDDOKUMENTE](state, value: Array<DokumentApiDto>) {
        state.dokumentList = state.dokumentList.concat(value);
    },
    [MAPDOKUMENTTYPES](state, values: Array<string>) {
        const result: Array<object> = [];
        values.forEach((val) => {
            result.push({ text: i18n.t(val).toString(), value: val });
        });
        state.dokumentTypes = result;
    },
    [MAPFILETYPES](state, values: Array<string>) {
        const result: Array<object> = [];
        values.forEach((val) => {
            result.push({ text: i18n.t(val).toString(), value: val });
        });
        state.fileTypes = result;
    },
    [MAPERSTELLTVON](state, values: Array<string>) {
        const result: Array<object> = [];
        values.forEach((val) => {
            result.push({ text: i18n.t(val).toString(), value: val });
        });
        state.erstelltVon = result;
    },
    [CLEARFILTER](state) {
        state.queryFilter = new DokumentFilterDto();
    },
    [APPLYFILTER](state, value: DokumentFilterDto) {
        state.queryFilter.dateFrom = value.dateFrom;
        state.queryFilter.dateTo = value.dateTo;
        state.queryFilter.zeitspanne = value.zeitspanne;
        state.queryFilter.dokumentTyp = value.dokumentTyp;
        state.queryFilter.fileTyp = value.fileTyp;
        state.queryFilter.erstelltVon = value.erstelltVon;
        state.queryFilter.schlagworte = value.schlagworte;
        state.queryFilter.bezeichnung = value.bezeichnung;
        state.queryFilter.sortBy = value.sortBy;
        state.queryFilter.sortOrder = value.sortOrder;
        state.queryFilter.offset = 0;
    },
    [LOADING](state, value: boolean) {
        state.loading = value;
    },
    [MAPSCHLAGWORTVORSCHLAEGE](state, values: Array<string>) {
        state.schlagwortVorschlaege = values;
    }
};
