<template>
    <v-container fluid>
        <v-row>
            <v-col sm="12" class="pl-5 pt-4 pb-0">
                <a href="https://www.vetdata.ch/aktive-lieferanten/" target="_blank">{{
                    $t('lieferantenrechnung.link.aktivelieferanten')
                }}</a>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-data-table
                    :headers="headers"
                    :items="lieferantenrechnungenList"
                    item-key="pdfDokumentGuid"
                    show-expand
                    single-expand
                    :expanded.sync="expanded"
                    :options.sync="options"
                    :loading="loading"
                    hide-default-footer
                    class="elevation-1 nowhow-table"
                    sort-by="rechnungDatum"
                    :server-items-length="totalNumberOfRecords"
                    @click:row="expandRow"
                    @item-expanded="expandRowIcon"
                    :must-sort="true"
                    :custom-sort="customSort"
                >
                    <template v-slot:top>
                        <TableHeader :filters="filters" @chipClosed="removeFilter">
                            <template>
                                {{ totalNumberOfRecords + ' ' + $t('archiv.table.header.items') }}
                                <v-text-field
                                    class="ml-5"
                                    v-if="showSearchBar"
                                    v-model="filterData.suchText"
                                    @input="searchFieldInputChanged"
                                    prepend-inner-icon="mdi-magnify"
                                    append-icon="mdi-close"
                                    @click:append="searchBarClosed"
                                    :label="$t('lieferantrechnungen.suchfeld')"
                                    hide-details
                                    autofocus
                                ></v-text-field>
                                <v-spacer />
                                <v-tooltip top color="secondary">
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" icon color="primary">
                                            <v-icon v-if="!showSearchBar" @click="showSearchBar = !showSearchBar"
                                                >mdi-magnify</v-icon
                                            >
                                        </v-btn>
                                    </template>
                                    <div>
                                        <span>
                                            {{ $t('tableheader.tooltip.search') }}
                                        </span>
                                    </div>
                                </v-tooltip>
                                <v-tooltip top color="secondary">
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-on="on"
                                            icon
                                            color="primary"
                                            @click="showFilterDrawer = !showFilterDrawer"
                                        >
                                            <v-icon>mdi-filter-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <div>
                                        <span>
                                            {{ $t('tableheader.tooltip.filter') }}
                                        </span>
                                    </div>
                                </v-tooltip>
                            </template>
                        </TableHeader>
                    </template>

                    <template v-slot:item.lieferantName="{ item }">
                        <TextHighlights :text="item.lieferantName" :search-text="filterData.suchText" />
                    </template>
                    <template v-slot:item.rechnungNummer="{ item }">
                        <TextHighlights :text="item.rechnungNummer" :search-text="filterData.suchText" />
                    </template>
                    <template v-slot:item.betrag="{ item }">
                        <span v-if="item.lieferantDokumentTyp === 'Rechnung'">
                            <span class="red--text">-{{ item.betrag.toFixed(2) }}</span>
                        </span>
                        <span v-else>
                            {{ item.betrag.toFixed(2) }}
                        </span>
                    </template>
                    <template v-slot:item.link="{ item }">
                        <v-btn
                            @click.stop
                            icon
                            :href="item.link"
                            @click="downloadBeleg({ id: item.pdfDokumentGuid, dokumentName: item.dokumentName })"
                        >
                            <v-icon color="primary" v-text="'mdi-download-outline'" />
                        </v-btn>
                    </template>

                    <template v-slot:expanded-item="{}">
                        <td></td>
                        <td :colspan="4">
                            <v-row>
                                <v-col>
                                    <v-data-table
                                        :headers="subHeaders"
                                        :items="lieferantenrechungDetails"
                                        disable-pagination
                                        item-key="produktNummer"
                                        class="grey lighten-2"
                                        hide-default-footer
                                    >
                                        <template v-slot:item.produkteNummer="{ item }">
                                            <TextHighlights
                                                :text="item.produkteNummer"
                                                :search-text="filterData.suchText"
                                            />
                                        </template>
                                        <template v-slot:item.produktBezeichnung="{ item }">
                                            <a v-if="item.produktNummerVetpoint" style="text-decoration: underline">
                                                <TextHighlights
                                                    :text="item.produktBezeichnung"
                                                    :search-text="filterData.suchText"
                                                    @click="openProduktNummerVetpointTab(item.produktNummerVetpoint)"
                                                />
                                            </a>
                                            <TextHighlights
                                                v-else
                                                :text="item.produktBezeichnung"
                                                :search-text="filterData.suchText"
                                                @click="openProduktNummerVetpointTab(item.produktNummerVetpoint)"
                                            />
                                        </template>
                                        <template v-slot:item.anzahl="{ item }">
                                            <span v-text="item.anzahl.toFixed(3)" />
                                        </template>
                                        <template v-slot:item.einheit="{ item }">
                                            <span v-if="language === 'de'">{{ item.einheitTextDe }} </span>
                                            <span v-if="language === 'fr'">{{ item.einheitTextFr }} </span>
                                        </template>
                                        <template v-slot:item.einzelPreis="{ item }">
                                            <span v-text="item.einzelPreis.toFixed(2)" />
                                        </template>
                                        <template v-slot:item.betragInklusivMwst="{ item }">
                                            <span v-text="item.betragInklusivMwst.toFixed(2)" />
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </td>
                    </template>
                </v-data-table>
                <scroll-loader class="mb-5" :loader-method="load">
                    <v-progress-circular indeterminate v-show="loading" />
                </scroll-loader>
            </v-col>
        </v-row>
        <FilterDrawer
            :visible.sync="showFilterDrawer"
            @cancel="cancelFilter()"
            @confirm="confirmFilter()"
            @clear="clearAllFilters(true)"
            @reset="clearAllFilters(false)"
        >
            <SingleFilter title="">
                <template>
                    <v-radio-group class="mt-0" row v-model="radios">
                        <h5 class="pb-0">{{ $t('archiv.filter.title.datum') }}</h5>
                        <v-col sm="12">
                            <v-radio value="specificTime" :label="$t('rechnungen.filter.label.specifictime')"></v-radio>
                        </v-col>
                        <v-col sm="12" class="pt-0">
                            <v-row>
                                <v-col sm="6">
                                    <NowhowDatepicker
                                        v-model="filterData.rechnungDatumVon"
                                        :disabled="radios !== 'specificTime'"
                                        label="archiv.filter.datum.von"
                                    >
                                    </NowhowDatepicker>
                                </v-col>
                                <v-col sm="6">
                                    <NowhowDatepicker
                                        v-model="filterData.rechnungDatumBis"
                                        :disabled="radios !== 'specificTime'"
                                        label="archiv.filter.datum.bis"
                                    >
                                    </NowhowDatepicker>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col sm="12" class="pt-0">
                            <v-radio value="selectTime" :label="$t('rechnungen.filter.label.selecttime')"></v-radio>
                        </v-col>
                        <v-col sm="12" class="pt-0 pb-8">
                            <v-select
                                :disabled="radios !== 'selectTime'"
                                filled
                                dense
                                hide-details
                                :items="ddTimeItems"
                                v-model="filterData.zeitspanne"
                            ></v-select>
                        </v-col>
                        <v-col sm="12" class="pt-0 pb-0">
                            <v-radio value="allTime" :label="$t('kontobrowser.filter.alltime')"></v-radio>
                        </v-col>
                    </v-radio-group>
                </template>
                <template>
                    <h5 class="pb-2">{{ $t('kontobrowser.filter.title.betrag') }}</h5>
                    <v-row class="pb-6 filterLine">
                        <v-col sm="6">
                            <v-text-field
                                v-model="filterData.betragVon"
                                hide-details
                                dense
                                filled
                                :label="$t('rechnungen.filter.label.betragvon')"
                            ></v-text-field>
                        </v-col>
                        <v-col sm="6">
                            <v-text-field
                                v-model="filterData.betragBis"
                                hide-details
                                dense
                                filled
                                :label="$t('rechnungen.filter.label.betragbis')"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </template>
            </SingleFilter>
        </FilterDrawer>
    </v-container>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { DateMixin } from '@/mixins/DateMixin';
import { ZeitspanneFilterApiDto, TvsLieferantRechnungApiDto, TvsLieferantRechnungDetailApiDto } from '@/api-schema';

import { NAME as NAME_GENERAL } from '@/store/modules/general';
const generalModul = namespace(NAME_GENERAL);
import { SUBTITLE, FOOTERTITLE } from '@/store/modules/general/mutations';

import { NAME as NAME_HEADERNOTIFICATION } from '@/store/modules/headerNotification';
const headerNotificationModul = namespace(NAME_HEADERNOTIFICATION);
import { NEW_ALERT } from '@/store/modules/headerNotification/actions';

import { NAME as NAME_SECURITY } from '@/store/modules/security';
const securityModul = namespace(NAME_SECURITY);
import { HASACCESS } from '@/store/modules/security/getters';
import { SETACCESSDENIED } from '@/store/modules/security/mutations';

import { NAME as NAME_RECHNUNGEN } from '../store';
const rechnungModul = namespace(NAME_RECHNUNGEN);
import { GET_ALL_RECHNUNGEN, GET_RECHNUNG_DETAILS, DOWNLOAD_BELEG } from '../store/actions';
import { APPLYFILTER, CLEARFILTER } from '../store/mutations';
import { LIEFERANTENRECHNUNGEN, LIEFERANTENRECHNUNGDETAILS, LOADING, TOTAL_NUMBER_OF_RECORDS } from '../store/getters';

import { NAME as NAME_RESOURCE } from '@/store/modules/resource';
const resourceModul = namespace(NAME_RESOURCE);
import { GET_LANGUAGE } from '@/store/modules/resource/getters';

import { RechnungFilterDto } from '@/types/RechnungFilterDto';

import SingleFilter from '@/components/SingleFilter.vue';
import FilterDrawer from '@/components/FilterDrawer.vue';
import TableHeader from '@/components/TableHeader.vue';
import NowhowDatepicker from '@/components/NowhowDatepicker.vue';
import TextHighlights from '@/components/TextHighlight/TextHighlights.vue';
import { Debounce } from 'vue-debounce-decorator';
import { TableHeaderFilterItem } from '@/types/TableHeaderFilterItem';
@Component({
    components: {
        SingleFilter,
        FilterDrawer,
        TableHeader,
        NowhowDatepicker,
        TextHighlights
    }
})
export default class Lieferantenrechnungen extends mixins(DateMixin) {
    @generalModul.Mutation(SUBTITLE) setSubTitle!: Function;
    @generalModul.Mutation(FOOTERTITLE) setFooterTitle!: Function;

    @headerNotificationModul.Action(NEW_ALERT) alertMessage!: Function;

    @rechnungModul.Action(GET_ALL_RECHNUNGEN) getAll!: Function;
    @rechnungModul.Action(GET_RECHNUNG_DETAILS) getDetails!: Function;
    @rechnungModul.Action(DOWNLOAD_BELEG) downloadBeleg!: Function;
    @rechnungModul.Getter(LIEFERANTENRECHNUNGEN) lieferantenrechnungenList!: Array<TvsLieferantRechnungApiDto>;
    @rechnungModul.Getter(LIEFERANTENRECHNUNGDETAILS)
    lieferantenrechungDetails!: Array<TvsLieferantRechnungDetailApiDto>;
    @rechnungModul.Getter(LOADING) loading!: boolean;
    @rechnungModul.Getter(TOTAL_NUMBER_OF_RECORDS) totalNumberOfRecords!: number;
    @rechnungModul.Mutation(CLEARFILTER) clearFilters!: Function;
    @rechnungModul.Mutation(APPLYFILTER) applyFilters!: Function;
    @securityModul.Getter(HASACCESS) hasAccess!: Function;
    @securityModul.Mutation(SETACCESSDENIED) setAccessDenied!: Function;
    @resourceModul.Getter(GET_LANGUAGE) language!: string;

    file = null;
    showSearchBar = false;
    searchField = '';
    filters: Array<TableHeaderFilterItem> = [];
    expanded = [];
    title = '';
    filterData: RechnungFilterDto = new RechnungFilterDto();
    filterDataCopy: RechnungFilterDto = new RechnungFilterDto();
    showFilterDrawer = false;
    radios = 'selectTime';
    radiosCopy = '';
    options: any = { sortDesc: [true], sortBy: ['rechnungDatum'] };
    ddTimeItems = [
        { text: this.$t('kontobrowser.filter.timeitems.letztezeit'), value: 'Last60Days' as ZeitspanneFilterApiDto },
        {
            text: this.$t('kontobrowser.filter.timeitems.letzte180tage'),
            value: 'Last180Days' as ZeitspanneFilterApiDto
        },
        { text: this.$t('kontobrowser.filter.timeitems.aktuelljahr'), value: 'CurrentYear' as ZeitspanneFilterApiDto },
        { text: this.$t('kontobrowser.filter.timeitems.vorjahr'), value: 'LastYear' as ZeitspanneFilterApiDto }
    ];
    get headers(): Array<any> {
        return [
            {
                text: this.$t('rechnungen.table.header.datum'),
                value: 'rechnungDatum'
            },
            {
                text: this.$t('rechnungen.table.header.lieferantname'),
                value: 'lieferantName'
            },
            {
                text: this.$t('rechnungen.table.header.rechnungnummer'),
                value: 'rechnungNummer'
            },
            {
                text: this.$t('rechnungen.table.header.betrag'),
                value: 'betrag',
                align: 'right'
            },
            {
                text: this.$t('rechnungen.table.header.beleg'),
                value: 'link',
                align: 'center',
                width: '150',
                sortable: false
            }
        ];
    }
    get subHeaders(): Array<any> {
        return [
            {
                text: this.$t('rechnungen.table.subheader.produktnummer'),
                value: 'produkteNummer'
            },
            { text: this.$t('rechnungen.table.subheader.bezeichnung'), value: 'produktBezeichnung' },
            { text: this.$t('rechnungen.table.subheader.anzahl'), value: 'anzahl', align: 'right' },
            { text: this.$t('rechnungen.table.subheader.einheit'), value: 'einheit' },
            { text: this.$t('rechnungen.table.subheader.einzelpreis'), value: 'einzelPreis', align: 'right' },
            { text: this.$t('rechnungen.table.subheader.betrag'), value: 'betragInklusivMwst', align: 'right' }
        ];
    }
    created() {
        this.setSubTitle(this.$t('lieferantenrechnung.subtitle'));
        this.setFooterTitle('S250');
    }

    mounted() {
        if (!this.hasAccess('Navigation.Lieferantenrechnung')) {
            this.setAccessDenied(true);
            this.alertMessage({ text: this.$t('errormessage.noaccesspage') });
        } else {
            this.setAccessDenied(false);
            this.filterData.zeitspanne = 'Last180Days';
            this.filters.push({
                key: 'archiv.filter.chip.title.datum',
                nameResourceKey: 'archiv.filter.chip.title.datum',
                value: this.$t('rechnungen.filter.chip.value.last180').toString()
            });
            this.applyFilters(this.filterData);
            this.getAll(0);
        }
    }

    private expandRow(row: never | TvsLieferantRechnungApiDto) {
        if (this.expanded.includes(row)) {
            this.expanded = [];
        } else {
            this.expanded = [];
            this.expanded.push(row);
            this.getDetails(row.xmlDokumentGuid);
        }
    }

    private openProduktNummerVetpointTab(produktNummerVetpoint: string) {
        if (produktNummerVetpoint != null) {
            window.open(`https://www.vetpoint.ch/ecommerce/control/product?product_id=${produktNummerVetpoint}`);
        }
    }

    private searchBarClosed() {
        this.showSearchBar = false;
        this.filterData.suchText = undefined;
        this.applyFilters(this.filterData);
        this.getAll(0);
    }

    private expandRowIcon(row: any) {
        if (this.expanded.includes(row.item)) {
            this.expanded = [];
        } else {
            this.expanded = [];
            this.expanded.push(row.item);
            this.getDetails(row.item.xmlDokumentGuid);
        }
    }

    @Debounce(400)
    searchFieldInputChanged() {
        this.applyFilters(this.filterData);
        this.getAll(0);
    }

    private ShowActiveFilters() {
        this.filters = [];
        if (this.radios === 'allTime') {
            this.filterData.rechnungDatumVon = undefined;
            this.filterData.rechnungDatumBis = undefined;
            this.filterData.zeitspanne = undefined;
        }
        if (this.radios === 'specificTime') {
            let filterValue = '';
            if (this.filterData.rechnungDatumVon && this.filterData.rechnungDatumBis) {
                filterValue = this.filterData.rechnungDatumVon + ' - ' + this.filterData.rechnungDatumBis;
            } else if (this.filterData.rechnungDatumVon) {
                filterValue = '<= ' + this.filterData.rechnungDatumVon;
            } else if (this.filterData.rechnungDatumBis) {
                filterValue = '>= ' + this.filterData.rechnungDatumBis;
            }

            this.filters = this.filters.concat({
                key: 'archiv.filter.chip.title.datum',
                nameResourceKey: 'archiv.filter.chip.title.datum',
                value: filterValue
            });
        }
        if (this.radios === 'selectTime') {
            this.filterData.rechnungDatumVon = undefined;
            this.filterData.rechnungDatumBis = undefined;
            if (this.filterData.zeitspanne === 'Last60Days') {
                this.filters = this.filters.concat({
                    key: 'archiv.filter.chip.title.datum',
                    nameResourceKey: 'archiv.filter.chip.title.datum',
                    value: this.$t('rechnungen.filter.chip.value.recent').toString()
                });
            } else if (this.filterData.zeitspanne === 'Last180Days') {
                this.filters = this.filters.concat({
                    key: 'archiv.filter.chip.title.datum',
                    nameResourceKey: 'archiv.filter.chip.title.datum',
                    value: this.$t('rechnungen.filter.chip.value.last180').toString()
                });
            } else if (this.filterData.zeitspanne === 'CurrentYear') {
                this.filters = this.filters.concat({
                    key: 'archiv.filter.chip.title.datum',
                    nameResourceKey: 'archiv.filter.chip.title.datum',
                    value: this.$t('rechnungen.filter.chip.value.currentyear').toString()
                });
            } else if (this.filterData.zeitspanne === 'LastYear') {
                this.filters = this.filters.concat({
                    key: 'archiv.filter.chip.title.datum',
                    nameResourceKey: 'archiv.filter.chip.title.datum',
                    value: this.$t('rechnungen.filter.chip.value.lastyear').toString()
                });
            }
        }
        if (this.filterData.betragVon !== undefined && this.filterData.betragBis) {
            this.filters = this.filters.concat({
                key: 'rechnungen.filter.chip.title.betrag',
                nameResourceKey: 'rechnungen.filter.chip.title.betrag',
                value: this.filterData.betragVon + ' - ' + this.filterData.betragBis
            });
        }
    }

    private customSort(items: any[]) {
        return items;
    }

    private removeFilter(value: string) {
        this.filters = this.filters.filter(function (obj) {
            return obj.key !== value;
        });
        if (value === 'archiv.filter.chip.title.datum') {
            this.filterData.rechnungDatumBis = undefined;
            this.filterData.rechnungDatumVon = undefined;
            this.filterData.zeitspanne = undefined;
            this.radios = 'allTime';
        } else if (value === 'rechnungen.filter.chip.title.betrag') {
            this.filterData.betragVon = undefined;
            this.filterData.betragBis = undefined;
        }
        this.applyFilters(this.filterData);
        this.getAll(0);
    }

    private clearAllFilters(closeFilter: boolean) {
        this.radios = 'selectTime';
        this.filterData = new RechnungFilterDto();
        this.filterData.rechnungDatumBis = undefined;
        this.filterData.rechnungDatumVon = undefined;
        this.filterData.zeitspanne = 'Last180Days';
        if (closeFilter) {
            this.clearFilters();
            this.filters = [];
            this.applyFilters(this.filterData);
            this.showFilterDrawer = false;
        }
    }

    load() {
        this.getAll(this.lieferantenrechnungenList.length);
    }

    private confirmFilter() {
        this.ShowActiveFilters();
        this.applyFilters(this.filterData);
        this.getAll(0);
        this.showFilterDrawer = false;
    }

    private cancelFilter() {
        this.filterData = Object.assign({}, this.filterDataCopy);
        this.radios = this.radiosCopy;
        this.showFilterDrawer = false;
    }

    @Watch('showFilterDrawer')
    onFilterDrawerOpened() {
        if (this.showFilterDrawer) {
            this.filterDataCopy = Object.assign({}, this.filterData);
            this.radiosCopy = this.radios;
        }
    }

    @Watch('options')
    onOptionsChanged() {
        this.filterData.sortBy = this.options.sortBy[0];
        this.filterData.sortBy =
            this.filterData.sortBy.substring(0, 1).toUpperCase() + this.filterData.sortBy.substring(1);
        this.filterData.sortOrder = this.options.sortDesc[0] ? 'Descending' : 'Ascending';
        this.applyFilters(this.filterData);
        this.getAll(0);
    }

    @Watch('language')
    onLanugageChanged() {
        this.ShowActiveFilters();
    }
}
</script>
