import { AxiosResponse } from 'axios';

export default class DownloadService {
    /**
     * Lädt die Datei in der Response herunter.
     * @param {AxiosResponse<File>} response Response
     * @param {string} defaultFileName Dateiname, falls die Response keinen Vorgibt.
     */
    public downloadFile(response: AxiosResponse<File>, defaultFileName: string) {
        const suggestedFileName = response.headers['x-suggested-filename'];
        const effectiveFileName = suggestedFileName === undefined ? defaultFileName : decodeURIComponent(suggestedFileName);

        console.log('fileName: ' + effectiveFileName);

        const mime = response.headers['content-type'];
        const blob = new Blob([response.data], { type: mime || 'application/octet-stream' });

        this.openFileSaveDialog(effectiveFileName, mime, blob);
    }



    /**
     * Lädt den Inhalt der Response als CSV-Datei herunter
     * @param {AxiosResponse<string>} response Response
     * @param {string} defaultFileName Dateiname, falls die Response keinen Vorgibt.
     */
    public downloadAsCsv(response: AxiosResponse<string>, defaultFileName: string) {
        const mime = 'text/csv';

        const blob = new Blob([response.data], { type: mime });

        this.openFileSaveDialog(defaultFileName, mime, blob);
    }

    /**
     * Öffnent die Datei in einem neuen Tab wenn der ContentTyp in der contentTypesToOpen Liste enthalten ist. Ansonsten wird die Datei herungergeladen
     * @param {AxiosResponse<File>} response Response
     * @param {string} defaultFileName Dateiname, falls die Response keinen Vorgibt.
     * @param {Array<string>} contentTypesToOpen Liste von ContentTypes welche geöffent werden sollen
     */
    public openOrDownloadFile(response: AxiosResponse<File>, defaultFileName: string, contentTypesToOpen: Array<string>) {
        const responseContentType = response.headers['content-type'];
        if (contentTypesToOpen.includes(responseContentType)) {
            const file = new Blob([response.data], { type: responseContentType });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        } else {
            this.downloadFile(response, defaultFileName);
        }
    }

    /**
     * Öffnent den Speichern-Diaolog
     * @param filename Den Namen der Datei
     * @param mime Typ der Datei
     * @param blob Inhalt der Datei
     */
    private openFileSaveDialog(filename: string, mime: string, blob: Blob) {
        const nav = (window.navigator as any);
        if (typeof nav.msSaveBlob !== 'undefined') {
            console.log('mssaveblob defined');

            // IE workaround for "HTML7007: One or more blob URLs were
            // revoked by closing the blob for which they were created.
            // These URLs will no longer resolve as the data backing
            // the URL has been freed."
            nav.msSaveBlob(blob, filename);
        } else {
            console.log('mssaveblob Undefined');
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    }
}

export const downloadService = new DownloadService();