import clone from 'clone';
import { IUploadService } from './IUploadService';
import BaseServie from '@/services/baseService';
import { AxiosResponse } from 'axios';
import { DokumentUploadDto } from '@/types/DokumentUploadDto';
import { SchlagwortUploadApiDto } from '@/api-schema';

export class UploadService extends BaseServie implements IUploadService {
    private RESOURCE_URL: string;
    private UPLOAD_URL: string;
    private MULTIUPLOAD_URL: string;
    private SCHLAGWORT_URL: string;
    constructor() {
        super();
        this.RESOURCE_URL = `${this.BASE_URL}dokumente`;
        this.UPLOAD_URL = `${this.RESOURCE_URL}/upload`;
        this.MULTIUPLOAD_URL = `${this.RESOURCE_URL}/multiupload`;
        this.SCHLAGWORT_URL = `${this.RESOURCE_URL}/schlagworte`;

        console.debug('ArchivService::constructor', this.RESOURCE_URL);
    }

    getSchlagwortVorschlaege(suchText: string): Promise<string[]> {
        return super.GET<Array<string>>(`${this.SCHLAGWORT_URL}/${suchText}`).then(response => {
            return response;
        });
    }

    addSchlagwortVorschlag(schlagwort: string): Promise<AxiosResponse<any>> {
        const schlagwortUploadObject = { schlagwort: schlagwort };
        return super.POST<SchlagwortUploadApiDto>(`${this.SCHLAGWORT_URL}`, schlagwortUploadObject);
    }

    deleteSchlagwortVorschlag(schlagwort: string): Promise<AxiosResponse<any>> {
        return super.DELETE(`${this.SCHLAGWORT_URL}/${schlagwort}`).then(response => {
            return response;
        });
    }

    async getDokumentTypen(): Promise<Array<string>> {
        return super.GET<Array<string>>(`${this.UPLOAD_URL}`).then(response => {
            return response;
        });
    }

    async uploadDokument(dokument: DokumentUploadDto, onUploadProgress?: (progressEvent: any) => void): Promise<AxiosResponse<any>> {
        const formData = new FormData();
        formData.append('dokument.datei', dokument.datei);
        formData.append('dokument.schlagworte', dokument.schlagworte);
        formData.append('dokument.dokumentTyp', dokument.dokumentTyp);
        // clonen damit nicht ursprüngliche Configs überschrieben werden
        const config = clone(this.uploadConfig);
        if (onUploadProgress) {
            config.onUploadProgress = onUploadProgress;
        }

        return super.POST<FormData>(`${this.RESOURCE_URL}`, formData, config).then(response => {
            return response;
        });
    }

    async uploadDokumente(dokumente: Array<DokumentUploadDto>, onUploadProgress?: (progressEvent: any) => void): Promise<AxiosResponse<any>> {
        const formData = new FormData();
        for (let i = 0; i < dokumente.length; i++) {
            const dokument = dokumente[i];
            formData.append('dokumente[' + i + '].datei', dokument.datei);
            formData.append('dokumente[' + i + '].schlagworte', dokument.schlagworte);
            formData.append('dokumente[' + i + '].dokumentTyp', dokument.dokumentTyp);
        }
        // clonen damit nicht ursprüngliche Configs überschrieben werden
        const config = clone(this.uploadConfig);
        if (onUploadProgress) {
            config.onUploadProgress = onUploadProgress;
        }

        return super.POST<FormData>(`${this.MULTIUPLOAD_URL}`, formData, config).then(response => {
            return response;
        });
    }
}

export const uploadService = new UploadService();
