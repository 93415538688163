import { IRootState } from '@/store';
import { GetterTree } from 'vuex';
import { IGeneralState, state } from './state';

export const TITLE = 'getTitle';
export const FOOTERTITLE = 'getFooterTitle';

export const getters: GetterTree<IGeneralState, IRootState> = {
    [TITLE]: state => state.subTitle,
    [FOOTERTITLE]: state => state.footerTitle
};
