<template>
    <v-overlay style="z-index: 9999;" :value="loadingSpinnerState.visible">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
        <div>{{ $t(loadingSpinnerState.text) }}</div>
    </v-overlay>
</template>

<script lang="ts">
import Vue from 'vue';
import { namespace } from 'vuex-class';

import { NAME as NAME_LOADINGSPINNER } from '@/store/modules/loadingSpinner';
const loadingSpinnerModul = namespace(NAME_LOADINGSPINNER);
import { ILoadingSpinnerState } from '@/store/modules/loadingSpinner/state';
import Component from 'vue-class-component';

@Component
export default class LoadingSpinner extends Vue {
    @loadingSpinnerModul.State(state => state) loadingSpinnerState!: ILoadingSpinnerState;
}
</script>

<style scoped>
.v-progress-circular {
    width: 100%;
}
</style>
