<template>
    <Bar :chart-id="id" :chart-data="chartData" :chart-options="options" />
</template>
<script lang="ts">
// import { HorizontalBar, mixins } from 'vue-chartjs-typescript';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs/legacy';

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

@Component({
    components: {
        Bar
    }
})
export default class BarChart extends Vue {
    @Prop({ required: true, type: String })
    id!: string;

    @Prop({ required: true, type: Object })
    chartData!: any;

    @Prop({ required: true, type: Object })
    options!: object;
}
</script>
