<template>
    <v-dialog v-model="modalVisible" persistent max-width="600">
        <v-card>
            <v-card-title class="headline font-weight-bold mb-2" v-text="$t('dialog.agbzustimmen.title')" />
            <v-card-text class="pb-0 pt-0">
                <span>
                    {{ $t('dialog.agbzustimmen.text1') }}
                    <u @click="agbLinkClicked" style="color: lightblue;">{{ $t('dialog.agbzustimmen.text2') }}</u>
                </span>
                <v-checkbox v-model="isSigned" :label="$t('dialog.agbzustimmen.checkbox.label')" hide-details />
            </v-card-text>
            <v-card-actions class="ml-4 mt-0">
                <v-btn
                    ref="submitButton"
                    :disabled="!isSigned"
                    @click="submitAgbDecision(true)"
                    color="primary"
                    class="modalbutton mb-3"
                    v-text="$t('components.confirmdialog.confirm')"
                />
                <v-btn
                    @click="submitAgbDecision(false)"
                    class="modalbutton mb-3"
                    v-text="$t('components.confirmdialog.cancel')"
                />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';

import { NAME as NAME_SECURITY } from '@/store/modules/security';
const securityModul = namespace(NAME_SECURITY);
import { CHECK_AGB_ACCEPTED } from '@/store/modules/security/actions';
import { GETMODALAGBVISIBLE } from '@/store/modules/security/getters';

@Component
export default class AgbConfirmDialog extends Vue {
    $refs!: {
        submitButton: any;
    };

    @securityModul.Getter(GETMODALAGBVISIBLE) modalVisible!: boolean;
    @securityModul.Action(CHECK_AGB_ACCEPTED) checkAgbAccepted!: Function;

    private isSigned = false;

    private submitAgbDecision(accepted: boolean) {
        this.checkAgbAccepted(accepted);
    }

    private agbLinkClicked() {
        this.$emit('openagblink');
    }
}
</script>
