import { IHomeService } from './IHomeService';
import BaseService from '@/services/baseService';
import { HomeFilterDto } from '@/types/HomeFilterDto';
import { TvsBuchungApiDtoSearchResponseApi, KontoSaldoApiDto } from '@/api-schema';
import { AxiosResponse } from 'axios';

export class HomeService extends BaseService implements IHomeService {
    private RESOURCE_URL: string;
    private BEWEGUNG_URL: string;
    private DOKUMENTE_URL: string;
    constructor() {
        super();
        this.RESOURCE_URL = `${this.BASE_URL}konto`;
        this.BEWEGUNG_URL = `${this.BASE_URL}konto/bewegungen`;
        this.DOKUMENTE_URL = `${this.BASE_URL}dokumente`;

        console.debug('HomeService::constructor', this.RESOURCE_URL);
    }

    async loadTabs(): Promise<Array<KontoSaldoApiDto>> {
        return super.GET<Array<KontoSaldoApiDto>>(`${this.RESOURCE_URL}`).then(response => {
            return response;
        });
    }
    async loadValues(filter: HomeFilterDto): Promise<TvsBuchungApiDtoSearchResponseApi> {
        return super
            .GET<TvsBuchungApiDtoSearchResponseApi>(`${this.BEWEGUNG_URL}/` + super.serializeObjectToQuerystring(filter))
            .then(response => {
                return response;
            });
    }
    async downloadRechnung(id: string): Promise<AxiosResponse<File>> {
        return super.DOWNLOAD<File>(`${this.DOKUMENTE_URL}/download/` + id).then(response => {
            return response;
        });
    }
}

export const homeService = new HomeService();
