<template>
    <v-dialog v-model="visible" width="500">
        <template v-slot:activator="{}">
            <v-list-item @click="open">
                <v-list-item-title v-text="$t('archiv.table.burgermenu.edit')" @click="burgermenuActive = false" />
            </v-list-item>
        </template>
        <FocusLock>
            <form @submit.prevent="submit">
                <v-card>
                    <v-card-title class="headline font-weight-bold mb-2">
                        {{ $t('archiv.editdialog.title') }}
                    </v-card-title>

                    <v-card-text class="pb-0">
                        <v-text-field
                            autofocus
                            :label="$t('archiv.table.header.dokumentname')"
                            :placeholder="$t('archiv.table.header.dokumentname')"
                            v-model="dokumentCopy.bezeichnung"
                        ></v-text-field>
                        <v-combobox
                            v-if="
                                dokumentTyp == 'archiv.dokumenttyp.eigenesdok' ||
                                    dokumentTyp == 'archiv.dokumenttyp.buchhaltung'
                            "
                            class="mt-3"
                            v-model="selectedSchlagworte"
                            :items="schlagwortSelection"
                            :search-input.sync="schlagwortSearchString"
                            :label="$t('upload.table.schlagworte')"
                            :hide-no-data="!schlagwortSearchString"
                            @keyup.space="addNewSchlagwort(schlagwortSearchString)"
                            @blur="addNewSchlagwort(schlagwortSearchString)"
                            append-icon=""
                            hide-selected
                            solo
                            multiple
                            dense
                        >
                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                <v-chip v-bind="attrs" :input-value="selected" label small>
                                    <span class="pr-2">
                                        {{ item }}
                                    </span>
                                    <v-icon small @click="parent.selectItem(item)">
                                        x
                                    </v-icon>
                                </v-chip>
                            </template>
                            <template v-slot:item="{ index, item }">
                                <v-text-field
                                    v-if="item === schlagwortEditText"
                                    v-model="schlagwortEditText"
                                    autofocus
                                    flat
                                    background-color="transparent"
                                    hide-details
                                    solo
                                ></v-text-field>
                                <div>
                                    {{ item }}
                                </div>
                                <v-spacer></v-spacer>
                                <v-list-item-action @click.stop>
                                    <v-btn icon @click.stop.prevent="initDeleteSchlagwort(index, item)">
                                        <v-icon>{{ item !== schlagwortEditText ? 'mdi-delete' : 'mdi-check' }}</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </template>
                        </v-combobox>
                        <v-text-field
                            v-else
                            :label="$t('archiv.table.header.schlagworte')"
                            :placeholder="$t('archiv.table.header.schlagworte')"
                            v-model="dokumentCopy.schlagworte"
                        ></v-text-field>
                        <v-text-field
                            :label="$t('archiv.editdialog.dateiname')"
                            :placeholder="$t('archiv.editdialog.dateiname')"
                            :value="fullDokumentName"
                            disabled
                            readonly
                        ></v-text-field>
                    </v-card-text>

                    <v-card-actions class="ml-4">
                        <v-btn color="primary" type="submit" class="modalbutton mb-3">
                            {{ $t('components.dialog.save') }}
                        </v-btn>
                        <v-btn @click="visible = false" class="modalbutton mb-3">
                            {{ $t('components.dialog.cancel') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </form>
        </FocusLock>
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { DokumentEditApiDto } from '@/api-schema';

import { namespace } from 'vuex-class';

import { NAME as NAME_ARCHIV } from '../store';
const archivModul = namespace(NAME_ARCHIV);

import {
    CREATE_SCHLAGWORT,
    DELETE_SCHLAGWORT,
    EDIT_DOKUMENT_INFO,
    GET_DOKUMENT,
    GET_SCHLAGWORTE
} from '../store/actions';
import { SCHLAGWORTVORSCHLAEGE } from '../store/getters';
import { Debounce } from 'vue-debounce-decorator';

@Component
export default class EditDokumentMetadataDialog extends Vue {
    @Prop({ required: true, type: Number })
    private dokumentId!: number;
    @Prop({ required: true, type: String })
    private fullDokumentName!: string;
    @Prop({ required: true, type: String })
    private dokumentTyp!: string;

    private visible = false;
    private dokumentCopy: DokumentEditApiDto = {};
    schlagwortSearchString = '';
    schlagwortEditText = '';
    selectedSchlagworte = [];

    @archivModul.Action(EDIT_DOKUMENT_INFO) edit!: Function;
    @archivModul.Action(GET_DOKUMENT) getDokument!: Function;
    @archivModul.Action(GET_SCHLAGWORTE) getSchlagworte!: Function;
    @archivModul.Action(CREATE_SCHLAGWORT) createSchlagwort!: Function;
    @archivModul.Action(DELETE_SCHLAGWORT) deleteSchlagwort!: Function;
    @archivModul.Getter(SCHLAGWORTVORSCHLAEGE) schlagwortSelection!: Array<string>;

    private onSave() {
        this.edit(this.dokumentCopy).then(() => {
            this.visible = false;
        });
    }

    submit() {
        this.onSave();
    }

    addNewSchlagwort(item: string) {
        const itemText = item.trim();
        if (itemText === '') {
            this.schlagwortSearchString = '';
            return;
        }
        this.selectedSchlagworte.push(itemText);
        this.dokumentCopy.schlagworte = this.selectedSchlagworte.join(' ');
        //Visible-Check, damit bei öffnen des Modal nicht alle Schlagworte überprüft werden.
        if (this.visible) {
            if (!this.schlagwortSelection.includes(itemText)) {
                this.createSchlagwort(item);
            }
        }
        this.schlagwortSearchString = '';
    }

    initDeleteSchlagwort(index, item) {
        this.deleteSchlagwort(item).then(response => {
            if (response.isSuccess) {
                const index = this.schlagwortSelection.indexOf(item);
                if (index > -1) {
                    this.schlagwortSelection.splice(index, 1);
                }
            }
        });
    }

    private open() {
        this.$emit('open');
        this.getDokument(this.dokumentId).then((response: any) => {
            this.dokumentCopy = Object.assign({}, response);
            this.selectedSchlagworte = [];
            this.schlagwortSearchString = '';
            if (this.dokumentCopy.schlagworte != null) {
                this.schlagwortSearchString = this.dokumentCopy.schlagworte;
                const multiSchlagworte = this.dokumentCopy.schlagworte.split(' ');
                if (multiSchlagworte.length > 1) {
                    multiSchlagworte.forEach(element => {
                        this.addNewSchlagwort(element);
                    });
                } else {
                    this.addNewSchlagwort(multiSchlagworte[0]);
                }
            }
            this.visible = true;
        });
    }

    @Watch('selectedSchlagworte')
    onSelectedSchlagworteChanged(newVal: any, oldVal: any) {
        if (this.visible) {
            this.dokumentCopy.schlagworte = this.selectedSchlagworte.join(' ');
            if (newVal.length === oldVal.length || newVal.length < oldVal.length) {
                return;
            }

            if (this.schlagwortSelection.includes(newVal[newVal.length - 1])) {
                return;
            }

            //Dieser Teil wird nur Ausgeführt wenn ein neues Schlagwort hinzugefügt wird.
            if (newVal[newVal.length - 1] != undefined) {
                this.createSchlagwort(newVal[newVal.length - 1]);
            }
        }
    }

    @Watch('schlagwortSearchString')
    @Debounce(400)
    onSearchStringChanged() {
        if (this.visible) {
            if (this.schlagwortSearchString != null) {
                const multiSchlagworte = this.schlagwortSearchString.split(' ');
                if (multiSchlagworte.length > 1) {
                    multiSchlagworte.forEach(element => {
                        this.addNewSchlagwort(element);
                    });
                }
                if (this.schlagwortSearchString.trim() != '') {
                    this.getSchlagworte(this.schlagwortSearchString);
                }
            }
        }
    }
}
</script>
