import { MutationTree } from 'vuex';
import { IProfilState } from './state';
import {
    TeilnehmerApiDto,
    TeilnehmerServiceApiDto,
    ServiceVerrechnungApiDto,
    BelegterSpeicherApiDto,
    BenutzerDetailApiDto,
    TeilnehmerZugriffApiDto,
    TeilnehmerZugriffCreateApiDto,
    TeilnehmerFunktionApiDto
} from '@/api-schema';
import { TeilnehmerBenutzerDto } from '@/types/UserDtos';

export const MAPKUNDEDATA = 'mapKundeData';
export const MAPSERVICEDATA = 'mapServiceData';
export const MAPVERRECHNUNGDATA = 'mapVerrechnungData';
export const MAPBENUTZERDATA = 'mapBenutzerData';
export const MAPBENUTZERDETAILS = 'mapBenutzerDetails';
export const MAPBELEGTERSPEICHER = 'mapBenutzerSpeicher';
export const MAPTEILNEHMERFUNKTIONEN = 'mapTeilnehmerFunktionen';
export const MAPTEILNEHMERZUGRIFFE = 'mapTeilnehmerZugriffe';
export const MAPCREATETEILNEHMERZUGRIFFELIST = 'mapCreateTeilnehmerList';

export const mutations: MutationTree<IProfilState> = {
    [MAPTEILNEHMERZUGRIFFE](state, value: Array<TeilnehmerZugriffApiDto>) {
        state.teilnehmerZugriffe = value;
    },
    [MAPCREATETEILNEHMERZUGRIFFELIST](state, value: Array<TeilnehmerZugriffCreateApiDto>) {
        const mappedValues = [];
        value.forEach(element => {
            const temp = { value: element.teilnehmerId, text: element.teilnehmerName };
            mappedValues.push(temp);
        });
        state.createTeilnehmerList = mappedValues;
    },
    [MAPKUNDEDATA](state, value: TeilnehmerApiDto) {
        state.kundeData = value;
    },
    [MAPSERVICEDATA](state, value: TeilnehmerServiceApiDto) {
        state.serviceData = value;
    },
    [MAPVERRECHNUNGDATA](state, value: ServiceVerrechnungApiDto) {
        state.verrechnungData = value;
    },
    [MAPBELEGTERSPEICHER](state, value: BelegterSpeicherApiDto) {
        state.speicherData = value;
    },
    [MAPBENUTZERDATA](state, value: Array<TeilnehmerBenutzerDto>) {
        state.benutzerData = value;
    },
    [MAPBENUTZERDETAILS](state, value: BenutzerDetailApiDto) {
        state.benutzerDetails = value;
    },
    [MAPTEILNEHMERFUNKTIONEN](state, value: TeilnehmerFunktionApiDto) {
        state.teilnehmerFunktionData = value;
    }
};
