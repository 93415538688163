import { IRootState } from '@/store';
import { ActionTree } from 'vuex';
import { IHomeState } from './state';

import { OFFSET, LOADING, TOTAL_NUMBER_OF_RECORDS, MAPTABLEVALUES, ADDTABLEVALUES, MAPTABS } from './mutations';
import { NAME } from './';
import { NEW_ERRORMESSAGES, CLOSE } from '@/store/modules/headerNotification/actions';

import { homeService } from '../services/HomeService';

import { downloadService } from '@/services/downloadService';
import i18n from '@/plugins/i18n';

export const LOAD_VALUES = 'loadValues';
export const LOAD_TAB = 'loadTabs';
export const DOWNLOAD_RECHNUNG = 'downloadRechnung';
export const OPEN_RECHNUNG = 'openRechnungDokument';

export const actions: ActionTree<IHomeState, IRootState> = {
    [LOAD_VALUES]({ commit, state, dispatch }: any, offset: number) {
        console.debug(NAME + '::' + LOAD_VALUES, offset);

        if (state.loading || (offset !== 0 && offset >= state.totalNumberOfRecords)) {
            return;
        }

        if (state.queryFilter.buchungDatumVon != undefined && state.queryFilter.buchungDatumBis != undefined) {
            const dateFrom = new Date(
                state.queryFilter.buchungDatumVon.substring(6, 10) as any,
                state.queryFilter.buchungDatumVon.substring(3, 5) as any,
                state.queryFilter.buchungDatumVon.substring(0, 2) as any
            );
            const dateTo = new Date(
                state.queryFilter.buchungDatumBis.substring(6, 10) as any,
                state.queryFilter.buchungDatumBis.substring(3, 5) as any,
                state.queryFilter.buchungDatumBis.substring(0, 2) as any
            );
            dispatch('headerNotification/' + CLOSE, {}, { root: true });
            if (dateFrom > dateTo) {
                dispatch(
                    'headerNotification/' + NEW_ERRORMESSAGES,
                    { errorMessages: i18n.t('errormessage.impossible.date') },
                    { root: true }
                );
                return;
            }
        }

        if (state.queryFilter.betragBis != undefined && state.queryFilter.betragVon != undefined) {
            if ((state.queryFilter.betragBis as number) < (state.queryFilter.betragVon as number)) {
                dispatch(
                    'headerNotification/' + NEW_ERRORMESSAGES,
                    { errorMessages: i18n.t('errormessage.impossible.betrag') },
                    { root: true }
                );
                return;
            }
        }

        commit(LOADING, true);
        commit(OFFSET, offset);

        homeService.loadValues(state.queryFilter).then(response => {
            if (!offset || offset < 1) {
                commit(TOTAL_NUMBER_OF_RECORDS, response.totalNumberOfRecords);
                commit(MAPTABLEVALUES, response.entries);
            } else {
                commit(ADDTABLEVALUES, response.entries);
            }
            commit(LOADING, false);
        });
    },
    [LOAD_TAB]({ commit }: any) {
        console.debug(NAME + '::' + LOAD_TAB);

        return homeService.loadTabs().then(response => {
            commit(MAPTABS, response);
        });
    },
    [DOWNLOAD_RECHNUNG]({ commit, state }: any, { id, dokumentName }: any) {
        console.debug(NAME + '::' + DOWNLOAD_RECHNUNG);

        homeService.downloadRechnung(id).then(response => {
            downloadService.downloadFile(response, dokumentName);
        });
    },
    [OPEN_RECHNUNG]({ commit }: any, id: string) {
        console.debug(NAME + '::' + OPEN_RECHNUNG);

        homeService.downloadRechnung(id).then(response => {
            downloadService.openOrDownloadFile(response, 'placeholder', ['application/pdf']);
        });
    }
};
